// Copyright © 2020-2021 HMD Global. All rights reserved.

/**
 * This are permission keys which may later came from the backend with profile request.
 *
 * For now it's hard coded in the frontend from the user's role.
 */
export enum PermissionKey {
  USER_MANAGEMENT = "USER_MANAGEMENT",
  ENTERPRISE_MANAGEMENT = "ENTERPRISE_MANAGEMENT",
  ENTERPRISE_DETAILS = "ENTERPRISE_DETAILS",
  DEVICE_GROUPS = "DEVICE_GROUPS",
  DEVICE_USERS = "DEVICE_USERS",
  VIEW_ONLY = "VIEW_ONLY",
}

export default PermissionKey;
