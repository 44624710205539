// Copyright © 2020 HMD Global. All rights reserved.

import React from "react";
import "./NotificationContainer.scss";
import { NOTIFICATION_CONTAINER_CLASS_NAME } from "../../../constants/classNames";
import MessageService, { MessageServiceDestructor } from "../../../services/MessageService";
import SuccessMessage from "../../common/successMessage/SuccessMessage";
import AlertMessage from "../../common/alertMessage/AlertMessage";
import ErrorMessage from "../../common/errorMessage/ErrorMessage";
import { map, reverse } from "../../../modules/lodash";
import TranslateCallback from "../../../TranslateCallback";
import LogService from "../../../services/LogService";
import { MessageModel } from "../../../services/types/MessageModel";
import { MessageType } from "../../../services/types/MessageType";

const LOG = LogService.createLogger("NotificationContainer");

export interface NotificationContainerProps {
  className?: string | undefined;

  t?: TranslateCallback;
}

export interface NotificationContainerState {
  messages: Readonly<Array<MessageModel>>;
}

export interface ContainerClickCallback {
  (event: any): void;
}

export class NotificationContainer extends React.Component<NotificationContainerProps, NotificationContainerState> {
  static defaultProps: NotificationContainerProps = {
    className: undefined,
  };

  private _addListener: MessageServiceDestructor | undefined;
  private _removeListener: MessageServiceDestructor | undefined;

  constructor(props: NotificationContainerProps) {
    super(props);

    this.state = {
      messages: [],
    };
  }

  componentDidMount() {
    this._addListener = MessageService.on(MessageService.Event.MESSAGE_ADDED, () => {
      LOG.debug("NotificationContainer: Detected message creation. Updating.");

      this._updateMessages();
    });

    this._removeListener = MessageService.on(MessageService.Event.MESSAGE_REMOVED, () => {
      LOG.debug("NotificationContainer: Detected message removal. Updating.");

      this._updateMessages();
    });

    LOG.debug("NotificationContainer: Initializing message container.");

    this._updateMessages();
  }

  componentWillUnmount() {
    if (this._addListener !== undefined) {
      this._addListener();
      this._addListener = undefined;
    }

    if (this._removeListener !== undefined) {
      this._removeListener();
      this._removeListener = undefined;
    }
  }

  render() {
    const messages = reverse(map(this.state.messages, (item: MessageModel) => this._getMessage(item)));

    return <div className={NOTIFICATION_CONTAINER_CLASS_NAME + " " + (this.props.className ?? "")}>{messages}</div>;
  }

  private _updateMessages() {
    this.setState({
      messages: MessageService.getMessages(),
    });
  }

  private _getMessage(message: MessageModel) {
    const t = this.props?.t ?? ((key: string): string => key);

    switch (message.type) {
      case MessageType.SUCCESS:
        return <SuccessMessage t={t} key={message.messageId} className={NOTIFICATION_CONTAINER_CLASS_NAME + "-message"} {...message} />;

      case MessageType.ALERT:
        return <AlertMessage t={t} key={message.messageId} className={NOTIFICATION_CONTAINER_CLASS_NAME + "-message"} {...message} />;

      case MessageType.ERROR:
        return <ErrorMessage t={t} key={message.messageId} className={NOTIFICATION_CONTAINER_CLASS_NAME + "-message"} {...message} />;
    }

    return <div className={NOTIFICATION_CONTAINER_CLASS_NAME + "-message"} />;
  }
}

export default NotificationContainer;
