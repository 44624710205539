// Copyright © 2020 HMD Global. All rights reserved.

import { has, remove } from "../modules/lodash";
import LogService from "./LogService";

const LOG = LogService.createLogger("EventObserver");

export interface EventObject {
  name: string;
}

export interface EventObserverCallback {
  (event: EventObject, ...args: Array<any>): void;
}

export interface EventObserverDestructorCallback {
  (): void;
}

export class EventObserver {
  private readonly _name: string;
  private readonly _callbacks: Record<string, Array<EventObserverCallback>>;

  public constructor(observerName: string) {
    this._name = observerName;
    this._callbacks = {};
  }

  public hasListeners(eventName: string) {
    return has(this._callbacks, eventName) && this._callbacks[eventName].length >= 1;
  }

  public triggerEvent(eventName: string, ...args: Array<any>) {
    if (!has(this._callbacks, eventName)) {
      LOG.warn(`Event "${eventName}" for "${this._name}" triggered -- but nothing listens it!`);
      return;
    }

    const eventData = { name: eventName };

    this._callbacks[eventName].forEach((callback) => {
      try {
        callback(eventData, ...args);
      } catch (err) {
        LOG.error(`Event callback error for event "${eventName}" for "${this._name}": `, err);
      }
    });
  }

  public listenEvent(eventName: string, callback: EventObserverCallback): EventObserverDestructorCallback {
    if (!has(this._callbacks, eventName)) {
      this._callbacks[eventName] = [callback];
    } else {
      this._callbacks[eventName].push(callback);
    }

    return () => {
      if (!has(this._callbacks, eventName)) return;

      remove(this._callbacks[eventName], (c: any) => c === callback);

      if (this._callbacks[eventName].length === 0) {
        delete this._callbacks[eventName];
      }
    };
  }
}

export default EventObserver;
