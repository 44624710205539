// Copyright © 2021 HMD Global. All rights reserved.

import { isBoolean } from "../../modules/lodash";

export interface AutoDismissibleMessage {
  readonly autoDismiss?: boolean;
}

export function isAutoDismissibleMessage(value: any): value is AutoDismissibleMessage {
  return !!value && (value?.autoDismiss === undefined || isBoolean(value?.autoDismiss));
}

export default AutoDismissibleMessage;
