// Copyright © 2021 HMD Global. All rights reserved.
import { v4 as uuidv4 } from "uuid";

export class StringUtils {
  public static stringToBase64(value: string): string {
    return btoa(StringUtils.stringToBinary(value));
  }

  public static stringToBinary(value: string): string {
    const codeUnits = new Uint16Array(value.length);

    for (let i = 0; i < codeUnits.length; i++) {
      codeUnits[i] = value.charCodeAt(i);
    }

    const uintArray = new Uint8Array(codeUnits.buffer);

    return String.fromCharCode(...uintArray);
  }

  public static truncateString(text: string, length: number): string {
    return text.length > length ? text.slice(0, length - 3) + "..." : text;
  }

  public static generateUuid() {
    return uuidv4();
  }
}

export default StringUtils;
