// Copyright © 2020 HMD Global. All rights reserved.

import React from "react";
import "./ModalContainer.scss";
import { MODAL_CONTAINER_CLASS_NAME } from "../../../constants/classNames";
import ModalService, { Modal, ModalServiceDestructor, ModalType } from "../../../services/ModalService";
import LogService from "../../../services/LogService";
import { map } from "../../../modules/lodash";
import styles from "../../../styles/variables.scss";

const LOG = LogService.createLogger("ModalContainer");

export interface ModalContainerProps {
  className?: string | undefined;
}

export interface ModalContainerState {
  modals: Array<Modal>;
}

export interface ContainerClickCallback {
  (event: any): void;
}

export class ModalContainer extends React.Component<ModalContainerProps, ModalContainerState> {
  static defaultProps: ModalContainerProps = {
    className: undefined,
  };

  private readonly _containerClickCallback: ContainerClickCallback;
  private readonly _modalClickCallback: ContainerClickCallback;
  private readonly _modalContentClickCallback: ContainerClickCallback;
  private _addListener: ModalServiceDestructor | undefined;
  private _removeListener: ModalServiceDestructor | undefined;

  constructor(props: ModalContainerProps) {
    super(props);

    this.state = {
      modals: [],
    };

    this._containerClickCallback = this._onContainerClick.bind(this);
    this._modalClickCallback = this._onModalClick.bind(this);
    this._modalContentClickCallback = this._onModalContentClick.bind(this);
  }

  componentDidMount() {
    this._addListener = ModalService.on(ModalService.Event.MODAL_ADDED, () => {
      LOG.debug("Detected new modal. Updating.");

      this._updateModals();
    });

    this._removeListener = ModalService.on(ModalService.Event.MODAL_REMOVED, () => {
      LOG.debug("Detected modal removal. Updating.");

      this._updateModals();
    });

    LOG.debug("Initializing modal container.");

    this._updateModals();
  }

  componentWillUnmount() {
    if (this._addListener !== undefined) {
      this._addListener();
      this._addListener = undefined;
    }

    if (this._removeListener !== undefined) {
      this._removeListener();
      this._removeListener = undefined;
    }
  }

  render() {
    const modals = this.state.modals;

    const modalDivs = map(modals, (modal: Modal, index: number): any => {
      const modalType = modal?.type ?? ModalType.CENTER;

      const isLastModal = modals.length - 1 === index;

      // FIXME: Using index as modal's key might not be good idea
      return (
        <div
          key={"modal:" + modalType + ":" + index}
          onMouseDown={this._modalClickCallback}
          className={
            MODAL_CONTAINER_CLASS_NAME +
            "-modal " +
            " " +
            MODAL_CONTAINER_CLASS_NAME +
            (isLastModal ? "-last-modal" : "-not-last-modal") +
            " " +
            MODAL_CONTAINER_CLASS_NAME +
            "-modal-type-" +
            modalType +
            " " +
            "hmd-theme-" +
            styles.themeInUse
          }
        >
          <ModalContainer.ModalContent modal={modal} click={this._modalContentClickCallback} />
        </div>
      );
    });

    const enabled = !!modals.length;

    return (
      <div
        className={MODAL_CONTAINER_CLASS_NAME + " " + (this.props.className ?? "") + " " + MODAL_CONTAINER_CLASS_NAME + (enabled ? "-enabled" : "-disabled")}
        onMouseDown={this._containerClickCallback}
      >
        {modalDivs}
      </div>
    );
  }

  private _onContainerClick(event: any) {
    // event.preventDefault();
    event.stopPropagation();

    // FIXME: Shouldn't we remove all modals?
    if (this.state.modals) {
      ModalService.removeLatestModal();
    }
  }

  static ModalContent(props: { modal: Modal; click: ContainerClickCallback }): any {
    const component = props.modal?.component ?? undefined;

    if (component === undefined) {
      return <div className={MODAL_CONTAINER_CLASS_NAME + "-modal-content"} onMouseDown={props.click} />;
    }

    if (React.isValidElement(component)) {
      return (
        <div className={MODAL_CONTAINER_CLASS_NAME + "-modal-content"} onMouseDown={props.click}>
          {component}
        </div>
      );
    }

    const Component = component;

    const componentProps = props.modal?.props ?? {};

    return (
      <div className={MODAL_CONTAINER_CLASS_NAME + "-modal-content"} onMouseDown={props.click}>
        <Component {...componentProps} />
      </div>
    );
  }

  private _updateModals() {
    const modals = ModalService.getAllModals();

    if (this.state.modals !== modals) {
      this.setState({
        modals: modals,
      });

      LOG.debug("Updated modals on the screen: ", modals);
    } else {
      LOG.debug("Reference to modals was not changed: ", modals);
    }
  }

  private _onModalClick(event: any) {
    // Do nothing so that container doesn't close

    const { modals } = this.state;

    // event.preventDefault();
    event.stopPropagation();

    LOG.debug("User clicked the modal div", event);

    modals.forEach((modal) => {
      switch (modal.type) {
        case "CENTER":
          ModalService.removeLatestModal();
          break;

        case "SIDE":
          return;

        case "EXCLUSIVE":
          return;

        default:
          return;
      }
    });
  }

  private _onModalContentClick(event: any) {
    // Do nothing so that container doesn't close

    // event.preventDefault();
    event.stopPropagation();

    LOG.debug("User clicked the modal content div", event);
  }
}

export default ModalContainer;
