// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";

import "./ErrorView.scss";
import { ERROR_VIEW_CLASS_NAME } from "../../../constants/classNames";
import ErrorMessage from "../../common/errorMessage/ErrorMessage";
import AppStateService from "../../../services/AppStateService";
import AppState from "../../../services/AppState";
import SessionService from "../../../services/SessionService";
import TranslateCallback from "../../../TranslateCallback";
import { MessageActionCallback } from "../../../services/MessageService";
import { IconType } from "../../common/icon/IconType";

export interface ErrorViewProps {
  className?: string | undefined;

  /**
   * Note! If you supply a translation key, no need to translate it.
   */
  error?: any;

  buttonLabel?: any;

  t?: TranslateCallback;
}

export interface ErrorViewState {}

export class ErrorView extends React.Component<ErrorViewProps, ErrorViewState> {
  private readonly onAction: MessageActionCallback;

  constructor(props: ErrorViewProps) {
    super(props);

    this.state = {};

    this.onAction = this._onAction.bind(this);
  }

  private _onAction() {
    SessionService.clearSession();
    AppStateService.setState(AppState.LOGIN_VIEW);
  }

  render() {
    const t = this.props.t ?? ((key: string): string => key);

    return (
      <div className={ERROR_VIEW_CLASS_NAME + " " + (this.props.className ?? "")}>
        <section className={ERROR_VIEW_CLASS_NAME + "-section"}>
          <ErrorMessage icon={IconType.NOTICE} content={this.props.error} t={t} action={this.onAction} actionLabel={this.props.buttonLabel} enableDismiss={false} />
        </section>
      </div>
    );
  }
}
