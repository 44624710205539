// Copyright © 2020, 2021 HMD Global. All rights reserved.

import LogService from "./LogService";

const LOG = LogService.createLogger("UserRole");

/**
 * **NOTE!**: If you add new system roles here, make sure those are listed at the InviteUserModal if not intended to be
 *            shown to the user and updated to UserRoleUtils.
 */
export enum UserRole {
  UNDEFINED = "UNDEFINED",

  /**
   * HMD admin
   */
  SYSTEM_ADMIN = "SYSTEM_ADMIN",

  /**
   * Organization's admin
   */
  ENTERPRISE_OWNER = "ENTERPRISE_OWNER",

  /**
   * User needs registration
   */
  REGISTRATION_NEEDED = "REGISTRATION_NEEDED",

  /**
   *
   */
  ENTERPRISE_ADMIN = "ENTERPRISE_ADMIN",

  /**
   *
   */
  ENTERPRISE_USER = "ENTERPRISE_USER",

  /**
   *
   */
  OBSERVER = "OBSERVER",

  /**
   *
   */
  TFA_REQUIRED = "TFA_REQUIRED",
}

/**
 * Advanced TypeScript hint check for UserRole enum strings.
 *
 * @param value
 */
export function isUserRole(value: string | undefined): value is UserRole {
  if (value === undefined) return false;

  switch (value) {
    case UserRole.SYSTEM_ADMIN:
    case UserRole.ENTERPRISE_OWNER:
    case UserRole.ENTERPRISE_ADMIN:
    case UserRole.ENTERPRISE_USER:
    case UserRole.REGISTRATION_NEEDED:
    case UserRole.OBSERVER:
      return true;
  }

  return false;
}

export function parseUserRole(value: string | undefined): UserRole {
  // Handle undefined
  if (value === undefined) return UserRole.UNDEFINED;

  // Check backend's values
  switch (value) {
    case "SYSTEM_ADMIN":
      return UserRole.SYSTEM_ADMIN;
    case "ENTERPRISE_OWNER":
      return UserRole.ENTERPRISE_OWNER;
    case "ENTERPRISE_ADMIN":
      return UserRole.ENTERPRISE_ADMIN;
    case "ENTERPRISE_USER":
      return UserRole.ENTERPRISE_USER;
    case "REGISTRATION_NEEDED":
      return UserRole.REGISTRATION_NEEDED;
    case "OBSERVER":
      return UserRole.OBSERVER;
  }

  // Check our own values (although I guess those are same at the moment)
  if (isUserRole(value)) return value;

  // Anything else is probably an error
  LOG.warn("Warning! Unsupported user role: ", value);
  return UserRole.UNDEFINED;
}

export default UserRole;
