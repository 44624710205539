// Copyright © 2021 HMD Global. All rights reserved.

import * as React from "react";
import "./EnterpriseAgreementView.scss";
import Section from "../../common/section/Section";
import TranslateCallback from "../../../TranslateCallback";
import AppStateService from "../../../services/AppStateService";
import AppStateEvent from "../../../services/AppStateEvent";
import AppStateUtils from "../../../services/AppStateUtils";
import { EventObserverDestructorCallback } from "../../../services/EventObserver";
import {
  T_ENTERPRISE_AGREEMENT_VIEW_OBSERVER_DISCLAIMER,
  T_ENTERPRISE_AGREEMENT_VIEW_OBSERVER_TITLE,
  T_ENTERPRISE_AGREEMENT_VIEW_SELECT_ENTERPRISE,
  T_PROFILE_MENU_SELECT_ENTERPRISE,
  T_SETUP_VIEW_SIGN_OUT_LABEL,
} from "../../../translations/translationTokens";
import { ENTERPRISE_AGREEMENT_VIEW_CLASS_NAME } from "../../../constants/classNames";
import ProfileService, { EnterpriseData, ProfileServiceDestructor } from "../../../services/ProfileService";
import NotificationContainer from "../../layout/notificationContainer/NotificationContainer";
import AppState from "../../../services/AppState";
import { EnterpriseAgreementForm } from "../../forms/enterpriseAgreementForm/EnterpriseAgreementForm";
import SelectField, { SelectChangeCallback } from "../../common/selectField/SelectField";
import EnterpriseId from "../../../types/EnterpriseId";
import AuthorizationService from "../../../services/AuthorizationService";
import FrontendAccessKey from "../../../constants/FrontendAccessKey";
import ComponentUtils from "../../ComponentUtils";
import SessionService from "../../../services/SessionService";
import Button, { ButtonClickCallback } from "../../common/button/Button";

export interface EnterpriseAgreementViewState {
  loading: boolean;
  userEnterprises: EnterpriseData[];
  enterpriseId: EnterpriseId | undefined;
}

export interface EnterpriseAgreementViewProps {
  t?: TranslateCallback;
  className?: string | undefined;
}

export class EnterpriseAgreementView extends React.Component<EnterpriseAgreementViewProps, EnterpriseAgreementViewState> {
  static defaultProps: Partial<EnterpriseAgreementViewProps> = {};

  private _appStateListener: EventObserverDestructorCallback | undefined;
  private _enterpriseAgreementBackendListener: ProfileServiceDestructor | undefined;
  private _changeEnterpriseCallback: SelectChangeCallback;
  private _signOutCallback: ButtonClickCallback;

  constructor(props: EnterpriseAgreementViewProps) {
    super(props);

    this.state = {
      loading: false,
      userEnterprises: [],
      enterpriseId: undefined,
    };

    this._appStateListener = undefined;
    this._changeEnterpriseCallback = this._onChangeEnterprise.bind(this);
    this._signOutCallback = this._onSignOut.bind(this);
  }

  componentDidMount() {
    this.setState({
      loading: AppStateUtils.isLoading(AppStateService.getState()),
      userEnterprises: ProfileService.getEnterprises(),
      enterpriseId: ProfileService.getEnterpriseId(),
    });

    this._appStateListener = AppStateService.on(AppStateEvent.CHANGED, () => {
      this.setState({
        loading: AppStateUtils.isLoading(AppStateService.getState()),
      });
    });

    this._enterpriseAgreementBackendListener = ProfileService.on(ProfileService.Event.ENTERPRISE_AGREEMENT_ACCEPTED, () => {
      AppStateService.setState(AppState.LOADING_VIEW);
      ProfileService.refreshProfileDataFromBackend();

      this.setState({
        loading: AppStateUtils.isLoading(AppStateService.getState()),
      });
    });
  }

  componentWillUnmount() {
    if (this._enterpriseAgreementBackendListener !== undefined) {
      this._enterpriseAgreementBackendListener();
      this._enterpriseAgreementBackendListener = undefined;
    }
  }

  render() {
    const t = this.props.t ?? ((key: string) => key);

    const { userEnterprises, enterpriseId } = this.state;

    const viewOnly = AuthorizationService.hasAccess(FrontendAccessKey.VIEW_ONLY);

    return (
      <div className={`${ENTERPRISE_AGREEMENT_VIEW_CLASS_NAME} ${this.props.className ?? ""}`}>
        <Section className={`${ENTERPRISE_AGREEMENT_VIEW_CLASS_NAME}-section`}>
          <div className={`${ENTERPRISE_AGREEMENT_VIEW_CLASS_NAME}-header`}>
            {userEnterprises.length > 1 && (
              <>
                <p className={`${ENTERPRISE_AGREEMENT_VIEW_CLASS_NAME}-select-enterprise-text`}>{t(T_ENTERPRISE_AGREEMENT_VIEW_SELECT_ENTERPRISE)}</p>
                <SelectField
                  className={`${ENTERPRISE_AGREEMENT_VIEW_CLASS_NAME}-select-enterprise`}
                  label={t(T_PROFILE_MENU_SELECT_ENTERPRISE)}
                  value={enterpriseId?.toString()}
                  options={userEnterprises.map((item: EnterpriseData) => ({ value: item.name, label: item.enterpriseDisplayName, data: item }))}
                  change={this._changeEnterpriseCallback}
                  disabledValues={enterpriseId ? [enterpriseId?.toString()] : []}
                />
              </>
            )}
          </div>
          <div className={`${ENTERPRISE_AGREEMENT_VIEW_CLASS_NAME}-content`}>
            {!viewOnly ? (
              <EnterpriseAgreementForm className={`${ENTERPRISE_AGREEMENT_VIEW_CLASS_NAME}-agreement-form`} t={t} loading={this.state.loading} />
            ) : (
              <>
                <h4 className={`${ENTERPRISE_AGREEMENT_VIEW_CLASS_NAME}-title`}>{t(T_ENTERPRISE_AGREEMENT_VIEW_OBSERVER_TITLE)}</h4>
                {ComponentUtils.formatParagraphWithLineBreaks(t(T_ENTERPRISE_AGREEMENT_VIEW_OBSERVER_DISCLAIMER), `${ENTERPRISE_AGREEMENT_VIEW_CLASS_NAME}-disclaimer`)}
                <Button borders={false} className={`${ENTERPRISE_AGREEMENT_VIEW_CLASS_NAME}-sign-out`} click={this._signOutCallback}>
                  {t(T_SETUP_VIEW_SIGN_OUT_LABEL)}
                </Button>
              </>
            )}
            <NotificationContainer className={`${ENTERPRISE_AGREEMENT_VIEW_CLASS_NAME}-notifications`} t={t} />
          </div>
        </Section>
      </div>
    );
  }

  private _onChangeEnterprise(value: any) {
    ProfileService.setSelectedEnterprise(value.data);
    window.location.reload();
  }

  private _onSignOut() {
    SessionService.logout();
  }
}

export default EnterpriseAgreementView;
