// Copyright © 2020 HMD Global. All rights reserved.

import RoutePath from "../constants/RoutePath";
import { reduce, startsWith } from "../modules/lodash";

export class RouteUtils {
  static setRouteParam(route: RoutePath, key: string, value: any): string {
    return route.replace(`/:${key}`, "/" + encodeURIComponent("" + value));
  }

  static parseRouteValue(value: string): string {
    return decodeURIComponent(value);
  }

  static parseQueryParams(value: string): Record<string, string> {
    if (startsWith(value, "?")) {
      value = value.substr(1);
    }

    const parts = value.split("&");

    return reduce(
      parts,
      (data: Record<string, string>, item: string) => {
        if (item.indexOf("=") < 0) {
          data[item] = "";
          return data;
        }

        const itemParts = item.split("=");

        const key: string = itemParts.shift() ?? "";
        const value: string = itemParts.join("=");
        data[key] = value;
        return data;
      },
      {},
    );
  }
}

export default RouteUtils;
