// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";

import "./ShowcaseView.scss";
import { SHOWCASE_VIEW_CLASS_NAME } from "../../../constants/classNames";
import { Showcase } from "../../common/showcase/showcase";
import Presentation from "../../common/presentation/Presentation";
import { PresentationModel } from "../../../services/AdService";
import { map } from "../../../modules/lodash";

export interface ShowcaseViewProps {
  className?: string | undefined;

  presentations: Readonly<Array<PresentationModel>>;
}

export interface ShowcaseViewState {}

export class ShowcaseView extends React.Component<ShowcaseViewProps, ShowcaseViewState> {
  constructor(props: ShowcaseViewProps) {
    super(props);

    this.state = {};
  }

  render() {
    const presentations = map(this.props.presentations, (item: PresentationModel) => {
      return <Presentation key={item.id} className={SHOWCASE_VIEW_CLASS_NAME + "-presentation-section"} {...item} />;
    });

    return <Showcase className={SHOWCASE_VIEW_CLASS_NAME + "-showcase " + (this.props.className ?? "")}>{presentations}</Showcase>;
  }
}
