import { has, isArray, keys, map } from "../modules/lodash";

export type QueryParamsObject = { [key: string]: string | string[] };

export type QueryParamsObjectWithoutArrays = { [key: string]: string };

export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

export class HttpUtils {
  /**
   *
   * @param queryParams
   * @param arrayKeys If the keyword exists in this array, the key param will support multiple values and will be an array.
   */
  static parseQueryParams(queryParams: string, arrayKeys: string[] | undefined = undefined): QueryParamsObject {
    const params: QueryParamsObject = {};

    if (queryParams.length <= 0) {
      return params;
    }

    const pairs = queryParams.split("&");

    map(pairs, (item: string) => {
      const [key, ...values] = item.split("=");

      const parsedKey = decodeURIComponent(key);

      if (arrayKeys && arrayKeys.indexOf(parsedKey) >= 0) {
        if (!has(params, parsedKey)) {
          params[parsedKey] = [];
        }
        (params[parsedKey] as string[]).push(decodeURIComponent(values.join("=")));
      } else {
        params[parsedKey] = decodeURIComponent(values.join("="));
      }
    });

    return params;
  }

  static stringifyQueryParams(params: { [key: string]: string | string[] }): string {
    return map(keys(params), (key: string): string => {
      const value: string | string[] = params[key];

      if (isArray(value)) {
        return map(value, (item: string): string => {
          return `${encodeURIComponent(key)}=${encodeURIComponent(item)}`;
        }).join("&");
      }

      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    }).join("&");
  }

  static toWebSocketUrl(url: string) {
    return url.replace(/(http)(s)?:\/\//, "ws$2://");
  }
}

export default HttpUtils;
