// Copyright © 2020, 2021 HMD Global. All rights reserved.

import UserRole from "./UserRole";
import {
  T_USER_ROLE_ENTERPRISE_ADMIN,
  T_USER_ROLE_ENTERPRISE_OWNER,
  T_USER_ROLE_ENTERPRISE_USER,
  T_USER_ROLE_OBSERVER,
  T_USER_ROLE_SYSTEM_ADMIN,
  T_USER_ROLE_UNDEFINED,
} from "../translations/translationTokens";

export class UserRoleUtils {
  public static getRoleTranslationToken(role: UserRole | undefined): string {
    switch (role) {
      case UserRole.UNDEFINED:
        return T_USER_ROLE_UNDEFINED;

      case UserRole.SYSTEM_ADMIN:
        return T_USER_ROLE_SYSTEM_ADMIN;

      case UserRole.ENTERPRISE_OWNER:
        return T_USER_ROLE_ENTERPRISE_OWNER;

      case UserRole.ENTERPRISE_ADMIN:
        return T_USER_ROLE_ENTERPRISE_ADMIN;

      case UserRole.ENTERPRISE_USER:
        return T_USER_ROLE_ENTERPRISE_USER;

      case UserRole.OBSERVER:
        return T_USER_ROLE_OBSERVER;
    }

    return T_USER_ROLE_UNDEFINED;
  }

  public static getNextUserRole(value: UserRole | undefined) {
    switch (value) {
      case UserRole.SYSTEM_ADMIN:
        return UserRole.UNDEFINED;
      case UserRole.ENTERPRISE_OWNER:
        return UserRole.SYSTEM_ADMIN;
      case UserRole.ENTERPRISE_ADMIN:
        return UserRole.ENTERPRISE_OWNER;
      case UserRole.ENTERPRISE_USER:
        return UserRole.ENTERPRISE_ADMIN;
      case UserRole.OBSERVER:
        return UserRole.ENTERPRISE_USER;
      case UserRole.UNDEFINED:
      case UserRole.REGISTRATION_NEEDED:
      default:
        return UserRole.ENTERPRISE_ADMIN;
    }
  }

  public static getPreviousUserRole(value: UserRole | undefined): UserRole {
    switch (value) {
      case UserRole.SYSTEM_ADMIN:
        return UserRole.ENTERPRISE_OWNER;
      case UserRole.ENTERPRISE_OWNER:
        return UserRole.ENTERPRISE_ADMIN;
      case UserRole.ENTERPRISE_ADMIN:
        return UserRole.ENTERPRISE_USER;
      case UserRole.ENTERPRISE_USER:
        return UserRole.OBSERVER;
      case UserRole.OBSERVER:
      case UserRole.UNDEFINED:
      case UserRole.REGISTRATION_NEEDED:
      default:
        return UserRole.UNDEFINED;
    }
  }
}

export default UserRoleUtils;
