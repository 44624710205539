// Copyright © 2020 HMD Global. All rights reserved.

import "./SuccessMessage.scss";
import { SUCCESS_MESSAGE_CLASS_NAME } from "../../../constants/classNames";
import * as React from "react";
import MessageService, { MessageActionCallback } from "../../../services/MessageService";
import Icon from "../icon/Icon";
import Button, { ButtonClickCallback, ButtonProps } from "../button/Button";
import TranslateCallback from "../../../TranslateCallback";
import { T_SUCCESS_MESSAGE_ACTION_LABEL, T_SUCCESS_MESSAGE_DISMISS } from "../../../translations/translationTokens";
import { isString } from "../../../modules/lodash";
import LogService from "../../../services/LogService";
import { MessageModel } from "../../../services/types/MessageModel";
import { MessageType } from "../../../services/types/MessageType";
import { ButtonType } from "../../../types/ButtonType";
import SuccessMessageModel from "../../../services/types/SuccessMessageModel";
import IconType from "../icon/IconType";
import TranslationParamsObject from "../../../types/TranslationParamsObject";

const LOG = LogService.createLogger("SuccessMessage");

export interface MessageDismissCallback {
  (): void;
}

export interface SuccessMessageProps extends SuccessMessageModel {
  className?: string | undefined;
  t?: TranslateCallback;
  message?: MessageModel;
  enableDismiss?: boolean;
  dismiss?: MessageDismissCallback;
  dismissLabel?: string;
}

export interface SuccessMessageState {}

export class SuccessMessage extends React.Component<SuccessMessageProps, SuccessMessageState> {
  private readonly _dismissCallback: ButtonClickCallback;
  private readonly _actionCallback: ButtonClickCallback;

  static defaultProps: Partial<SuccessMessageProps> = {
    type: MessageType.SUCCESS,
    enableDismiss: true,
  };

  constructor(props: SuccessMessageProps) {
    super(props);

    this.state = {};

    this._dismissCallback = this._onDismiss.bind(this);
    this._actionCallback = this._onAction.bind(this);
  }

  private _getMessageEnableDismiss(): boolean {
    return this.props?.enableDismiss ?? this.props?.message?.enableDismiss ?? true;
  }

  private _getMessageIcon(): IconType | undefined {
    return this.props?.icon ?? this.props?.message?.icon ?? undefined;
  }

  private _getMessageActionCallback(): MessageActionCallback | undefined {
    return this.props?.action ?? this.props?.message?.action ?? undefined;
  }

  private _getMessageId(): number | undefined {
    return this.props?.messageId ?? this.props?.message?.messageId ?? undefined;
  }

  private _getMessageActionLabel(): string {
    return this.props?.actionLabel ?? this.props?.message?.actionLabel ?? T_SUCCESS_MESSAGE_ACTION_LABEL;
  }

  private _getMessageActionButtonType(): ButtonType | undefined {
    return this.props?.actionButtonType ?? this.props?.message?.actionButtonType ?? ButtonType.PRIMARY;
  }

  private _getMessageActionProps(): ButtonProps | undefined {
    return this.props?.actionButtonProps ?? this.props?.message?.actionButtonProps ?? undefined;
  }

  private _getMessageContent(t: TranslateCallback, translationParams: TranslationParamsObject): any {
    const content = this.props?.content ?? this.props?.message?.content ?? undefined;

    if (content === undefined) {
      return this.props.children;
    }

    if (!React.isValidElement(content)) {
      if (isString(content)) {
        return t(content, translationParams);
      } else {
        return "" + content;
      }
    }

    return content ?? null;
  }

  private _getMessageDismissCallback(): MessageDismissCallback | undefined {
    return this.props?.dismiss ?? undefined;
  }

  private _getMessageDismissLabel(): string {
    return this.props?.dismissLabel ?? this.props?.message?.dismissLabel ?? T_SUCCESS_MESSAGE_DISMISS;
  }

  render() {
    const t = this.props.t ?? ((key) => key);

    const translationParams: TranslationParamsObject = this.props?.translationParams ?? this.props?.message?.translationParams ?? {};

    const icon = this._getMessageIcon();

    return (
      <div className={SUCCESS_MESSAGE_CLASS_NAME + " " + (this.props.className ?? "")}>
        {icon ? <Icon className={SUCCESS_MESSAGE_CLASS_NAME + "-icon"} type={icon} /> : null}

        <div className={SUCCESS_MESSAGE_CLASS_NAME + "-content"}>{this._getMessageContent(t, translationParams)}</div>

        {this._getMessageEnableDismiss() ? (
          <Button className={SUCCESS_MESSAGE_CLASS_NAME + "-dismiss"} click={this._dismissCallback} transparent={true} borders={false}>
            {t(this._getMessageDismissLabel(), translationParams)}
          </Button>
        ) : null}

        {this._getMessageActionCallback() ? (
          <Button className={SUCCESS_MESSAGE_CLASS_NAME + "-action"} click={this._actionCallback} borders={false} type={this._getMessageActionButtonType()}>
            {t(this._getMessageActionLabel(), translationParams)}
          </Button>
        ) : null}
      </div>
    );
  }

  private _onAction() {
    const action = this._getMessageActionCallback();

    if (action) {
      try {
        action();
      } catch (err) {
        LOG.error("SuccessMessage: Error: ", err);
      }
    } else if (this.props.message) {
      LOG.warn("SuccessMessage: Warning! No action defined.");
    }
  }

  private _onDismiss() {
    const dismiss = this._getMessageDismissCallback();
    const messageId = this._getMessageId();

    if (dismiss) {
      try {
        dismiss();
      } catch (err) {
        LOG.error("SuccessMessage: Error: ", err);
      }
    } else if (messageId) {
      MessageService.removeMessageById(messageId);
    } else {
      LOG.debug("SuccessMessage: Warning! No dismiss handler defined for message: ", this.props);
    }
  }
}

export default SuccessMessage;
