// Copyright © 2020-2021 HMD Global. All rights reserved.

/**
 * This are frontend's access keys
 */
export enum FrontendAccessKey {
  USER_MANAGEMENT_VIEW = "userManagement.view",
  USER_MANAGEMENT_MODIFY = "userManagement.user.modify",
  USER_MANAGEMENT_RESET = "userManagement.user.reset",
  USER_MANAGEMENT_DELETE = "userManagement.user.delete",
  USER_MANAGEMENT_DEMOTE = "userManagement.user.demote",
  USER_MANAGEMENT_PROMOTE = "userManagement.user.promote",
  USER_MANAGEMENT_ACTIVATE = "userManagement.user.activate",
  USER_MANAGEMENT_DEACTIVATE = "userManagement.user.deactivate",
  ENTERPRISE_VIEW = "enterprise.view",
  ENTERPRISE_MANAGEMENT_VIEW = "enterpriseManagement.view",
  ENTERPRISE_MANAGEMENT_MODIFY = "enterpriseManagement.enterprise.modify",
  ENTERPRISE_MANAGEMENT_DELETE = "enterpriseManagement.enterprise.delete",
  DEVICE_GROUP_VIEW = "deviceGroup.view",
  DEVICE_GROUPS_LIST_VIEW = "deviceGroupsList.view",
  VIEW_ONLY = "viewOnly",
  DEVICE_USER_VIEW = "deviceGroup.view",
  DEVICE_USERS_LIST_VIEW = "deviceGroupsList.view",
}

export default FrontendAccessKey;
