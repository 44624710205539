// Copyright © 2020 HMD Global. All rights reserved.

import AppStateService from "./AppStateService";
import AppState from "./AppState";
import LogService from "./LogService";

const LOG = LogService.createLogger("AppInitializerUtils");

export interface InitializableService {
  initialize(): void;
  getName(): string;
}

/**
 * The base app initialized service.
 *
 * See also AuthorizedAppInitializerService and UnauthorizedAppInitializerService.
 */
export class AppInitializerUtils {
  public static initializeArray(items: Array<InitializableService>) {
    AppStateService.setState(AppState.LOADING_VIEW);

    items.forEach((service) => {
      try {
        service.initialize();
      } catch (err) {
        LOG.error("Exception in ", service.getName(), ".initialize(): ", err);
      }
    });
  }
}

export default AppInitializerUtils;
