// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import TranslateCallback from "../TranslateCallback";
import { forEach } from "../modules/lodash";

export class TranslateUtils {
  public static magicTranslateWithComponents(t: TranslateCallback, translateKey: string, componentParams: Record<string, any>) {
    /**
     * This is a magic string used a a placeholder for ReactJS component (eg. a page number field) in a translation
     * token, which is automatically placed. Eg. user does not need to know it.
     */
    const MAGIC_KEY = "%%";

    const keys = Object.keys(componentParams);

    const translateOptions: Record<string, string> = {};

    forEach(keys, (key: string) => {
      translateOptions[key] = MAGIC_KEY + key + MAGIC_KEY;
    });

    const contentWithMagicKeys = t(translateKey, translateOptions);

    const parts = contentWithMagicKeys.split(MAGIC_KEY).map((item: string, index: number): any => {
      if (index === 0 || index === contentWithMagicKeys.length - 1) {
        if (item) {
          return (
            <span key={`item-${index}`} className={`index-${index}`}>
              {item}
            </span>
          );
        }
        return <React.Fragment key={`item-${index}`} />;
      }

      if (keys.indexOf(item) >= 0) {
        return <React.Fragment key={`item-${index}-${item}`}>{componentParams[item]}</React.Fragment>;
      }

      if (!item) {
        return <React.Fragment key={`item-${index}`} />;
      }

      return (
        <span className={`index-${index}`} key={`item-${index}`}>
          {item}
        </span>
      );
    });

    return <React.Fragment>{parts}</React.Fragment>;
  }
}

export default TranslateUtils;
