// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import "./EmailField.scss";
import { EMAIL_FIELD_CLASS_NAME } from "../../../constants/classNames";
import { HTMLInputChangeCallback, TextFieldChangeCallback, InputValidCallback } from "../textField/TextField";
import Icon from "../icon/Icon";
import { IconType } from "../icon/IconType";
import FieldSeparator from "../fieldSeparator/FieldSeparator";
import { ChangeEvent, RefObject } from "react";
import Field from "../field/Field";
import LabelType from "../label/LabelType";
import Label from "../label/Label";
import LogService from "../../../services/LogService";
import { ValidateUtils } from "../../../services/ValidateUtils";
import UserService from "../../../services/UserService";
import { T_EMAIL_NOT_AVAILABLE_MESSAGE } from "../../../translations/translationTokens";
import { TFunction } from "i18next";

const LOG = LogService.createLogger("EmailField");

export interface EmailFieldProps {
  t?: TFunction;
  className?: string;
  label?: string;
  labelType?: LabelType;
  iconLabel?: string;
  icon?: string;
  enabled?: boolean;
  autoFocus?: boolean;
  borders?: boolean;
  placeholder?: string;
  isValid?: InputValidCallback;
  change?: TextFieldChangeCallback;
  value?: string;
  message?: string;
  validate?: boolean;
  checkAvailable?: boolean;
  checkAvailableCallback?: (available: boolean) => void;
}

export interface EmailFieldState {
  validEmail: boolean;
  available: boolean;
}

export class EmailField extends React.Component<EmailFieldProps, EmailFieldState> {
  private readonly inputRef: RefObject<HTMLInputElement>;
  private readonly inputChangeCallback: HTMLInputChangeCallback;

  static defaultProps: Partial<EmailFieldProps> = {
    labelType: LabelType.DEFAULT,
    placeholder: "",
    autoFocus: false,
    borders: true,
    enabled: true,
    icon: IconType.EMAIL,
    validate: false,
    checkAvailable: false,
  };

  constructor(props: EmailFieldProps) {
    super(props);

    this.state = {
      validEmail: true,
      available: false,
    };

    this.inputRef = React.createRef();

    this.inputChangeCallback = this.onChange.bind(this);

    this._validateEmail = this._validateEmail.bind(this);
  }

  componentDidMount() {
    if (this.inputRef?.current && this.props.autoFocus) {
      this.inputRef.current.focus();
    }
  }

  onChange(event: ChangeEvent<HTMLInputElement>) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (this.props.change) {
      try {
        this.props.change(event.target.value);
      } catch (err) {
        LOG.error("TextField: Change Error: ", err);
      }
    } else {
      LOG.error("TextField: No change callback defined");
    }
  }

  private async _validateEmail() {
    if (!this.props.validate) return;
    if (this.props.value && this.props.checkAvailable) {
      UserService.checkEmailAvailability(this.props.value).then((value) => {
        LOG.info("Setting available value to: ", value);
        this.setState({
          available: value,
          validEmail: !!this.props.value && ValidateUtils.isEmailValid(this.props.value) && value,
        });
        this.props.checkAvailableCallback?.(value);
      });
    } else {
      this.setState({
        available: true,
        validEmail: !!this.props.value && ValidateUtils.isEmailValid(this.props.value),
      });
      this.props.checkAvailableCallback?.(true);
    }
  }

  private _isValid(): boolean {
    if (this.props.isValid) {
      try {
        return this.props.isValid(this.props.value);
      } catch (err) {
        LOG.error("Exception in isValid callback: ", err);
      }
      return false;
    }

    return true;
  }

  render() {
    const t = this.props?.t ?? ((key: string): string => key);
    const isValid = this._isValid();

    const hasIcon = !!this.props.icon && this.props.icon !== IconType.NONE;
    return (
      <Label
        className={
          EMAIL_FIELD_CLASS_NAME +
          " " +
          (this.props.className ?? "") +
          " " +
          EMAIL_FIELD_CLASS_NAME +
          (this.props.enabled ? "-enabled" : "-disabled") +
          " " +
          EMAIL_FIELD_CLASS_NAME +
          (isValid && this.state.validEmail ? "-valid" : "-invalid")
        }
        label={this.props.label}
        type={this.props.labelType}
        field={Field}
        message={this.state.available ? this.props.message : t(T_EMAIL_NOT_AVAILABLE_MESSAGE)}
        hideMessage={this.state.validEmail}
        fieldProps={{
          className: EMAIL_FIELD_CLASS_NAME + "-field",
          borders: this.props.borders,
        }}
      >
        {hasIcon ? (
          <>
            <Icon className={EMAIL_FIELD_CLASS_NAME + "-icon"} label={this.props.iconLabel ?? ""} type={this.props.icon} />
            <FieldSeparator />
          </>
        ) : (
          ""
        )}

        <input
          type="email"
          className={EMAIL_FIELD_CLASS_NAME + "-input"}
          ref={this.inputRef}
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={this.inputChangeCallback}
          onBlur={this._validateEmail}
        />
        {this.state.validEmail}
      </Label>
    );
  }
}

export default EmailField;
