// Copyright © 2020 HMD Global. All rights reserved.

import React, { MouseEvent } from "react";
import "./Button.scss";
import { BUTTON_CLASS_NAME } from "../../../constants/classNames";
import FormService from "../../../services/FormService";
import Loader from "../loader/Loader";
import LogService from "../../../services/LogService";
import { ButtonType, isButtonType } from "../../../types/ButtonType";
import { isArray, isBoolean, isFunction, isObject, isString } from "../../../modules/lodash";

const LOG = LogService.createLogger("Button");

export interface HTMLButtonClickCallback {
  (event: MouseEvent<HTMLButtonElement>): void;
}
export interface MouseEnterCallback {
  (): void;
}
export interface MouseLeaveCallback {
  (): void;
}
export interface MouseOutCallback {
  (): void;
}

/**
 * This is a counter for identifying buttons
 */
let LAST_BUTTON_ID = 0;

export interface ButtonClickCallback {
  (): void;
}

export interface ButtonHoverCallback {
  (isHovering: boolean): void;
}

export interface ButtonProps {
  className?: string | undefined;
  label?: any | undefined;
  click?: ButtonClickCallback;
  type?: ButtonType;
  title?: string;
  enabled?: boolean;
  transparent?: boolean;
  isSubmit?: boolean;
  loading?: boolean;
  borders?: boolean;
  form?: string;
  hoverCallback?: ButtonHoverCallback;
  buttonProps?: Record<string, any>;
}

/**
 * This is a runtime check for isButtonProps.
 *
 * You probably do not need it -- or need to make a subset-interface (see example from AutoDismissibleMessage interface).
 *
 * @param value
 */
export function isButtonProps(value: any): value is ButtonProps {
  return (
    !!value &&
    (value?.className === undefined || isString(value?.className)) &&
    (value?.click === undefined || isFunction(value?.click)) &&
    (value?.type === undefined || isButtonType(value?.type)) &&
    (value?.title === undefined || isString(value?.title)) &&
    (value?.enabled === undefined || isBoolean(value?.enabled)) &&
    (value?.transparent === undefined || isBoolean(value?.transparent)) &&
    (value?.isSubmit === undefined || isBoolean(value?.isSubmit)) &&
    (value?.loading === undefined || isBoolean(value?.loading)) &&
    (value?.borders === undefined || isBoolean(value?.borders)) &&
    (value?.form === undefined || isString(value?.form)) &&
    (value?.hoverCallback === undefined || isFunction(value?.hoverCallback)) &&
    (value?.buttonProps === undefined || (!isArray(value?.buttonProps) && isObject(value?.buttonProps)))
  );
}

export interface ButtonState {}

export class Button extends React.Component<ButtonProps, ButtonState> {
  private readonly _buttonClickCallback: HTMLButtonClickCallback;
  private readonly _onMouseEnterCallback: MouseEnterCallback;
  private readonly _onMouseLeaveCallback: MouseOutCallback;

  private _isHovering: boolean;
  private _delayedCallHovering: any | undefined;
  private readonly _id: number;

  static defaultProps: Partial<ButtonProps> = {
    label: "",
    enabled: true,
    borders: true,
    transparent: false,
    isSubmit: false,
    loading: false,
    type: ButtonType.DEFAULT,
  };

  constructor(props: ButtonProps) {
    super(props);

    LAST_BUTTON_ID += 1;

    this._id = LAST_BUTTON_ID;

    this._isHovering = false;
    this._delayedCallHovering = undefined;

    this.state = {};

    this._buttonClickCallback = this._onClick.bind(this);
    this._onMouseEnterCallback = this._onMouseEnter.bind(this);
    this._onMouseLeaveCallback = this._onMouseLeave.bind(this);
  }

  render() {
    const hasBorders = this.props?.borders ?? true;
    const isTransparent = this.props?.transparent ?? false;
    const isSubmit = this.props?.isSubmit ?? false;
    const isLoading = this.props.loading ?? false;
    const isEnabled = this.props?.enabled ?? true;
    const isDisabled = !isEnabled || isLoading;
    const loader = isLoading ? <Loader visibleTime={0} className={BUTTON_CLASS_NAME + "-loader"} /> : "";

    const buttonProps: {
      ref?: React.RefObject<HTMLButtonElement>;
      onClick?: any;
      title?: any;
      onMouseEnter?: any;
      onMouseLeave?: any;
      onMouseOut?: any;
    } =
      this.props.click || this.props.form
        ? {
            ...this.props.buttonProps,
            onClick: this._buttonClickCallback,
          }
        : {
            ...this.props.buttonProps,
          };

    if (this.props.title) {
      buttonProps.title = this.props.title;
    }

    if (this.props.hoverCallback) {
      buttonProps.onMouseEnter = this._onMouseEnterCallback;
      buttonProps.onMouseLeave = this._onMouseLeaveCallback;
    }

    return (
      <button
        key={"button-" + this._id}
        className={
          BUTTON_CLASS_NAME +
          " " +
          (this.props.className ?? "") +
          " " +
          BUTTON_CLASS_NAME +
          (hasBorders ? "-borders" : "-no-borders") +
          (isTransparent ? " " + BUTTON_CLASS_NAME + "-transparent" : "") +
          " " +
          BUTTON_CLASS_NAME +
          "-type-" +
          this.props.type +
          " " +
          BUTTON_CLASS_NAME +
          (isDisabled ? "-disabled" : "-enabled")
        }
        type={isSubmit ? "submit" : "button"}
        disabled={isDisabled}
        {...buttonProps}
      >
        {this.props.label}
        {this.props.children}
        {loader}
      </button>
    );
  }

  private _callHover(isHovering: boolean) {
    if (this.props.hoverCallback) {
      try {
        this.props.hoverCallback(isHovering);
      } catch (err) {
        LOG.error("_callHover: Error: ", err);
      }
    } else {
      LOG.error("_callHover: No hover callback detected");
    }
  }

  private _delayCallHover() {
    if (this._delayedCallHovering) {
      clearTimeout(this._delayedCallHovering);
    }

    this._delayedCallHovering = setTimeout(() => {
      this._callHover(this._isHovering);
    }, 200);
  }

  private _onMouseEnter() {
    if (!this._isHovering) {
      LOG.debug("_onMouseEnter: Enabling hover");
      this._isHovering = true;
      this._callHover(true);
    } else {
      LOG.debug("_onMouseEnter: Already hovering");
    }
  }

  private _onMouseLeave() {
    if (this._isHovering) {
      LOG.debug("_onMouseLeave: Disabling hover");
      this._isHovering = false;
      this._delayCallHover();
    } else {
      LOG.debug("_onMouseLeave: Already not hovering");
    }
  }

  private _onClick(event: MouseEvent<HTMLButtonElement>) {
    if (this.props.click) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      LOG.debug("Calling click prop", event);

      try {
        this.props.click();
      } catch (err) {
        LOG.error("Error: ", err);
      }
    } else if (this.props.form) {
      if (FormService.hasFormController(this.props.form)) {
        if (event) {
          event.preventDefault();
          event.stopPropagation();
        }

        try {
          LOG.debug("Calling FormService to submit the form: ", this.props.form);
          FormService.submitForm(this.props.form);
        } catch (err) {
          LOG.error("Error: ", err);
        }
      } else {
        LOG.warn("Warning! FormService did not have a form registered: ", this.props.form);
      }
    } else if (this.props.isSubmit) {
      LOG.warn("Warning! Button did not have a click component -- but it was a submit button.");
    } else {
      LOG.warn("Warning! Button did not have a click component.");
    }
  }
}

export default Button;
