// Copyright © 2020 HMD Global. All rights reserved.

import AppState from "./AppState";
import AppStateEvent from "./AppStateEvent";
import AppStateUtils from "./AppStateUtils";
import EventObserver, { EventObserverCallback, EventObserverDestructorCallback } from "./EventObserver";
import LogService from "./LogService";

const LOG = LogService.createLogger("AppStateService");

export class AppStateService {
  public static Event = AppStateEvent;

  private static _observer: EventObserver = new EventObserver("AppStateService");

  private static _state: AppState = AppState.LOGIN_VIEW;

  public static getState(): AppState {
    return this._state;
  }

  public static setState(state: AppState) {
    if (this._state !== state) {
      this._state = state;

      LOG.info("State changed to ", AppStateUtils.getStateName(this._state));

      this._triggerChangeCallbacks();
    } else {
      LOG.debug("State was already as ", AppStateUtils.getStateName(this._state));
    }
  }

  public static on(e: AppStateEvent, callback: EventObserverCallback): EventObserverDestructorCallback {
    return this._observer.listenEvent(e, callback);
  }

  public static isUnauthorizedView(): boolean {
    return AppStateUtils.isLoginView(this.getState());
  }

  public static isAuthorizedView(): boolean {
    return !AppStateUtils.isLoginView(this.getState());
  }

  private static _triggerChangeCallbacks() {
    if (this._observer.hasListeners(AppStateEvent.CHANGED)) {
      this._observer.triggerEvent(AppStateEvent.CHANGED);
    }
  }
}

export default AppStateService;
