// Copyright © 2020, 2021 HMD Global. All rights reserved.

import { isNumber, trim } from "../modules/lodash";
import NodeEnv, { parseNodeEnv } from "./NodeEnv";
import LogLevel, { parseLogLevel } from "../types/LogLevel";

function parseStringValue(value: any): string | undefined {
  if (!value) return undefined;

  value = trim(`${value}`);

  if (!value) return undefined;

  return value;
}

function parseIntegerValue(value: any): number | undefined {
  if (!value) return undefined;

  value = parseInt(trim(`${value}`), 10);

  if (isNaN(value) || !isNumber(value)) return undefined;

  return value;
}

/**
 * Parse ENV value which is a boolean.
 *
 * @param value
 */
function parseBooleanValue(value: string | undefined): boolean | undefined {
  if (!value) return undefined;
  return value.toLowerCase() === "true";
}

export const NODE_ENV: NodeEnv = parseNodeEnv(process?.env?.NODE_ENV);
export const IS_PRODUCTION_BUILD: boolean = NODE_ENV === NodeEnv.PRODUCTION;
export const IS_DEVELOPMENT_BUILD: boolean = NODE_ENV === NodeEnv.DEVELOPMENT;
export const IS_TEST_BUILD: boolean = NODE_ENV === NodeEnv.TEST;

export const DEFAULT_LOG_LEVEL: LogLevel =
  parseLogLevel(parseStringValue(process?.env?.REACT_APP_LOG_LEVEL)) ?? (IS_PRODUCTION_BUILD ? LogLevel.INFO : IS_TEST_BUILD ? LogLevel.NONE : LogLevel.DEBUG);

export const BACKEND_URL: string = parseStringValue(process?.env?.REACT_APP_BACKEND_URL) ?? "";
export const BACKEND_PATH: string = parseStringValue(process?.env?.REACT_APP_BACKEND_PATH) ?? "/api";
export const BACKEND_LOGIN_PATH: string = parseStringValue(process?.env?.REACT_APP_BACKEND_LOGIN_PATH) ?? "/login";
export const BACKEND_LOGOUT_PATH: string = parseStringValue(process?.env?.REACT_APP_BACKEND_LOGOUT_PATH) ?? "/logout";
export const BACKEND_VERSION: string = parseStringValue(process?.env?.REACT_APP_BACKEND_VERSION) ?? "23.11.9";

export const FRONTEND_VERSION: string = parseStringValue(process?.env?.REACT_APP_FRONTEND_VERSION) ?? BACKEND_VERSION;
export const FRONTEND_NEW_FEATURES_ENABLED: boolean = parseBooleanValue(process?.env?.REACT_APP_NEW_FEATURES_ENABLED) ?? IS_DEVELOPMENT_BUILD;
export const FRONTEND_WILDCARD_CONFIG_ENABLED: boolean = parseBooleanValue(process?.env?.REACT_APP_WILDCARD_CONFIG_ENABLED) ?? true;
export const FRONTEND_WORK_PROFILE_ENABLED: boolean = parseBooleanValue(process?.env?.REACT_APP_WORK_PROFILE_ENABLED) ?? true;
export const FRONTEND_USER_MANAGEMENT_ENABLED: boolean = parseBooleanValue(process?.env?.REACT_APP_USER_MANAGEMENT_ENABLED) ?? true;
export const FRONTEND_BULK_ACTIONS_ENABLED: boolean = parseBooleanValue(process?.env?.REACT_APP_BULK_ACTIONS_ENABLED) ?? true;
export const FRONTEND_ENTERPRISE_MANAGEMENT_ENABLED: boolean = parseBooleanValue(process?.env?.REACT_APP_FRONTEND_ENTERPRISE_ENABLED) ?? true;
export const FRONTEND_FILTERING_ENABLED: boolean = parseBooleanValue(process?.env?.REACT_APP_FILTERING_ENABLED) ?? true;
export const FRONTEND_CHINESE_LANG_ENABLED: boolean = parseBooleanValue(process?.env?.REACT_APP_LANG_CHINESE_ENABLED) ?? false;
export const FRONTEND_ZERO_TOUCH_ENABLED: boolean = parseBooleanValue(process?.env?.REACT_APP_ZERO_TOUCH_ENABLED) ?? false;
export const FRONTEND_DEVICE_GROUPS_ENABLED: boolean = parseBooleanValue(process?.env?.REACT_APP_DEVICE_GROUPS_ENABLED) ?? true;
export const FRONTEND_DEVICE_MESSAGING_ENABLED: boolean = parseBooleanValue(process?.env?.REACT_APP_DEVICE_GROUPS_ENABLED) ?? true;
export const FRONTEND_USERS_IMPORT_ENABLED: boolean = parseBooleanValue(process?.env?.REACT_APP_USERS_IMPORT_ENABLED) ?? true;
export const FRONTEND_DEVICE_USERS_ENABLED: boolean = parseBooleanValue(process?.env?.REACT_APP_DEVICE_USERS_ENABLED) ?? true;
export const FRONTEND_LOCATION_HISTORY_ENABLED: boolean = parseBooleanValue(process?.env?.REACT_APP_LOCATION_HISTORY_ENABLED) ?? true;
export const FRONTEND_FREEZE_PERIOD_ENABLED: boolean = parseBooleanValue(process?.env?.REACT_APP_FREEZE_PERIOD_ENABLED) ?? true;
export const FRONTEND_DEVICE_OPERATIONS_LOG_ENABLED: boolean = parseBooleanValue(process?.env?.REACT_APP_DEVICE_EVENTS_LOG_ENABLED) ?? true;
export const FRONTEND_GEOFENCING_ENABLED: boolean = parseBooleanValue(process?.env?.REACT_APP_FRONTEND_GEOFENCING_ENABLED) ?? true;
export const FRONTEND_TOASTS_ENABLED: boolean = parseBooleanValue(process?.env?.REACT_APP_FRONTEND_TOASTS_ENABLED) ?? true;
export const FRONTEND_REMOTE_MANAGEMENT_ENABLED: boolean = parseBooleanValue(process?.env?.REACT_APP_FRONTEND_REMOTE_MANAGEMENT_ENABLED) ?? true;
export const FRONTEND_CONTACT_LIST_ENABLED: boolean = parseBooleanValue(process?.env?.REACT_APP_FRONTEND_CONTACT_LIST_ENABLED) ?? true;

export const FRONTEND_LOG_SERVER_ENABLED: boolean = parseBooleanValue(process?.env?.REACT_APP_LOG_SERVER_ENABLED) ?? true;
export const FRONTEND_LOG_SERVER_URL: string = parseStringValue(process?.env?.REACT_APP_LOG_SERVER_URL) ?? "https://frontend-log-server-fltfv65dfa-lz.a.run.app";

export const FRONTEND_ENABLE_DEBUG_LOG_ON_PRODUCTION: boolean = parseBooleanValue(process?.env?.REACT_APP_ENABLE_DEBUG_LOG_ON_PRODUCTION) ?? false;

/**
 * The time to wait until sending logs to the remote server
 */
export const FRONTEND_LOG_SERVER_SEND_INTERVAL: number = parseIntegerValue(process?.env?.REACT_APP_LOG_SERVER_SEND_INTERVAL) ?? 1000;

/**
 * The time to wait if an error happens
 */
export const FRONTEND_LOG_SERVER_ERROR_INTERVAL: number = parseIntegerValue(process?.env?.REACT_APP_LOG_SERVER_ERROR_INTERVAL) ?? 20000;

/**
 * The maximum size of the frontend's local queue in memory
 */
export const FRONTEND_LOG_SERVER_QUEUE_SIZE: number = parseIntegerValue(process?.env?.REACT_APP_LOG_SERVER_QUEUE_SIZE) ?? 10000;

/**
 * The maximum size of log messages to send in one request to the server
 */
export const FRONTEND_LOG_SERVER_SEND_SIZE: number = parseIntegerValue(process?.env?.REACT_APP_LOG_SERVER_SEND_SIZE) ?? 1000;

/**
 * If true, also the debug level logs are sent to the backend
 */
export const FRONTEND_LOG_SERVER_DEBUG_LEVEL_ENABLED: boolean = parseBooleanValue(process?.env?.REACT_APP_LOG_SERVER_DEBUG_LEVEL_ENABLED) ?? false;
