// Copyright © 2020 HMD Global. All rights reserved.

import HttpMethod from "./HttpMethod";
import HttpRequestType from "./HttpRequestType";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, CancelToken, CancelTokenSource, Cancel } from "axios";
import qs from "querystring";
import SessionService from "./SessionService";
import { endsWith, has, isObject, isString, some } from "../modules/lodash";
import { BACKEND_API_LOGIN_PHRASE } from "../constants/backend";
import LogService from "./LogService";
import RouteService from "./RouteService";
import BackendService from "./BackendService";
import ProfileService from "./ProfileService";
import HttpHeader from "../constants/HttpHeader";

const LOG = LogService.createLogger("HttpService");

export type HttpResponse<T> = AxiosResponse<T>;

export type HttpErrorResponse<T> = AxiosError<T>;

export interface PageableSortObject {
  unsorted?: boolean;
  sorted?: boolean;
  empty?: boolean;
}

export interface Pageable<T> {
  content?: Array<T>;
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: string;
  size?: number;
  sort?: PageableSortObject;
  totalElements?: number;
  totalPages?: number;
}

export enum ResponseObjectActionType {
  IS_OBJECT,
  NOT_OBJECT,
  LOGIN_VIEW,
  ERROR_VIEW,
}

function isMessageObjectInterface(obj: any): obj is { message?: any } {
  return isObject(obj);
}

/**
 * Axios cancel exception is not a real class, but an interface, so we cannot detect it directly.
 *
 * You must check the isCancelled() state from the cancel token.
 *
 * @param value
 */
function implementsAxiosCancelInterface(value: any): value is Cancel {
  return isMessageObjectInterface(value) && isString(value?.message);
}

export class CancelledHttpRequestError extends Error {
  constructor(m: string) {
    super(m);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, CancelledHttpRequestError.prototype);
  }
}

export class HttpRequestCanceller {
  private readonly _source: CancelTokenSource;
  private _cancelled: boolean;

  constructor() {
    this._cancelled = false;
    this._source = axios.CancelToken.source();
  }

  public getToken(): CancelToken {
    return this._source.token;
  }

  public isCancelled(): boolean {
    return this._cancelled;
  }

  public cancel(reason: string) {
    if (!this._cancelled) {
      LOG.debug("HttpRequestCanceller: Cancelling request because of: ", reason);

      this._cancelled = true;

      this._source.cancel(reason);
    } else {
      LOG.debug("HttpRequestCanceller: Request was already cancelled. Reason was: ", reason);
    }
  }

  public static isCancelledError(value: any): boolean {
    return value instanceof CancelledHttpRequestError;
  }
}

export class HttpService {
  public static createCanceller(): HttpRequestCanceller {
    return new HttpRequestCanceller();
  }

  public static requestWithCanceller(
    canceller: HttpRequestCanceller,
    method: HttpMethod,
    url: string,
    data: any = undefined,
    requestType: HttpRequestType = HttpRequestType.JSON,
    customHeaders: Record<string, string> = {},
    responseType: any = "json",
  ) {
    return this._request(canceller, method, url, data, requestType, customHeaders, responseType).then(
      HttpService.onSuccessRequest,
      HttpService.onFailedRequestWithCanceller(canceller),
    );
  }

  /**
   *
   * @param method
   * @param url
   * @param data
   * @param requestType
   * @param customHeaders
   */
  public static request(
    method: HttpMethod,
    url: string,
    data: any = undefined,
    requestType: HttpRequestType = HttpRequestType.JSON,
    customHeaders: Record<string, string> = {},
    responseType: any = "json",
  ): Promise<HttpResponse<any>> {
    return this._request(undefined, method, url, data, requestType, customHeaders, responseType).then(HttpService.onSuccessRequest, HttpService.onFailedRequest);
  }

  /**
   *
   * @param method
   * @param url
   * @param data
   * @param requestType
   * @param customHeaders
   * @param canceller
   */
  private static _request(
    canceller: HttpRequestCanceller | undefined,
    method: HttpMethod,
    url: string,
    data: any = undefined,
    requestType: HttpRequestType = HttpRequestType.JSON,
    customHeaders: Record<string, string> = {},
    responseType: any = "json",
  ): Promise<HttpResponse<any>> {
    LOG.debug("Request started:", method, url, "with", data, "as", requestType);

    if (BackendService.hasCurrentBackend() && !has(customHeaders, HttpHeader.EMM_BACKEND)) {
      customHeaders = {
        ...customHeaders,
        [HttpHeader.EMM_BACKEND]: BackendService.getCurrentBackend(),
      };
    }

    if (ProfileService.hasEnterprise() && !has(customHeaders, HttpHeader.EMM_ENTERPRISE)) {
      customHeaders = {
        ...customHeaders,
        [HttpHeader.EMM_ENTERPRISE]: ProfileService.getEnterpriseId()?.toString() ?? "",
      };
    }

    const config: AxiosRequestConfig = {
      maxRedirects: 0,
      headers: {
        ...customHeaders,
        [HttpHeader.CONTENT_TYPE]: HttpService.getContentType(requestType),
        [HttpHeader.REQUESTED_WITH]: "emm-frontend-ajax",
      },
      validateStatus: (status) => status >= 200 && status < 400,
      responseType,
    };

    if (canceller) {
      config.cancelToken = canceller.getToken();
    }

    if (data) {
      if (requestType === HttpRequestType.URLENCODED) {
        data = qs.stringify(data);
      }
    }

    switch (method) {
      case HttpMethod.GET:
        if (data) {
          config.params = data;
        }
        return axios.get(url, config);

      case HttpMethod.POST:
        return axios.post(url, data, config);

      case HttpMethod.PUT:
        return axios.put(url, data, config);

      case HttpMethod.DELETE:
        if (data) {
          config.data = data;
        }
        return axios.delete(url, config);

      default:
        throw new TypeError("Method is unimplemented!");
    }
  }

  public static getContentType(requestType: HttpRequestType) {
    switch (requestType) {
      case HttpRequestType.JSON:
        return "application/json";
      case HttpRequestType.URLENCODED:
        return "application/x-www-form-urlencoded";
      case HttpRequestType.FILE:
        return "multipart/form-data";
    }
  }

  public static onSuccessRequest(response: HttpResponse<any>): Promise<HttpResponse<any>> | HttpResponse<any> {
    // Temporary workaround
    if (HttpService.isLogoutRequired(response)) {
      LOG.info("[onSuccessRequest] Response received successfully but logout was required: ", response);

      SessionService.logout();

      return Promise.reject(response);
    } else {
      LOG.debug("Response received successfully: ", response);
    }

    return response;
  }

  public static onFailedRequest(err: HttpErrorResponse<any>): Promise<HttpResponse<any>> | HttpResponse<any> {
    const response: HttpResponse<any> | undefined = err.response;

    // Temporary workaround
    if (response && HttpService.isLogoutRequired(response)) {
      LOG.info("[onFailedRequest] Response received with errors and logout was required: ", err, response);

      SessionService.logout();

      return Promise.reject(response);
    } else {
      LOG.debug("Response received with errors: ", err, response);
    }

    return Promise.reject(response);
  }

  public static onFailedRequestWithCanceller(canceller: HttpRequestCanceller): (err: HttpErrorResponse<any>) => Promise<HttpResponse<any>> | HttpResponse<any> {
    return (err: HttpErrorResponse<any>): Promise<HttpResponse<any>> | HttpResponse<any> => {
      if (canceller.isCancelled() && implementsAxiosCancelInterface(err)) {
        return Promise.reject(new CancelledHttpRequestError(err.message));
      }

      const response: HttpResponse<any> | undefined = err.response;

      // Temporary workaround
      if (response && HttpService.isLogoutRequired(response)) {
        LOG.info("[onFailedRequestWithCanceller] Response received with errors and logout was required: ", err, response);

        SessionService.logout();

        return Promise.reject(response);
      } else {
        LOG.debug("Response received with errors: ", err, response);
      }

      return Promise.reject(response);
    };
  }

  public static checkIfResponseUrlHasError(response: any): boolean {
    if (has(response?.headers, HttpHeader.X_LOCATION)) {
      const locationTargetUrl = response?.headers[HttpHeader.X_LOCATION];
      if (locationTargetUrl && locationTargetUrl.indexOf("?error") >= 0) {
        return true;
      }
    }

    const responseUrl = response?.request?.responseURL;

    return !!(responseUrl && responseUrl.indexOf("?error") >= 0);
  }

  public static checkIfResponseUrlHasEndsWithLogin(response: any): boolean {
    if (has(response?.headers, HttpHeader.X_LOCATION)) {
      const locationTargetUrl = response?.headers[HttpHeader.X_LOCATION];
      if (locationTargetUrl && endsWith(locationTargetUrl, "/login")) {
        return true;
      }
    }

    const responseUrl = response?.request?.responseURL;

    return !!responseUrl && endsWith(responseUrl, "/login");
  }

  public static checkIfResponseDataHasLoginPhrase(response: any): boolean {
    if (isString(response?.data)) {
      return !!some(BACKEND_API_LOGIN_PHRASE, (phrase: string) => response.data.indexOf(phrase) >= 0);
    }

    return false;
  }

  public static checkResponseObject(response: any): ResponseObjectActionType {
    if (HttpService.checkIfResponseUrlHasEndsWithLogin(response)) {
      return ResponseObjectActionType.LOGIN_VIEW;
    }

    if (HttpService.checkIfResponseUrlHasError(response)) {
      return ResponseObjectActionType.ERROR_VIEW;
    }

    if (!isObject(response.data)) {
      if (HttpService.checkIfResponseDataHasLoginPhrase(response)) {
        return ResponseObjectActionType.LOGIN_VIEW;
      }

      return ResponseObjectActionType.NOT_OBJECT;
    }

    return ResponseObjectActionType.IS_OBJECT;
  }

  public static verifyResponseObject(response: any): boolean {
    const action = HttpService.checkResponseObject(response);

    switch (action) {
      case ResponseObjectActionType.NOT_OBJECT:
        throw new TypeError("HttpService.verifyResponseObject: Response was not an object: " + response.data);

      case ResponseObjectActionType.LOGIN_VIEW:
        RouteService.setLoginView();
        return true;

      case ResponseObjectActionType.ERROR_VIEW:
        RouteService.setErrorView();
        return true;
    }

    return false;
  }

  protected static isLogoutRequired(response: HttpResponse<any>): boolean {
    const headers = response?.headers ?? undefined;

    // Temporary workaround
    const ajaxLocation = HttpHeader.X_LOCATION;
    const ajaxLocationValue = (headers && headers[ajaxLocation]) ?? "";

    if (ajaxLocationValue.indexOf("login?error=true") >= 0) {
      return true;
    }

    return endsWith(ajaxLocationValue, "/login");
  }
}

export default HttpService;
