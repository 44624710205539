// Copyright © 2020 HMD Global. All rights reserved.

import LocalStorageService from "./LocalStorageService";
import LocalStorageKey from "./LocalStorageKey";

export class JSONLocalStorageService {
  static hasProperty(key: LocalStorageKey): boolean {
    return LocalStorageService.hasProperty(key);
  }

  static setProperty(key: LocalStorageKey, value: any) {
    LocalStorageService.setProperty(key, JSON.stringify(value));
  }

  static getProperty(key: LocalStorageKey): any {
    const value = LocalStorageService.getProperty(key);

    return value ? JSON.parse(value) : undefined;
  }
}

export default JSONLocalStorageService;
