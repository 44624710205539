// Copyright © 2020 HMD Global. All rights reserved.

import { remove } from "../modules/lodash";
import EventObserver, { EventObserverCallback, EventObserverDestructorCallback } from "./EventObserver";
import LogService from "./LogService";
import MessageModel from "./types/MessageModel";
import { isAutoDismissibleMessage } from "./types/AutoDismissibleMessage";

const LOG = LogService.createLogger("MessageService");

const DEFAULT_AUTO_DISMISS_TIME = 4000;

export enum MessageServiceEvent {
  MESSAGE_ADDED = "messageService:messageAdded",
  MESSAGE_REMOVED = "messageService:messageRemoved",
}

export type MessageServiceDestructor = EventObserverDestructorCallback;

export interface MessageActionCallback {
  (): void;
}

export interface MessageDestructor {
  (): void;
}

export class MessageService {
  public static Event = MessageServiceEvent;

  private static _lastMessageId = 0;
  private static _observer: EventObserver = new EventObserver("MessageService");
  private static readonly messages: Array<MessageModel> = [];

  public static hasMessages(): boolean {
    return this.messages.length > 0;
  }

  public static getMessages(): Readonly<Array<MessageModel>> {
    return this.messages;
  }

  public static createMessage(message: MessageModel): MessageDestructor {
    let dismissTimeout: any = undefined;

    const messageId = (this._lastMessageId += 1);

    const internalMessage: MessageModel = {
      ...message,
      messageId: messageId,
    };

    const typeExists = this.messages.find((m) => m.type === message.type);

    if (typeExists) {
      this.removeMessage(typeExists);
    }

    if (!this.messages.find((m) => m.content === message.content)) {
      this.messages.push(internalMessage);
    }

    this._observer.triggerEvent(MessageServiceEvent.MESSAGE_ADDED, internalMessage);

    if (isAutoDismissibleMessage(internalMessage) && !!internalMessage?.autoDismiss) {
      dismissTimeout = setTimeout(() => {
        dismissTimeout = undefined;

        this.removeMessage(internalMessage);
      }, DEFAULT_AUTO_DISMISS_TIME);
    }

    return () => {
      this.removeMessage(internalMessage);

      if (dismissTimeout !== undefined) {
        clearTimeout(dismissTimeout);
      }
    };
  }

  private static removeMessage(message: MessageModel) {
    const removed = remove(this.messages, (item) => item === message);

    if (!removed.length) {
      LOG.warn("Warning! Nothing to remove for message: ", message);
    } else {
      this._observer.triggerEvent(MessageServiceEvent.MESSAGE_REMOVED);
    }
  }

  public static removeMessageById(messageId: number) {
    const removed = remove(this.messages, (item) => item.messageId === messageId);

    if (!removed.length) {
      LOG.warn("Warning! Nothing to remove for message #", messageId);
    } else {
      this._observer.triggerEvent(MessageServiceEvent.MESSAGE_REMOVED);
    }
  }

  public static on(e: MessageServiceEvent, callback: EventObserverCallback): MessageServiceDestructor {
    return this._observer.listenEvent(e, callback);
  }

  public static clearMessages(): void {
    if (this.hasMessages()) {
      const messages = this.getMessages();

      messages.forEach((msg) => {
        this.removeMessage(msg);
      });
    }
  }
}

export default MessageService;
