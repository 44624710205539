// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import "./form.scss";
import { FORM_CLASS_NAME } from "../../../constants/classNames";
import { FormEvent } from "react";
import FormService, { FormName, FormServiceDestructor } from "../../../services/FormService";
import Loader from "../loader/Loader";
import LogService from "../../../services/LogService";

const LOG = LogService.createLogger("Form");

export interface HTMLFormSubmitCallback {
  (event: FormEvent<HTMLFormElement>): void;
}

export interface FormSubmitCallback {
  (name: FormName | undefined): void;
}

export interface FormProps {
  className?: string | undefined;

  title?: any | undefined;

  submit?: FormSubmitCallback;

  name?: FormName;

  loading?: boolean;
}

export interface FormState {}

export class Form extends React.Component<FormProps, FormState> {
  private readonly submitCallback: HTMLFormSubmitCallback;
  private formServiceDestructor: FormServiceDestructor | undefined;

  static defaultProps: FormProps = {
    className: undefined,

    title: undefined,

    loading: false,
  };

  isLoading(): boolean {
    return !!this.props.loading;
  }

  constructor(props: FormProps) {
    super(props);

    this.state = {};

    this.submitCallback = this._onSubmit.bind(this);
    this.formServiceDestructor = undefined;
  }

  componentDidMount() {
    if (this.props.name) {
      this.formServiceDestructor = FormService.registerFormController(this.props.name, this);
    }
  }

  componentWillUnmount() {
    if (this.formServiceDestructor) {
      this.formServiceDestructor();
      this.formServiceDestructor = undefined;
    }
  }

  render() {
    const loader = this.props.loading ? <Loader visibleTime={0} className={FORM_CLASS_NAME + "-loader"} /> : "";

    return (
      <div className={FORM_CLASS_NAME + " " + (this.props.className ?? "")}>
        {this.props.title ? <h3 className={FORM_CLASS_NAME + "-title"}>{this.props.title}</h3> : ""}

        <form className={FORM_CLASS_NAME + "-fields"} onSubmit={this.submitCallback}>
          {this.props.children}
        </form>

        {loader}
      </div>
    );
  }

  _onSubmit(event: FormEvent<HTMLFormElement>) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.submitForm();
  }

  public submitForm() {
    if (this.props.submit) {
      LOG.debug("FORM SUBMIT", this.props.name);
      this.props.submit(this.props.name);
    } else {
      LOG.warn("Warning! Form submit without submit callback: ", this.props.name);
    }
  }
}

export default Form;
