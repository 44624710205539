import LogMessage from "../types/LogMessage";
import { FRONTEND_LOG_SERVER_QUEUE_SIZE } from "../constants/environment";
import { forEach } from "../modules/lodash";
import LogService from "./LogService";

export interface LogRefreshCallback {
  (): void;
}

export class RemoteLogQueue {
  /**
   * This is a local queue which stores log messages until they are sent to the remote server.
   *
   * @private
   */
  private static _logQueue: LogMessage[] = [];

  private static _logRefreshCallback: LogRefreshCallback | undefined = undefined;

  /**
   * RemoteLogService uses this.
   *
   * *NOTE!* Nobody else should touch it.
   *
   * @param callback
   */
  public static setRefreshCallback(callback: LogRefreshCallback) {
    if (RemoteLogQueue._logRefreshCallback !== undefined) {
      LogService.origConsole.warn("[RemoteLogQueue] Warning! Log refresh callback was already defined. This might be an error.");
    }

    RemoteLogQueue._logRefreshCallback = callback;
  }

  public static addMessage(msg: LogMessage) {
    const origConsole = LogService.origConsole;

    try {
      const queue = RemoteLogQueue._logQueue;

      if ((queue?.length ?? 0) >= FRONTEND_LOG_SERVER_QUEUE_SIZE) {
        origConsole.warn("[RemoteLogQueue] Warning! Log queue full. Message not send to the remote log server.");
      } else {
        queue.push(msg);

        if (RemoteLogQueue._logRefreshCallback) {
          try {
            RemoteLogQueue._logRefreshCallback();
          } catch (err) {
            LogService.origConsole.error("[RemoteLogQueue] Error while triggering refesh callback: ", err);
          }
        }
      }
    } catch (err) {
      origConsole.error("[RemoteLogQueue] Error while writing log message to the remote queue: ", err);
    }
  }

  public static isEmpty(): boolean {
    const queue = RemoteLogQueue._logQueue;

    return queue.length === 0;
  }

  public static getMessages(limit: number): LogMessage[] {
    const queue = RemoteLogQueue._logQueue;

    let len = queue?.length ?? 0;

    if (len >= 1) {
      if (len >= limit) {
        len = limit;
      }

      const buffer: LogMessage[] = [];

      let bufferLen: number = buffer?.length ?? 0;
      let queueLen: number = queue?.length ?? 0;

      while (bufferLen <= len || queueLen < 1) {
        const item = queue.shift();

        if (item) {
          buffer.unshift(item);
        } else {
          break;
        }

        queueLen = queue?.length ?? 0;
        bufferLen = buffer?.length ?? 0;
      }

      return buffer;
    }

    return [];
  }

  public static pushBackMessages(items: LogMessage[]) {
    const queue = RemoteLogQueue._logQueue;

    forEach(items, (item: LogMessage) => {
      queue.unshift(item);
    });
  }
}

export default RemoteLogQueue;
