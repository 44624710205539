// Copyright © 2020 HMD Global. All rights reserved.

import { has } from "../modules/lodash";
import * as React from "react";
import TranslateCallback from "../TranslateCallback";
import { FormName } from "../services/FormService";
import LogService from "../services/LogService";

const LOG = LogService.createLogger("ComponentUtils");

export interface ClassNameProps {
  className?: string | undefined;
}

export interface ChildrenProps {
  children?: any;
}

export interface TranslateProps {
  t?: TranslateCallback;
}

export interface FormSubmitCallback<T> {
  (formState: T): void;
}

export interface CommonFormProps<T> {
  name?: FormName;
  submit: FormSubmitCallback<T>;
  error?: any;
  loading?: boolean;
  buttons?: boolean;
}

export class ComponentUtils {
  /**
   *
   * @param props {Object} Properties which may have className
   * @param className The class name to add, or append.
   */
  public static copyWithClassName(props: any, className: string): Record<string, unknown> {
    const copiedProps = { ...props };

    if (has(copiedProps, className)) {
      copiedProps.className = copiedProps.className + " " + className;
    } else {
      copiedProps.className = className;
    }

    return copiedProps;
  }

  /**
   *
   * @param Component
   * @param Props
   */
  public static getAsContent(Component: any, Props: any = undefined): any {
    if (!Component) return undefined;

    if (React.isValidElement(Component)) {
      return Component;
    }

    return <Component {...Props} />;
  }

  public static formatFragmentWithLineBreaks(value: string): any {
    return (
      <>
        {value.split("\n").map((item, idx) => (
          <span key={idx}>
            {item}
            <br />
          </span>
        ))}
      </>
    );
  }

  public static formatParagraphWithLineBreaks(value: string, className: string | undefined = undefined): any {
    return <p className={className ? className : ""}>{ComponentUtils.formatFragmentWithLineBreaks(value)}</p>;
  }

  public static getElementWidthWithoutPadding(el: HTMLElement): number {
    const leftPadding = parseInt(window.getComputedStyle(el, null).getPropertyValue("padding-left"), 10);
    const rightPadding = parseInt(window.getComputedStyle(el, null).getPropertyValue("padding-right"), 10);
    return el.getBoundingClientRect().width - leftPadding - rightPadding;
  }

  public static getElementWidthWithPadding(el: HTMLElement): number {
    const computedStyle = window.getComputedStyle(el, null);

    //LOG.debug('ComponentUtils: el = ', el);
    //LOG.debug('ComponentUtils: el.offsetWidth = ', el.offsetWidth);

    const clientRect = el.getBoundingClientRect();
    //LOG.debug('ComponentUtils: clientRect = ', clientRect);

    const width = clientRect.width;
    //LOG.debug('ComponentUtils: width = ', width);

    const computedPaddingLeft = computedStyle.getPropertyValue("padding-left");
    //LOG.debug('ComponentUtils: computedPaddingLeft ', computedPaddingLeft);

    const computedPaddingRight = computedStyle.getPropertyValue("padding-right");
    //LOG.debug('ComponentUtils: computedPaddingRight ', computedPaddingRight);

    const computedBoxSizing = computedStyle.getPropertyValue("box-sizing");
    const isBorderBox = computedBoxSizing === "border-box";

    //LOG.debug('ComponentUtils: computedBoxSizing ', isBorderBox, computedBoxSizing);

    if (!isBorderBox) {
      const leftPadding = parseInt(computedPaddingLeft, 10);
      //LOG.debug('ComponentUtils: leftPadding = ', leftPadding);

      const rightPadding = parseInt(computedPaddingRight, 10);
      //LOG.debug('ComponentUtils: rightPadding = ', rightPadding);

      return width + leftPadding + rightPadding;
    } else {
      return width;
    }
  }

  public static getElementHeight(el: HTMLElement): number {
    const topPadding = parseInt(window.getComputedStyle(el, null).getPropertyValue("padding-top"), 10);

    const bottomPadding = parseInt(window.getComputedStyle(el, null).getPropertyValue("padding-bottom"), 10);

    return el.getBoundingClientRect().height - topPadding - bottomPadding;
  }

  public static isElementOverflowing(el: HTMLTableElement | HTMLDivElement, parentElement: HTMLTableElement | HTMLDivElement) {
    if (!el.parentElement) {
      LOG.warn("Warning! Could not find parent element");
      return;
    }

    const elemWidth = ComponentUtils.getElementWidthWithPadding(el);
    const parentWidth = ComponentUtils.getElementWidthWithPadding(parentElement);

    //const parentsParentElement = parentElement?.parentElement ?? undefined;
    //const parentsParentWidth = parentsParentElement ? ComponentUtils.getElementWidthWithPadding(parentsParentElement) : undefined;

    //LOG.debug('ComponentUtils: isElementOverflowing: ', el, elemWidth, parentElement, parentWidth, parentsParentElement, parentsParentWidth);

    return elemWidth > parentWidth;
  }

  /**
   * Loop recursively from the element to its parents until a scrolling element is found, otherwise returns `undefined`.
   *
   * @param el
   * @returns The scrollTop value, otherwise `0`.
   */
  public static findScrollingElementRecursively(el: HTMLElement): HTMLElement | undefined {
    const scrollTop = el?.scrollTop ?? 0;
    const scrollLeft = el?.scrollLeft ?? 0;

    if (scrollTop !== 0 || scrollLeft !== 0) {
      return el;
    }

    if (el?.parentElement) {
      return ComponentUtils.findScrollingElementRecursively(el.parentElement);
    }

    return undefined;
  }

  /**
   * Fetch scrollTop recursively from the element until it is found from the element itself or one of its parents.
   *
   * @param el
   * @returns The scrollTop value, otherwise `0`.
   */
  public static getRecursiveScrollTop(el: HTMLElement): number {
    return ComponentUtils.findScrollingElementRecursively(el)?.scrollTop ?? 0;
  }

  /**
   * Fetch scrollLeft recursively from the element until it is found from the element itself or one of its parents.
   *
   * @param el
   * @returns The scrollLeft value, otherwise `0`.
   */
  public static getRecursiveScrollLeft(el: HTMLElement): number {
    return ComponentUtils.findScrollingElementRecursively(el)?.scrollLeft ?? 0;
  }
}

export default ComponentUtils;
