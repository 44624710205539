// Copyright © 2020, 2021 HMD Global. All rights reserved.

import keys from "lodash/keys";
import values from "lodash/values";
import map from "lodash/map";
import reverse from "lodash/reverse";
import concat from "lodash/concat";
import some from "lodash/some";
import every from "lodash/every";
import slice from "lodash/slice";
import filter from "lodash/filter";
import find from "lodash/find";
import sortBy from "lodash/sortBy";
import findIndex from "lodash/findIndex";
import trim from "lodash/trim";
import indexOf from "lodash/indexOf";
import forEach from "lodash/forEach";
import reduce from "lodash/reduce";
import uniq from "lodash/uniq";
import has from "lodash/has";
import get from "lodash/get";
import set from "lodash/set";
import chunk from "lodash/chunk";
import fill from "lodash/fill";
import isEqual from "lodash/isEqual";
import isNumber from "lodash/isNumber";
import isNaN from "lodash/isNaN";
import isObject from "lodash/isObject";
import isNull from "lodash/isNull";
import isPlainObject from "lodash/isPlainObject";
import isFunction from "lodash/isFunction";
import isString from "lodash/isString";
import isArray from "lodash/isArray";
import isBoolean from "lodash/isBoolean";
import isEmpty from "lodash/isEmpty";
import remove from "lodash/remove";
import range from "lodash/range";
import endsWith from "lodash/endsWith";
import startsWith from "lodash/startsWith";
import take from "lodash/take";
import takeRight from "lodash/takeRight";
import clone from "lodash/clone";
import sum from "lodash/sum";

function isDefined(value: any): boolean {
  return value !== undefined;
}

export function isStringOrUndefined(value: any): value is string | undefined {
  return value === undefined || isString(value);
}

export function isNumberOrUndefined(value: any): value is number | undefined {
  return value === undefined || isNumber(value);
}

export function isBooleanOrUndefined(value: any): value is boolean | undefined {
  return value === undefined || isBoolean(value);
}

export function isArrayOrUndefined(value: any): value is any[] | undefined {
  return value === undefined || isArray(value);
}

export function isStringArray(value: any): value is string[] {
  return isArray(value) && every(value, isString);
}

export function isStringArrayOrUndefined(value: any): value is string[] {
  return value === undefined || isStringArray(value);
}

export { keys };
export { values };
export { endsWith };
export { map };
export { reverse };
export { uniq };
export { sortBy };
export { some };
export { every };
export { concat };
export { find };
export { trim };
export { findIndex };
export { indexOf };
export { slice };
export { chunk };
export { fill };
export { range };
export { filter };
export { forEach };
export { reduce };
export { has };
export { get };
export { set };
export { isEqual };
export { isNumber };
export { isNaN };
export { isFunction };
export { isDefined };
export { isNull };
export { isString };
export { isArray };
export { isEmpty };
export { isBoolean };
export { isObject };
export { isPlainObject };
export { remove };
export { startsWith };
export { take };
export { takeRight };
export { clone };
export { sum };
