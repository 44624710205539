// Copyright © 2021 HMD Global. All rights reserved.

export class ValidateUtils {
  public static isEmailValid(emailAddress: string): boolean {
    if (!emailAddress) return false;

    const emailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

    return emailRegex.test(emailAddress);
  }
}
