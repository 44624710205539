// Copyright © 2020 HMD Global. All rights reserved.

import { T_LANG_ENGLISH, T_LANG_FINNISH, T_LANG_FRENCH, T_LANG_CHINESE_SIMPLIFIED, T_LANG_GERMAN, T_LANG_PORTUGUESE, T_LANG_SPANISH } from "../translations/translationTokens";
import EventObserver, { EventObserverCallback, EventObserverDestructorCallback } from "./EventObserver";
import LogService from "./LogService";
import { ENABLED_LANGUAGES } from "../constants/frontend";
import { IS_DEVELOPMENT_BUILD } from "../constants/environment";
import { Language } from "../translations/Language";

const LOG = LogService.createLogger("TranslationService");

export enum TranslationServiceEvent {
  LANGUAGE_CHANGED = "TranslationService:languageChanged",
}

export type TranslationServiceDestructor = EventObserverDestructorCallback;

export class TranslationService {
  private static _missingTranslations: Record<string, string> = {};

  public static Event = TranslationServiceEvent;

  private static _observer: EventObserver = new EventObserver("TranslationService");

  private static _language: Language = Language.UNDEFINED;

  static getAllLanguages(): Array<Language> {
    return ENABLED_LANGUAGES;
  }

  static getLanguageTranslationToken(name: Language): string {
    switch (name) {
      case Language.ENGLISH:
        return T_LANG_ENGLISH;
      case Language.FINNISH:
        return T_LANG_FINNISH;
      case Language.GERMAN:
        return T_LANG_GERMAN;
      case Language.PORTUGUESE:
        return T_LANG_PORTUGUESE;
      case Language.FRENCH:
        return T_LANG_FRENCH;
      case Language.CHINESE:
        return T_LANG_CHINESE_SIMPLIFIED;
      case Language.SPANISH:
        return T_LANG_SPANISH;
    }
    throw new TypeError("Unknown language: " + name);
  }

  static getCurrentLanguage() {
    return this._language;
  }

  static setCurrentLanguage(l: Language) {
    if (l !== this._language) {
      this._language = l;

      this._observer.triggerEvent(TranslationServiceEvent.LANGUAGE_CHANGED);

      LOG.debug("Language set as ", l);
    }
  }

  public static on(e: TranslationServiceEvent, callback: EventObserverCallback): TranslationServiceDestructor {
    return this._observer.listenEvent(e, callback);
  }

  public static parseLanguage(value: string): Language {
    if (value.startsWith("en-") || value === "en") return Language.ENGLISH;
    if (value.startsWith("fi-") || value === "fi") return Language.FINNISH;
    if (value.startsWith("de-") || value === "de") return Language.GERMAN;
    if (value.startsWith("pt-") || value === "pt") return Language.PORTUGUESE;
    if (value.startsWith("fr-") || value === "fr") return Language.FRENCH;
    if (value.startsWith("zh-") || value === "zh") return Language.CHINESE;
    if (value.startsWith("es-") || value === "es") return Language.SPANISH;

    return Language.ENGLISH;
  }

  public static addMissingTranslationKey(key: string) {
    if (IS_DEVELOPMENT_BUILD) {
      this._missingTranslations[key] = key.replaceAll(/^([^.]*\.)+/g, "");
    }
  }

  public static getMissingTranslations() {
    return this._missingTranslations;
  }
}

if (IS_DEVELOPMENT_BUILD) {
  // noinspection TypeScriptUnresolvedVariable
  // @ts-ignore
  window.hmdTranslationService = TranslationService;
}

export default TranslationService;
