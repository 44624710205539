// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import "./LoadingView.scss";
import { LOADING_VIEW_CLASS_NAME } from "../../../constants/classNames";
import Loader from "../../common/loader/Loader";

export interface LoadingViewProps {
  className?: string | undefined;

  error?: any;

  buttonLabel?: any;
}

export interface LoadingViewState {}

export class LoadingView extends React.Component<LoadingViewProps, LoadingViewState> {
  constructor(props: LoadingViewProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className={LOADING_VIEW_CLASS_NAME + " " + (this.props.className ?? "")}>
        <Loader visibleTime={0} />
      </div>
    );
  }
}

export default LoadingView;
