import UserDTO from "./types/UserDTO";
import UserModel from "./types/UserModel";
import { map } from "../modules/lodash";
import EnterpriseId from "../types/EnterpriseId";
import EnterpriseUtils from "./EnterpriseUtils";
import UserStatus, { parseUserStatus } from "./types/UserStatus";
import UserRole from "./UserRole";
import { T_USER_ROLE_PREFIX } from "../translations/translationTokens";
import TranslateCallback from "../TranslateCallback";
import UserRoleUtils from "./UserRoleUtils";

export class UserUtils {
  public static parseToBackendKey(key: string): string {
    return key;
  }

  public static convertBackendModelAsFrontendModel(value: UserDTO): UserModel {
    const userStatus: UserStatus | undefined = parseUserStatus(value?.status);

    let enterprises: EnterpriseId[] | undefined;

    if (value?.enterprises) {
      enterprises = map(value.enterprises, (item: string): EnterpriseId => {
        const obj = EnterpriseUtils.parseEnterpriseIdObject(item);
        if (!obj) throw new TypeError("Response had illegal enterprise ID");
        return obj;
      });
    }

    const { canResetTfa } = value;

    return {
      ...value,
      enterprises: enterprises,
      status: userStatus,
      canResetTfa,
    };
  }

  public static convertFrontendModelAsBackendDTO(value: UserModel): UserDTO {
    let enterprises: string[] | undefined;

    if (value?.enterprises) {
      enterprises = map(value.enterprises, (item: EnterpriseId): string => {
        return item.getGoogleId();
      });
    }

    return {
      ...value,
      enterprises: enterprises,
    };
  }

  public static getUserTranslationParams(t: TranslateCallback, model: UserModel): { [key: string]: string } {
    const userRole: UserRole | undefined = model?.role;

    return {
      FIRST_NAME: model?.firstName ?? "",
      LAST_NAME: model?.lastName ?? "",
      NAME: model?.name ?? "",
      EMAIL: model?.email ?? "",
      PROMOTE_ROLE: t(T_USER_ROLE_PREFIX + "." + UserRoleUtils.getNextUserRole(userRole)),
      DEMOTE_ROLE: t(T_USER_ROLE_PREFIX + "." + UserRoleUtils.getPreviousUserRole(userRole)),
      ROLE: t(T_USER_ROLE_PREFIX + "." + model?.role),
    };
  }
}
