// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import { has, isString } from "../../../modules/lodash";

import "./Icon.scss";

import { ICON_CLASS_NAME } from "../../../constants/classNames";

import { IconImage } from "./IconImage";

export interface IconProps {
  className?: string;
  type?: string;
  label?: string;
}

export interface IconState {}

export class Icon extends React.Component<IconProps, IconState> {
  static defaultProps: Partial<IconProps> = {};

  constructor(props: IconProps) {
    super(props);
    this.state = {};
  }

  render() {
    const type = this.props.type;

    if (!type || !has(IconImage, type)) {
      return <span className={ICON_CLASS_NAME + " " + ICON_CLASS_NAME + "-no-type " + (this.props.className ?? "")} />;
    }

    const iconValue = IconImage[type];

    if (isString(iconValue)) {
      if (!iconValue) {
        return <span className={ICON_CLASS_NAME + " " + ICON_CLASS_NAME + "-no-value " + ICON_CLASS_NAME + `-type-${type} ` + (this.props.className ?? "")} />;
      }

      return (
        <span className={ICON_CLASS_NAME + " " + ICON_CLASS_NAME + "-img " + ICON_CLASS_NAME + `-type-${type} ` + (this.props.className ?? "")}>
          <img className={ICON_CLASS_NAME + "-content"} src={iconValue} alt={this.props.label} />
        </span>
      );
    }

    if (React.isValidElement(iconValue)) {
      return <span className={ICON_CLASS_NAME + " " + ICON_CLASS_NAME + "-element " + ICON_CLASS_NAME + `-type-${type} ` + (this.props.className ?? "")}>{iconValue}</span>;
    }

    const IconValue = iconValue;

    return (
      <span className={ICON_CLASS_NAME + " " + ICON_CLASS_NAME + "-component " + ICON_CLASS_NAME + `-type-${type} ` + (this.props.className ?? "")}>
        <IconValue className={ICON_CLASS_NAME + "-content"} title={this.props.label} />
      </span>
    );
  }
}

export default Icon;
