// Copyright © 2021 HMD Global. All rights reserved.

import * as React from "react";

import "./NotFoundView.scss";
import { NOT_FOUND_VIEW_CLASS_NAME } from "../../../constants/classNames";
import TranslateCallback from "../../../TranslateCallback";
import Icon from "../../common/icon/Icon";
import { T_NOT_FOUND_VIEW_TITLE, T_NOT_FOUND_VIEW_CONTENT } from "../../../translations/translationTokens";
import Loader from "../../common/loader/Loader";

export interface NotFoundViewProps {
  className?: string | undefined;

  t?: TranslateCallback;

  backlink: string;
}

export interface NotFoundViewState {
  initial: boolean;
}

export class NotFoundView extends React.Component<NotFoundViewProps, NotFoundViewState> {
  constructor(props: NotFoundViewProps) {
    super(props);

    this.state = {
      initial: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        initial: false,
      });
    }, 500);
  }

  render() {
    const t = this.props.t ?? ((key: string): string => key);
    const initial = this.state.initial;

    return (
      <div className={`${NOT_FOUND_VIEW_CLASS_NAME} ${this.props.className ?? ""}`}>
        <section className={`${NOT_FOUND_VIEW_CLASS_NAME}-section`}>
          {initial ? (
            <Loader />
          ) : (
            <React.Fragment>
              <div className={`${NOT_FOUND_VIEW_CLASS_NAME}-icon-wrapper`}>
                <Icon className={`${NOT_FOUND_VIEW_CLASS_NAME}-icon`} type="NOTICE" />
              </div>
              <p className={`${NOT_FOUND_VIEW_CLASS_NAME}-section-title`}>{t(T_NOT_FOUND_VIEW_TITLE)}</p>
              <p className={`${NOT_FOUND_VIEW_CLASS_NAME}-section-content`}>{t(T_NOT_FOUND_VIEW_CONTENT)}</p>
              <p className={`${NOT_FOUND_VIEW_CLASS_NAME}-backlink`}>
                <a href="/">{t(this.props.backlink)}</a>
              </p>
            </React.Fragment>
          )}
        </section>
      </div>
    );
  }
}
