// Copyright © 2020 HMD Global. All rights reserved.

export enum LabelType {
  /**
   * The label is hidden.
   */
  HIDDEN = "HIDDEN",

  /**
   * The label will be displayed above the field with a smaller text.
   *
   * The default label type.
   */
  DEFAULT = "DEFAULT",

  /**
   * The label will be displayed next to the field on the left side with a bit bigger font.
   */
  WIDE = "WIDE",
}

export default LabelType;
