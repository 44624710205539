import { isString } from "../../modules/lodash";

/**
 * Our Log-Server knows this DTO as NewLoggerResponseDTO.
 */
export interface NewRemoteLoggerDTO {
  logId: string;
  logToken: string;
}

export function isNewRemoteLoggerDTO(value: any): value is NewRemoteLoggerDTO {
  return !!value && isString(value?.logId) && isString(value?.logToken);
}

export default NewRemoteLoggerDTO;
