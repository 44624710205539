import NetworkService from "./NetworkService";
import MessageService, { MessageDestructor } from "./MessageService";
import { IconType } from "../components/common/icon/IconType";
import { T_NETWORK_CONNECTION_WARNING_SUMMARY, T_NETWORK_CONNECTION_WARNING_TITLE } from "../translations/translationTokens";
import { MessageType } from "./types/MessageType";

export class NetworkMessageService {
  private static _messageDestructor: MessageDestructor | undefined = undefined;

  public static getName(): string {
    return "NetworkMessageService";
  }

  static initialize() {
    if (!NetworkService.hasNetworkConnection()) {
      this._addNetworkConnectionMessage();
    }

    NetworkService.on(NetworkService.Event.CONNECTION_STATE_UPDATED, this._onNetworkConnectionChange.bind(this));
  }

  private static _onNetworkConnectionChange() {
    if (!NetworkService.hasNetworkConnection()) {
      this._addNetworkConnectionMessage();
    } else {
      this._removeNetworkConnectionMessage();
    }
  }

  private static _addNetworkConnectionMessage() {
    if (this._messageDestructor) {
      this._messageDestructor();
    }

    this._messageDestructor = MessageService.createMessage({
      type: MessageType.ERROR,
      icon: IconType.NOTICE,
      content: [T_NETWORK_CONNECTION_WARNING_TITLE, T_NETWORK_CONNECTION_WARNING_SUMMARY],
      enableDismiss: false,
    });
  }

  private static _removeNetworkConnectionMessage() {
    if (this._messageDestructor) {
      this._messageDestructor();
      this._messageDestructor = undefined;
    }
  }
}

export default NetworkMessageService;
