// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import "./LoginView.scss";
import { LoginForm, LoginFormState, LoginFormSubmitCallback } from "../../forms/loginForm/LoginForm";
import SessionService from "../../../services/SessionService";
import { LOGIN_VIEW_CLASS_NAME } from "../../../constants/classNames";
import TranslateCallback from "../../../TranslateCallback";
import { ShowcaseView } from "../showcaseView/ShowcaseView";
import Branding from "../../layout/branding/Branding";
import AppStateService from "../../../services/AppStateService";
import AppStateEvent from "../../../services/AppStateEvent";
import AppStateUtils from "../../../services/AppStateUtils";
import { EventObserverDestructorCallback } from "../../../services/EventObserver";
import NotificationContainer from "../../layout/notificationContainer/NotificationContainer";
import MessageService from "../../../services/MessageService";
import {
  T_FORGOT_PASSWORD_SEND_FORM_TITLE,
  T_LOGIN_SERVICE_LOGIN_ERROR,
  T_LOGIN_VIEW_CANCEL_FORGOT_PASSWORD_BUTTON_LABEL,
  T_LOGIN_VIEW_FORGOT_PASSWORD_BUTTON_LABEL,
  T_LOGIN_VIEW_FORGOT_PASSWORD_SENT_TEXT,
  T_LOGIN_VIEW_FORGOT_PASSWORD_SENT_TITLE,
  T_LOGIN_VIEW_HELP_LINK,
  T_LOGIN_VIEW_PASSWORD_RESET_SUCCESS_MESSAGE_TEXT,
  T_NOT_FOUND_VIEW_UNAUTHORIZED_BACKLINK,
} from "../../../translations/translationTokens";
import AdService, { PresentationModel } from "../../../services/AdService";
import LogService from "../../../services/LogService";
import { TemporaryViewDataProperty, TemporaryViewDataService } from "../../../services/TemporaryViewDataService";
import Button, { ButtonClickCallback } from "../../common/button/Button";
import { ForgotPasswordForm, ForgotPasswordFormState, ForgotPasswordFormSubmitCallback } from "../../forms/forgotPasswordForm/ForgotPasswordForm";
import ProfileService, { ProfileServiceDestructor, ProfileServiceEvent } from "../../../services/ProfileService";
import ComponentUtils from "../../ComponentUtils";
import { ChangePasswordForm, ChangePasswordFormState, ChangePasswordFormSubmitCallback } from "../../forms/changePasswordForm/ChangePasswordForm";
import RouteUtils from "../../../services/RouteUtils";
import { BrowserRouter as Router, Route, useParams } from "react-router-dom";
import RouteService from "../../../services/RouteService";
import RoutePath from "../../../constants/RoutePath";
import Switch from "../../common/switch/Switch";
import { MessageType } from "../../../services/types/MessageType";
import { isString, trim } from "../../../modules/lodash";
import BackendService from "../../../services/BackendService";
import { NotFoundView } from "../notFoundView/NotFoundView";
import BackendUtils from "../../../services/BackendUtils";
import { LOGIN_HELP_KNOWLEDGE_HUB_LINK } from "../../../constants/frontend";
import IconType from "../../common/icon/IconType";

const LOG = LogService.createLogger("LoginView");

/**
 * From a security point of view, saving passwords in the UI is risky, but this was requested so that
 * the user does not have to input their old password instantly after first login to the change password form.
 *
 * It is stored for 60 seconds by default in the client memory.
 *
 * If a password cannot be found, the user has to input their old password again if it is needed to change the password.
 */
const TEMPORARY_PASSWORD_SAVE_TIMEOUT = 60 * 1024;

export interface LoginViewState {
  loading: boolean;
  email: string;
  forgotPasswordSent: boolean;
  presentations: Readonly<Array<PresentationModel>>;
}

export interface LoginViewProps {
  t?: TranslateCallback;
  className?: string | undefined;
}

export class LoginView extends React.Component<LoginViewProps, LoginViewState> {
  static defaultProps: Partial<LoginViewProps> = {};

  private _newPassword: string;
  private readonly _forgotPasswordCallback: ButtonClickCallback;
  private readonly _cancelForgotPasswordCallback: ButtonClickCallback;
  private readonly _cancelResetPasswordCallback: ButtonClickCallback;
  private readonly _submitCallback: LoginFormSubmitCallback;
  private readonly _submitForgotPasswordCallback: ForgotPasswordFormSubmitCallback;
  private readonly _resetPasswordSubmitCallback: ChangePasswordFormSubmitCallback;

  private _forgotPasswordSentListener: ProfileServiceDestructor | undefined;
  private _resetPasswordSuccessListener: ProfileServiceDestructor | undefined;
  private _appStateListener: EventObserverDestructorCallback | undefined;

  constructor(props: LoginViewProps) {
    super(props);

    this._newPassword = "";

    this.state = {
      email: "",
      forgotPasswordSent: false,
      presentations: AdService.getLoginPresentations(),
      loading: AppStateUtils.isLoading(AppStateService.getState()),
    };

    this._appStateListener = undefined;
    this._resetPasswordSuccessListener = undefined;

    this._forgotPasswordCallback = this._onForgotPasswordClick.bind(this);
    this._cancelForgotPasswordCallback = this._onCancelForgotPasswordClick.bind(this);
    this._cancelResetPasswordCallback = this._onCancelResetPasswordClick.bind(this);
    this._submitCallback = this._onLogin.bind(this);
    this._submitForgotPasswordCallback = this._onForgotPasswordSubmit.bind(this);
    this._resetPasswordSubmitCallback = this._onResetPasswordSubmit.bind(this);
  }

  componentDidMount() {
    const presentations = AdService.getLoginPresentations();
    const loading = AppStateUtils.isLoading(AppStateService.getState());

    if (this.state.presentations !== presentations || loading !== this.state.loading) {
      this.setState({
        presentations: presentations,
        loading: loading,
      });
    }

    this._appStateListener = AppStateService.on(AppStateEvent.CHANGED, () => {
      const isLoading = AppStateUtils.isLoading(AppStateService.getState());

      if (this.state.loading !== isLoading || this.state.forgotPasswordSent) {
        this.setState({
          loading: isLoading,
          forgotPasswordSent: false,
        });
      }
    });

    this._resetPasswordSuccessListener = ProfileService.on(ProfileServiceEvent.RESET_PASSWORD_SUCCESS, () => {
      LOG.debug("Successful password reset. Redirecting user to the login page.");

      MessageService.clearMessages();

      MessageService.createMessage({
        type: MessageType.SUCCESS,
        content: T_LOGIN_VIEW_PASSWORD_RESET_SUCCESS_MESSAGE_TEXT,
      });

      this._disableForgotPasswordSentState();
    });
  }

  componentWillUnmount() {
    if (this._appStateListener !== undefined) {
      this._appStateListener();
      this._appStateListener = undefined;
    }

    if (this._forgotPasswordSentListener !== undefined) {
      this._forgotPasswordSentListener();
      this._forgotPasswordSentListener = undefined;
    }
  }

  render() {
    const t = this.props.t ?? ((key: string) => key);

    return (
      <Router>
        <div className={`${LOGIN_VIEW_CLASS_NAME} ${this.props.className ?? ""}`}>
          <div className={LOGIN_VIEW_CLASS_NAME + "-left"}>
            <ShowcaseView presentations={this.state.presentations} />
          </div>

          <div className={LOGIN_VIEW_CLASS_NAME + "-right"}>
            <section className={LOGIN_VIEW_CLASS_NAME + "-section"}>
              <header className={LOGIN_VIEW_CLASS_NAME + "-section-header"}>
                <Branding className={LOGIN_VIEW_CLASS_NAME + "-branding"} t={t} />
              </header>

              <article className={LOGIN_VIEW_CLASS_NAME + "-section-content"}>
                <div className={LOGIN_VIEW_CLASS_NAME + "-content"}>{this._renderContent()}</div>
              </article>

              <NotificationContainer className={LOGIN_VIEW_CLASS_NAME + "-notifications"} t={t} />
            </section>
          </div>
        </div>
      </Router>
    );
  }

  private _renderContent() {
    const t = this.props.t ?? ((key: string) => key);

    return (
      <Switch>
        <Route exact path={RoutePath.UNAUTHORIZED_RESET_PASSWORD}>
          {this._renderResetPasswordPage()}
        </Route>
        <Route exact path={RoutePath.UNAUTHORIZED_FORGOT_PASSWORD}>
          {this._renderForgotPasswordPage()}
        </Route>

        <Route exact path={RoutePath.UNAUTHORIZED_RESET_PASSWORD_WITH_BACKEND}>
          <LoginView.SetupBackendIdFromRouteParams>{this._renderResetPasswordPage()}</LoginView.SetupBackendIdFromRouteParams>
        </Route>

        <Route exact path={RoutePath.UNAUTHORIZED_FORGOT_PASSWORD_WITH_BACKEND}>
          <LoginView.SetupBackendIdFromRouteParams>{this._renderForgotPasswordPage()}</LoginView.SetupBackendIdFromRouteParams>
        </Route>

        <Route exact path={RoutePath.INDEX_WITH_BACKEND}>
          <LoginView.SetupBackendIdFromRouteParams>{this._renderLoginPage()}</LoginView.SetupBackendIdFromRouteParams>
        </Route>

        <Route exact path={RoutePath.INDEX}>
          {this._renderLoginPage()}
        </Route>

        <Route>
          <NotFoundView t={t} backlink={T_NOT_FOUND_VIEW_UNAUTHORIZED_BACKLINK} />
        </Route>
      </Switch>
    );
  }

  private _renderLoginPage(): React.ReactFragment {
    const t = this.props.t ?? ((key: string) => key);

    return (
      <React.Fragment>
        <LoginForm className={LOGIN_VIEW_CLASS_NAME + "-form"} t={t} loading={this.state.loading} submit={this._submitCallback} />

        <Button className={LOGIN_VIEW_CLASS_NAME + "-forgot-password"} borders={false} label={t(T_LOGIN_VIEW_FORGOT_PASSWORD_BUTTON_LABEL)} click={this._forgotPasswordCallback} />
        <a href={LOGIN_HELP_KNOWLEDGE_HUB_LINK} target="_blank" rel="noopener noreferrer" className={LOGIN_VIEW_CLASS_NAME + "-help-link"}>
          <strong>{t(T_LOGIN_VIEW_HELP_LINK)}</strong>
        </a>
      </React.Fragment>
    );
  }

  private _renderResetPasswordPage(): React.ReactFragment {
    const t = this.props.t ?? ((key: string) => key);

    return (
      <React.Fragment>
        <ChangePasswordForm
          className={LOGIN_VIEW_CLASS_NAME + "-reset-password-form"}
          t={t}
          loading={this.state.loading}
          oldPasswordValidationDisabled={true}
          oldPasswordEnabled={false}
          submit={this._resetPasswordSubmitCallback}
        />

        <Button
          className={LOGIN_VIEW_CLASS_NAME + "-forgot-password"}
          borders={false}
          label={t(T_LOGIN_VIEW_CANCEL_FORGOT_PASSWORD_BUTTON_LABEL)}
          click={this._cancelResetPasswordCallback}
        />
      </React.Fragment>
    );
  }

  private _renderForgotPasswordPage(): React.ReactFragment {
    const t = this.props.t ?? ((key: string) => key);

    if (this.state.forgotPasswordSent) {
      return (
        <React.Fragment>
          <h1>{t(T_FORGOT_PASSWORD_SEND_FORM_TITLE)}</h1>
          <h2 className={`${LOGIN_VIEW_CLASS_NAME}-content-title`}>{t(T_LOGIN_VIEW_FORGOT_PASSWORD_SENT_TITLE)}</h2>

          {ComponentUtils.formatParagraphWithLineBreaks(t(T_LOGIN_VIEW_FORGOT_PASSWORD_SENT_TEXT, { EMAIL: this.state.email }))}

          <Button
            className={LOGIN_VIEW_CLASS_NAME + "-forgot-password"}
            borders={false}
            label={t(T_LOGIN_VIEW_CANCEL_FORGOT_PASSWORD_BUTTON_LABEL)}
            click={this._cancelForgotPasswordCallback}
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <ForgotPasswordForm className={LOGIN_VIEW_CLASS_NAME + "-form"} t={t} loading={this.state.loading} submit={this._submitForgotPasswordCallback} />

        <Button
          className={LOGIN_VIEW_CLASS_NAME + "-forgot-password"}
          borders={false}
          label={t(T_LOGIN_VIEW_CANCEL_FORGOT_PASSWORD_BUTTON_LABEL)}
          click={this._cancelForgotPasswordCallback}
        />
      </React.Fragment>
    );
  }

  private _onLogin(formData: LoginFormState) {
    try {
      SessionService.login(formData.email, formData.password);

      TemporaryViewDataService.saveTemporaryViewData(TemporaryViewDataProperty.LOGIN_PASSWORD, formData.password, TEMPORARY_PASSWORD_SAVE_TIMEOUT);
    } catch (err) {
      LOG.error("_onLogin: Exception: ", err);

      MessageService.createMessage({
        type: MessageType.ERROR,
        content: T_LOGIN_SERVICE_LOGIN_ERROR,
        icon: IconType.NOTICE,
      });
    }
  }

  private _onForgotPasswordSubmit(formData: ForgotPasswordFormState) {
    const email = formData.email;

    this.setState({
      loading: true,
      forgotPasswordSent: false,
    });

    ProfileService.forgotPassword(email)
      .then(() => {
        LOG.info(`Successful response for forget password request for ${email}`);

        this.setState({
          email: formData.email,
          forgotPasswordSent: true,
          loading: false,
        });
      })
      .catch((err) => {
        LOG.error(`Error while requesting forget password email for ${email}: `, err);

        this.setState({
          loading: false,
        });
      });
  }

  private _onForgotPasswordClick() {
    this._disableForgotPasswordSentState();

    RouteService.setRouteTarget(RoutePath.UNAUTHORIZED_FORGOT_PASSWORD);
  }

  private _onCancelForgotPasswordClick() {
    this._disableForgotPasswordSentState();

    RouteService.setRouteTarget(RoutePath.INDEX);
  }

  private _onCancelResetPasswordClick() {
    this._disableForgotPasswordSentState();

    RouteService.setRouteTarget(RoutePath.INDEX);
  }

  private _disableForgotPasswordSentState() {
    if (this.state.forgotPasswordSent) {
      this.setState({
        forgotPasswordSent: false,
      });
    }
  }

  private _onResetPasswordSubmit(formData: ChangePasswordFormState) {
    const queryParams = RouteUtils.parseQueryParams(window?.location?.search);

    const token: string = queryParams?.token ?? "";

    LOG.debug("SUBMIT: ", formData.password1, formData.password2, token);

    ProfileService.resetPassword(token, formData.password1);
  }

  public static SetupBackendIdFromRouteParams(props: any): any {
    // @ts-ignore
    let { backendId } = useParams();

    if (backendId !== undefined && isString(backendId) && backendId.length) {
      backendId = trim(backendId);
      if (BackendUtils.isValidBackendId(backendId)) {
        if (backendId !== BackendService.getCurrentBackend()) {
          LOG.debug(`Updating the backend ID as ${backendId} from URL parameters`);
          BackendService.setCurrentBackend(backendId);
        } else {
          LOG.debug("Backend ID was already same: ", backendId);
        }
      } else {
        LOG.debug("Backend ID from the path was not valid: ", backendId);
      }
    } else {
      LOG.debug("Backend ID from the path was not defined: ", backendId);
    }

    return <>{props.children}</>;
  }
}

export default LoginView;
