// Copyright © 2020 HMD Global. All rights reserved.

import AppStateService from "./AppStateService";
import AppState from "./AppState";
import LocalStorageService from "./LocalStorageService";
import LocalStorageKey from "./LocalStorageKey";
import HttpService, { HttpResponse } from "./HttpService";
import HttpMethod from "./HttpMethod";
import HttpRequestType from "./HttpRequestType";
import { ANY_BACKEND_ID, API_LOGIN_URL, API_LOGOUT_URL, API_SIGN_UP_URL, BACKEND_EMM_PROFILE_PATH } from "../constants/backend";
import AppStateUtils from "./AppStateUtils";
import ProfileService from "./ProfileService";
import EventObserver, { EventObserverCallback, EventObserverDestructorCallback } from "./EventObserver";
import JSONLocalStorageService from "./JSONLocalStorageService";
import { has, isString } from "../modules/lodash";
import MessageService from "./MessageService";
import { T_LOGIN_SERVICE_CREDENTIALS_ERROR, T_LOGIN_SERVICE_DEACTIVATED_ERROR, T_LOGIN_SERVICE_LOGIN_ERROR } from "../translations/translationTokens";
import LogService from "./LogService";
import RoutePath from "../constants/RoutePath";
import RouteService, { RouteLocationObject } from "./RouteService";
import BackendService from "./BackendService";
import HttpHeader from "../constants/HttpHeader";
import StringUtils from "./StringUtils";
import { MessageType } from "./types/MessageType";
import WindowService from "./WindowService";
import { FRONTEND_UNAUTHORIZED_BUNDLE_PATH } from "../constants/frontend";
import IconType from "../components/common/icon/IconType";

const LOG = LogService.createLogger("SessionService");

export type SessionServiceDestructor = EventObserverDestructorCallback;

export enum SessionServiceEvent {
  BACKEND_KEY_CHANGED = "SessionService:backendKey:changed",
  SIGN_UP_DATA_CHANGED = "SessionService:signUpData:changed",
}

export interface SignUpUrlObject {
  name: string;
  url: string;
}

export interface SignUpResponseData {
  signupUrl: SignUpUrlObject;
  token: string;
}

export type SignUpHttpResponse = HttpResponse<SignUpResponseData>;

export class SessionService {
  public static Event = SessionServiceEvent;

  private static _observer: EventObserver = new EventObserver("SessionService");
  private static _backend = "";
  private static _signUpData: SignUpResponseData | undefined = undefined;
  private static _setupEnabled = false;

  public static isLoggedIn(): boolean {
    return this._backend !== "" || AppStateService.isAuthorizedView();
  }

  /**
   *
   */
  public static getName(): string {
    return "SessionService";
  }

  /**
   * Called when the app starts
   */
  public static initialize() {
    // FIXME: This initialization probably should be inside BackendService...
    if (LocalStorageService.hasProperty(LocalStorageKey.BACKEND_KEY)) {
      this._backend = BackendService.getCurrentBackend() ?? "";

      LOG.debug("Backend detected as ", this._backend);

      // if ( this._backend && AppStateService.getState() === AppState.LOGIN_VIEW ) {
      //     ProfileService.initializeProfile();
      // }

      this._observer.triggerEvent(SessionServiceEvent.BACKEND_KEY_CHANGED);
    } else {
      LOG.debug("No session key detected");
    }

    if (JSONLocalStorageService.hasProperty(LocalStorageKey.SETUP_ENABLED)) {
      this._setupEnabled = JSONLocalStorageService.getProperty(LocalStorageKey.SETUP_ENABLED);
      LOG.debug("Setting setup enabled as ", this._setupEnabled, " from localStorage");
    }

    LOG.debug(`${this.getName()} initialized.`);
  }

  public static isSetupEnabled(): boolean {
    return this._setupEnabled;
  }

  public static setSetupEnabled(value: boolean) {
    LOG.debug("Setting setup enabled as ", value);

    this._setupEnabled = value;

    JSONLocalStorageService.setProperty(LocalStorageKey.SETUP_ENABLED, value);
  }

  /**
   *
   */
  public static clearSession() {
    ProfileService.clearProfileData();
  }

  /**
   * Called when user wants to log in
   *
   * @param email
   * @param password
   */
  public static login(email: string, password: string) {
    if (!email) throw new TypeError("email is required");
    if (!password) throw new TypeError("Password is required");

    this.loginToBackend(ANY_BACKEND_ID, email, password);
  }

  /**
   * Called when user wants to log in
   *
   * @param backendId
   * @param email
   * @param password
   */
  public static loginToBackend(backendId: string, email: string, password: string) {
    if (!email) throw new TypeError("email is required");
    if (!password) throw new TypeError("Password is required");

    if (backendId) {
      LOG.info(`[loginToBackend] Logging in as user "${email}" using backend "${backendId}"...`);
    } else {
      LOG.info(`[loginToBackend] Logging in as user "${email}" without known backend...`);
    }

    SessionService.clearSession();
    AppStateService.setState(AppState.LOGIN_VIEW_WITH_LOADING);

    const changed = this._setBackendKey(backendId);

    HttpService.request(
      HttpMethod.POST,
      `${API_LOGIN_URL(backendId)}`,
      {
        username: email,
        password: password,
      },
      HttpRequestType.URLENCODED,
      {
        [HttpHeader.EMM_USER]: StringUtils.stringToBase64(email),
        [HttpHeader.EMM_PASSWORD]: StringUtils.stringToBase64(password),
      },
    )
      .then(
        (response) => {
          const responseUrl = response?.request?.responseURL;

          LOG.info("[loginToBackend] Success with responseUrl = ", responseUrl);

          if (isString(response.data)) {
            if (responseUrl && responseUrl.indexOf("?error") >= 0) {
              LOG.info("[loginToBackend] Backend indicated an error. Displaying login error for user.");

              SessionService.clearSession();

              MessageService.createMessage({
                type: MessageType.ERROR,
                content: T_LOGIN_SERVICE_LOGIN_ERROR,
                icon: IconType.NOTICE,
              });

              AppStateService.setState(AppState.LOGIN_VIEW);

              return;
            }
          } else {
            HttpService.verifyResponseObject(response);
          }

          const responseHeaders = response?.headers ?? {};
          LOG.debug(`[loginToBackend] responseHeaders =`, responseHeaders);

          const emmBackend = has(responseHeaders, HttpHeader.EMM_BACKEND) ? responseHeaders[HttpHeader.EMM_BACKEND] : "";

          LOG.info(`Received backend as "${emmBackend}"`);

          const changed2 = this._setBackendKey(emmBackend);

          if (responseUrl.endsWith(BACKEND_EMM_PROFILE_PATH)) {
            LOG.info("[loginToBackend] Loading profile data from the successful login request");
            ProfileService.refreshProfileDataFromPayload(response.data);
          } else {
            LOG.info("[loginToBackend] Triggering profile data request after successful login");
            ProfileService.refreshProfileDataFromBackend();
          }

          if (changed2) {
            LOG.debug("[loginToBackend] Changed backend as ", emmBackend);
            this._observer.triggerEvent(SessionServiceEvent.BACKEND_KEY_CHANGED);
          }

          LOG.debug("[loginToBackend] Moving to INDEX...");
          RouteService.setRouteTarget(RoutePath.INDEX);
        },
        (response) => {
          LOG.error("[loginToBackend] Login failed: ", response);

          const statusCode = response?.status;

          if (statusCode === 401) {
            MessageService.createMessage({
              type: MessageType.ERROR,
              content: T_LOGIN_SERVICE_DEACTIVATED_ERROR,
              icon: IconType.NOTICE,
            });
          } else {
            MessageService.createMessage({
              type: MessageType.ERROR,
              content: T_LOGIN_SERVICE_CREDENTIALS_ERROR,
              icon: IconType.NOTICE,
            });
          }

          AppStateService.setState(AppState.LOGIN_VIEW);
        },
      )
      .catch((error) => {
        LOG.error("[loginToBackend] Internal login error: ", error);

        MessageService.createMessage({
          type: MessageType.ERROR,
          content: T_LOGIN_SERVICE_LOGIN_ERROR,
          icon: IconType.NOTICE,
        });

        AppStateService.setState(AppState.LOGIN_VIEW);
      });

    if (changed) {
      this._observer.triggerEvent(SessionServiceEvent.BACKEND_KEY_CHANGED);
    }
  }

  private static _frontendLogout() {
    LOG.info("Frontend logout initiated.");

    const currentLocation: RouteLocationObject | undefined = RouteService.getRouteLocation();

    const changed = this._unsetBackendKey();

    const currentAppState: AppState = AppStateService.getState();

    if (!AppStateUtils.isLoginView(currentAppState)) {
      LOG.debug("_frontendLogout: Clearing session");
      this.clearSession();

      if (currentLocation) {
        LOG.debug("_frontendLogout: Moving to login bundle");
        WindowService.goToBundle(FRONTEND_UNAUTHORIZED_BUNDLE_PATH);
        WindowService.setLocationHref("/");
      } else {
        LOG.debug("_frontendLogout: Moving to login view");
        AppStateService.setState(AppState.LOGIN_VIEW);
      }
    } else {
      LOG.debug("_frontendLogout: Clearing session");

      this.clearSession();
    }

    if (changed) {
      this._observer.triggerEvent(SessionServiceEvent.BACKEND_KEY_CHANGED);
    }
  }

  /**
   *
   */
  public static logout() {
    const backendId = BackendService.getCurrentBackend();

    if (backendId) {
      LOG.info(`Logout requested: Notifying remote backend "${backendId}"...`);
    } else {
      LOG.info(`Logout requested: Notifying remote backend...`);
    }

    HttpService.request(HttpMethod.POST, `${API_LOGOUT_URL(backendId)}`).finally(() => {
      if (backendId) {
        LOG.info(`Backend "${backendId}" has been notified. Proceeding to logout in frontend.`);
      } else {
        LOG.info(`Backend has been notified. Proceeding to logout in frontend.`);
      }

      this._frontendLogout();
    });
  }

  /**
   *
   * @param key
   */
  private static _setBackendKey(key: string): boolean {
    if (this._backend !== key) {
      LOG.debug(`Backend changed as ${key}`);

      this._backend = key;

      BackendService.setCurrentBackend(key);

      return true;
    }

    return false;
  }

  /**
   *
   */
  private static _unsetBackendKey(): boolean {
    const currentPath = WindowService.getLocationPathname();
    const backendKey = this._backend;

    if (backendKey !== "") {
      // Check if we are on a location which has a backend key
      if (currentPath.startsWith(`/${backendKey}/`) || currentPath === `/${backendKey}`) {
        LOG.debug("Not resetting backend key which is already in the URL:", backendKey, currentPath);
        return false;
      }

      LOG.debug(`Backend key removed: `, backendKey);

      this._backend = "";

      BackendService.setCurrentBackend("");

      return true;
    }

    return false;
  }

  public static getSignUpUrl(): string | undefined {
    LOG.debug("signUpData : ", this._signUpData);

    return this._signUpData?.signupUrl?.url ?? undefined;
  }

  public static refreshSignUpData() {
    const backendId = BackendService.getCurrentBackend();

    LOG.info(`[refreshSignUpData] Requesting sign up data from backend "${backendId}"`);

    HttpService.request(HttpMethod.POST, `${API_SIGN_UP_URL(backendId)}`, {}, HttpRequestType.JSON)
      .then(
        (response: SignUpHttpResponse) => {
          if (HttpService.verifyResponseObject(response)) return;

          LOG.info("[refreshSignUpData] Success: ", response);

          this._signUpData = response.data;

          AppStateService.setState(AppState.SETUP_VIEW);

          // AppStateService.setState(AppState.LOADING_VIEW);
          // ProfileService.refreshProfileData();

          this._observer.triggerEvent(SessionServiceEvent.SIGN_UP_DATA_CHANGED);
        },
        (response) => {
          LOG.error("SIGN UP FAIL: ", response);

          AppStateService.setState(AppState.ERROR_VIEW);
        },
      )
      .catch((error) => {
        LOG.error("[refreshSignUpData] Internal sign up error: ", error);

        AppStateService.setState(AppState.ERROR_VIEW);
      });
  }

  public static on(e: SessionServiceEvent, callback: EventObserverCallback): SessionServiceDestructor {
    return this._observer.listenEvent(e, callback);
  }
}

export default SessionService;
