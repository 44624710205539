// Copyright © 2020 HMD Global. All rights reserved.

import { isNumber } from "../modules/lodash";

/**
 * Note! If you add new codes here, update also function isErrorCode() below.
 */
export enum ErrorCode {
  CREATE_POLICY_FAILED = 10000,
  FETCH_POLICY_FAILED = 10001,
  UPDATE_POLICY_FAILED = 10002,
  DELETE_POLICY_FAILED = 10003,
  DELETE_POLICY_FAILED_REASON_LINKED_TO_RESOURCES = 10004,
  DELETE_POLICY_FAILED_REASON_CANNOT_DELETE_DEFAULT_POLICY = 10005,
  UPDATE_POLICY_CONFLICT = 10006,

  FETCH_DEVICE_FAILED = 10011,
  UPDATE_DEVICE_FAILED = 10012,
  UPDATE_DEVICE_LIST_FAILED = 10013,
  DEVICE_VIEW_UNSPECIFIED_EXCEPTION = 10019,

  CHANGE_PASSWORD_FAILED = 11001,
  FORGOT_PASSWORD_FAILED = 11002,

  FETCH_LOCATION_FAILED = 12001,
  FETCH_LOCATION_LIST_FAILED = 12002,

  INVITE_USER_FAILED = 13001,
  USER_ALREADY_EXISTS = 13002,
  UPDATE_USER_FAILED = 13012,
  PROMOTE_USER_FAILED = 13020,
  DEMOTE_USER_FAILED = 13021,
  ACTIVATE_USER_FAILED = 13022,
  DEACTIVATE_USER_FAILED = 13023,
  DELETE_USER_FAILED = 13024,

  FETCH_ENTERPRISE_FAILED = 14001,
  ENTERPRISE_ADD_FAILED = 14002,
  ENTERPRISE_UPDATE_FAILED = 14003,
  ENTERPRISE_DELETE_FAILED = 14004,

  FETCH_DEVICE_GROUP_FAILED = 15001,
  DEVICE_GROUP_ADD_FAILED = 15002,
  DEVICE_GROUP_UPDATE_FAILED = 15003,
  DEVICE_GROUP_DELETE_FAILED = 15004,
  ADD_DEVICE_TO_GROUP_FAILED = 15005,
  REMOVE_DEVICE_FROM_GROUP_FAILED = 15006,
  ADD_DEVICE_TO_GROUP_FAILED_ASSIGNED_USER = 15007,

  ASSIGN_DEVICE_USER_FAILED = 16001,
  REMOVE_DEVICE_USER_FAILED = 16002,

  POLICY_VIEW_COMPONENT_HAS_NO_POLICY_ID = 20001,
  POLICY_VIEW_COMPONENT_HAS_NO_ENTERPRISE_ID = 20002,
  POLICY_VIEW_UNSPECIFIED_EXCEPTION = 20099,

  FETCH_DASHBOARD_FAILED = 21000,
  UPDATE_DASHBOARD_CONFIG_FAILED = 21001,

  GENERATING_ENROLMENT_TOKEN_FAILED = 30000,
  GENERATING_ENROLMENT_TOKEN_FAILED_USER_MAX_DEVICES = 30111,

  TFA_REGISTER_ERROR = 31000,
  TFA_VERIFY_ERROR = 31001,

  API_ERROR_CODE_GENERIC_ERROR = 50000,
  API_ERROR_CODE_UNKNOWN_ERROR = 50001,

  API_ERROR_CODE_ENTITY_NOT_FOUND = 50010,
  API_ERROR_CODE_ENTITY_ALREADY_EXISTS = 50011,
  API_ERROR_CODE_DATABASE_ERROR = 50012,
  API_ERROR_CODE_JSON_RESPONSE = 50013,
  API_ERROR_CODE_CLIENT_VERSION_NOT_COMPATIBLE = 50014,

  API_ERROR_CODE_BAD_OTP_CODE = 50401,
  API_ERROR_CODE_INVALID_ARGUMENTS = 50402,
  API_ERROR_CODE_FORBIDDEN = 50403,
  API_ERROR_CODE_UNKNOWN_API = 50404,

  API_ERROR_CODE_PASSWORD_TOO_SHORT = 51000,
  API_ERROR_CODE_PASSWORD_TOO_LONG = 51001,
  API_ERROR_CODE_PASSWORD_UPPER_CASE = 51002,
  API_ERROR_CODE_PASSWORD_DIGITS = 51003,
  API_ERROR_CODE_PASSWORD_SPECIALS = 51004,
  API_ERROR_CODE_PASSWORD_DOES_NOT_MATCH = 51005,
  API_ERROR_CODE_PASSWORD_MUST_BE_DIFFERENT = 51006,
  API_ERROR_CODE_OLD_PASSWORD_DOES_NOT_MATCH = 51007,

  API_ERROR_CODE_DEVICE_INVALID_STATE_CHANGE = 52000,
  API_ERROR_CODE_DEVICE_REPLACED_BY_CANNOT_BE_EMPTY = 52001,
  API_ERROR_CODE_DEVICE_REPLACED_BY_MUST_BE_VALID = 52002,
  API_ERROR_CODE_DEVICE_IMEI_MUST_BE_VALID = 52003,
  API_ERROR_CODE_DEVICE_ALREADY_ENROLLED = 52004,
  API_ERROR_CODE_DEVICE_ALREADY_ENROLLED_TO_ANOTHER_PROGRAM = 52005,
  API_ERROR_CODE_CANNOT_CHANGE_OPERATION_MODE_IF_DEVICES = 52006,
  API_ERROR_CODE_CANNOT_OVERRIDE_GROUP_POLICY = 52007,
  API_ERROR_CODE_GROUP_ALREADY_EXISTS = 52010,

  API_ERROR_CODE_POLICY_ALREADY_EXISTS = 53000,
  API_ERROR_CODE_POLICY_DOES_NOT_EXIST = 53001,
  API_ERROR_CODE_POLICY_OUTDATED = 53002,
  API_ERROR_CODE_DEFAULT_POLICY_ALREADY_EXISTS = 53003,
  API_ERROR_CODE_DELETE_POLICY_FAILED_REASON_LINKED_TO_RESOURCES = 53004,
}

export function isErrorCode(value: any): value is ErrorCode {
  switch (value) {
    case ErrorCode.CREATE_POLICY_FAILED:
    case ErrorCode.FETCH_POLICY_FAILED:
    case ErrorCode.UPDATE_POLICY_FAILED:
    case ErrorCode.DELETE_POLICY_FAILED:
    case ErrorCode.DELETE_POLICY_FAILED_REASON_LINKED_TO_RESOURCES:
    case ErrorCode.DELETE_POLICY_FAILED_REASON_CANNOT_DELETE_DEFAULT_POLICY:
    case ErrorCode.UPDATE_POLICY_CONFLICT:
    case ErrorCode.FETCH_DEVICE_FAILED:
    case ErrorCode.UPDATE_DEVICE_FAILED:
    case ErrorCode.UPDATE_DEVICE_LIST_FAILED:
    case ErrorCode.DEVICE_VIEW_UNSPECIFIED_EXCEPTION:
    case ErrorCode.CHANGE_PASSWORD_FAILED:
    case ErrorCode.FORGOT_PASSWORD_FAILED:
    case ErrorCode.FETCH_LOCATION_FAILED:
    case ErrorCode.FETCH_LOCATION_LIST_FAILED:
    case ErrorCode.INVITE_USER_FAILED:
    case ErrorCode.USER_ALREADY_EXISTS:
    case ErrorCode.UPDATE_USER_FAILED:
    case ErrorCode.PROMOTE_USER_FAILED:
    case ErrorCode.DEMOTE_USER_FAILED:
    case ErrorCode.ACTIVATE_USER_FAILED:
    case ErrorCode.DEACTIVATE_USER_FAILED:
    case ErrorCode.DELETE_USER_FAILED:
    case ErrorCode.ENTERPRISE_ADD_FAILED:
    case ErrorCode.ENTERPRISE_DELETE_FAILED:
    case ErrorCode.ENTERPRISE_UPDATE_FAILED:
    case ErrorCode.POLICY_VIEW_COMPONENT_HAS_NO_POLICY_ID:
    case ErrorCode.POLICY_VIEW_COMPONENT_HAS_NO_ENTERPRISE_ID:
    case ErrorCode.POLICY_VIEW_UNSPECIFIED_EXCEPTION:
    case ErrorCode.FETCH_DASHBOARD_FAILED:
    case ErrorCode.UPDATE_DASHBOARD_CONFIG_FAILED:
    case ErrorCode.ASSIGN_DEVICE_USER_FAILED:
    case ErrorCode.REMOVE_DEVICE_USER_FAILED:
    case ErrorCode.API_ERROR_CODE_GENERIC_ERROR:
    case ErrorCode.API_ERROR_CODE_UNKNOWN_ERROR:
    case ErrorCode.API_ERROR_CODE_ENTITY_NOT_FOUND:
    case ErrorCode.API_ERROR_CODE_ENTITY_ALREADY_EXISTS:
    case ErrorCode.API_ERROR_CODE_DATABASE_ERROR:
    case ErrorCode.API_ERROR_CODE_JSON_RESPONSE:
    case ErrorCode.API_ERROR_CODE_CLIENT_VERSION_NOT_COMPATIBLE:
    case ErrorCode.API_ERROR_CODE_BAD_OTP_CODE:
    case ErrorCode.API_ERROR_CODE_INVALID_ARGUMENTS:
    case ErrorCode.API_ERROR_CODE_FORBIDDEN:
    case ErrorCode.API_ERROR_CODE_UNKNOWN_API:
    case ErrorCode.API_ERROR_CODE_PASSWORD_TOO_SHORT:
    case ErrorCode.API_ERROR_CODE_PASSWORD_TOO_LONG:
    case ErrorCode.API_ERROR_CODE_PASSWORD_UPPER_CASE:
    case ErrorCode.API_ERROR_CODE_PASSWORD_DIGITS:
    case ErrorCode.API_ERROR_CODE_PASSWORD_SPECIALS:
    case ErrorCode.API_ERROR_CODE_PASSWORD_DOES_NOT_MATCH:
    case ErrorCode.API_ERROR_CODE_PASSWORD_MUST_BE_DIFFERENT:
    case ErrorCode.API_ERROR_CODE_OLD_PASSWORD_DOES_NOT_MATCH:
    case ErrorCode.API_ERROR_CODE_DEVICE_INVALID_STATE_CHANGE:
    case ErrorCode.API_ERROR_CODE_DEVICE_REPLACED_BY_CANNOT_BE_EMPTY:
    case ErrorCode.API_ERROR_CODE_DEVICE_REPLACED_BY_MUST_BE_VALID:
    case ErrorCode.API_ERROR_CODE_DEVICE_IMEI_MUST_BE_VALID:
    case ErrorCode.API_ERROR_CODE_DEVICE_ALREADY_ENROLLED:
    case ErrorCode.API_ERROR_CODE_DEVICE_ALREADY_ENROLLED_TO_ANOTHER_PROGRAM:
    case ErrorCode.API_ERROR_CODE_CANNOT_CHANGE_OPERATION_MODE_IF_DEVICES:
    case ErrorCode.API_ERROR_CODE_POLICY_ALREADY_EXISTS:
    case ErrorCode.API_ERROR_CODE_POLICY_DOES_NOT_EXIST:
    case ErrorCode.API_ERROR_CODE_POLICY_OUTDATED:
    case ErrorCode.API_ERROR_CODE_DEFAULT_POLICY_ALREADY_EXISTS:
    case ErrorCode.ADD_DEVICE_TO_GROUP_FAILED_ASSIGNED_USER:
    case ErrorCode.API_ERROR_CODE_CANNOT_OVERRIDE_GROUP_POLICY:
    case ErrorCode.API_ERROR_CODE_GROUP_ALREADY_EXISTS:
      return true;

    default:
      return false;
  }
}

export interface ErrorObject {
  readonly code: ErrorCode;

  readonly error: any;
}

export function isErrorObject(value: any): value is ErrorObject {
  return !!value && isErrorCode(value?.code) && !!value?.error;
}

export class ErrorService {
  static isErrorObject(obj: any): obj is ErrorObject {
    return !!(obj && isNumber(obj.code) && !!obj.error);
  }

  static createError(code: ErrorCode, error: any): ErrorObject {
    return {
      code: code,
      error: error,
    };
  }
}

export default ErrorService;
