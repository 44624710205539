// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import "./TextField.scss";
import { ChangeEvent, RefObject } from "react";
import { TEXT_FIELD_CLASS_NAME } from "../../../constants/classNames";
import Icon from "../icon/Icon";
import FieldSeparator from "../fieldSeparator/FieldSeparator";
import Field from "../field/Field";
import Label, { LabelProps } from "../label/Label";
import LabelType from "../label/LabelType";
import { isString } from "../../../modules/lodash";
import LogService from "../../../services/LogService";
import FormUtils, { AutoCompleteInputType, AutoCompleteType } from "../../../services/FormUtils";
import Button, { ButtonClickCallback } from "../button/Button";
import { IconType } from "../icon/IconType";
import { DEFAULT_TEXTFIELD_MAXLENGTH } from "../../../constants/frontend";

const LOG = LogService.createLogger("TextField");

export interface HTMLInputChangeCallback {
  (event: ChangeEvent<HTMLInputElement>): void;
}
export interface HTMLInputFocusCallback {
  (event: ChangeEvent<HTMLInputElement>): void;
}
export interface HTMLInputBlurCallback {
  (event: ChangeEvent<HTMLInputElement>): void;
}

export interface TextFieldChangeCallback {
  (value: string): void;
}

export interface ToggleFocusCallback {
  (value: boolean): void;
}

export interface InputValidCallback {
  (value: string | undefined): boolean;
}

export interface TextFieldProps {
  className?: string | undefined;
  label?: string | undefined;
  labelType?: LabelType | undefined;
  icon?: string | undefined;
  iconLabel?: string | undefined;
  placeholder?: string | undefined;
  isValid?: InputValidCallback;
  change?: TextFieldChangeCallback;
  autoFocus?: boolean;
  clearButton?: boolean;
  autoComplete?: AutoCompleteInputType;
  value?: string;
  enabled?: boolean;
  borders: boolean;
  size?: number;
  maxLength?: number;
  highlight?: boolean;
  message?: string;
  limiter?: boolean;
  name?: string;
  tooltip?: any;
}

export interface TextFieldState {
  value: string;
}

export class TextField extends React.Component<TextFieldProps, TextFieldState> {
  private readonly _inputRef: RefObject<HTMLInputElement>;
  private readonly _inputChangeCallback: HTMLInputChangeCallback;
  private readonly _clearValueCallback: ButtonClickCallback;

  static defaultProps: Partial<TextFieldProps> = {
    labelType: LabelType.DEFAULT,
    autoFocus: false,
    placeholder: "",
    borders: true,
    enabled: true,
    highlight: false,
    autoComplete: AutoCompleteType.DEFAULT,
    limiter: false,
  };

  constructor(props: TextFieldProps) {
    super(props);

    this.state = {
      value: "",
    };

    this._inputRef = React.createRef();

    this._inputChangeCallback = this._onChange.bind(this);
    this._clearValueCallback = this._onClearValue.bind(this);
  }

  componentDidMount() {
    this._updatePropsValue();

    if (this._inputRef?.current && this.props.autoFocus) {
      LOG.debug("Auto focusing on ", this._inputRef.current);
      this._inputRef.current.focus();
    }
  }

  componentDidUpdate(prevProps: Readonly<TextFieldProps>) {
    if (this.props.value !== prevProps.value) {
      this._updatePropsValue();
    }
  }

  render() {
    const isValid = this._isValid();

    const inputProps: { maxLength?: number; autoComplete?: string } = {};
    let limiterLength = DEFAULT_TEXTFIELD_MAXLENGTH;

    if (this.props?.maxLength !== undefined) {
      inputProps.maxLength = this.props.maxLength;
      limiterLength = this.props.maxLength;
    }

    let inputSize = this.props.size ?? (this.props?.value ?? "").length;

    if (inputSize < 3) {
      inputSize = 3;
    }

    const autoCompleteString = FormUtils.parseAutoCompleteString(this.props?.autoComplete);
    if (autoCompleteString) {
      inputProps.autoComplete = autoCompleteString;
    }

    const labelProps: Partial<LabelProps> = {};

    if (this.props.tooltip) {
      labelProps.tooltip = this.props.tooltip;
    }

    return (
      <Label
        className={
          TEXT_FIELD_CLASS_NAME +
          " " +
          TEXT_FIELD_CLASS_NAME +
          (this.props.enabled ? "-enabled" : "-disabled") +
          " " +
          TEXT_FIELD_CLASS_NAME +
          (isValid ? "-valid" : "-invalid") +
          " " +
          (this.props.className ?? "")
        }
        type={this.props.labelType}
        label={this.props.label}
        field={Field}
        message={this.props.message}
        fieldProps={{
          className: TEXT_FIELD_CLASS_NAME + "-field",
          borders: this.props.borders,
          highlight: this.props.highlight,
        }}
        {...labelProps}
      >
        {this.props.limiter && this.state.value.length > limiterLength / 2 ? (
          <div className={`${TEXT_FIELD_CLASS_NAME}-limiter`}>
            <span className={this.state.value.length > limiterLength ? `${TEXT_FIELD_CLASS_NAME}-limiter-over` : `${TEXT_FIELD_CLASS_NAME}-limiter-under`}>
              {this.state.value.length > limiterLength ? `-${this.state.value.length - limiterLength}` : this.state.value.length}
              {` / `}
              {limiterLength}
            </span>
          </div>
        ) : null}

        {this.props.icon && (
          <>
            <Icon className={TEXT_FIELD_CLASS_NAME + "-icon"} label={this.props.iconLabel ?? ""} type={this.props.icon} />
            <FieldSeparator className={TEXT_FIELD_CLASS_NAME + "-separator"} />
          </>
        )}

        <input
          {...inputProps}
          type="text"
          size={inputSize}
          className={TEXT_FIELD_CLASS_NAME + "-input"}
          ref={this._inputRef}
          placeholder={this.props.placeholder}
          value={this.state.value}
          onChange={this._inputChangeCallback}
          disabled={!this.props.enabled}
        />

        {this.props.clearButton && this.state.value !== "" ? (
          <>
            <FieldSeparator className={TEXT_FIELD_CLASS_NAME + "-separator"} />
            <Button className={TEXT_FIELD_CLASS_NAME + "-clear-button"} borders={false} click={this._clearValueCallback}>
              <Icon type={IconType.CLOSE} />
            </Button>
          </>
        ) : null}
      </Label>
    );
  }

  private _onChange(event: ChangeEvent<HTMLInputElement>) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const newValue = event?.target?.value ?? undefined;

    if (!isString(newValue)) {
      LOG.warn("TextField: Warning! Event target value was not a string: ", newValue);
      return;
    }

    this._setNewValue(newValue);
  }

  private _onClearValue() {
    this._setNewValue("");
  }

  private _setNewValue(newValue: string) {
    if (this.state.value !== newValue) {
      this.setState({
        value: newValue,
      });
    }

    if (this.props.change) {
      try {
        this.props.change(newValue);
      } catch (err) {
        LOG.error("TextField: Change Error: ", err);
      }
    } else {
      LOG.error("TextField: No change callback defined");
    }
  }

  private _updatePropsValue() {
    const currentValue = this.props.value ?? "";

    if (!isString(currentValue)) {
      LOG.debug("TextField: Warning! Parent value was not a string or undefined: ", currentValue);
      return;
    }

    if (this.state.value !== currentValue) {
      this.setState({
        value: currentValue,
      });
    }
  }

  private _isValid(): boolean {
    if (this.props.isValid) {
      try {
        return this.props.isValid(this.state.value);
      } catch (err) {
        LOG.error("Exception in isValid callback: ", err);
      }
      return false;
    }

    return true;
  }
}

export default TextField;
