// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import "./SignOutModal.scss";
import TranslateCallback from "../../../TranslateCallback";
import { SIGNOUT_MODAL_CLASS_NAME } from "../../../constants/classNames";
import SessionService from "../../../services/SessionService";
import Button, { ButtonClickCallback } from "../../common/button/Button";
import ModalService from "../../../services/ModalService";
import { T_SIGNOUT_MODAL_CANCEL_LABEL, T_SIGNOUT_MODAL_CONTENT, T_SIGNOUT_MODAL_SIGNOUT_LABEL, T_SIGNOUT_MODAL_TITLE } from "../../../translations/translationTokens";
import ComponentUtils from "../../ComponentUtils";
import { ButtonType } from "../../../types/ButtonType";
import LogService from "../../../services/LogService";

const LOG = LogService.createLogger("SignOutModal");

export interface SignOutModalProps {
  t?: TranslateCallback;

  className?: string | undefined;
}

export interface SignOutModalState {}

export class SignOutModal extends React.Component<SignOutModalProps, SignOutModalState> {
  private readonly cancelCallback: ButtonClickCallback;
  private readonly signOutCallback: ButtonClickCallback;

  constructor(props: SignOutModalProps) {
    super(props);

    this.state = {};

    this.signOutCallback = this._onSignOut.bind(this);
    this.cancelCallback = this._onCancel.bind(this);
  }

  render() {
    const t = this.props.t ?? ((key: string) => key);

    return (
      <div className={SIGNOUT_MODAL_CLASS_NAME + " " + (this.props.className ?? "")}>
        <h2>{t(T_SIGNOUT_MODAL_TITLE)}</h2>

        {ComponentUtils.formatParagraphWithLineBreaks(t(T_SIGNOUT_MODAL_CONTENT))}

        <div className={SIGNOUT_MODAL_CLASS_NAME + "-controls"}>
          <Button className={SIGNOUT_MODAL_CLASS_NAME + "-cancel-button"} type={ButtonType.DEFAULT} borders={false} click={this.cancelCallback}>
            {t(T_SIGNOUT_MODAL_CANCEL_LABEL)}
          </Button>

          <Button className={SIGNOUT_MODAL_CLASS_NAME + "-signout-button"} type={ButtonType.DANGER} click={this.signOutCallback}>
            {t(T_SIGNOUT_MODAL_SIGNOUT_LABEL)}
          </Button>
        </div>
      </div>
    );
  }

  private _onCancel() {
    ModalService.removeFirstModal();
  }

  private _onSignOut() {
    LOG.info("Logout through sign out modal initiated.");

    SessionService.logout();

    ModalService.removeFirstModal();
  }
}

export default SignOutModal;
