// Copyright © 2020, 2021 HMD Global. All rights reserved.

import AppState from "./AppState";

export class AppStateUtils {
  static getStateName(state: AppState): string {
    switch (state) {
      case AppState.ERROR_VIEW:
        return "ERROR_VIEW";
      case AppState.LOADING_VIEW:
        return "LOADING_VIEW";
      case AppState.SETUP_VIEW:
        return "SETUP_VIEW";
      case AppState.LOGIN_VIEW:
        return "LOGIN_VIEW";
      case AppState.CHANGE_PASSWORD_VIEW:
        return "CHANGE_PASSWORD_VIEW";
      case AppState.TERMS_AND_CONDITIONS_VIEW:
        return "TERMS_AND_CONDITIONS_VIEW";
      case AppState.ENTERPRISE_AGREEMENT_VIEW:
        return "ENTERPRISE_AGREEMENT_VIEW";
      case AppState.LOGIN_VIEW_WITH_LOADING:
        return "LOGIN_VIEW_WITH_LOADING";
      case AppState.AUTHORIZED_VIEW:
        return "AUTHORIZED_VIEW";
      case AppState.TFA_VIEW:
        return "TFA_VIEW";

      default:
        return `UNKNOWN(${state})`;
    }
  }

  static isLoading(state: AppState): boolean {
    switch (state) {
      case AppState.LOADING_VIEW:
      case AppState.LOGIN_VIEW_WITH_LOADING:
        return true;

      default:
        return false;
    }
  }

  static isLoginView(state: AppState): boolean {
    switch (state) {
      case AppState.LOGIN_VIEW:
      case AppState.LOGIN_VIEW_WITH_LOADING:
        return true;

      default:
        return false;
    }
  }
}

export default AppStateUtils;
