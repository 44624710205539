// Copyright © 2020 HMD Global. All rights reserved.

import EventObserver, { EventObserverCallback, EventObserverDestructorCallback } from "./EventObserver";
import LogService from "./LogService";
import SessionService from "./SessionService";
import AppStateService from "./AppStateService";
import AppState from "./AppState";
import { get, isObject, isString, keys, isNull } from "../modules/lodash";
import HttpUtils, { QueryParamsObject } from "./HttpUtils";

const LOG = LogService.createLogger("RouteService");

/**
 * This is the delay to wait until triggering the PUSH_HISTORY event again after a listener is attached, if there was an
 * PUSH_HISTORY event triggered before any attached listeners.
 */
const PUSH_HISTORY_SET_DELAY_AFTER_LISTENER = 50;

/**
 * This is the initial delay to wait for a listener to be attached when there is no PUSH_HISTORY event listener attached
 * when the event is triggered.
 */
const PUSH_HISTORY_SET_MAX_DELAY = 5000;

export interface RouteLocationObject {
  key?: string;
  pathname: string;
  search: string;
  hash: string;
  state?: { [key: string]: any } | null;
}

export function isRouteLocationObject(value: any): value is RouteLocationObject {
  if (!isObject(value)) return false;
  const state = get(value, "state");
  const key = get(value, "key");
  return (
    (key === undefined || isString(key)) &&
    isString(get(value, "pathname")) &&
    isString(get(value, "search")) &&
    isString(get(value, "hash")) &&
    (state === undefined || isNull(state) || isObject(state))
  );
}

export enum RouteServiceEvent {
  /**
   * This event can be triggered to push a new route to the history
   *
   * Route switch implementation like our Switch wrapper (used in AuthorizedView and LoginView) will listen these events.
   */
  PUSH_HISTORY_REQUEST = "RouteService:pushHistoryRequest",

  /**
   * This event is triggered after anew route was pushed to the history.
   *
   * Route switch implementation like our Switch wrapper (used in AuthorizedView and LoginView) will trigger these events.
   */
  LOCATION_CHANGED = "RouteService:locationChanged",
}

export type RouteServiceDestructor = EventObserverDestructorCallback;

export class RouteService {
  public static Event = RouteServiceEvent;

  private static readonly _delayedSetRouteTargetCallback = RouteService._delayedSetRouteTarget.bind(RouteService);

  private static _observer: EventObserver = new EventObserver("RouteService");
  private static _pushHistoryValue: string | undefined = undefined;
  private static _pushHistoryParams: QueryParamsObject | undefined = undefined;
  private static _pushHistoryTimeout: any | undefined = undefined;
  private static _location: RouteLocationObject | undefined;

  public static on(e: RouteServiceEvent, callback: EventObserverCallback): RouteServiceDestructor {
    if (e === RouteServiceEvent.PUSH_HISTORY_REQUEST && this._pushHistoryValue !== undefined) {
      if (this._pushHistoryTimeout) {
        LOG.debug("Resetting _pushHistoryTimeout: ", this._pushHistoryTimeout, this._pushHistoryValue);
        clearTimeout(this._pushHistoryTimeout);
        this._pushHistoryTimeout = undefined;
      } else {
        LOG.debug("Setting new _pushHistoryTimeout for ", this._pushHistoryValue);
      }

      this._pushHistoryTimeout = setTimeout(this._delayedSetRouteTargetCallback, PUSH_HISTORY_SET_DELAY_AFTER_LISTENER);
    }

    return this._observer.listenEvent(e, callback);
  }

  /**
   * This will trigger an event to change the state.
   *
   * If there is no event listener attached at the time of the call, a timeout will be set to do that later, which
   * fixes a problem that loading page might not have the listener yet attached. (Eg. HomeView triggering page change.)
   *
   * @param target
   * @param params
   */
  public static setRouteTarget(target: string, params: QueryParamsObject | undefined = undefined) {
    if (this._observer.hasListeners(RouteServiceEvent.PUSH_HISTORY_REQUEST)) {
      LOG.debug("setRouteTarget: Triggering route with target: ", target, params);

      this._triggerRouteTarget(target, params);
    } else {
      LOG.debug("setRouteTarget: Delaying triggering since no listeners yet: ", target);

      this._pushHistoryValue = target;
      this._pushHistoryParams = params;

      this._pushHistoryTimeout = setTimeout(this._delayedSetRouteTargetCallback, PUSH_HISTORY_SET_MAX_DELAY);
    }
  }

  public static hasDelayedSetRouteTarget(): boolean {
    return this._pushHistoryTimeout !== undefined;
  }

  public static setErrorView() {
    SessionService.clearSession();
    AppStateService.setState(AppState.ERROR_VIEW);
  }

  public static setLoginView() {
    SessionService.clearSession();
    AppStateService.setState(AppState.LOGIN_VIEW);
    LOG.info("Moving to login view.");
  }

  public static getRouteLocation(): RouteLocationObject | undefined {
    return this._location ? { ...this._location } : undefined;
  }

  /**
   * This method is used from the component using useLocation to set location of our system.
   *
   * Usually it is our Switch wrapper (used from AuthorizedView or LoginView).
   *
   * @param location
   */
  public static setRouteLocation(location: RouteLocationObject | undefined) {
    if (this._location !== location) {
      this._location = location;

      if (this._observer.hasListeners(RouteServiceEvent.LOCATION_CHANGED)) {
        LOG.debug("setRouteLocation: Triggering LOCATION_CHANGED event for ", location);

        this._triggerLocationChanged(location);
      } else {
        LOG.debug("setRouteLocation: Nothing listens LOCATION_CHANGED event for ", location);
      }
    } else {
      LOG.debug("setRouteLocation: Location did not change: ", location);
    }
  }

  private static _triggerLocationChanged(target: RouteLocationObject | undefined) {
    this._observer.triggerEvent(RouteServiceEvent.LOCATION_CHANGED, target);
  }

  private static _triggerRouteTarget(target: string, params: QueryParamsObject | undefined) {
    const targetWithParams = params === undefined || keys(params).length <= 0 ? target : `${target}?${HttpUtils.stringifyQueryParams(params)}`;

    this._observer.triggerEvent(RouteServiceEvent.PUSH_HISTORY_REQUEST, targetWithParams);
  }

  private static _delayedSetRouteTarget() {
    this._pushHistoryTimeout = undefined;

    const value: string | undefined = this._pushHistoryValue;
    const params: QueryParamsObject | undefined = this._pushHistoryParams;

    if (value === undefined) {
      LOG.error("No push history value to trigger.");
      return;
    }

    if (this._observer.hasListeners(RouteServiceEvent.PUSH_HISTORY_REQUEST)) {
      LOG.debug("_delayedSetRouteTarget: Triggering ", value, params);

      this._pushHistoryValue = undefined;
      this._pushHistoryParams = undefined;

      this._triggerRouteTarget(value, params);
    } else {
      LOG.warn(`Cannot change state to "${value}" -- No listeners attached.`);
    }
  }
}

export default RouteService;
