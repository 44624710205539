// Copyright © 2020 HMD Global. All rights reserved.

import { isString } from "../modules/lodash";

export class TestUtils {
  static isUUIDString(value: any): value is string {
    return isString(value) && /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/.test(value);
  }

  static isGUIDString(value: any): value is string {
    return isString(value) && /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/.test(value);
  }
}

export default TestUtils;
