// Copyright © 2020, 2021 HMD Global. All rights reserved.

import LogMessage from "../types/LogMessage";
import LogService from "./LogService";
import { FRONTEND_LOG_SERVER_ENABLED, FRONTEND_LOG_SERVER_ERROR_INTERVAL, FRONTEND_LOG_SERVER_SEND_INTERVAL, FRONTEND_LOG_SERVER_SEND_SIZE } from "../constants/environment";
import HttpService, { HttpResponse } from "./HttpService";
import HttpMethod from "./HttpMethod";
import { GET_REMOTE_LOG_TOKEN_URL, WRITE_REMOTE_LOG_URL } from "../constants/backend";
import { isNewRemoteLoggerDTO } from "./types/NewRemoteLoggerDTO";
import LogRequestDTO, { LogRequestMessageDTO } from "../types/LogRequestDTO";
import RemoteLogQueue from "./RemoteLogQueue";
import { isString, map, trim } from "../modules/lodash";
import LocalStorageService from "./LocalStorageService";
import LocalStorageKey from "./LocalStorageKey";
import { stringifyLogLevel } from "../types/LogLevel";

const LOG = LogService.createLogger("RemoteLogService");

export class RemoteLogService {
  private static _lastRequestHadError = false;

  private static _delayedSendTimer: any = undefined;

  private static _sendingData = false;

  /**
   * This is the ID inside the logTOken, fetched from the remote log server, which can be used as a reference
   * to show for end-users to be attached to their support ticket, etc.
   *
   * @private
   */
  private static _logId = "";

  /**
   * This is a JWT token fetched from the remote log server, which can be used to
   * send frontend's log to the remote server.
   *
   * @private
   */
  private static _logToken = "";

  public static getName(): string {
    return "RemoteLogService";
  }

  public static initialize() {
    if (FRONTEND_LOG_SERVER_ENABLED) {
      try {
        RemoteLogService._initializeLogToken();
      } catch (err) {
        console.error("Error while initializing RemoteLogService: ", err);
      }

      try {
        RemoteLogQueue.setRefreshCallback((): void => {
          RemoteLogService._onRefresh();
        });
      } catch (err) {
        console.error("Error while initializing log interval: ", err);
      }
    } else {
      LogService.origConsole.info("[RemoteLogService] Remote logging disabled.");
    }
  }

  public static getLogId(): string {
    return RemoteLogService._logId;
  }

  private static _onRefresh(): void {
    if (RemoteLogService._delayedSendTimer) {
      if (RemoteLogService._delayedSendTimer) {
        clearTimeout(RemoteLogService._delayedSendTimer);
        RemoteLogService._delayedSendTimer = undefined;
      }
    }

    RemoteLogService._delayedSendTimer = setTimeout(
      () => {
        try {
          if (RemoteLogService._delayedSendTimer) {
            RemoteLogService._delayedSendTimer = undefined;
          }

          RemoteLogService._sendMessagesIfAny();
        } catch (err) {
          LOG.error("Error while refresh callback: ", err);

          this._onRefresh();
        }
      },
      RemoteLogService._lastRequestHadError ? FRONTEND_LOG_SERVER_ERROR_INTERVAL : FRONTEND_LOG_SERVER_SEND_INTERVAL,
    );
  }

  private static _hasLogToSent(): boolean {
    return !RemoteLogQueue.isEmpty();
  }

  private static _sendMessagesIfAny() {
    if (RemoteLogService._delayedSendTimer) {
      if (RemoteLogService._delayedSendTimer) {
        clearTimeout(RemoteLogService._delayedSendTimer);
        RemoteLogService._delayedSendTimer = undefined;
      }
    }

    if (RemoteLogService._sendingData) {
      LOG.debug(`Sending log: We were already sending data.`);
      return;
    }

    if (!RemoteLogService._hasLogToSent()) {
      //LOG.debug(`Sending log: We didn't have any log to sent.`);
      return;
    }

    if (!RemoteLogService._logToken) {
      LOG.debug(`Sending log: We don't have logToken yet.`);
      return;
    }

    const buffer: LogMessage[] = RemoteLogQueue.getMessages(FRONTEND_LOG_SERVER_SEND_SIZE);

    if (buffer.length) {
      RemoteLogService._sendMessages(buffer);
    }
  }

  private static _initializeLogToken(): void {
    try {
      if (RemoteLogService._logToken) {
        LOG.warn("Warning! The service was already initialized.");
        return;
      }

      HttpService.request(HttpMethod.POST, GET_REMOTE_LOG_TOKEN_URL)
        .then((response: HttpResponse<any>): void => {
          const data = response?.data;

          if (!isNewRemoteLoggerDTO(data)) {
            throw new TypeError("The response was not NewRemoteLoggerDTO: " + JSON.stringify(data));
          }

          const logId = data.logId;

          RemoteLogService._logId = data.logId;
          RemoteLogService._logToken = data.logToken;

          // This is just for help in investigating
          let previousLogId = "";
          if (LocalStorageService.hasProperty(LocalStorageKey.PREVIOUS_LOG_ID)) {
            previousLogId = LocalStorageService.getProperty(LocalStorageKey.PREVIOUS_LOG_ID);
          }

          if (previousLogId) {
            LOG.info(`New log ID is '${logId}' (previous was '${previousLogId}')`);
          } else {
            LOG.info(`New log ID is '${logId}'`);
          }

          LocalStorageService.setProperty(LocalStorageKey.PREVIOUS_LOG_ID, logId);

          RemoteLogService._onRefresh();
        })
        .catch((err) => {
          RemoteLogService._errorInitialize(err);
        });
    } catch (err) {
      RemoteLogService._errorInitialize(err);
    }
  }

  private static _errorInitialize(err: any) {
    if (!RemoteLogService._logToken) {
      LOG.error("Error while initializing remote log service: ", err);
      LOG.warn("Warning! Failed to initialize connection to the remote log server. We will try again later.");

      setTimeout(RemoteLogService._initializeLogToken, FRONTEND_LOG_SERVER_ERROR_INTERVAL);
    } else {
      LOG.warn("Warning! We were already initialized, not handling the error: ", err);
    }
  }

  private static _sendMessages(messages: LogMessage[]) {
    const logToken: string = RemoteLogService._logToken;

    if (!logToken) throw new TypeError(`We did not have logToken yet!`);

    if (RemoteLogService._sendingData) throw new TypeError("We are already sending data!");

    RemoteLogService._sendingData = true;

    const data: LogRequestDTO = {
      logToken: logToken,
      payload: map(messages, (item: LogMessage): LogRequestMessageDTO => {
        return {
          content: item.content,
          level: stringifyLogLevel(item.level),
          time: item.time,
        };
      }),
    };

    HttpService.request(HttpMethod.POST, WRITE_REMOTE_LOG_URL, data)
      .then(
        (response: HttpResponse<any>): void => {
          RemoteLogService._sendingData = false;
          RemoteLogService._lastRequestHadError = false;

          const data = response?.data;

          if (isString(data) && trim(data) === "OK") {
            if (RemoteLogService._hasLogToSent()) {
              LogService.origConsole.debug("[RemoteLogService] One patch of log sent successfully. Sending more next.");

              RemoteLogService._sendMessagesIfAny();
            } else {
              LogService.origConsole.debug("[RemoteLogService] All log sent successfully.");
            }
          } else {
            LOG.error("The response was not OK: ", data);
            RemoteLogQueue.pushBackMessages(messages);
          }
        },
        (err) => {
          RemoteLogService._sendingData = false;
          RemoteLogService._lastRequestHadError = true;

          LOG.warn("Failed to sent some log messages -- will try again later: ", err);
          RemoteLogQueue.pushBackMessages(messages);

          this._onRefresh();
        },
      )
      .catch((err) => {
        RemoteLogService._lastRequestHadError = true;

        LOG.error("Error while sending log: ", err);

        this._onRefresh();
      });
  }
}

export default RemoteLogService;
