export enum HttpHeader {
  EMM_BACKEND = "x-emm-backend",
  EMM_USER = "x-emm-user",
  EMM_PASSWORD = "x-emm-password",
  EMM_ENTERPRISE = "x-emm-enterprise",
  CONTENT_TYPE = "content-type",
  REQUESTED_WITH = "x-requested-with",

  /**
   * Our EMM Gateway will change the "Location" header field returned from the inner backend for AJAX requests to
   * "X-Location" so that the browser doesn't actually implement the useless relocation, and we can do that in the
   * frontend.
   */
  X_LOCATION = "x-location",

  /**
   * This field is used in the reset password flow with *the EMM Java Backend*.
   *
   * **NOTE!** It is required even though the data is in the body, too!
   */
  X_TOKEN = "X-token",
}

export default HttpHeader;
