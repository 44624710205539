// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import "./Presentation.scss";
import { PRESENTATION_CLASS_NAME } from "../../../constants/classNames";
import styles from "../../../styles/variables.scss";

export interface PresentationProps {
  className?: string;
  image?: string;
  imageAlt?: string;
  title?: string;
  content?: string;
}

export interface PresentationState {}

export class Presentation extends React.Component<PresentationProps, PresentationState> {
  static defaultProps: Partial<PresentationProps> = {};

  constructor(props: PresentationProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <article className={`${PRESENTATION_CLASS_NAME} hmd-theme-${styles.themeInUse} ${this.props.className ?? ""}`}>
        {this.props.image !== undefined && (
          <div className={`${PRESENTATION_CLASS_NAME}-image-container`}>
            <img src={this.props.image} className={`${PRESENTATION_CLASS_NAME}-image`} alt={this.props?.imageAlt ?? ""} />
          </div>
        )}
        {this.props.title !== undefined && <h3>{this.props.title}</h3>}
        {this.props.content !== undefined && <p>{this.props.content}</p>}
      </article>
    );
  }
}

export default Presentation;
