import LocalStorageService from "./LocalStorageService";
import LocalStorageKey from "./LocalStorageKey";
import LogService from "./LogService";
import { trim } from "../modules/lodash";
import WindowService from "./WindowService";
import BackendUtils from "./BackendUtils";

const LOG = LogService.createLogger("BackendService");

export class BackendService {
  private static _initialized = false;
  private static _backend = "";

  public static getName(): string {
    return "BackendService";
  }

  public static initialize() {
    this._backend = this._getCurrentBackend();

    this._initialized = true;

    if (this._backend) {
      LOG.info(`Backend initialized as ${this._backend}`);
    } else {
      LOG.info(`Backend initialized without known backend`);
    }
  }

  private static _getURLBackendId(): string | undefined {
    const urlBackendId = WindowService.parseFirstURIComponent();
    if (urlBackendId && BackendUtils.isValidBackendId(urlBackendId)) {
      return urlBackendId;
    }

    return undefined;
  }

  private static _getLocalStorageBackendId(): string | undefined {
    if (!LocalStorageService.hasProperty(LocalStorageKey.BACKEND_KEY)) {
      return undefined;
    }

    const backendId = LocalStorageService.getProperty(LocalStorageKey.BACKEND_KEY);

    if (backendId === "") {
      return undefined;
    }

    return backendId;
  }

  private static _getCurrentBackend(): string {
    const urlBackendId = this._getURLBackendId();
    const localStorageBackendId = this._getLocalStorageBackendId();

    if (urlBackendId) {
      if (localStorageBackendId) {
        LocalStorageService.setProperty(LocalStorageKey.BACKEND_KEY, urlBackendId);
      }

      return urlBackendId;
    }

    return localStorageBackendId ?? "";
  }

  public static getCurrentBackend(): string {
    if (!this._initialized) {
      LOG.warn("Use of the API before service was initialized. Probably initialization order is wrong.");
      return this._getCurrentBackend();
    }

    return this._backend;
  }

  public static hasCurrentBackend(): boolean {
    if (!this._initialized) {
      return !!this.getCurrentBackend();
    }

    return !!this._backend;
  }

  public static setCurrentBackend(value: string) {
    value = trim(value);

    const originalBackend = this._backend;

    if (!originalBackend) {
      if (value === "") {
        LOG.info(`No changes / Still no known backend`);
      } else {
        this._backend = value;

        LocalStorageService.setProperty(LocalStorageKey.BACKEND_KEY, value);

        LOG.info(`Backend set as ${value}`);
      }
    } else {
      if (!value) {
        this._backend = "";

        LocalStorageService.removeProperty(LocalStorageKey.BACKEND_KEY);

        LOG.info(`Backend changed from ${originalBackend} to undefined`);
      } else {
        this._backend = value;

        LocalStorageService.setProperty(LocalStorageKey.BACKEND_KEY, value);

        LOG.info(`Backend changed from ${originalBackend} to ${value}`);
      }
    }
  }

  public static clearCurrentBackend() {
    this._backend = "";

    LocalStorageService.setProperty(LocalStorageKey.BACKEND_KEY, "");

    LOG.info("Backend cleared.");
  }
}

export default BackendService;
