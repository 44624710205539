// Copyright © 2020 HMD Global. All rights reserved.

import { isString, values } from "../../../modules/lodash";

export enum IconType {
  /**
   * Special type to indicate no icon should be displayed.
   */
  NONE = "NONE",
  PASSWORD = "LOCK",
  EMAIL = "EMAIL",
  EYE = "EYE",
  EYE_CROSSED = "EYE_CROSSED",
  ARROW_LEFT = "ARROW_LEFT",
  ARROW_RIGHT = "ARROW_RIGHT",
  LOCATION = "LOCATION",
  SORT = "SORT",
  SORT_UP = "SORT_UP",
  SORT_DOWN = "SORT_DOWN",
  HOME = "HOME",
  TARGET = "TARGET",
  APPLICATIONS = "APPLICATIONS",
  POLICIES = "POLICIES",
  DEVICES = "DEVICES",
  USERS = "USERS",
  LOADING = "LOADING",
  LOCK = "LOCK",
  EDIT = "EDIT",
  REMOVE = "REMOVE",
  ADD = "ADD",
  ADD_SIMPLE = "ADD_SIMPLE",
  NOTICE = "NOTICE",
  CLOSE = "CLOSE",
  HELP = "HELP",
  NEXT = "NEXT",
  PREV = "PREV",
  EXT_LINK = "EXT_LINK",
  TOGGLE_ON = "TOGGLE_ON",
  TOGGLE_OFF = "TOGGLE_OFF",
  REFRESH = "REFRESH",
  GEARS = "GEARS",
  DONE = "DONE",
  SEARCH = "SEARCH",
  TRIANGLE_NOTICE = "TRIANGLE_NOTICE",
  RESET = "RESET",
  DELETE = "DELETE",
  DOT = "DOT",
  ACTION_DOWN = "ACTION_DOWN",
  MENU = "MENU",
  KEY = "KEY",
  WARNING = "WARNING",
  COPY = "COPY",
  STOP = "STOP",
  FILTER = "FILTER",
  ENTERPRISE = "ENTERPRISE",
  ENROLLMENT = "ENROLLMENT",
  DOWNLOAD = "DOWNLOAD",
  DEVICE_GROUP = "DEVICE_GROUP",
  MESSAGE = "MESSAGE",
  HELP_FAQ = "HELP_FAQ",
  VIDEO = "VIDEO",
  USER_GUIDE = "USER_GUIDE",
  PLAY = "PLAY",
  IMPORT_USERS = "IMPORT_USERS",
  DEVICE_USER = "DEVICE_USER",
  DRAWER = "DRAWER",
  MAP = "MAP",
  GEOFENCE = "GEOFENCE",
  FREEZE_PERIOD = "FREEZE_PERIOD",
  CONNECTION = "CONNECTION",
  FULLSCREEN = "FULLSCREEN",
  ENROLL_DEVICE = "ENROLL_DEVICE",
  HAMBURGER = "HAMBURGER",
  CONTACT_LIST = "CONTACT_LIST",
  APP_UPDATES = "APP_UPDATES",
  SYSTEM_UPDATES = "SYSTEM_UPDATES",
  COMPANION_APP = "COMPANION_APP",
  SECURITY = "SECURITY",
  PROFILE = "PROFILE",
  NETWORK = "NETWORK",
  DEVICE = "DEVICE",
  BRIEFCASE = "BRIEFCASE",
}

const ICON_TYPE_VALUES: Array<string> = values(IconType);

export function isIconType(value: any): value is IconType {
  if (!isString(value)) return false;
  return ICON_TYPE_VALUES.indexOf(value) >= 0;
}

export default IconType;
