// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import "./CheckboxField.scss";
import { ChangeEvent, RefObject } from "react";
import { CHECKBOX_FIELD_CLASS_NAME } from "../../../constants/classNames";
import LogService from "../../../services/LogService";

const LOG = LogService.createLogger("CheckboxField");

export interface HTMLInputChangeCallback {
  (event: ChangeEvent<HTMLInputElement>): void;
}

export interface CheckboxFieldChangeCallback {
  (checked: boolean | undefined): void;
}

export interface CheckboxFieldProps {
  className?: string | undefined;

  label?: string | JSX.Element | undefined;

  change?: CheckboxFieldChangeCallback;

  autoFocus?: boolean;

  /**
   * This value may be:
   *  - false or true -- Parent has set the state
   *  - undefined -- Parent does not keep state; use prop.change to update state to parent as a callback
   */
  checked?: boolean;

  enabled?: boolean;
}

export interface CheckboxFieldState {
  checked: boolean;
}

export class CheckboxField extends React.Component<CheckboxFieldProps, CheckboxFieldState> {
  private readonly inputRef: RefObject<HTMLInputElement>;
  private readonly inputChangeCallback: HTMLInputChangeCallback;

  static defaultProps: CheckboxFieldProps = {
    className: undefined,
    label: undefined,
    autoFocus: false,
    enabled: true,
  };

  constructor(props: CheckboxFieldProps) {
    super(props);

    this.state = {
      checked: this.props.checked ?? false,
    };

    this.inputRef = React.createRef();

    this.inputChangeCallback = this.onChange.bind(this);
  }

  componentDidMount() {
    if (this.props.checked !== undefined) {
      if (this.state.checked !== this.props.checked) {
        this.setState({
          checked: this.props.checked ?? false,
        });
      }
    }

    if (this.inputRef?.current && this.props.autoFocus) {
      LOG.debug("Auto focusing on ", this.inputRef.current);
      this.inputRef.current.focus();
    }
  }

  componentDidUpdate(prevProps: Readonly<CheckboxFieldProps>) {
    if (this.props.checked !== prevProps.checked && this.state.checked !== this.props.checked) {
      this.setState({
        checked: this.props.checked ?? false,
      });
    }
  }

  onChange(event: ChangeEvent<HTMLInputElement>) {
    const newState = event.target.checked;

    if (this.props.checked !== undefined) {
      if (newState !== this.props.checked) {
        this._setParentState(newState);
        this._setInternalState(newState);
      } else {
        LOG.debug("CheckboxField: No changes to props value: ", newState);
      }
    } else {
      if (newState !== this.state.checked) {
        this._setParentState(newState);
        this._setInternalState(newState);
      } else {
        LOG.debug("CheckboxField: No changes to internal value: ", newState);
      }
    }
  }

  private _setInternalState(newState: boolean) {
    if (this.state.checked !== newState) {
      LOG.debug("_setInternalState: Setting internal state as ", newState);

      this.setState({
        checked: newState,
      });
    } else {
      LOG.debug("_setInternalState: Internal state was already as ", newState);
    }
  }

  private _setParentState(newState: boolean) {
    try {
      if (this.props.change) {
        LOG.debug("_setParentState: Setting parent state as ", newState);
        this.props.change(newState);
      } else {
        LOG.debug("_setParentState: Parent prop change was not defined");
      }
    } catch (err) {
      LOG.error("_setParentState: Change Error: ", err);
    }
  }

  render() {
    return (
      <label className={`${CHECKBOX_FIELD_CLASS_NAME} ${CHECKBOX_FIELD_CLASS_NAME + (this.props.enabled ? "-enabled" : "-disabled")} ${this.props.className ?? ""}`}>
        <input key={Math.random()} type="checkbox" ref={this.inputRef} defaultChecked={this.state.checked} onChange={this.inputChangeCallback} disabled={!this.props.enabled} />
        {this.props.label}
      </label>
    );
  }
}

export default CheckboxField;
