// Copyright © 2022 HMD Global. All rights reserved.

import * as React from "react";
import { ChangeEvent, RefObject } from "react";
import "./TfaView.scss";
import Section from "../../common/section/Section";
import TranslateCallback from "../../../TranslateCallback";
import AppStateService from "../../../services/AppStateService";
import AppStateEvent from "../../../services/AppStateEvent";
import AppStateUtils from "../../../services/AppStateUtils";
import { EventObserverDestructorCallback } from "../../../services/EventObserver";
import { T_TFA_VIEW_TITLE, T_LOGIN_SIGN_IN_BUTTON_LABEL, T_TFA_VIEW_SUBTITLE, T_TFA_REGISTER_CODE_INGRESS } from "../../../translations/translationTokens";
import { TFA_VIEW_CLASS_NAME } from "../../../constants/classNames";
import ProfileService, { ProfileServiceDestructor } from "../../../services/ProfileService";
import NotificationContainer from "../../layout/notificationContainer/NotificationContainer";
import AppState from "../../../services/AppState";
import EnterpriseId from "../../../types/EnterpriseId";
import Button, { ButtonClickCallback } from "../../common/button/Button";
import Branding from "../../layout/branding/Branding";
import { SplitView } from "../splitView/SplitView";
import { ShowcaseView } from "../showcaseView/ShowcaseView";
import AdService, { PresentationModel } from "../../../services/AdService";
import ButtonType from "../../../types/ButtonType";
import TranslateUtils from "../../../services/TranslateUtils";
import { GOOGLE_PLAY_AUTHENTICATOR_LINK } from "../../../constants/frontend";

export interface TfaViewState {
  loading: boolean;
  enterpriseId: EnterpriseId | undefined;
  qrImage: string | undefined;
  tfaRegistrationNeeded: boolean;
  presentations: Readonly<Array<PresentationModel>>;
  code: string[];
}

export interface TfaViewProps {
  t?: TranslateCallback;
  className?: string | undefined;
}

export class TfaView extends React.Component<TfaViewProps, TfaViewState> {
  static defaultProps: Partial<TfaViewProps> = {};

  private _appStateListener: EventObserverDestructorCallback | undefined;
  private _tfaBackendListener: ProfileServiceDestructor | undefined;
  private _submitTfaCallback: ButtonClickCallback;
  private _codeCallback: any;
  private _keyDownCallback: any;
  private readonly firstRef: RefObject<HTMLInputElement>;

  constructor(props: TfaViewProps) {
    super(props);

    this.state = {
      loading: false,
      enterpriseId: undefined,
      qrImage: undefined,
      tfaRegistrationNeeded: false,
      presentations: AdService.getLoginPresentations(),
      code: [],
    };

    this.firstRef = React.createRef();

    this._appStateListener = undefined;
    this._submitTfaCallback = this._onSubmitTfa.bind(this);
    this._codeCallback = this._onCodeChange.bind(this);
    this._keyDownCallback = this._onKeyDown.bind(this);
  }

  componentDidMount() {
    this.setState({
      loading: AppStateUtils.isLoading(AppStateService.getState()),
      enterpriseId: ProfileService.getEnterpriseId(),
      tfaRegistrationNeeded: ProfileService.getTfaRegistrationNeeded(),
    });

    ProfileService.getTfaQr().then((url) => this.setState({ qrImage: url }));

    this._appStateListener = AppStateService.on(AppStateEvent.CHANGED, () => {
      this.setState({
        loading: AppStateUtils.isLoading(AppStateService.getState()),
      });
    });

    this._tfaBackendListener = ProfileService.on(ProfileService.Event.TFA_VERIFIED, () => {
      AppStateService.setState(AppState.LOADING_VIEW);
      ProfileService.refreshProfileDataFromBackend();

      this.setState({
        loading: AppStateUtils.isLoading(AppStateService.getState()),
        tfaRegistrationNeeded: ProfileService.getTfaRegistrationNeeded(),
      });
    });
  }

  componentWillUnmount() {
    if (this._tfaBackendListener !== undefined) {
      this._tfaBackendListener();
      this._tfaBackendListener = undefined;
    }
  }

  render() {
    const t = this.props.t ?? ((key: string) => key);

    const { qrImage, tfaRegistrationNeeded, code } = this.state;

    return (
      <SplitView className={`${TFA_VIEW_CLASS_NAME} ${this.props.className ?? ""}`}>
        <ShowcaseView presentations={this.state.presentations} />
        <Section className={`${TFA_VIEW_CLASS_NAME}-section`}>
          <Branding className={`${TFA_VIEW_CLASS_NAME}-branding`} t={t} />
          <div className={`${TFA_VIEW_CLASS_NAME}-content`}>
            <h4 className={`${TFA_VIEW_CLASS_NAME}-title`}>{t(T_TFA_VIEW_TITLE)}</h4>
            {tfaRegistrationNeeded && (
              <div className={`${TFA_VIEW_CLASS_NAME}-register`}>
                <p className={`${TFA_VIEW_CLASS_NAME}-disclaimer`}>
                  {TranslateUtils.magicTranslateWithComponents(t, T_TFA_REGISTER_CODE_INGRESS, {
                    AUTHENTICATOR_LINK: (
                      <a target="_blank" rel="noopener noreferrer" href={GOOGLE_PLAY_AUTHENTICATOR_LINK}>
                        Google Authenticator
                      </a>
                    ),
                  })}
                </p>
                <div className={`${TFA_VIEW_CLASS_NAME}-qr`}>
                  <img src={qrImage} alt="tfa-qr" />
                </div>
              </div>
            )}
            <h5 className={`${TFA_VIEW_CLASS_NAME}-subtitle`}>{t(T_TFA_VIEW_SUBTITLE)}</h5>
            <div className={`${TFA_VIEW_CLASS_NAME}-inputs`}>
              <input autoFocus ref={this.firstRef} name="0" maxLength={1} style={{ order: 1 }} value={code[0]} onKeyDown={this._keyDownCallback} onChange={this._codeCallback} />
              <input name="1" maxLength={1} style={{ order: 2 }} value={code[1]} onKeyDown={this._keyDownCallback} onChange={this._codeCallback} />
              <input name="2" maxLength={1} style={{ order: 3 }} value={code[2]} onKeyDown={this._keyDownCallback} onChange={this._codeCallback} />
              <input name="3" maxLength={1} style={{ order: 4 }} value={code[3]} onKeyDown={this._keyDownCallback} onChange={this._codeCallback} />
              <input name="4" maxLength={1} style={{ order: 5 }} value={code[4]} onKeyDown={this._keyDownCallback} onChange={this._codeCallback} />
              <input name="5" maxLength={1} style={{ order: 6 }} value={code[5]} onKeyDown={this._keyDownCallback} onChange={this._codeCallback} />
            </div>
            <div className={`${TFA_VIEW_CLASS_NAME}-actions`}>
              <Button type={ButtonType.PRIMARY} enabled={code.length >= 6} className={`${TFA_VIEW_CLASS_NAME}-register`} click={this._submitTfaCallback}>
                {t(T_LOGIN_SIGN_IN_BUTTON_LABEL)}
              </Button>
            </div>
            <NotificationContainer className={`${TFA_VIEW_CLASS_NAME}-notifications`} t={t} />
          </div>
        </Section>
      </SplitView>
    );
  }

  private _onSubmitTfa() {
    const { code, tfaRegistrationNeeded } = this.state;
    const verificationCode = code.join("");

    if (tfaRegistrationNeeded) {
      ProfileService.registerCode(verificationCode).then(() => {
        ProfileService.verifyCode(verificationCode).catch(() => {
          this.setState(
            {
              code: [],
            },
            () => {
              const inputs = document.querySelectorAll("input");
              Array.from(inputs).forEach((input) => (input.value = ""));
              this.firstRef.current?.focus();
            },
          );
        });
      });
    } else {
      ProfileService.verifyCode(verificationCode).catch(() => {
        this.setState(
          {
            code: [],
          },
          () => {
            const inputs = document.querySelectorAll("input");
            Array.from(inputs).forEach((input) => (input.value = ""));
            this.firstRef.current?.focus();
          },
        );
      });
    }
  }

  private _onCodeChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value, nextSibling } = e.target;
    const { code } = this.state;
    const index = parseInt(name);

    code[index] = value;
    this.setState({ code }, () => {
      nextSibling && (nextSibling as HTMLElement).focus();
    });
  }

  private _onKeyDown(e: any) {
    const { name, previousSibling } = e.target;
    const { code } = this.state;
    const index = parseInt(name);

    if (e.key === "Backspace") {
      e.preventDefault();
      code[index] = "";
      this.setState({ code }, () => {
        previousSibling && (previousSibling as HTMLElement).focus();
      });
    }

    if (e.key === "Enter" && code.length >= 6) {
      this._onSubmitTfa();
    }
  }
}

export default TfaView;
