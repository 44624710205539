// Copyright © 2020 HMD Global. All rights reserved.

import AssertUtils from "../services/AssertUtils";
import { IS_DEVELOPMENT_BUILD } from "../constants/environment";

/**
 * Frontend's device id data model
 */
export class EnterpriseId {
  /**
   * The enterprise ID, eg. `"LC04ghmbil"`
   *
   * @private
   */
  private readonly _id: string;

  /**
   *
   * @param enterpriseId The enterprise ID, eg. `"LC04ghmbil"`
   */
  constructor(enterpriseId: string) {
    if (IS_DEVELOPMENT_BUILD) {
      AssertUtils.isString(enterpriseId);

      if (enterpriseId.startsWith("enterprises/")) {
        throw new TypeError("The enterprise ID must not start with enterprises/");
      }
    }

    if (!enterpriseId) throw new TypeError("EnterpriseId: enterpriseId was not defined");

    this._id = enterpriseId;
  }

  /**
   * Returns the enterprise ID part, eg. `"LC04ghmbil"`
   */
  public getId(): string {
    return this._id;
  }

  /**
   * Returns the full Google ID part, eg. `"enterprises/:enterpriseId"`
   */
  public getGoogleId(): string {
    return `enterprises/${this._id}`;
  }

  public valueOf(): string {
    return this.getGoogleId();
  }

  public toString(): string {
    return this.valueOf();
  }

  public toJSON(): string {
    return this.valueOf();
  }

  public isEqual(value: any): value is EnterpriseId {
    return !!value && value instanceof EnterpriseId && value.valueOf() === this.valueOf();
  }

  public static create(enterpriseId: string): EnterpriseId {
    return new EnterpriseId(enterpriseId);
  }

  public static isEqual(a: EnterpriseId | undefined, b: EnterpriseId | undefined) {
    if (a === b) return true;

    if (!a || !(a instanceof EnterpriseId)) return false;

    return a.isEqual(b);
  }
}

export default EnterpriseId;
