// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import "./Label.scss";
import { LABEL_CLASS_NAME } from "../../../constants/classNames";
import LabelType from "./LabelType";
import LogService from "../../../services/LogService";
import Tooltip from "../tooltip/Tooltip";

const LOG = LogService.createLogger("Label");

export interface LabelClickCallback {
  (): void;
}

export interface LabelProps {
  className?: string;
  label?: string;
  click?: LabelClickCallback;
  type?: LabelType;
  field?: string | any;
  fieldProps?: Record<string, any>;
  tooltip?: any;
  message?: string;
  hideMessage?: boolean;
}

export interface LabelState {}

interface HTMLLabelClickCallback {
  (event: React.MouseEvent<HTMLLabelElement>): void;
}

export class Label extends React.Component<LabelProps, LabelState> {
  private readonly _onLabelClickCallback: HTMLLabelClickCallback;

  static defaultProps: LabelProps = {
    type: LabelType.DEFAULT,
    field: "div",
    hideMessage: false,
    fieldProps: {},
  };

  constructor(props: LabelProps) {
    super(props);

    this._onLabelClickCallback = this._onLabelClick.bind(this);
  }

  render() {
    const FieldTag = this.props.field ?? "div";
    const fieldProps = this.props.fieldProps ?? {};

    return (
      <label data-cy={"label"} className={`${LABEL_CLASS_NAME} ${LABEL_CLASS_NAME}-type-${this.props.type} ${this.props.className ?? ""}`} onClick={this._onLabelClickCallback}>
        <div className={`${LABEL_CLASS_NAME}-text`}>
          <span className={`${LABEL_CLASS_NAME}-text-value`}>{this.props.label}</span>
          {this.props.tooltip && <Tooltip className={`${LABEL_CLASS_NAME}-text-tooltip-popup`}>{this.props.tooltip}</Tooltip>}
        </div>

        <FieldTag {...fieldProps} className={`${LABEL_CLASS_NAME}-field ${fieldProps.className ?? ""}`}>
          {this.props.children}
        </FieldTag>

        {this.props.message && (
          <div className={`${LABEL_CLASS_NAME}-message ${this.props.hideMessage ? "hidden" : "shown"}`}>
            <span>{this.props.message}</span>
          </div>
        )}
      </label>
    );
  }

  private _onLabelClick(event: React.MouseEvent<HTMLLabelElement>) {
    event.preventDefault();
    event.stopPropagation();

    if (this.props.click) {
      try {
        this.props.click();
      } catch (err) {
        LOG.error("Label: Label click handler error: ", err);
      }
    }
  }
}

export default Label;
