// Copyright © 2021 HMD Global. All rights reserved.

import WINDOW from "../global/window";
import LogService from "./LogService";
import EventObserver, { EventObserverCallback } from "./EventObserver";

const LOG = LogService.createLogger("NetworkService");

export enum NetworkServiceEvent {
  CONNECTION_STATE_UPDATED = "networkService:connectionUpdated",
}

export interface NetworkServiceDestructor {
  (): void;
}

export class NetworkService {
  public static Event = NetworkServiceEvent;

  private static _observer: EventObserver = new EventObserver("NetworkService");

  private static _networkEnabled = true;

  private static _updateStateCallback: () => void;

  public static getName(): string {
    return "NetworkService";
  }

  public static initialize() {
    this._updateStateCallback = this._updateState.bind(this);

    this._updateState();

    WINDOW.addEventListener("online", this._updateStateCallback);
    WINDOW.addEventListener("offline", this._updateStateCallback);
  }

  public static hasNetworkConnection(): boolean {
    return this._networkEnabled;
  }

  public static on(e: NetworkServiceEvent, callback: EventObserverCallback): NetworkServiceDestructor {
    return this._observer.listenEvent(e, callback);
  }

  private static _updateState() {
    const previousState: boolean = this._networkEnabled ?? true;
    const newState: boolean = WINDOW?.navigator?.onLine ?? true;

    if (newState !== previousState) {
      LOG.debug(`State changed from ${previousState} to ${newState}`);

      this._networkEnabled = newState;

      this._observer.triggerEvent(NetworkServiceEvent.CONNECTION_STATE_UPDATED, newState);
    }
  }
}

export default NetworkService;
