// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import "./TermsAndConditionsView.scss";
import { SplitView } from "../splitView/SplitView";
import Section from "../../common/section/Section";
import TranslateCallback from "../../../TranslateCallback";
import { ShowcaseView } from "../showcaseView/ShowcaseView";
import Branding from "../../layout/branding/Branding";
import AppStateService from "../../../services/AppStateService";
import AppStateEvent from "../../../services/AppStateEvent";
import AppStateUtils from "../../../services/AppStateUtils";
import { EventObserverDestructorCallback } from "../../../services/EventObserver";
import MessageService from "../../../services/MessageService";
import { IconType } from "../../common/icon/IconType";
import { T_LOGIN_SERVICE_LOGIN_ERROR } from "../../../translations/translationTokens";
import AdService, { PresentationModel } from "../../../services/AdService";
import LogService from "../../../services/LogService";
import { LOGIN_VIEW_CLASS_NAME, TERMS_AND_CONDITIONS_VIEW_CLASS_NAME } from "../../../constants/classNames";
import ProfileService, { ProfileServiceDestructor } from "../../../services/ProfileService";
import { MessageType } from "../../../services/types/MessageType";
import { TermsAndConditionsForm } from "../../forms/termsAndConditionsForm/TermsAndConditionsForm";
import { ButtonClickCallback } from "../../common/button/Button";
import NotificationContainer from "../../layout/notificationContainer/NotificationContainer";
import AppState from "../../../services/AppState";

const LOG = LogService.createLogger("TermsAndConditionsView");

export interface TermsAndConditionsViewState {
  loading: boolean;
  presentations: Readonly<Array<PresentationModel>>;
}

export interface TermsAndConditionsViewProps {
  t?: TranslateCallback;
  className?: string | undefined;
}

export class TermsAndConditionsView extends React.Component<TermsAndConditionsViewProps, TermsAndConditionsViewState> {
  static defaultProps: Partial<TermsAndConditionsViewProps> = {};

  private _appStateListener: EventObserverDestructorCallback | undefined;
  private _tcBackendListener: ProfileServiceDestructor | undefined;

  private readonly _acceptTermsAndConditionsCallback: ButtonClickCallback;

  constructor(props: TermsAndConditionsViewProps) {
    super(props);

    this.state = {
      presentations: AdService.getLoginPresentations(),
      loading: AppStateUtils.isLoading(AppStateService.getState()),
    };

    this._appStateListener = undefined;
    this._acceptTermsAndConditionsCallback = this._onAcceptTermsAndConditions.bind(this);
  }

  componentDidMount() {
    this.setState({
      presentations: AdService.getLoginPresentations(),
      loading: AppStateUtils.isLoading(AppStateService.getState()),
    });

    this._appStateListener = AppStateService.on(AppStateEvent.CHANGED, () => {
      this.setState({
        loading: AppStateUtils.isLoading(AppStateService.getState()),
      });
    });

    this._tcBackendListener = ProfileService.on(ProfileService.Event.TERMS_AND_CONDITIONS_ACCEPTED, () => {
      AppStateService.setState(AppState.LOADING_VIEW);
      ProfileService.refreshProfileDataFromBackend();

      this.setState({
        loading: AppStateUtils.isLoading(AppStateService.getState()),
      });
    });
  }

  componentWillUnmount() {
    if (this._tcBackendListener !== undefined) {
      this._tcBackendListener();
      this._tcBackendListener = undefined;
    }
  }

  render() {
    const t = this.props.t ?? ((key: string) => key);

    return (
      <SplitView className={TERMS_AND_CONDITIONS_VIEW_CLASS_NAME + " " + (this.props.className ?? "")}>
        <ShowcaseView presentations={this.state.presentations} />
        <Section className={TERMS_AND_CONDITIONS_VIEW_CLASS_NAME + "-section"}>
          <Branding className={TERMS_AND_CONDITIONS_VIEW_CLASS_NAME + "-branding"} t={t} />
          <div className={TERMS_AND_CONDITIONS_VIEW_CLASS_NAME + "-content"}>
            <TermsAndConditionsForm className={LOGIN_VIEW_CLASS_NAME + "-tc-form"} t={t} loading={this.state.loading} submit={this._acceptTermsAndConditionsCallback} />
            <NotificationContainer className={TERMS_AND_CONDITIONS_VIEW_CLASS_NAME + "-notifications"} t={t} />
          </div>
        </Section>
      </SplitView>
    );
  }

  private _onAcceptTermsAndConditions() {
    try {
      ProfileService.acceptTermsAndConditions();
    } catch (err) {
      LOG.error("Exception: ", err);

      MessageService.createMessage({
        type: MessageType.ERROR,
        icon: IconType.NOTICE,
        content: T_LOGIN_SERVICE_LOGIN_ERROR,
      });
    }
  }
}

export default TermsAndConditionsView;
