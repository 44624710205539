// Copyright © 2020 HMD Global. All rights reserved.

import EventObserver, { EventObserverCallback, EventObserverDestructorCallback } from "./EventObserver";
import WINDOW from "../global/window";
import { has, isEqual, isString, map, startsWith } from "../modules/lodash";
import LogService from "./LogService";
import RouteService from "./RouteService";
import HttpUtils, { QueryParamsObject, QueryParamsObjectWithoutArrays } from "./HttpUtils";

const LOG = LogService.createLogger("WindowService");

export enum WindowServiceEvent {
  WINDOW_RESIZED = "windowService:windowResized",
}

export type WindowServiceDestructor = EventObserverDestructorCallback;

const WINDOW_RESIZE_EVENT_DELAY = 500;

export class WindowService {
  public static Event = WindowServiceEvent;

  private static _observer: EventObserver = new EventObserver("WindowService");

  private static _delayedEventTimeout: any;

  public static getName(): string {
    return "WindowService";
  }

  public static initialize() {
    window.addEventListener("resize", () => {
      if (this._observer.hasListeners(WindowServiceEvent.WINDOW_RESIZED)) {
        if (this._delayedEventTimeout !== undefined) {
          clearTimeout(this._delayedEventTimeout);
        }

        this._delayedEventTimeout = setTimeout(() => {
          this._delayedEventTimeout = undefined;

          this._observer.triggerEvent(WindowServiceEvent.WINDOW_RESIZED);
        }, WINDOW_RESIZE_EVENT_DELAY);
      }
    });

    try {
      const params: QueryParamsObjectWithoutArrays = WindowService.getQueryParams() as QueryParamsObjectWithoutArrays;
      if (has(params, "go")) {
        const go = params["go"];
        if (has(params, "params")) {
          const goParams = JSON.parse(params?.params);
          LOG.debug("Setting route to ", go, goParams);
          RouteService.setRouteTarget(go, goParams);
        } else {
          LOG.debug("Setting route to ", go);
          RouteService.setRouteTarget(go);
        }
      }
    } catch (err) {
      LOG.error("Failed to set route: ", err);
    }

    LOG.debug(`${this.getName()} initialized: ${WINDOW.location.href}`);
  }

  /**
   * Returns the query parameters as a encoded string without the initial "?" character
   */
  public static getQueryParamsString(): string {
    const searchString = WINDOW.location?.search ?? "?";

    if (searchString.length <= 0) {
      return "";
    }

    if (searchString[0] === "?") {
      return searchString.substring(1);
    }

    return searchString;
  }

  public static getQueryParams(arrayKeys: string[] | undefined = undefined): QueryParamsObject | QueryParamsObjectWithoutArrays {
    return HttpUtils.parseQueryParams(WindowService.getQueryParamsString(), arrayKeys);
  }

  public static getWidth(): number {
    return WINDOW.innerWidth;
  }

  public static getHeight(): number {
    return WINDOW.innerHeight;
  }

  public static on(e: WindowServiceEvent, callback: EventObserverCallback): WindowServiceDestructor {
    return this._observer.listenEvent(e, callback);
  }

  public static getLocationPathname(): string {
    return WINDOW?.location?.pathname ?? "";
  }

  public static getLocationHref(): string {
    return WINDOW?.location?.href ?? "";
  }

  public static isLocationAtPathName(name: string): boolean {
    return startsWith(WindowService.getLocationPathname(), name);
  }

  public static setLocationHref(value: string) {
    LOG.debug("Moving user to URL: ", value);
    WINDOW.location.replace(value);
  }

  public static goToBundle(value: string) {
    const currentPath = this.getLocationPathname();
    const currentQueryParams = this.getQueryParams();

    WindowService.setLocationHref(value + "?go=" + encodeURIComponent(currentPath) + "&params=" + encodeURIComponent(JSON.stringify(currentQueryParams)));
  }

  /**
   *
   * @param key
   * @param value If it's non-string, will use JSON.stringify() to turn it into string
   */
  public static setQueryParam(key: string, value: any) {
    const newValue = isString(value) ? value : JSON.stringify(value);

    const params = WindowService.getQueryParams();

    if (params[key] !== newValue) {
      params[key] = newValue;

      LOG.debug("Setting query param as: ", key, value);

      RouteService.setRouteTarget(WindowService.getLocationPathname() + "?" + HttpUtils.stringifyQueryParams(params));
    } else {
      LOG.debug("Query param was already set: ", key, value);
    }
  }

  /**
   *
   * @param key
   * @param list If items are non-string, will use JSON.stringify() to turn it into string
   */
  public static setQueryParamList(key: string, list: any[]) {
    const newList = map(list, (item: any): string => (isString(item) ? item : JSON.stringify(item)));

    const params = WindowService.getQueryParams();

    if (!isEqual(params[key], newList)) {
      params[key] = newList;

      LOG.debug("Setting query param as list: ", key, newList);

      RouteService.setRouteTarget(WindowService.getLocationPathname() + "?" + HttpUtils.stringifyQueryParams(params));
    } else {
      LOG.debug("Query param list was already set to the same value: ", key, newList);
    }
  }

  public static unsetQueryParam(key: string) {
    const params = WindowService.getQueryParams();

    if (has(params, key)) {
      delete params[key];

      LOG.debug("Removing query param: ", key);
      RouteService.setRouteTarget(WindowService.getLocationPathname() + "?" + HttpUtils.stringifyQueryParams(params));
    } else {
      LOG.debug("Query param was already absent: ", key);
    }
  }

  /**
   * This function parses the next path item from the current URL and returns it.
   *
   * @param path This is the path item to look for, eg if the path is `"policies"` and the url is "/policies/FOO/A/B/C"
   *             it will return the string at the "FOO".
   */
  public static parseNextURIComponent(path: string): string {
    const items = (WINDOW?.location?.pathname ?? "").split(`/${path}/`);
    items.shift();
    return items.shift() ?? "";
  }

  /**
   * This function parses the first path item from the current URL.
   *
   * Eg. if window.pathname is "/12345678/reset_password" it will return 12345678.
   *
   */
  public static parseFirstURIComponent(): string {
    const items = (WINDOW?.location?.pathname ?? "").split(`/`);
    items.shift();
    return items.shift() ?? "";
  }
}

export default WindowService;
