// Copyright © 2020, 2021 HMD Global. All rights reserved.

export const ADD_ENTERPRISE_MODAL_CLASS_NAME = "hmd-add-enterprise-modal";
export const EDIT_ENTERPRISE_MODAL_CLASS_NAME = "hmd-edit-enterprise-modal";
export const DELETE_ENTERPRISE_MODAL_CLASS_NAME = "hmd-delete-enterprise-modal";
export const APP_CLASS_NAME = "App";
export const AD_CLASS_NAME = "hmd-a";
export const FORM_CONTAINER_CLASS_NAME = "hmd-form-container";
export const FORM_CLASS_NAME = "hmd-form";
export const FIELD_CLASS_NAME = "hmd-field";
export const FIELD_ICON_CLASS_NAME = "hmd-field-icon";
export const TEXT_FIELD_CLASS_NAME = "hmd-text-field";
export const TEXT_AREA_FIELD_CLASS_NAME = "hmd-text-area-field";
export const FIELD_SIZE_CLASS_NAME = "hmd-field-size";
export const TIME_FIELD_CLASS_NAME = "hmd-time-field";
export const PASSWORD_FIELD_CLASS_NAME = "hmd-password-field";
export const CHANGE_PASSWORD_VIEW_CLASS_NAME = "hmd-change-password-view";
export const CHANGE_PASSWORD_FORM_CLASS_NAME = "hmd-change-password-form";
export const TERMS_AND_CONDITIONS_VIEW_CLASS_NAME = "hmd-terms-and-conditions-view";
export const TERMS_AND_CONDITIONS_FORM_CLASS_NAME = "hmd-terms-and-conditions-form";
export const EMAIL_FIELD_CLASS_NAME = "hmd-email-field";
export const INTEGER_FIELD_CLASS_NAME = "hmd-integer-field";
export const CHECKBOX_FIELD_CLASS_NAME = "hmd-checkbox-field";
export const SELECT_FIELD_CLASS_NAME = "hmd-select-field";
export const SELECT_POLICY_FIELD_CLASS_NAME = "hmd-select-policy-field";
export const DATE_TIME_FIELD_CLASS_NAME = "hmd-date-time-field";
export const DURATION_FIELD_CLASS_NAME = "hmd-duration-field";
export const TOGGLE_FIELD_CLASS_NAME = "hmd-toggle-field";
export const LABEL_CLASS_NAME = "hmd-label";
export const BUTTON_CLASS_NAME = "hmd-button";
export const FORM_SUBMIT_BUTTON_CLASS_NAME = "hmd-form-submit-button";
export const LOGIN_FORM_CLASS_NAME = "hmd-login-form";
export const FORGOT_PASSWORD_FORM_CLASS_NAME = "hmd-forgot-password-form";
export const ENTERPRISE_EDIT_FORM_CLASS_NAME = "hmd-enterprise-edit-form";
export const POLICY_EDIT_FORM_CLASS_NAME = "hmd-policy-edit-form";
export const ICON_CLASS_NAME = "hmd-icon";
export const FIELD_SEPARATOR_CLASS_NAME = "hmd-field-separator";
export const ERROR_MESSAGE_CLASS_NAME = "hmd-error-message";
export const ALERT_MESSAGE_CLASS_NAME = "hmd-alert-message";
export const SUCCESS_MESSAGE_CLASS_NAME = "hmd-success-message";
export const BRANDING_CLASS_NAME = "hmd-branding";
export const LOGIN_VIEW_CLASS_NAME = "hmd-login-view";
export const AUTHORIZED_VIEW_CLASS_NAME = "hmd-authorized-view";
export const SHOWCASE_CLASS_NAME = "hmd-showcase";
export const APP_SELECT_IFRAME_CLASS_NAME = "hmd-app-select-iframe";
export const ZERO_TOUCH_IFRAME_CLASS_NAME = "hmd-app-zero-touch-iframe";
export const GOOGLE_MANAGED_APP_CONFIG_IFRAME_CLASS_NAME = "hmd-managed-app-config-iframe";
export const APP_PICKER_CLASS_NAME = "hmd-app-picker";
export const MAP_CLASS_NAME = "hmd-map";
export const KIOSK_APP_PICKER_CLASS_NAME = "hmd-kiosk-app-picker";
export const APPLICATION_LIST_VIEW_CLASS_NAME = "hmd-application-list-view";
export const ERROR_VIEW_CLASS_NAME = "hmd-error-view";
export const LOADING_VIEW_CLASS_NAME = "hmd-loading-view";
export const SHOWCASE_VIEW_CLASS_NAME = "hmd-showcase-view";
export const SETUP_VIEW_CLASS_NAME = "hmd-setup-view";
export const MODAL_CONTAINER_CLASS_NAME = "hmd-modal-container";
export const NOTIFICATION_CONTAINER_CLASS_NAME = "hmd-notification-container";
export const SIGNOUT_MODAL_CLASS_NAME = "hmd-signout-modal";
export const DEVICE_LIST_VIEW_CLASS_NAME = "hmd-device-list-view";
export const DELETE_USER_MODAL_CLASS_NAME = "hmd-delete-user-modal";
export const INVITE_USER_MODAL_CLASS_NAME = "hmd-invite-user-modal";
export const EDIT_USER_MODAL_CLASS_NAME = "hmd-edit-user-modal";
export const EDIT_USER_ENTERPRISES_MODAL_CLASS_NAME = "hmd-edit-user-enterprises-modal";
export const DEVICE_VIEW_CLASS_NAME = "hmd-device-view";
export const POLICY_VIEW_CLASS_NAME = "hmd-policy-view";
export const TABLE_CLASS_NAME = "hmd-table";
export const TOOLTIP_CLASS_NAME = "hmd-tooltip";
export const TOOLTIP_CONTENT_CLASS_NAME = "hmd-tooltip-content";
export const TABLE_HEADER_CLASS_NAME = "hmd-table-header";
export const TABLE_FOOTER_CLASS_NAME = "hmd-table-footer";
export const NOTICE_CLASS_NAME = "hmd-notice";
export const IMG_CLASS_NAME = "hmd-img";
export const USER_LIST_VIEW_CLASS_NAME = "hmd-user-list-view";
export const HOME_VIEW_CLASS_NAME = "hmd-home-view";
export const POLICY_LIST_VIEW_CLASS_NAME = "hmd-policy-list-view";
export const PROFILE_INFO_CLASS_NAME = "hmd-profile-info";
export const PRESENTATION_CLASS_NAME = "hmd-presentation";
export const POPUP_CLASS_NAME = "hmd-popup";
export const PROFILE_MENU_CLASS_NAME = "hmd-profile-menu";
export const LOADER_CLASS_NAME = "hmd-loader";
export const NEW_POLICY_MODAL_CLASS_NAME = "hmd-new-policy-modal";
export const ACCOUNT_VIEW_CLASS_NAME = "hmd-account-view";
export const PAGE_BUTTONS_CLASS_NAME = "hmd-page-buttons";
export const CONFIRM_MODAL_CLASS_NAME = "hmd-confirm-modal";
export const PASSWORD_REQUIREMENTS_MODAL_CLASS_NAME = "hmd-password-requirements-modal";
export const DELETE_POLICY_MODAL_CLASS_NAME = "hmd-delete-policy-modal";
export const EDIT_DEVICE_MODAL_CLASS_NAME = "hmd-edit-device-modal";
export const DEVICE_ENROLMENT_MODAL_CLASS_NAME = "hmd-device-enrolment-modal";
export const RESET_DEVICE_MODAL_CLASS_NAME = "hmd-reset-device-modal";
export const RESET_DEVICE_LIST_MODAL_CLASS_NAME = "hmd-reset-device-list-modal";
export const DEVICE_ON_MAP_MODAL_CLASS_NAME = "hmd-device-on-map-modal";
export const DEPLOY_POLICY_MODAL_CLASS_NAME = "hmd-deploy-policy-modal";
export const LOCK_DEVICE_MODAL_CLASS_NAME = "hmd-lock-device-modal";
export const CONFIRM_DEVICE_LIST_MODAL_CLASS_NAME = "hmd-confirm-device-list-modal";
export const RESET_USER_PASSWORD_MODAL_CLASS_NAME = "hmd-reset-user-password-modal";
export const PROMOTE_USER_MODAL_CLASS_NAME = "hmd-promote-user-modal";
export const DEMOTE_USER_MODAL_CLASS_NAME = "hmd-demote-user-modal";
export const ACTIVATE_USER_MODAL_CLASS_NAME = "hmd-activate-user-modal";
export const DEACTIVATE_USER_MODAL_CLASS_NAME = "hmd-deactivate-user-modal";
export const REBOOT_DEVICE_MODAL_CLASS_NAME = "hmd-reboot-device-modal";
export const SELECT_AND_DEPLOY_POLICY_TO_DEVICE_MODAL_CLASS_NAME = "hmd-select-and-deploy-policy-to-device-modal";
export const SELECT_AND_DEPLOY_POLICY_TO_DEVICE_LIST_MODAL_CLASS_NAME = "hmd-select-and-deploy-policy-to-device-list-modal";
export const APPROVE_APPS_MODAL_CLASS_NAME = "hmd-approve-apps-modal";
export const NETWORK_CONFIGURATIONS_MODAL_CLASS_NAME = "hmd-network-configurations-modal";
export const POLICY_APP_CONFIG_CLASS_NAME = "hmd-policy-app-config";
export const APPLICATION_POLICY_MODAL_CLASS_NAME = "hmd-application-policy-modal";
export const MANAGED_CONFIGURATION_TEMPLATE_FIELD_CLASS_NAME = "hmd-managed-configuration-template-field";
export const ENTERPRISE_LIST_VIEW_CLASS_NAME = "hmd-enterprise-list-view";
export const ENTERPRISE_VIEW_CLASS_NAME = "hmd-enterprise-view";
export const PASSWORD_REQUIREMENTS_FIELD_CLASS_NAME = "hmd-password-requirements-field";
export const BYTES_CLASS_NAME = "hmd-bytes";
export const CHART_CLASS_NAME = "hmd-chart";
export const PIE_CHART_CLASS_NAME = "hmd-pie-chart";
export const BAR_CHART_CLASS_NAME = "hmd-bar-chart";
export const INFO_BOX_CLASS_NAME = "hmd-info-box";
export const BUBBLE_COUNTER_CLASS_NAME = "hmd-bubble-counter";
export const ABOUT_LEGAL_MODAL_CLASS_NAME = "hmd-about-legal-modal";
export const MASONRY_CLASS_NAME = "hmd-masonry";
export const MANAGED_APP_CONFIGURATION_MODAL_CLASS_NAME = "hmd-managed-app-configuration-modal";
export const PACKAGE_NAME_FIELD_CLASS_NAME = "hmd-package-name-field";
export const DEVICE_APPLICATIONS_MODAL_CLASS_NAME = "hmd-device-applications-modal";
export const DEVICE_STATE_COMPONENT_CLASS_NAME = "hmd-device-state-component";
export const DEVICE_FILTER_OPTIONS_COMPONENT_CLASS_NAME = "hmd-device-filter-options-component";
export const APPLICATION_STATE_COMPONENT_CLASS_NAME = "hmd-application-state-component";
export const DASHBOARD_CONFIG_MODAL_CLASS_NAME = "hmd-dashboard-config-modal";
export const NOT_FOUND_VIEW_CLASS_NAME = "hmd-not-found-view";
export const EXPAND_SECTION_CLASS_NAME = "hmd-expand-section";
export const RESET_PASSWORD_ON_DEVICE_MODAL_CLASS_NAME = "hmd-reset-password-on-device-modal";
export const RESET_PASSWORD_ON_DEVICE_LIST_MODAL_CLASS_NAME = "hmd-reset-password-on-device-list-modal";
export const ENTERPRISE_AGREEMENT_VIEW_CLASS_NAME = "hmd-enterprise-agreement-view";
export const ENTERPRISE_AGREEMENT_FORM_CLASS_NAME = "hmd-enterprise-agreement-form";
export const DEVICE_GROUPS_LIST_VIEW_CLASS_NAME = "hmd-device-groups-list-view";
export const DEVICE_GROUP_VIEW_CLASS_NAME = "hmd-device-group-view";
export const ADD_DEVICE_GROUP_MODAL_CLASS_NAME = "hmd-add-device-group-modal";
export const ADD_DEVICE_TO_GROUP_MODAL_CLASS_NAME = "hmd-add-device-to-group-modal";
export const ADD_DEVICE_LIST_TO_GROUP_MODAL_CLASS_NAME = "hmd-add-device-list-to-group-modal";
export const EDIT_DEVICE_GROUP_MODAL_CLASS_NAME = "hmd-edit-device-group-modal";
export const DELETE_DEVICE_GROUP_MODAL_CLASS_NAME = "hmd-delete-device-group-modal";
export const REMOVE_DEVICE_FROM_GROUP_MODAL_CLASS_NAME = "hmd-remove-device-from-group-modal";
export const REMOVE_DEVICE_LIST_FROM_GROUP_MODAL_CLASS_NAME = "hmd-remove-device-list-from-group-modal";
export const DEVICE_MESSAGE_MODAL_CLASS_NAME = "hmd-device-message-modal";
export const DEVICE_LIST_MESSAGE_MODAL_CLASS_NAME = "hmd-device-list-message-modal";
export const DEVICE_GROUP_MESSAGE_MODAL_CLASS_NAME = "hmd-device-group-message-modal";
export const HELP_NAVIGATION_VIEW_CLASS_NAME = "hmd-help-navigation-view";
export const YOUTUBE_PLAYLIST_VIEW_CLASS_NAME = "hmd-youtube-playlist-view";
export const IMPORT_USERS_MODAL_CLASS_NAME = "hmd-import-users-modal";
export const TFA_VIEW_CLASS_NAME = "hmd-tfa-view";
export const RESET_TFA_MODAL_CLASS_NAME = "hmd-reset-tfa-modal";
export const DEVICE_USER_LIST_VIEW_CLASS_NAME = "hmd-device-user-list-view";
export const ADD_DEVICE_USER_MODAL_CLASS_NAME = "hmd-add-device-user-modal";
export const EDIT_DEVICE_USER_MODAL_CLASS_NAME = "hmd-edit-device-user-modal";
export const DELETE_DEVICE_USER_MODAL_CLASS_NAME = "hmd-delete-device-user-modal";
export const ASSIGN_DEVICE_USER_MODAL_CLASS_NAME = "hmd-assign-device-user-modal";
export const IMPORT_DEVICE_USERS_MODAL_CLASS_NAME = "hmd-import-device-users-modal";
export const CONFIGURE_APPS_MODAL_CLASS_NAME = "hmd-configure-apps-modal";
export const ENTERPRISE_APP_CONFIGURATION_MODAL_CLASS_NAME = "hmd-enterprise-app-configuration-modal";
export const APPLY_PERMISSION_MODAL_CLASS_NAME = "hmd-apply-permission-to-apps-modal";
export const ADD_USERS_TO_ENTERPRISE_MODAL_CLASS_NAME = "hmd-add-users-to-enterprise-modal";
export const DEVICE_USER_VIEW_CLASS_NAME = "hmd-device-user-view";
export const USER_MANAGED_APP_CONFIGURATION_MODAL_CLASS_NAME = "hmd-user-managed-app-configuration-modal";
export const POLICY_PERSONAL_APP_CONFIG_CLASS_NAME = "hmd-policy-personal-app-config";
export const CONFIRM_LARGE_DEVICE_GROUP_UPDATE_MODAL_CLASS_NAME = "hmd-confirm-large-device-group-update-modal";
export const WARNING_CLASS_NAME = "hmd-warning-component";
export const ADD_GEOFENCE_MODAL_CLASS_NAME = "hmd-add-geofence-modal";
export const EDIT_GEOFENCE_MODAL_CLASS_NAME = "hmd-edit-geofence-modal";
export const DEVICE_REMOTE_MANAGEMENT_MODAL_CLASS_NAME = "hmd-device-remote-management-modal";
export const ADD_FREEZE_PERIOD_MODAL_CLASS_NAME = "hmd-add-freeze-period-modal";
export const CSV_EXPORT_MODAL_CLASS_NAME = "hmd-csv-export-modal";
export const RELINQUISH_OWNERSHIP_MODAL_CLASS_NAME = "hmd-relinquish-ownership-modal";
export const TOAST_CONTAINER_CLASS_NAME = "hmd-toast-container";
export const TOAST_NOTIFICATION_CLASS_NAME = "hmd-toast-notification";
export const DEVICE_OPERATIONS_LOG_MODAL_CLASS_NAME = "hmd-device-operations-log-modal";
export const LOCK_SCREEN_KEYGUARD_CONFIGURATION_MODAL_CLASS_NAME = "hmd-lock-screen-keyguard-configuration-modal";
export const LOCK_SCREEN_KEYGUARD_FIELD_CLASS_NAME = "hmd-lock-screen-keyguard-field";
export const POLICY_MANAGE_CONTACTS_MODAL_CLASS_NAME = "hmd-policy-manage-contacts-modal";
