// Copyright © 2020 HMD Global. All rights reserved.

import { isString } from "../modules/lodash";
import { MINIMUM_PASSWORD_LENGTH } from "../constants/backend";

export class ProfileUtils {
  /**
   * Checks password validity for EMM system.
   *
   * EMM password must be:
   *
   *   - Type of string
   *
   *   - At least MINIMUM_PASSWORD_LENGTH characters long
   *
   *   - Have at least one special character (eg. not one of A-Z, a-z or 0-9)
   *
   *   - Have at least one uppercase letter (A-Z)
   *
   * @param value
   */
  public static validatePassword(value: string): value is string {
    return !!value && isString(value) && value.length >= MINIMUM_PASSWORD_LENGTH && /[^A-Za-z0-9]/.test(value) && /[A-Z]/.test(value) && /\d/.test(value);
  }

  /**
   * Validates that password2 starts with password1
   *
   * @param password1
   * @param password2
   */
  public static validatePasswordHasEqualStart(password1: string, password2: string): boolean {
    return !!password1 && password2.length <= password1.length && password1.substr(0, password2.length) === password2;
  }

  /**
   *
   *  - Verify user provided old password
   *  - Verify user did not use same password again
   *
   * @param oldPassword
   * @param newPassword
   */
  public static validateOldPassword(oldPassword: string, newPassword: string): boolean {
    return !!oldPassword && newPassword !== oldPassword;
  }

  /**
   * Verify user submitted identical passwords
   *
   * @param newPassword
   * @param newPasswordAgain
   */
  public static validatePasswordsMatch(newPassword: string, newPasswordAgain: string): boolean {
    return !!newPasswordAgain && newPasswordAgain === newPassword;
  }

  /**
   * Validate that newPassword is in correct format (see ProfileUtils.validatePassword()) and that it is not same as
   * old password, and newPasswordAgain is same as newPassword, too.
   *
   * @param oldPassword
   * @param newPassword
   * @param newPasswordAgain
   */
  public static validatePasswordChange(oldPassword: string, newPassword: string, newPasswordAgain: string): boolean {
    return (
      ProfileUtils.validateOldPassword(oldPassword, newPassword) && ProfileUtils.validatePassword(newPassword) && ProfileUtils.validatePasswordsMatch(newPasswordAgain, newPassword)
    );
  }
}

export default ProfileUtils;
