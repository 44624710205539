// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import "./SelectField.scss";
import ReactSelect, { MenuPlacement, Styles as ReactSelectStyles } from "react-select";
import styleVariables from "../../../styles/variables.scss";
import { find } from "lodash";
import { SELECT_FIELD_CLASS_NAME } from "../../../constants/classNames";
import Field from "../field/Field";
import { TFunction } from "i18next";
import { every, filter, map } from "../../../modules/lodash";
import LabelType from "../label/LabelType";
import Label, { LabelProps } from "../label/Label";
import LogService from "../../../services/LogService";
import FieldSeparator from "../fieldSeparator/FieldSeparator";
import Button, { ButtonClickCallback } from "../button/Button";
import Icon from "../icon/Icon";
import { IconType } from "../icon/IconType";

const LOG = LogService.createLogger("SelectField");

/**
 * This is a counter for identifying select fields (for debugging)
 */
let LAST_SELECT_ID = 0;

export interface SelectOptionWithType<T> {
  value?: T;
  label?: string;
}

export type SelectOption = SelectOptionWithType<any>;
export type SelectOptionOf<T> = SelectOptionWithType<T>;

export interface SelectClearCallback {
  (): void;
}
export interface SelectChangeCallback {
  (value: SelectOption, actionMeta: any): void;
}
export interface SelectValidCallback {
  (value: SelectOption | undefined): boolean;
}

export interface SelectChangeCallbackOf<T> {
  (value: SelectOptionOf<T>, actionMeta: any): void;
}

export interface SelectValidCallbackOf<T> {
  (value: SelectOptionOf<T> | undefined): boolean;
}

interface ReactSelectChangeCallback {
  (value: any, actionMeta: any): void;
}

export interface SelectProps {
  className?: string | undefined;
  t?: TFunction;
  label?: string | undefined;
  labelType?: LabelType;
  icon?: string | undefined;
  name?: string | undefined;
  placeholder?: string | undefined;
  borders?: boolean;
  enabled?: boolean;
  isValid?: SelectValidCallback;
  change?: SelectChangeCallback;
  clear?: SelectClearCallback;
  value?: string;
  options?: SelectOption[];
  clearButton?: boolean;
  menuPortalTarget?: any;
  menuPlacement?: MenuPlacement;

  /**
   * If defined, will create a tool tip icon with a hovering popup content for the field, which includes help text for
   * every option.
   */
  tooltipPrefix?: string | undefined;

  tooltip?: any | undefined;

  /**
   * You may add SelectOption.value's here which should not be visible in the selection
   */
  hideValues?: string[];

  /**
   * You may add SelectOption.value's here which should be visible in the selection but not selectable
   */
  disabledValues?: string[];
}

export interface SelectState {
  options?: SelectOption[];
}

export type SelectOptionStyles = ReactSelectStyles<SelectOption, false>;

export class SelectField extends React.Component<SelectProps, SelectState> {
  private readonly _id: number;
  private readonly _changeCallback: ReactSelectChangeCallback;
  private readonly _isOptionDisabledCallback: (option: SelectOption) => boolean;
  private readonly _clearValueCallback: ButtonClickCallback;

  private styles: Partial<SelectOptionStyles>;

  static defaultProps: Partial<SelectProps> = {
    placeholder: "...",
    borders: true,
    enabled: true,
    hideValues: [],
    disabledValues: [],
    labelType: LabelType.DEFAULT,
    menuPortalTarget: document.body,
  };

  constructor(props: SelectProps) {
    super(props);

    this.state = {
      options: [],
    };

    this.styles = this._createStyles();

    LAST_SELECT_ID += 1;

    this._id = LAST_SELECT_ID;

    this._changeCallback = this._onChange.bind(this);
    this._isOptionDisabledCallback = this._isOptionDisabled.bind(this);
    this._clearValueCallback = this._onClearValue.bind(this);
  }

  componentDidMount() {
    this.styles = this._createStyles();
    this._updateOptions();
  }

  componentDidUpdate(prevProps: Readonly<SelectProps>) {
    if (this.props.borders !== prevProps.borders) {
      this.styles = this._createStyles();
      // } else if (this.props.value !== prevProps.value) {
      //     this.styles = this._createStyles();
    }

    if (this.props.t !== prevProps.t || this.props.options !== prevProps.options || this.props.hideValues !== prevProps.hideValues) {
      this._updateOptions();
    } else {
      LOG.debug(this._id + ": No changes for options: ", this.props.t, prevProps.t, this.props.options, prevProps.options, this.props.hideValues, prevProps.hideValues);
    }
  }

  render() {
    const t = this.props?.t ?? ((key: string): string => key);

    const selectedOption: SelectOption | undefined = this._getSelectedOption(this.props.value);

    const isValid = this._isValidWithValue(selectedOption);

    const selectedLabel: string | undefined = selectedOption?.label;

    const style: { width?: string } = {};

    if (this.props.labelType === LabelType.WIDE) {
      style.width = `${8 * (selectedLabel ? selectedLabel.length : 1) + 100}px`;
    }

    const fieldProps: Record<string, any> = {
      className: SELECT_FIELD_CLASS_NAME + "-field",
      borders: this.props.borders,
      style: style,
    };

    const labelProps: Partial<LabelProps> = {};

    if (this.props.tooltip) {
      labelProps.tooltip = this.props.tooltip;
    }

    if (this.props.tooltipPrefix) {
      labelProps.tooltip = (
        <div className={SELECT_FIELD_CLASS_NAME + "-tooltip"}>
          {map(this.state.options, (option: SelectOption) => {
            return (
              <p key={option.value} className={SELECT_FIELD_CLASS_NAME + "-tooltip-item"}>
                <strong className={SELECT_FIELD_CLASS_NAME + "-tooltip-item-label"}>{option.label}</strong>
                <span className={SELECT_FIELD_CLASS_NAME + "-tooltip-item-content"}> {t(this.props.tooltipPrefix + "." + option.value)}</span>
              </p>
            );
          })}
        </div>
      );
    }

    LOG.debug(this._id + ": selectedOption / value / options = ", selectedOption, this.props.value, this.state.options);

    return (
      <Label
        {...labelProps}
        className={
          SELECT_FIELD_CLASS_NAME +
          " " +
          (this.props.className ?? "") +
          " " +
          SELECT_FIELD_CLASS_NAME +
          (this.props.enabled ? "-enabled" : "-disabled") +
          " " +
          SELECT_FIELD_CLASS_NAME +
          (isValid ? "-valid" : "-invalid")
        }
        label={this.props.label}
        type={this.props.labelType}
        field={Field}
        fieldProps={fieldProps}
      >
        <ReactSelect
          className={SELECT_FIELD_CLASS_NAME + "-input"}
          classNamePrefix={SELECT_FIELD_CLASS_NAME}
          menuPortalTarget={this.props.menuPortalTarget}
          styles={this.styles}
          name={this.props.name}
          placeholder={this.props.placeholder}
          options={this.state.options}
          value={selectedOption ?? null}
          isDisabled={!this.props.enabled}
          onChange={this._changeCallback}
          menuPlacement={this.props.menuPlacement ?? "bottom"}
          isOptionDisabled={this._isOptionDisabledCallback}
        />

        {this.props.clearButton && this.props.value ? (
          <>
            <FieldSeparator className={SELECT_FIELD_CLASS_NAME + "-separator"} />
            <Button className={SELECT_FIELD_CLASS_NAME + "-clear-button"} borders={false} click={this._clearValueCallback}>
              <Icon type={IconType.CLOSE} />
            </Button>
          </>
        ) : null}
      </Label>
    );
  }

  private _updateOptions() {
    let rawOptions = this.props?.options;

    const hideValues = this.props?.hideValues;
    const t = this.props?.t;

    if (rawOptions) {
      LOG.debug(this._id + ": Updating options: ", rawOptions, hideValues, t);

      if (hideValues) {
        rawOptions = filter(rawOptions, (item: SelectOption): boolean => {
          return every(hideValues, (hideValue: string): boolean => item.value !== hideValue);
        });
      }

      LOG.debug(this._id + ": RAW OPTIONS = ", rawOptions);

      let options = [];

      if (t) {
        options = map(rawOptions, (item: SelectOption): SelectOption => {
          return {
            label: t(item.label ?? item.value),
            value: item.value,
          };
        });
      } else {
        options = rawOptions;
      }

      LOG.debug(this._id + ": STATE CHANGE: options = ", options);

      this.setState({
        ...this.state,
        options: options,
      });
    } else {
      LOG.warn("Warning! No options defined for select #" + this._id);
    }
  }

  private _createStyles(): Partial<SelectOptionStyles> {
    return {
      container: (provided) => ({
        ...provided,
        padding: 0,
        margin: 0,
      }),

      control: (provided) => ({
        ...provided,
        padding: 0,
        borderWidth: 0,
        //borderWidth: this.props.borders ? styleVariables.selectBorderSize : 0,
        //borderRadius: styleVariables.selectBorderRadius,
        //backgroundColor: styleVariables.selectBackgroundColor,
        //borderColor: styleVariables.selectBorderColor
      }),

      placeholder: (provided) => ({
        ...provided,
        color: styleVariables.selectPlaceholderColor,
      }),

      indicatorSeparator: (provided) => ({
        ...provided,
        width: this.props.borders ? styleVariables.selectIndicatorSeparatorSize : 0,
        backgroundColor: styleVariables.selectIndicatorSeparatorColor,
      }),

      menuPortal: (provided) => ({
        ...provided,
        zIndex: 99999,
      }),

      // menu: (provided, state) => ({
      //     ...provided,
      //     // width: state.selectProps.width,
      //     // borderBottom: '1px dotted pink',
      //     // color: state.selectProps.menuColor,
      //     // color: state.selectProps.menuColor,
      //     color: 'red',
      //     padding: 0,
      // })
      //
      // control: (_, { selectProps: { width }}) => ({
      //     width: width
      // })

      // , singleValue: (provided, state) => {
      //
      //     const opacity = state.isDisabled ? 0.5 : 1;
      //
      //     const transition = 'opacity 300ms';
      //
      //     return { ...provided, opacity, transition };
      //
      // }
    };
  }

  private _isValidWithValue(value: SelectOption | undefined): boolean {
    if (this.props.isValid) {
      try {
        return this.props.isValid(value);
      } catch (err) {
        LOG.error(this._id + ": Exception in isValid callback: ", err);
      }
      return false;
    }

    return true;
  }

  private _getSelectedOption(value: string | undefined): SelectOption | undefined {
    if (value === undefined) return undefined;

    return find(this.state.options, (item: SelectOption) => item.value === value);
  }

  private _onChange(value: SelectOption, actionMeta: any) {
    if (this.props?.change) {
      try {
        this.props.change(value, actionMeta);
      } catch (err) {
        LOG.error("Error in change callback: ", err);
      }
    } else {
      LOG.warn("No change callback defined");
    }
  }

  private _isOptionDisabled(option: SelectOption): boolean {
    if (!this.props.disabledValues) return false;
    return !!this.props.disabledValues.find((item: string) => option.value === item);
  }

  private _onClearValue() {
    if (this.props?.clear) {
      try {
        this.props.clear();
      } catch (err) {
        LOG.error("Error in clear callback: ", err);
      }
    } else {
      LOG.warn("No clear callback defined");
    }
  }
}

export default SelectField;
