import { every } from "../modules/lodash";

export class BackendUtils {
  /**
   * This tests the syntax of a backend ID
   *
   * @param value {string}
   */
  public static isValidBackendId(value: string): boolean {
    return every(value, (char: string) => "0123456789".includes(char));
  }
}

export default BackendUtils;
