// Copyright © 2020 HMD Global. All rights reserved.

import DEVICE_IMAGE from "../assets/hmd-login-page.svg";
//import SIMCARD_IMAGE from "../assets/images/simcard.svg";
import NDAC_REBRANDING from "../assets/nokia/Ndac-rebranding.png";
import styles from "../styles/variables.scss";

export interface PresentationModel {
  readonly id: string;
  readonly image?: string;
  readonly imageAlt?: string;
  readonly title?: string;
  readonly content?: string;
  readonly url?: string;
  readonly urlText?: string;
}

export class AdService {
  private static _presentations: Readonly<Array<PresentationModel>> = [
    {
      id: "1",
      image: DEVICE_IMAGE,
      imageAlt: "logo",
      //title: 'Keep your organization connected with HMD Connect',
      //content: 'Get full control and the ease of managing your data at price that works for you.',
      //url: undefined,
      //urlText: undefined
    },
    //,
    // {
    //     id: '2',
    //     image: DEVICE_IMAGE,
    //     imageAlt: 'logo',
    //     title: 'Lorem 2 ipsum dolor sit amet',
    //     content: 'The second quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox',
    //     url: undefined,
    //     urlText: undefined
    // },
    // {
    //     id: '3',
    //     image: DEVICE_IMAGE,
    //     imageAlt: 'logo',
    //     title: 'Lorem 3 ipsum dolor sit amet',
    //     content: 'The second quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox',
    //     url: undefined,
    //     urlText: undefined
    // }
  ];

  private static _sideAds = [
    // {
    //     id: '4',
    //     image: SIMCARD_IMAGE,
    //     imageAlt: 'simcard',
    //     title: 'Need more SIM cards for your company?',
    //     content: '',
    //     url: '/ad/4',
    //     urlText: 'Order now'
    // }
  ];

  private static _ndac_presentation: Readonly<Array<PresentationModel>> = [
    {
      id: "1",
      image: NDAC_REBRANDING,
      imageAlt: "logo",
    },
  ];

  public static getLoginPresentations(): Readonly<Array<PresentationModel>> {
    return styles.themeInUse === "ndac" ? this._ndac_presentation : this._presentations;
  }

  public static getSetupPresentations(): Readonly<Array<PresentationModel>> {
    return styles.themeInUse === "ndac" ? this._ndac_presentation : this._presentations;
  }

  public static getAuthorizedViewFooterAd(): PresentationModel | undefined {
    return this._sideAds.length ? this._sideAds[0] : undefined;
  }
}

export default AdService;
