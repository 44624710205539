// Copyright © 2023 HMD Global. All rights reserved.

import React, { useState, useRef, ReactNode } from "react";
import "./Tooltip.scss";
import styleVariables from "../../../styles/variables.scss";
import {
  Placement,
  offset,
  flip,
  shift,
  autoUpdate,
  useFloating,
  useInteractions,
  useHover,
  useFocus,
  useRole,
  useDismiss,
  FloatingPortal,
  arrow,
  FloatingArrow,
} from "@floating-ui/react";
import IconType from "../icon/IconType";
import { TOOLTIP_CLASS_NAME, ICON_CLASS_NAME } from "../../../constants/classNames";
import { IconImage } from "../icon/IconImage";
import LogService from "../../../services/LogService";

const ARROW_HEIGHT = 7;

const LOG = LogService.createLogger("Tooltip");

interface TooltipProps {
  className?: string;
  placement?: Placement;
  children?: ReactNode;
}

const Tooltip = ({ placement = "top", className, children }: TooltipProps) => {
  const [open, setOpen] = useState(false);
  const arrowRef = useRef(null);

  const { x, y, refs, floating, strategy, context } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(ARROW_HEIGHT),
      flip(),
      shift({ padding: 8 }),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  LOG.debug(placement, strategy, x, y, context);

  const { getReferenceProps, getFloatingProps } = useInteractions([useHover(context), useFocus(context), useRole(context, { role: "tooltip" }), useDismiss(context)]);

  const IconValue = IconImage[IconType.HELP];

  return (
    <div className={`${TOOLTIP_CLASS_NAME} ${className ?? ""}`}>
      <span className={`${ICON_CLASS_NAME} ${ICON_CLASS_NAME}-component ${ICON_CLASS_NAME}-type-${IconType.HELP} `} ref={refs.setReference} {...getReferenceProps()}>
        <IconValue className={`${ICON_CLASS_NAME}-content`} />
      </span>

      {open && (
        <FloatingPortal>
          <div
            {...getFloatingProps({
              ref: floating,
              className: `${TOOLTIP_CLASS_NAME}-container`,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
              },
            })}
          >
            <FloatingArrow
              ref={arrowRef}
              context={context}
              fill={styleVariables.whiteColor}
              height={ARROW_HEIGHT}
              stroke={styleVariables.borderColor}
              strokeWidth={styleVariables.borderWidth}
            />
            {children}
          </div>
        </FloatingPortal>
      )}
    </div>
  );
};

export default Tooltip;
