// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import "./SubmitButton.scss";
import { FORM_SUBMIT_BUTTON_CLASS_NAME } from "../../../constants/classNames";
import Button from "../button/Button";
import { FormName } from "../../../services/FormService";
import { ButtonType } from "../../../types/ButtonType";

export interface SubmitButtonProps {
  form?: FormName;
  className?: string | undefined;
  label?: any | undefined;
  type?: ButtonType;
  loading?: boolean;
  enabled?: boolean;
}

export interface SubmitButtonState {}

export class SubmitButton extends React.Component<SubmitButtonProps, SubmitButtonState> {
  static defaultProps: SubmitButtonProps = {
    className: undefined,
    enabled: true,
    type: ButtonType.PRIMARY,
    loading: false,
    label: undefined,
  };

  constructor(props: SubmitButtonProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Button
        className={FORM_SUBMIT_BUTTON_CLASS_NAME + " " + FORM_SUBMIT_BUTTON_CLASS_NAME + (this.props.enabled ? "-enabled" : "-disabled") + " " + (this.props.className ?? "")}
        type={this.props.type}
        loading={this.props.loading}
        form={this.props.form}
        isSubmit={true}
        enabled={this.props.enabled}
      >
        {this.props.label}
      </Button>
    );
  }
}

export default SubmitButton;
