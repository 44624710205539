// Copyright © 2020 HMD Global. All rights reserved.

import RouteUtils from "../services/RouteUtils";
import DeviceId from "../types/DeviceId";
import PolicyId from "../types/PolicyId";
import AssertUtils from "../services/AssertUtils";
import { isString } from "../modules/lodash";
import EnterpriseId from "../types/EnterpriseId";

export const DEVICE_ID_KEY = "deviceId";
export const POLICY_ID_KEY = "policyId";
export const ENTERPRISE_ID_KEY = "enterpriseId";
export const DEVICE_GROUP_ID_KEY = "groupId";
export const DEVICE_USER_ID_KEY = "deviceUserId";

export enum RoutePath {
  UNAUTHORIZED_FORGOT_PASSWORD_WITH_BACKEND = "/:backendId/forgot_password",
  UNAUTHORIZED_RESET_PASSWORD_WITH_BACKEND = "/:backendId/reset_password",
  ACCEPT_T_C_WITH_BACKEND = "/:backendId/accept_term_and_conditions",
  INDEX_WITH_BACKEND = "/:backendId",
  UNAUTHORIZED_FORGOT_PASSWORD = "/forgot_password",
  UNAUTHORIZED_RESET_PASSWORD = "/reset_password",
  DEVICE_LIST = "/devices",
  DEVICE_GROUP_LIST = "/device_groups",
  APPLICATION_LIST = "/applications",
  ZERO_TOUCH = "/zerotouch",
  POLICY_LIST = "/policies",
  USER_LIST = "/users",
  DEVICE_USER_LIST = "/device_users",
  ENTERPRISE_LIST = "/enterprises",
  DEVICE = "/devices/:deviceId",
  DEVICE_GROUP = "/device_groups/:groupId",
  POLICY = "/policies/:policyId",
  ENTERPRISE = "/enterprises/:enterpriseId",
  ACCOUNT = "/account",
  INDEX = "/",
  HELP = "/help",
  VIDEOS = "/help/videos",
  DEVICE_USER = "/device_users/:deviceUserId",
  GOOGLE_MAP = "/map",
}

export function getDevicePath(deviceId: DeviceId): string {
  AssertUtils.isInstanceOf(deviceId, DeviceId);
  return RouteUtils.setRouteParam(RoutePath.DEVICE, DEVICE_ID_KEY, deviceId.getId());
}

export function getPolicyPath(policyId: PolicyId): string {
  AssertUtils.isInstanceOf(policyId, PolicyId);
  return RouteUtils.setRouteParam(RoutePath.POLICY, POLICY_ID_KEY, policyId.getId());
}

export function getEnterprisePath(enterpriseId: EnterpriseId): string {
  AssertUtils.isInstanceOf(enterpriseId, EnterpriseId);
  return RouteUtils.setRouteParam(RoutePath.ENTERPRISE, ENTERPRISE_ID_KEY, enterpriseId.getId());
}

export function getDeviceGroupPath(groupId: number): string {
  return RouteUtils.setRouteParam(RoutePath.DEVICE_GROUP, DEVICE_GROUP_ID_KEY, groupId);
}

export function getDeviceUserPath(deviceUserId: number): string {
  return RouteUtils.setRouteParam(RoutePath.DEVICE_USER, DEVICE_USER_ID_KEY, deviceUserId);
}

export function isRoutePath(value: any): value is RoutePath {
  if (!isString(value)) return false;

  switch (value) {
    case RoutePath.UNAUTHORIZED_FORGOT_PASSWORD_WITH_BACKEND:
    case RoutePath.UNAUTHORIZED_RESET_PASSWORD_WITH_BACKEND:
    case RoutePath.ACCEPT_T_C_WITH_BACKEND:
    case RoutePath.INDEX_WITH_BACKEND:
    case RoutePath.UNAUTHORIZED_FORGOT_PASSWORD:
    case RoutePath.UNAUTHORIZED_RESET_PASSWORD:
    case RoutePath.DEVICE_LIST:
    case RoutePath.DEVICE_GROUP_LIST:
    case RoutePath.APPLICATION_LIST:
    case RoutePath.ZERO_TOUCH:
    case RoutePath.POLICY_LIST:
    case RoutePath.USER_LIST:
    case RoutePath.DEVICE_USER_LIST:
    case RoutePath.ENTERPRISE_LIST:
    case RoutePath.ENTERPRISE:
    case RoutePath.DEVICE:
    case RoutePath.DEVICE_GROUP:
    case RoutePath.POLICY:
    case RoutePath.ACCOUNT:
    case RoutePath.INDEX:
    case RoutePath.HELP:
    case RoutePath.VIDEOS:
    case RoutePath.DEVICE_USER:
    case RoutePath.GOOGLE_MAP:
      return true;

    default:
      return false;
  }
}

/**
 * Returns `true` if this route target is available in both bundles.
 *
 * @param path
 */
export function isRoutePathInBothBundles(path: RoutePath): boolean {
  switch (path) {
    case RoutePath.INDEX:
    case RoutePath.INDEX_WITH_BACKEND:
      return true;

    default:
      return false;
  }
}

/**
 * Returns `true` if this route path is only available on unauthorized bundle
 *
 * @param path
 */
export function isRoutePathAuthorizedResource(path: RoutePath): boolean {
  switch (path) {
    case RoutePath.UNAUTHORIZED_FORGOT_PASSWORD_WITH_BACKEND:
    case RoutePath.UNAUTHORIZED_FORGOT_PASSWORD:
    case RoutePath.UNAUTHORIZED_RESET_PASSWORD:
    case RoutePath.UNAUTHORIZED_RESET_PASSWORD_WITH_BACKEND:
      return false;

    default:
      return true;
  }
}

/**
 * Note! This will show index pages as authorized resource. See also isRoutePathInBothBundles().
 *
 * @param path
 */
export function isRoutePathUnauthorizedResource(path: RoutePath): boolean {
  return !isRoutePathAuthorizedResource(path);
}

export default RoutePath;
