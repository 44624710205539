// Copyright © 2020 HMD Global. All rights reserved.

export enum LocalStorageKey {
  BACKEND_KEY = "hmdBackendKey",
  PREVIOUS_LOG_ID = "previousLogId",
  SETUP_ENABLED = "hmdSetupWizard",
  TABLE_CONFIG = "hmdTableConfig",
  PAGE_AMOUNT = "hmdPageAmount",
  SELECTED_ENTERPRISE = "selectedEnterprise",
}

export default LocalStorageKey;
