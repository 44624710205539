// Copyright © 2020 HMD Global. All rights reserved.

import { isString, isObject, isBoolean, isNumber, isArray } from "../modules/lodash";
import { IS_DEVELOPMENT_BUILD } from "../constants/environment";
import TestUtils from "./TestUtils";

export class AssertUtils {
  static isString(value: string) {
    if (IS_DEVELOPMENT_BUILD) {
      if (!isString(value)) {
        throw new TypeError("Asserted value was not string: " + value);
      }
    }
  }

  static isGUIDString(value: string) {
    if (IS_DEVELOPMENT_BUILD) {
      if (!TestUtils.isGUIDString(value)) {
        throw new TypeError("Asserted value was not GUID: " + value);
      }
    }
  }

  static isObject(value: Record<string, any>) {
    if (IS_DEVELOPMENT_BUILD) {
      if (!isObject(value)) {
        throw new TypeError("Asserted value was not object: " + value);
      }
    }
  }

  static isInstanceOf(value: Record<string, any>, type: any) {
    if (IS_DEVELOPMENT_BUILD) {
      if (!(isObject(value) && value instanceof type)) {
        throw new TypeError(`Asserted value was not instance of "${`${type}`.split("\n").shift()}": ${value}`);
      }
    }
  }

  static isBoolean(value: boolean) {
    if (IS_DEVELOPMENT_BUILD) {
      if (!isBoolean(value)) {
        throw new TypeError("Asserted value was not boolean: " + value);
      }
    }
  }

  static isNumber(value: number) {
    if (IS_DEVELOPMENT_BUILD) {
      if (!isNumber(value)) {
        throw new TypeError("Asserted value was not number: " + value);
      }
    }
  }

  static isNumberAndInRange(value: number, min: number, max: number) {
    if (IS_DEVELOPMENT_BUILD) {
      if (!isNumber(value)) {
        throw new TypeError("Asserted value not number: " + value);
      }

      if (value < min) {
        throw new TypeError(`Asserted value not in range ${min} - ${max}: ` + value);
      }

      if (value > max) {
        throw new TypeError(`Asserted value not in range ${min} - ${max}: ` + value);
      }
    }
  }

  static isNumberAndGreaterThan(value: number, min: number) {
    if (IS_DEVELOPMENT_BUILD) {
      if (!isNumber(value)) {
        throw new TypeError("Asserted value not number: " + value);
      }

      if (value < min) {
        throw new TypeError(`Asserted value was less than ${min}: ` + value);
      }
    }
  }

  static isArray(value: Array<any>) {
    if (IS_DEVELOPMENT_BUILD) {
      if (!isArray(value)) {
        throw new TypeError("Asserted value was not array: " + value);
      }
    }
  }
}

export default AssertUtils;
