// Copyright © 2020 HMD Global. All rights reserved.

import AssertUtils from "../services/AssertUtils";
import { IS_DEVELOPMENT_BUILD } from "../constants/environment";
import EnterpriseId from "./EnterpriseId";

/**
 * Frontend's policy id data model
 */
export class PolicyId {
  /**
   * The enterprise ID, eg. `"LC04ghmbil"`
   *
   * @private
   */
  private readonly _enterpriseId: string;

  /**
   * The policy name, eg. `"My Policy"`
   *
   * @private
   */
  private readonly _id: string;

  /**
   *
   * @param enterpriseId The enterprise ID, eg. `"LC04ghmbil"`
   * @param policyId The policy name, eg. `"My Policy"`
   */
  constructor(enterpriseId: string, policyId: string) {
    if (IS_DEVELOPMENT_BUILD) {
      AssertUtils.isString(enterpriseId);
      AssertUtils.isString(policyId);

      if (enterpriseId.startsWith("enterprises/")) {
        throw new TypeError("The enterprise ID must not start with enterprises/");
      }

      if (policyId.startsWith("enterprises/")) {
        throw new TypeError("The policy name must not start with enterprises/");
      }
    }

    if (!enterpriseId) throw new TypeError("PolicyId: enterpriseId was not defined");
    if (!policyId) throw new TypeError("PolicyId: policyId was not defined");

    this._enterpriseId = enterpriseId;
    this._id = policyId;
  }

  /**
   * Returns the enterprise ID part, eg. `"LC04ghmbil"`
   */
  public getEnterpriseId(): string {
    return this._enterpriseId;
  }

  public getEnterpriseIdObject(): EnterpriseId {
    return EnterpriseId.create(this.getEnterpriseId());
  }

  /**
   * Returns the policy name part, eg. `"3b92f9437b4f9c9c"`
   */
  public getId(): string {
    return this._id;
  }

  /**
   * Returns the full Google ID part, eg. `"enterprises/:enterpriseId/policies/:policyId"`
   */
  public getGoogleId(): string {
    return `enterprises/${this._enterpriseId}/policies/${this._id}`;
  }

  public valueOf(): string {
    return this.getGoogleId();
  }

  public toString(): string {
    return this.valueOf();
  }

  public toJSON(): string {
    return this.valueOf();
  }

  public isEqual(value: any): value is PolicyId {
    return !!value && value instanceof PolicyId && value.valueOf() === this.valueOf();
  }

  /**
   *
   * @param enterpriseId The enterprise ID, eg. `"LC04ghmbil"`
   * @param policyId The policy name, eg. `"My Policy"`
   * @returns The PolicyId instance
   */
  public static create(enterpriseId: string, policyId: string): PolicyId {
    return new PolicyId(enterpriseId, policyId);
  }

  public static isEqual(a: PolicyId | undefined, b: PolicyId | undefined) {
    if (a === b) return true;

    if (!a || !(a instanceof PolicyId)) return false;

    return a.isEqual(b);
  }
}

export default PolicyId;
