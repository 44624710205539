// Copyright © 2020 HMD Global. All rights reserved.

import { isString } from "../modules/lodash";

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export function parseSortDirection(value: any): SortDirection | undefined {
  if (!isString(value)) return undefined;

  value = value.toUpperCase();

  switch (value) {
    case "A":
    case "ASC":
      return SortDirection.ASC;

    case "D":
    case "DSC":
    case "DESC":
      return SortDirection.DESC;
  }

  return undefined;
}

export default SortDirection;
