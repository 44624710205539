// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import "./Section.scss";
import ComponentUtils from "../../ComponentUtils";

export const SECTION_CLASS_NAME = "hmd-section";

export enum SectionType {
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
}

export interface SectionProps {
  className?: string | undefined;
  type?: string;
  headerComponent?: any;
  headerProps?: Record<string, unknown>;
  contentComponent?: any;
  contentProps?: Record<string, unknown>;
  footerComponent?: any;
  footerProps?: Record<string, unknown>;
}

export interface SectionState {}

export class Section extends React.Component<SectionProps, SectionState> {
  static defaultProps: SectionProps = {
    className: undefined,

    type: SectionType.VERTICAL,

    headerComponent: undefined,
    contentComponent: undefined,
    footerComponent: undefined,

    headerProps: {},
    contentProps: {},
    footerProps: {},
  };

  constructor(props: SectionProps) {
    super(props);

    this.state = {};
  }

  render() {
    const children = React.Children.toArray(this.props.children);

    const HeaderComponent = this.props.headerComponent ?? children.shift() ?? "";
    const ContentComponent = this.props.contentComponent ?? children.shift() ?? "";
    const FooterComponent = this.props.footerComponent ?? children.shift() ?? "";

    const headerContent = ComponentUtils.getAsContent(HeaderComponent, this.props.headerProps);
    const contentContent = ComponentUtils.getAsContent(ContentComponent, this.props.contentProps);
    const footerContent = ComponentUtils.getAsContent(FooterComponent, this.props.footerProps);

    return (
      <section className={SECTION_CLASS_NAME + " " + (this.props.className ?? "") + " " + SECTION_CLASS_NAME + "-" + this.props.type}>
        {headerContent ? <header className={SECTION_CLASS_NAME + "-header"}>{headerContent}</header> : ""}

        {contentContent ? <article className={SECTION_CLASS_NAME + "-content"}>{contentContent}</article> : ""}

        {footerContent ? <footer className={SECTION_CLASS_NAME + "-footer"}>{footerContent}</footer> : ""}
      </section>
    );
  }
}

export default Section;
