// Copyright © 2020, 2021 HMD Global. All rights reserved.

import FrontendAccessKey from "./FrontendAccessKey";
import PermissionKey from "./PermissionKey";
import UserRole from "../services/UserRole";

/**
 * This mapping could be changed to the backend later.
 *
 * The permission is granted if one or more permission keys match for a specific access key.
 */
export const FRONTEND_PERMISSIONS: { [key: string]: PermissionKey[] } = {
  [FrontendAccessKey.USER_MANAGEMENT_VIEW]: [PermissionKey.USER_MANAGEMENT],
  [FrontendAccessKey.USER_MANAGEMENT_MODIFY]: [PermissionKey.USER_MANAGEMENT],
  [FrontendAccessKey.USER_MANAGEMENT_RESET]: [PermissionKey.USER_MANAGEMENT],
  [FrontendAccessKey.USER_MANAGEMENT_DELETE]: [PermissionKey.USER_MANAGEMENT],
  [FrontendAccessKey.USER_MANAGEMENT_DEMOTE]: [PermissionKey.USER_MANAGEMENT],
  [FrontendAccessKey.USER_MANAGEMENT_PROMOTE]: [PermissionKey.USER_MANAGEMENT],
  [FrontendAccessKey.USER_MANAGEMENT_ACTIVATE]: [PermissionKey.USER_MANAGEMENT],
  [FrontendAccessKey.USER_MANAGEMENT_DEACTIVATE]: [PermissionKey.USER_MANAGEMENT],
  [FrontendAccessKey.ENTERPRISE_MANAGEMENT_VIEW]: [PermissionKey.ENTERPRISE_MANAGEMENT],
  [FrontendAccessKey.ENTERPRISE_MANAGEMENT_MODIFY]: [PermissionKey.ENTERPRISE_MANAGEMENT],
  [FrontendAccessKey.ENTERPRISE_MANAGEMENT_DELETE]: [PermissionKey.ENTERPRISE_MANAGEMENT],
  [FrontendAccessKey.ENTERPRISE_VIEW]: [PermissionKey.ENTERPRISE_DETAILS],
  [FrontendAccessKey.DEVICE_GROUP_VIEW]: [PermissionKey.DEVICE_GROUPS],
  [FrontendAccessKey.DEVICE_GROUPS_LIST_VIEW]: [PermissionKey.DEVICE_GROUPS],
  [FrontendAccessKey.DEVICE_USER_VIEW]: [PermissionKey.DEVICE_USERS],
  [FrontendAccessKey.DEVICE_USERS_LIST_VIEW]: [PermissionKey.DEVICE_USERS],
  [FrontendAccessKey.VIEW_ONLY]: [PermissionKey.VIEW_ONLY],
};

export const USER_ROLE_PERMISSIONS: { [key: string]: PermissionKey[] } = {
  [UserRole.OBSERVER]: [PermissionKey.VIEW_ONLY, PermissionKey.DEVICE_GROUPS, PermissionKey.DEVICE_USERS],
  [UserRole.ENTERPRISE_USER]: [PermissionKey.DEVICE_GROUPS, PermissionKey.DEVICE_USERS],
  [UserRole.ENTERPRISE_ADMIN]: [PermissionKey.ENTERPRISE_DETAILS, PermissionKey.DEVICE_GROUPS, PermissionKey.DEVICE_USERS, PermissionKey.USER_MANAGEMENT],
  [UserRole.ENTERPRISE_OWNER]: [
    PermissionKey.USER_MANAGEMENT,
    PermissionKey.ENTERPRISE_MANAGEMENT,
    PermissionKey.ENTERPRISE_DETAILS,
    PermissionKey.DEVICE_GROUPS,
    PermissionKey.DEVICE_USERS,
  ],
  [UserRole.SYSTEM_ADMIN]: [
    PermissionKey.USER_MANAGEMENT,
    PermissionKey.ENTERPRISE_MANAGEMENT,
    PermissionKey.ENTERPRISE_DETAILS,
    PermissionKey.DEVICE_GROUPS,
    PermissionKey.DEVICE_USERS,
  ],
};
