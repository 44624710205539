// Copyright © 2021 HMD Global. All rights reserved.

import * as React from "react";
import "./ExpandSection.scss";
import { EXPAND_SECTION_CLASS_NAME } from "../../../constants/classNames";
import Icon from "../icon/Icon";
import IconType from "../icon/IconType";

export interface ExpandSectionProps {
  title?: string;
  openInitially?: boolean;
  isOpen?: boolean;
}

export interface ExpandSectionState {
  isOpen?: boolean;
}

export class ExpandSection extends React.Component<ExpandSectionProps, ExpandSectionState> {
  static defaultProps: Partial<ExpandSectionProps> = {
    openInitially: true,
  };

  constructor(props: ExpandSectionProps) {
    super(props);

    this.state = {
      isOpen: this.props.openInitially,
    };

    this._onClick = this._onClick.bind(this);
  }

  private _onClick(): void {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  }

  componentDidMount() {
    this.setState({
      isOpen: this.props.openInitially,
    });
  }

  componentDidUpdate(prevProps: Readonly<ExpandSectionProps>) {
    if (prevProps.openInitially !== this.props.openInitially) {
      this.setState({
        isOpen: this.props.openInitially,
      });
    }
  }

  render() {
    return (
      <section className={EXPAND_SECTION_CLASS_NAME}>
        <h4 className={`${EXPAND_SECTION_CLASS_NAME}-title ${this.state.isOpen ? "is-open" : "closed"}`} onClick={this._onClick}>
          <Icon className={`${EXPAND_SECTION_CLASS_NAME}-title-icon`} type={IconType.ACTION_DOWN} />
          {this.props.title}
        </h4>
        <div className={`${EXPAND_SECTION_CLASS_NAME}-content ${this.state.isOpen ? "is-open" : "closed"}`}>{this.props.children}</div>
      </section>
    );
  }
}

export default ExpandSection;
