// Copyright © 2020, 2021 HMD Global. All rights reserved.

import LogService from "./LogService";
import EnterpriseId from "../types/EnterpriseId";
import { isString } from "../modules/lodash";

const LOG = LogService.createLogger("EnterpriseUtils");

export class EnterpriseUtils {
  /**
   * Returns the enterprise ID part of the full resource name
   *
   * @param value eg. `"enterprises/:enterpriseId/...`
   */
  public static parseEnterpriseIdString(value: string | undefined): string | undefined {
    if (!value) {
      LOG.warn("parseEnterpriseId: value was invalid: ", value);
      return undefined;
    }

    if (!isString(value)) {
      LOG.warn("parseEnterpriseId: value was not a string: ", value);
      return undefined;
    }

    if (value.startsWith("enterprises/")) {
      value = value.substr("enterprises/".length);

      const i = value.indexOf("/");

      if (i < 0) {
        LOG.warn('parseEnterpriseId: value did not have "/": ', value);
        return value;
      }

      return value.substr(0, i);
    }

    LOG.warn('parseEnterpriseId: value did not start with "enterprises/": ', value);
    return value;
  }

  /**
   * Returns the enterprise ID part of the full resource name
   *
   * @param value eg. `"enterprises/:enterpriseId/...`
   */
  public static parseEnterpriseIdObject(value: string | undefined): EnterpriseId | undefined {
    const id = this.parseEnterpriseIdString(value);
    return id ? new EnterpriseId(id) : undefined;
  }

  private static colorToHex(color: number) {
    const hex = color.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  }

  public static convertColorToFrontend(bin: number | undefined) {
    if (!bin) {
      return;
    }
    const [r, g, b] = [bin >> 16, (bin >> 8) & 0xff, bin & 0xff];
    return `#${this.colorToHex(r)}${this.colorToHex(g)}${this.colorToHex(b)}`.toUpperCase();
  }

  public static convertColorToBackend(hex: string) {
    if (hex[0] !== "#") {
      hex = `#${hex}`;
    }

    const r = parseInt(hex[1] + hex[2], 16);
    const g = parseInt(hex[3] + hex[4], 16);
    const b = parseInt(hex[5] + hex[6], 16);

    return (r << 16) | (g << 8) | b;
  }
}

export default EnterpriseUtils;
