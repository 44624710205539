// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import "./SplitView.scss";
import ComponentUtils from "../../ComponentUtils";

export const SPLIT_VIEW_CLASS_NAME = "hmd-split-view";

interface SplitViewProps {
  className?: string | undefined;
  leftComponent?: any;
  leftProps?: Record<string, unknown>;
  rightComponent?: any;
  rightProps?: Record<string, unknown>;
}

interface SplitViewState {}

export class SplitView extends React.Component<SplitViewProps, SplitViewState> {
  static defaultProps: SplitViewProps = {
    className: undefined,
    leftComponent: undefined,
    rightComponent: undefined,
    leftProps: undefined,
    rightProps: undefined,
  };

  constructor(props: SplitViewProps) {
    super(props);

    this.state = {};
  }

  render() {
    const children = React.Children.toArray(this.props.children);

    const LeftComponent = this.props.leftComponent ?? children.shift() ?? "";
    const leftContent = ComponentUtils.getAsContent(LeftComponent, this.props.leftProps);

    const RightComponent = this.props.rightComponent ?? children.shift() ?? "";
    const rightContent = ComponentUtils.getAsContent(RightComponent, this.props.rightProps);

    return (
      <div className={SPLIT_VIEW_CLASS_NAME + " " + (this.props.className ?? "")}>
        <div className={SPLIT_VIEW_CLASS_NAME + "-left"}>{leftContent}</div>
        <div className={SPLIT_VIEW_CLASS_NAME + "-right"}>{rightContent}</div>
      </div>
    );
  }
}
