// Copyright © 2020 HMD Global. All rights reserved.

import HttpService, { HttpRequestCanceller, HttpResponse, Pageable } from "./HttpService";
import UserModel, { UserIdType } from "./types/UserModel";
import { SortDirection } from "../constants/SortDirection";
import BackendService from "./BackendService";
import HttpMethod from "./HttpMethod";
import { map } from "../modules/lodash";
import EventObserver, { EventObserverCallback, EventObserverDestructorCallback } from "./EventObserver";
import LogService from "./LogService";
import UserDTO from "./types/UserDTO";
import {
  API_ACTIVATE_USER_URL,
  API_CHECK_EMAIL,
  API_DEACTIVATE_USER_URL,
  API_DELETE_USER_URL,
  API_DEMOTE_USER_URL,
  API_EDIT_USER_ENTERPRISES,
  API_ENTERPRISE_USER_LIST_URL,
  API_IMPORT_USERS,
  API_PROMOTE_USER_URL,
  API_USER_EDIT_URL,
  API_USER_INVITE_URL,
  API_USER_LIST_URL,
  API_USER_SEARCH_URL,
  API_USER_URL,
} from "../constants/backend";
import { UserUtils } from "./UserUtils";
import HttpRequestType from "./HttpRequestType";
import ErrorService, { ErrorCode, isErrorObject } from "./ErrorService";
import { EnterpriseModel } from "./types/EnterpriseModel";
import EnterpriseInvitesDTO from "./types/EnterpriseInvitesDTO";

export enum UserServiceEvent {
  /**
   * The User model is provided as the second parameter
   */
  USER_ADDED = "UserServiceEvent:userAdded",

  /**
   * Triggered if user model's data changes
   */
  USER_UPDATED = "UserServiceEvent:userUpdated",
}

const LOG = LogService.createLogger("UserService");

export type UserModelPage = Pageable<UserModel>;
export type UserServiceDestructor = EventObserverDestructorCallback;
export type UserServiceCanceller = HttpRequestCanceller;
export type UserListDTO = Pageable<UserDTO>;

export class UserService {
  public static Event = UserServiceEvent;
  public static RequestCanceller = HttpRequestCanceller;

  private static _observer: EventObserver = new EventObserver("UserService");

  public static createCanceller(): UserServiceCanceller {
    return HttpService.createCanceller();
  }

  public static getUserList(
    pageSize = 10,
    page = 0,
    sort = "name",
    sortDirection: SortDirection,
    search: string | undefined = undefined,
    fullRefresh = false,
    canceller: UserServiceCanceller | undefined = undefined,
  ): Promise<UserModelPage> {
    if (canceller) {
      LOG.debug("getUserList: (with canceller): ", pageSize, page, fullRefresh);
    } else {
      LOG.debug("getUserList: (without canceller): ", pageSize, page, fullRefresh);
    }

    const backendSortKey = UserUtils.parseToBackendKey(sort);

    const backendId = BackendService.getCurrentBackend();

    const url =
      search === undefined || search === ""
        ? API_USER_LIST_URL(backendId, pageSize, page, backendSortKey, sortDirection)
        : API_USER_SEARCH_URL(backendId, pageSize, page, backendSortKey, sortDirection, search);

    let ret: Promise<HttpResponse<UserListDTO>> | undefined;

    if (canceller) {
      ret = HttpService.requestWithCanceller(canceller, HttpMethod.GET, url);
    } else {
      ret = HttpService.request(HttpMethod.GET, url);
    }

    return ret.then((response: HttpResponse<UserListDTO>): UserModelPage => {
      LOG.debug("getUserList: response = ", response);

      if (HttpService.verifyResponseObject(response)) {
        return {
          content: [],
          size: pageSize,
          totalElements: 0,
          totalPages: 0,
        };
      }

      if (response.data) {
        return {
          ...response.data,
          content: map(response.data?.content ?? [], (item: UserDTO): UserModel => UserUtils.convertBackendModelAsFrontendModel(item)),
        };
      } else {
        return {
          content: [],
          size: pageSize,
          totalElements: 0,
          totalPages: 0,
        };
      }
    });
  }

  public static getEnterpriseUserList(
    enterpriseId: string,
    pageSize = 10,
    page = 0,
    sort = "name",
    sortDirection: SortDirection,
    fullRefresh = false,
    canceller: UserServiceCanceller | undefined = undefined,
  ): Promise<UserModelPage> {
    if (canceller) {
      LOG.debug("getUserList: (with canceller): ", pageSize, page, fullRefresh);
    } else {
      LOG.debug("getUserList: (without canceller): ", pageSize, page, fullRefresh);
    }

    const backendSortKey = UserUtils.parseToBackendKey(sort);

    const backendId = BackendService.getCurrentBackend();

    const url = API_ENTERPRISE_USER_LIST_URL(enterpriseId, backendId, pageSize, page, backendSortKey, sortDirection);

    let ret: Promise<HttpResponse<UserListDTO>> | undefined;

    if (canceller) {
      ret = HttpService.requestWithCanceller(canceller, HttpMethod.GET, url);
    } else {
      ret = HttpService.request(HttpMethod.GET, url);
    }

    return ret.then((response: HttpResponse<UserListDTO>): UserModelPage => {
      LOG.debug("getUserList: response = ", response);

      if (HttpService.verifyResponseObject(response)) {
        return {
          content: [],
          size: pageSize,
          totalElements: 0,
          totalPages: 0,
        };
      }

      if (response.data) {
        return {
          ...response.data,
          content: map(response.data?.content ?? [], (item: UserDTO): UserModel => UserUtils.convertBackendModelAsFrontendModel(item)),
        };
      } else {
        return {
          content: [],
          size: pageSize,
          totalElements: 0,
          totalPages: 0,
        };
      }
    });
  }

  public static getUserModel(userId: UserIdType): Promise<UserModel> {
    const backendId = BackendService.getCurrentBackend();

    return HttpService.request(HttpMethod.GET, API_USER_URL(userId, backendId)).then((response: HttpResponse<UserDTO>) => {
      return UserUtils.convertBackendModelAsFrontendModel(response?.data ?? {});
    });
  }

  public static inviteUser(model: UserModel): Promise<void> {
    const newModel: UserDTO = UserUtils.convertFrontendModelAsBackendDTO({
      ...model,
    });

    const payload = {
      invites: [newModel],
    };

    const backendId = BackendService.getCurrentBackend();

    return HttpService.request(HttpMethod.POST, API_USER_INVITE_URL(backendId), payload, HttpRequestType.JSON)
      .then(
        (response: HttpResponse<any>) => {
          const data = response?.data;
          const failedUsers: string[] = data.failedUsers;

          if (failedUsers.length === 0) {
            if (HttpService.verifyResponseObject(response)) return;

            LOG.debug("User invite success: ", response);
            this._observer.triggerEvent(UserServiceEvent.USER_ADDED, model);
          } else {
            LOG.error("User invite failed: ", response);
            return Promise.reject(ErrorService.createError(ErrorCode.INVITE_USER_FAILED, response));
          }
        },
        (response) => {
          LOG.error("User invite failed: ", response);

          if (response.data.errorCode === ErrorCode.API_ERROR_CODE_ENTITY_ALREADY_EXISTS) {
            return Promise.reject(ErrorService.createError(ErrorCode.USER_ALREADY_EXISTS, response));
          }
          return Promise.reject(ErrorService.createError(ErrorCode.INVITE_USER_FAILED, response));
        },
      )
      .catch((error) => {
        LOG.error("User invite failed: ", error);

        if (isErrorObject(error)) {
          return Promise.reject(error);
        }

        return Promise.reject(ErrorService.createError(ErrorCode.INVITE_USER_FAILED, error));
      });
  }

  public static promoteUser(model: UserModel): Promise<void> {
    const userId = model?.id;

    if (!userId) throw new TypeError("User id is required");

    LOG.debug("promoteUser: ", userId, model);

    const backendId = BackendService.getCurrentBackend();

    return HttpService.request(HttpMethod.PUT, API_PROMOTE_USER_URL(backendId, userId))
      .then(
        (response: HttpResponse<UserDTO>) => {
          const statusCode = response?.status;
          if (statusCode !== 200) throw new TypeError(`Status code was not 200: ${statusCode}`);

          LOG.debug("User promote success: ", response);
        },
        (response) => {
          LOG.error("User promote failed: ", response);

          return Promise.reject(ErrorService.createError(ErrorCode.PROMOTE_USER_FAILED, response));
        },
      )
      .catch((error) => {
        LOG.error("User promote failed: ", error);

        return Promise.reject(ErrorService.createError(ErrorCode.PROMOTE_USER_FAILED, error));
      });
  }

  public static demoteUser(model: UserModel): Promise<void> {
    const userId = model?.id;

    if (!userId) throw new TypeError("User id is required");

    LOG.debug("demoteUser: ", userId, model);

    const backendId = BackendService.getCurrentBackend();

    return HttpService.request(HttpMethod.PUT, API_DEMOTE_USER_URL(backendId, userId))
      .then(
        (response: HttpResponse<UserDTO>) => {
          const statusCode = response?.status;
          if (statusCode !== 200) throw new TypeError(`Status code was not 200: ${statusCode}`);

          LOG.debug("User demote success: ", response);
        },
        (response) => {
          LOG.error("User demote failed: ", response);

          return Promise.reject(ErrorService.createError(ErrorCode.DEMOTE_USER_FAILED, response));
        },
      )
      .catch((error) => {
        LOG.error("User demote failed: ", error);

        return Promise.reject(ErrorService.createError(ErrorCode.DEMOTE_USER_FAILED, error));
      });
  }

  public static activateUser(model: UserModel): Promise<void> {
    const userId = model?.id;

    if (!userId) throw new TypeError("User id is required");

    LOG.debug("activateUser: ", userId, model);

    const backendId = BackendService.getCurrentBackend();

    return HttpService.request(HttpMethod.PUT, API_ACTIVATE_USER_URL(backendId, userId))
      .then(
        (response: HttpResponse<UserDTO>) => {
          const statusCode = response?.status;
          if (statusCode !== 200) throw new TypeError(`Status code was not 200: ${statusCode}`);

          LOG.debug("User activate success: ", response);
        },
        (response) => {
          LOG.error("User activate failed: ", response);

          return Promise.reject(ErrorService.createError(ErrorCode.ACTIVATE_USER_FAILED, response));
        },
      )
      .catch((error) => {
        LOG.error("User activate failed: ", error);

        return Promise.reject(ErrorService.createError(ErrorCode.ACTIVATE_USER_FAILED, error));
      });
  }

  public static deactivateUser(model: UserModel): Promise<void> {
    const userId = model?.id;

    if (!userId) throw new TypeError("User id is required");

    LOG.debug("activateUser: ", userId, model);

    const backendId = BackendService.getCurrentBackend();

    return HttpService.request(HttpMethod.PUT, API_DEACTIVATE_USER_URL(backendId, userId))
      .then(
        (response: HttpResponse<UserDTO>) => {
          const statusCode = response?.status;
          if (statusCode !== 200) throw new TypeError(`Status code was not 200: ${statusCode}`);

          LOG.debug("User deactivate success: ", response);
        },
        (response) => {
          LOG.error("User deactivate failed: ", response);

          return Promise.reject(ErrorService.createError(ErrorCode.DEACTIVATE_USER_FAILED, response));
        },
      )
      .catch((error) => {
        LOG.error("User deactivate failed: ", error);

        return Promise.reject(ErrorService.createError(ErrorCode.DEACTIVATE_USER_FAILED, error));
      });
  }

  public static deleteUser(model: UserModel): Promise<void> {
    const userId = model?.id;

    if (!userId) throw new TypeError("User id is required");

    LOG.debug("deleteUser: ", userId, model);

    const backendId = BackendService.getCurrentBackend();

    return HttpService.request(HttpMethod.DELETE, API_DELETE_USER_URL(userId, backendId))
      .then(
        (response: HttpResponse<UserDTO>) => {
          const statusCode = response?.status;
          if (statusCode !== 200) throw new TypeError(`Status code was not 200: ${statusCode}`);

          LOG.debug("User delete success: ", response);
        },
        (response) => {
          LOG.error("User delete failed: ", response);

          return Promise.reject(ErrorService.createError(ErrorCode.DELETE_USER_FAILED, response));
        },
      )
      .catch((error) => {
        LOG.error("User delete failed: ", error);

        return Promise.reject(ErrorService.createError(ErrorCode.DELETE_USER_FAILED, error));
      });
  }

  public static editUser(userId: UserIdType, model: UserModel): Promise<void> {
    LOG.debug("editUser: ", model);

    const newModel: UserDTO = UserUtils.convertFrontendModelAsBackendDTO(model);

    const backendId = BackendService.getCurrentBackend();

    return HttpService.request(HttpMethod.PUT, API_USER_EDIT_URL(userId, backendId), newModel, HttpRequestType.JSON)
      .then(
        (response: HttpResponse<UserDTO>) => {
          if (HttpService.verifyResponseObject(response)) return;

          LOG.debug("User update success: ", response);

          this._observer.triggerEvent(UserService.Event.USER_UPDATED, userId);
        },
        (response) => {
          LOG.error("User update failed: ", response);

          return Promise.reject(ErrorService.createError(ErrorCode.UPDATE_USER_FAILED, response));
        },
      )
      .catch((error) => {
        LOG.error("User update failed: ", error);

        return Promise.reject(ErrorService.createError(ErrorCode.UPDATE_USER_FAILED, error));
      });
  }

  public static editUserEnterprises(userId: UserIdType, enterprises: EnterpriseModel[]) {
    LOG.debug("editUserEnterprises: ", enterprises);

    const payload = [
      {
        userId,
        enterprises: enterprises.map((item) => item.name),
      },
    ];

    const backendId = BackendService.getCurrentBackend();

    return HttpService.request(HttpMethod.PUT, API_EDIT_USER_ENTERPRISES(backendId), payload, HttpRequestType.JSON)
      .then(
        (response: HttpResponse<EnterpriseInvitesDTO>) => {
          if (HttpService.verifyResponseObject(response)) return;

          LOG.debug("Edit user enterprises success: ", response);

          this._observer.triggerEvent(UserService.Event.USER_UPDATED, userId);
        },
        (response) => {
          LOG.error("Edit user enterprises failed: ", response);

          return Promise.reject(ErrorService.createError(ErrorCode.UPDATE_USER_FAILED, response));
        },
      )
      .catch((error) => {
        LOG.error("Edit user enterprises failed: ", error);

        return Promise.reject(ErrorService.createError(ErrorCode.UPDATE_USER_FAILED, error));
      });
  }

  public static addUsersToEnterprise(users: any) {
    const backendId = BackendService.getCurrentBackend();

    return HttpService.request(HttpMethod.PUT, API_EDIT_USER_ENTERPRISES(backendId), users, HttpRequestType.JSON)
      .then(
        (response: HttpResponse<EnterpriseInvitesDTO>) => {
          if (HttpService.verifyResponseObject(response)) return;

          LOG.debug("Edit user enterprises success: ", response);

          // this._observer.triggerEvent(UserService.Event.USER_UPDATED, userId);
        },
        (response) => {
          LOG.error("Edit user enterprises failed: ", response);

          return Promise.reject(ErrorService.createError(ErrorCode.UPDATE_USER_FAILED, response));
        },
      )
      .catch((error) => {
        LOG.error("Edit user enterprises failed: ", error);

        return Promise.reject(ErrorService.createError(ErrorCode.UPDATE_USER_FAILED, error));
      });
  }

  public static importUsers(enterprise: string, role: string, formData: any) {
    const backendId = BackendService.getCurrentBackend();

    return HttpService.request(HttpMethod.POST, API_IMPORT_USERS(enterprise, role, backendId), formData, HttpRequestType.FILE)
      .then((res) => {
        LOG.debug("Import users success: ", res);
      })
      .catch((error) => {
        LOG.error("Import users error: ", error);
        return Promise.reject(ErrorService.createError(ErrorCode.UPDATE_USER_FAILED, error));
      });
  }

  public static async checkEmailAvailability(email: string) {
    const backendId = BackendService.getCurrentBackend();
    return await HttpService.request(HttpMethod.POST, API_CHECK_EMAIL(email, backendId))
      .then((res) => {
        LOG.info("API CALL SUCCESS! email is available: ", res.data);
        if (res.data == 200) {
          return true;
        }
        return false;
      })
      .catch((error) => {
        LOG.error("Email Check error", error);
        return Promise.reject(ErrorService.createError(ErrorCode.API_ERROR_CODE_ENTITY_ALREADY_EXISTS, error));
      });
  }

  public static on(e: UserServiceEvent, callback: EventObserverCallback): UserServiceDestructor {
    return this._observer.listenEvent(e, callback);
  }
}

export default UserService;
