// Copyright © 2021 HMD Global. All rights reserved.

import React, { useEffect, useState } from "react";
import "./UnauthorizedApp.scss";
import UnauthorizedAppInitializerService from "../../services/UnauthorizedAppInitializerService";
import LoginView from "../../components/views/loginView/LoginView";
import AppStateService from "../../services/AppStateService";
import AppState from "../../services/AppState";
import AppStateUtils from "../../services/AppStateUtils";
import { useCookies } from "react-cookie";
import { APP_CLASS_NAME } from "../../constants/classNames";
import { API_COOKIE_NAMES, API_COOKIE_PATH } from "../../constants/backend";
import { useTranslation } from "react-i18next";
import { T_ERROR_VIEW_BUTTON_LABEL, T_ERROR_VIEW_MESSAGE } from "../../translations/translationTokens";
import { ErrorView } from "../../components/views/errorView/ErrorView";
import ProfileService from "../../services/ProfileService";
import { LoadingView } from "../../components/views/loadingView/LoadingView";
import TranslationService from "../../services/TranslationService";
import LogService from "../../services/LogService";
import ChangePasswordView from "../../components/views/changePasswordView/ChangePasswordView";
import WindowService from "../../services/WindowService";
import { FRONTEND_AUTHORIZED_BUNDLE_PATH } from "../../constants/frontend";
import TermsAndConditionsView from "../../components/views/termsAndConditionsView/TermsAndConditionsView";
import { forEach } from "../../modules/lodash";
import EnterpriseAgreementView from "../../components/views/enterpriseAgreementView/EnterpriseAgreementView";
import styles from "../../styles/variables.scss";
import TfaView from "../../components/views/tfaView/TfaView";

UnauthorizedAppInitializerService.initializeServices();

const LOG = LogService.createLogger("UnauthorizedApp");

LOG.debug("Services started; state is " + AppStateUtils.getStateName(AppStateService.getState()));

export function UnauthorizedApp() {
  const serviceAppState = AppStateService.getState();

  const [appState, setAppState] = useState(serviceAppState);

  LOG.debug("UI rendered; state is " + AppStateUtils.getStateName(appState) + "@UI vs " + AppStateUtils.getStateName(serviceAppState) + "@services");

  const [cookies /*setCookie*/, , removeCookie] = useCookies(API_COOKIE_NAMES);

  const existingCookieList = API_COOKIE_NAMES.filter((cookieName: string): boolean => {
    return !!(cookies && cookies[cookieName]);
  });

  const existingCookie: string | undefined = existingCookieList.shift();

  if (existingCookie) {
    LOG.debug("Cookie detected: " + existingCookie + ' ("' + cookies[existingCookie] + '")');

    if (AppStateUtils.isLoginView(serviceAppState)) {
      LOG.debug("Triggering profile initialization since we are on the login view and have a cookie.");
      ProfileService.initializeProfile();
    }
  }

  useEffect(() => {
    const destructor = AppStateService.on(AppStateService.Event.CHANGED, () => {
      const newState = AppStateService.getState();

      if (AppStateUtils.isLoginView(newState)) {
        LOG.debug("State changed as ", AppStateUtils.getStateName(newState), " -- Removing cookies: ", API_COOKIE_NAMES);

        forEach(API_COOKIE_NAMES, (cookieName: string) => {
          removeCookie(cookieName, { path: API_COOKIE_PATH });
        });
      } else {
        LOG.debug("State changed as " + AppStateUtils.getStateName(newState));
      }

      setAppState(newState);
    });

    return () => {
      const state = AppStateService.getState();
      LOG.debug("Stopped at " + AppStateUtils.getStateName(state));

      destructor();
    };
  }, [removeCookie]);

  const { t, i18n } = useTranslation();

  const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || "";

  TranslationService.setCurrentLanguage(TranslationService.parseLanguage(getCurrentLng()));

  useEffect(() => {
    return TranslationService.on(TranslationService.Event.LANGUAGE_CHANGED, () => {
      const newLanguage = TranslationService.getCurrentLanguage();
      LOG.debug("Language changed as " + TranslationService.getLanguageTranslationToken(newLanguage));

      i18n.changeLanguage(newLanguage).catch((err) => {
        LOG.error("ERROR while changing language: ", err);
      });
    });
  }, [i18n]);

  switch (appState) {
    case AppState.LOADING_VIEW:
      return <LoadingView />;

    case AppState.ERROR_VIEW:
      return <ErrorView t={t} error={T_ERROR_VIEW_MESSAGE} buttonLabel={t(T_ERROR_VIEW_BUTTON_LABEL)} />;

    case AppState.AUTHORIZED_VIEW:
    case AppState.SETUP_VIEW:
      WindowService.goToBundle(FRONTEND_AUTHORIZED_BUNDLE_PATH);
      return <LoadingView />;

    case AppState.CHANGE_PASSWORD_VIEW:
      return <ChangePasswordView t={t} className={`${APP_CLASS_NAME} hmd-theme-${styles.themeInUse}`} />;

    case AppState.TERMS_AND_CONDITIONS_VIEW:
      return <TermsAndConditionsView t={t} className={`${APP_CLASS_NAME} hmd-theme-${styles.themeInUse}`} />;

    case AppState.ENTERPRISE_AGREEMENT_VIEW:
      return <EnterpriseAgreementView t={t} className={APP_CLASS_NAME} />;

    case AppState.TFA_VIEW:
      return <TfaView t={t} className={`${APP_CLASS_NAME} hmd-theme-${styles.themeInUse}`} />;

    default:
      return <LoginView t={t} className={`${APP_CLASS_NAME} hmd-theme-${styles.themeInUse}`} />;
  }
}

export default UnauthorizedApp;
