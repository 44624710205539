// Copyright © 2020, 2021 HMD Global. All rights reserved.

export enum NodeEnv {
  PRODUCTION = "production",
  DEVELOPMENT = "development",
  TEST = "test",
}

export function parseNodeEnv(value: any): NodeEnv {
  switch (value) {
    case "PRODUCTION":
    case "production":
      return NodeEnv.PRODUCTION;

    case "DEVELOPMENT":
    case "development":
      return NodeEnv.DEVELOPMENT;

    case "TEST":
    case "test":
      return NodeEnv.TEST;

    default:
      return NodeEnv.DEVELOPMENT;
  }
}

export default NodeEnv;
