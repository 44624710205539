// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import "./TermsAndConditionsForm.scss";
import { TERMS_AND_CONDITIONS_FORM_CLASS_NAME } from "../../../constants/classNames";
import Form, { FormSubmitCallback } from "../../common/form/Form";
import SubmitButton from "../../common/submitButton/SubmitButton";
import {
  T_LOGIN_VIEW_B2B_PRIVACY_NOTICE_LINK_TEXT,
  T_LOGIN_VIEW_CONTINUE_BUTTON_LABEL,
  T_LOGIN_VIEW_DISCLAIMER,
  T_LOGIN_VIEW_PRIVACY_SUPPLEMENT_LINK_TEXT,
  T_LOGIN_VIEW_TERMS_AND_CONDITIONS_CHECKBOX_TEXT,
  T_LOGIN_VIEW_TERMS_AND_CONDITIONS_LINK_TEXT,
  T_LOGIN_VIEW_WELCOME_TITLE,
} from "../../../translations/translationTokens";
import TranslateCallback from "../../../TranslateCallback";
import CheckboxField, { CheckboxFieldChangeCallback } from "../../common/checkboxField/CheckboxField";
import TranslateUtils from "../../../services/TranslateUtils";
import { B2B_PRIVACY_NOTICE_URL, PRIVACY_SUPPLEMENT_URL, TERMS_AND_CONDITIONS_URL } from "../../../constants/backend";

export interface TermsAndConditionsFormState {
  termsAndConditionsAccepted: boolean;
}

export interface TermsAndConditionsFormSubmitCallback {
  (formData: TermsAndConditionsFormState): void;
}

export interface TermsAndConditionsFormProps {
  t?: TranslateCallback;
  className?: string | undefined;
  submit: TermsAndConditionsFormSubmitCallback;
  loading?: boolean;
  error?: any;
}

export class TermsAndConditionsForm extends React.Component<TermsAndConditionsFormProps, TermsAndConditionsFormState> {
  private readonly _termsAndConditionsCheckboxCallback: CheckboxFieldChangeCallback;

  static defaultProps: TermsAndConditionsFormProps = {
    className: undefined,
    error: undefined,
    submit: () => undefined,
    loading: false,
  };

  public handleSubmit: FormSubmitCallback | undefined;

  constructor(props: TermsAndConditionsFormProps) {
    super(props);

    this.state = {
      termsAndConditionsAccepted: false,
    };

    this.handleSubmit = this._handleSubmit.bind(this);
    this._termsAndConditionsCheckboxCallback = this._onTermsAndConditionsCheckboxChanged.bind(this);
  }

  private _handleSubmit() {
    this.props.submit(this.state);
  }

  private _onTermsAndConditionsCheckboxChanged() {
    this.setState({
      termsAndConditionsAccepted: !this.state.termsAndConditionsAccepted,
    });
  }

  render() {
    const t = this.props.t ?? ((key: string) => key);

    const error = this.props.error ?? "";

    return (
      <Form title={t(T_LOGIN_VIEW_WELCOME_TITLE)} className={TERMS_AND_CONDITIONS_FORM_CLASS_NAME} loading={this.props.loading} submit={this.handleSubmit}>
        <p className={TERMS_AND_CONDITIONS_FORM_CLASS_NAME + "-disclaimer"}>{t(T_LOGIN_VIEW_DISCLAIMER)}</p>

        <CheckboxField
          className={TERMS_AND_CONDITIONS_FORM_CLASS_NAME + "-tc-checkbox"}
          label={TranslateUtils.magicTranslateWithComponents(t, T_LOGIN_VIEW_TERMS_AND_CONDITIONS_CHECKBOX_TEXT, {
            TERM_AND_CONDITIONS_LINK: (
              <a target="_blank" rel="noopener noreferrer" href={TERMS_AND_CONDITIONS_URL}>
                {t(T_LOGIN_VIEW_TERMS_AND_CONDITIONS_LINK_TEXT)}
              </a>
            ),
            B2B_PRIVACY_NOTICE_LINK: (
              <a target="_blank" rel="noopener noreferrer" href={B2B_PRIVACY_NOTICE_URL}>
                {t(T_LOGIN_VIEW_B2B_PRIVACY_NOTICE_LINK_TEXT)}
              </a>
            ),
            PRIVACY_SUPPLEMENT_LINK: (
              <a target="_blank" rel="noopener noreferrer" href={PRIVACY_SUPPLEMENT_URL}>
                {t(T_LOGIN_VIEW_PRIVACY_SUPPLEMENT_LINK_TEXT)}
              </a>
            ),
          })}
          checked={this.state.termsAndConditionsAccepted}
          change={this._termsAndConditionsCheckboxCallback}
        />

        <SubmitButton
          className={TERMS_AND_CONDITIONS_FORM_CLASS_NAME + "-submit-button"}
          enabled={this.state.termsAndConditionsAccepted}
          label={t(T_LOGIN_VIEW_CONTINUE_BUTTON_LABEL)}
        />

        {error}
      </Form>
    );
  }
}
