// Copyright © 2020 HMD Global. All rights reserved.

import React, { ChangeEvent, RefObject } from "react";
import "./PasswordField.scss";
import { PASSWORD_FIELD_CLASS_NAME } from "../../../constants/classNames";

import { HTMLInputBlurCallback, HTMLInputChangeCallback, HTMLInputFocusCallback, TextFieldChangeCallback, InputValidCallback, ToggleFocusCallback } from "../textField/TextField";
import Icon from "../icon/Icon";
import { IconType } from "../icon/IconType";
import FieldSeparator from "../fieldSeparator/FieldSeparator";
import { T_PASSWORD_VISIBILITY_ALT_TEXT } from "../../../translations/translationTokens";
import Button, { ButtonClickCallback } from "../button/Button";
import Field from "../field/Field";
import LabelType from "../label/LabelType";
import Label from "../label/Label";
import LogService from "../../../services/LogService";
import FormUtils, { AutoCompleteInputType, AutoCompleteType } from "../../../services/FormUtils";

const LOG = LogService.createLogger("PasswordField");

export interface PasswordFieldValidatorCallback {
  (): boolean;
}

export interface PasswordFieldProps {
  className?: string | undefined;
  label?: string | undefined;
  labelType?: LabelType;
  iconLabel?: string | undefined;
  icon?: string | undefined;
  enabled?: boolean;
  showPasswordIcon?: string | undefined;
  hidePasswordIcon?: string | undefined;
  autoFocus?: boolean;
  borders?: boolean;
  autoComplete?: AutoCompleteInputType;
  placeholder?: string | undefined;
  isValid?: InputValidCallback;
  change?: TextFieldChangeCallback;
  value?: string;
  toggleFocus?: ToggleFocusCallback;
}

export interface PasswordFieldState {
  passwordVisible: boolean;
}

export class PasswordField extends React.Component<PasswordFieldProps, PasswordFieldState> {
  private readonly inputRef: RefObject<HTMLInputElement>;
  private readonly inputChangeCallback: HTMLInputChangeCallback;
  private readonly onFocusCallback: HTMLInputFocusCallback;
  private readonly onBlurCallback: HTMLInputBlurCallback;

  private onVisiblityClick: ButtonClickCallback | undefined;

  static defaultProps: PasswordFieldProps = {
    className: undefined,
    label: undefined,
    labelType: LabelType.DEFAULT,
    iconLabel: undefined,
    placeholder: "",
    autoFocus: false,
    borders: true,
    enabled: true,
    autoComplete: AutoCompleteType.DEFAULT,
    showPasswordIcon: IconType.EYE,
    hidePasswordIcon: IconType.EYE_CROSSED,
  };

  constructor(props: PasswordFieldProps) {
    super(props);

    this.state = {
      passwordVisible: false,
    };

    this.onVisiblityClick = this._onVisiblityClick.bind(this);

    this.inputRef = React.createRef();

    this.inputChangeCallback = this.onChange.bind(this);
    this.onFocusCallback = this.onFocus.bind(this);
    this.onBlurCallback = this.onBlur.bind(this);
  }

  componentDidMount() {
    if (this.inputRef?.current && this.props.autoFocus) {
      this.inputRef.current.focus();
    }
  }

  protected _onVisiblityClick() {
    this.setState({
      passwordVisible: !this.state.passwordVisible,
    });
  }

  isPasswordVisible(): boolean {
    return this.state.passwordVisible;
  }

  private _toggleFocus(value: boolean) {
    if (this.props.toggleFocus) {
      try {
        this.props.toggleFocus(value);
      } catch (err) {
        LOG.error("Exception in _toggleFocus: ", err);
      }
    } else {
      LOG.warn("No toggleFocus callback prop defined");
    }
  }

  onFocus() {
    this._toggleFocus(true);
  }

  onBlur() {
    this._toggleFocus(false);
  }

  onChange(event: ChangeEvent<HTMLInputElement>) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (this.props.change) {
      try {
        this.props.change(event.target.value);
      } catch (err) {
        LOG.error("TextField: Change Error: ", err);
      }
    } else {
      LOG.error("TextField: No change callback defined");
    }
  }

  private _isValid(): boolean {
    if (this.props.isValid) {
      try {
        return this.props.isValid(this.props.value);
      } catch (err) {
        LOG.error("Exception in isValid callback: ", err);
      }
      return false;
    }

    return true;
  }

  render() {
    const isValid = this._isValid();

    const inputProps: { onFocus?: any; onBlur?: any; autoComplete?: string } = {};

    if (this.props?.toggleFocus) {
      inputProps.onFocus = this.onFocusCallback;
      inputProps.onBlur = this.onBlurCallback;
    }

    const autoCompleteString = FormUtils.parseAutoCompleteString(this.props?.autoComplete);
    if (autoCompleteString) {
      inputProps.autoComplete = autoCompleteString;
    }

    return (
      <Label
        className={
          PASSWORD_FIELD_CLASS_NAME +
          " " +
          (this.props.className ?? "") +
          " " +
          PASSWORD_FIELD_CLASS_NAME +
          (this.props.enabled ? "-enabled" : "-disabled") +
          " " +
          PASSWORD_FIELD_CLASS_NAME +
          (isValid ? "-valid" : "-invalid")
        }
        label={this.props.label}
        type={this.props.labelType}
        field={Field}
        fieldProps={{
          className: PASSWORD_FIELD_CLASS_NAME + "-field",
          borders: this.props.borders,
        }}
      >
        {this.props.icon ? <Icon className={PASSWORD_FIELD_CLASS_NAME + "-icon"} label={this.props.iconLabel ?? ""} type={this.props.icon} /> : ""}

        {this.props.icon ? <FieldSeparator /> : ""}

        <input
          {...inputProps}
          type={this.isPasswordVisible() ? "text" : "password"}
          className={PASSWORD_FIELD_CLASS_NAME + "-input"}
          ref={this.inputRef}
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={this.inputChangeCallback}
        />

        <FieldSeparator />

        <Button className={PASSWORD_FIELD_CLASS_NAME + "-visibility-button"} click={this.onVisiblityClick} borders={false}>
          <Icon
            className={PASSWORD_FIELD_CLASS_NAME + "-visibility-icon"}
            label={this.props.label ?? T_PASSWORD_VISIBILITY_ALT_TEXT}
            type={this.isPasswordVisible() ? this.props.hidePasswordIcon : this.props.showPasswordIcon}
          />
        </Button>
      </Label>
    );
  }
}

export default PasswordField;
