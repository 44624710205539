// Copyright © 2020 HMD Global. All rights reserved.

export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export default HttpMethod;
