// Copyright © 2020 HMD Global. All rights reserved.

export enum Language {
  UNDEFINED = "UNDEFINED",
  ENGLISH = "en-US",
  FINNISH = "fi",
  GERMAN = "de",
  FRENCH = "fr",
  PORTUGUESE = "pt",
  CHINESE = "zh",
  SPANISH = "es",
}

export default Language;
