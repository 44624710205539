// Copyright © 2020 HMD Global. All rights reserved.

import "./Branding.scss";
import { BRANDING_CLASS_NAME } from "../../../constants/classNames";
import * as React from "react";
import { T_SETUP_VIEW_SIGN_OUT_LABEL } from "../../../translations/translationTokens";
import TranslateCallback from "../../../TranslateCallback";
import Button, { ButtonClickCallback } from "../../common/button/Button";
import SessionService, { SessionServiceDestructor, SessionServiceEvent } from "../../../services/SessionService";
import SelectField, { SelectChangeCallback, SelectOption } from "../../common/selectField/SelectField";
import TranslationService, { TranslationServiceDestructor } from "../../../services/TranslationService";
import { map } from "../../../modules/lodash";
import ModalService, { ModalType } from "../../../services/ModalService";
import SignOutModal from "../../modals/signOutModal/SignOutModal";
import LogService from "../../../services/LogService";
import styles from "../../../styles/variables.scss";
import { ReactComponent as HmdEmmSvgLogo } from "../../../assets/HMD-Enable-Pro-logo.svg";
import { ReactComponent as NdacSvgLogo } from "../../../assets/Nokia-logo-Bright-blue.svg";
import { Language } from "../../../translations/Language";

const LOG = LogService.createLogger("Branding");

export interface BrandingProps {
  t?: TranslateCallback;
  className?: string | undefined;
}

export interface BrandingState {
  loggedIn: boolean;
  language: Language;
  languageOptions: SelectOption[];
}

export class Branding extends React.Component<BrandingProps, BrandingState> {
  static defaultProps: BrandingProps = {
    className: undefined,
  };

  private _profileChangeListener: SessionServiceDestructor | undefined;
  private _languageChangeListener: TranslationServiceDestructor | undefined;

  private readonly _signOutCallback: ButtonClickCallback;
  private readonly _changeLanguageCallback: SelectChangeCallback;

  constructor(props: BrandingProps) {
    super(props);

    this.state = {
      loggedIn: Branding.isLoggedIn(),
      language: TranslationService.getCurrentLanguage(),
      languageOptions: [],
    };

    this._languageChangeListener = undefined;

    this._signOutCallback = this._signOut.bind(this);
    this._changeLanguageCallback = this._changeLanguage.bind(this);
  }

  private static createLanguageOptions(t: TranslateCallback): SelectOption[] {
    return map(TranslationService.getAllLanguages(), (l: Language) => ({
      label: t(TranslationService.getLanguageTranslationToken(l)),
      value: l,
    }));
  }

  private static isLoggedIn(): boolean {
    return SessionService.isLoggedIn();
  }

  private _signOut() {
    const t = this.props.t ?? ((key: string) => key);

    ModalService.createModal({
      type: ModalType.CENTER,
      component: <SignOutModal t={t} />,
    });
  }

  private _changeLanguage(item: any) {
    const value = item?.value ?? undefined;

    LOG.debug("SET LANGUAGE ", value);

    if (value) {
      TranslationService.setCurrentLanguage(TranslationService.parseLanguage(value));
    }
  }

  componentDidMount() {
    this._profileChangeListener = SessionService.on(SessionServiceEvent.BACKEND_KEY_CHANGED, () => {
      this.setState({
        loggedIn: Branding.isLoggedIn(),
      });
    });

    this._languageChangeListener = TranslationService.on(TranslationService.Event.LANGUAGE_CHANGED, () => {
      const t = this.props.t ?? ((key: string) => key);

      this.setState({
        language: TranslationService.getCurrentLanguage(),
        languageOptions: Branding.createLanguageOptions(t),
      });
    });

    this._updateLanguageOptions();
  }

  private _updateLanguageOptions() {
    const t = this.props.t ?? ((key: string) => key);

    this.setState({
      languageOptions: Branding.createLanguageOptions(t),
    });
  }

  componentWillUnmount() {
    if (this._profileChangeListener !== undefined) {
      this._profileChangeListener();
      this._profileChangeListener = undefined;
    }
  }

  render() {
    const t = this.props.t ?? ((key: string) => key);

    let options: any = "";

    if (this.state.loggedIn) {
      options = (
        <Button borders={false} click={this._signOutCallback}>
          {t(T_SETUP_VIEW_SIGN_OUT_LABEL)}
        </Button>
      );
    } else if (this.state.languageOptions.length >= 2) {
      options = <SelectField options={this.state.languageOptions} value={this.state.language} change={this._changeLanguageCallback} />;
    }

    return (
      <div className={`${BRANDING_CLASS_NAME} ${this.props.className ?? ""}`}>
        <div className={`${BRANDING_CLASS_NAME}-logo-container`}>
          <div className={`${BRANDING_CLASS_NAME}-logo`}>{styles.themeInUse === "default" ? <HmdEmmSvgLogo /> : <NdacSvgLogo />}</div>
        </div>

        {options && (
          <div className={`${BRANDING_CLASS_NAME}-options-container`}>
            <div className={`${BRANDING_CLASS_NAME}-options`}>{options}</div>
          </div>
        )}
      </div>
    );
  }
}

export default Branding;
