// Copyright © 2021 HMD Global. All rights reserved.

import { isString } from "../../modules/lodash";

export enum MessageType {
  SUCCESS = "SUCCESS",
  ALERT = "ALERT",
  ERROR = "ERROR",
}

export function isMessageType(value: any): value is MessageType {
  if (!isString(value)) return false;
  switch (value) {
    case MessageType.SUCCESS:
    case MessageType.ALERT:
    case MessageType.ERROR:
      return true;
  }
  return false;
}

export default MessageType;
