// Copyright © 2020, 2021 HMD Global. All rights reserved.

export const T_COMMON_CANCEL = "common.cancel";
export const T_COMMON_LOCK = "common.lock";
export const T_COMMON_ACCEPT = "common.accept";
export const T_COMMON_SAVE = "common.save";
export const T_COMMON_CONTINUE = "common.continue";
export const T_COMMON_EMAIL = "common.email";
export const T_COMMON_UNDEFINED = "common.undefined";
export const T_COMMON_OK = "common.ok";
export const T_COMMON_NO_RESULTS = "common.noResults";
export const T_COMMON_TERMS_AND_CONDITIONS_LINK_TEXT = "common.termsAndConditionsLinkText";
export const T_COMMON_B2B_PRIVACY_NOTICE_LINK_TEXT = "common.b2bPrivacyNoticeLinkText";
export const T_COMMON_PRIVACY_SUPPLEMENT_LINK_TEXT = "common.privacySupplementLinkText";
export const T_COMMON_DEVICES = "common.devices";

export const T_LOGIN_SIGN_IN_FORM_TITLE = "login.signIn";
export const T_LOGIN_SIGN_IN_BUTTON_LABEL = "login.signIn";
export const T_LOGIN_SIGN_OUT_BUTTON_LABEL = "login.signOut";
export const T_LOGIN_FORM_EMAIL_LABEL = "login.emailLabel";
export const T_LOGIN_FORM_EMAIL_PLACEHOLDER = "login.emailPlaceholder";
export const T_LOGIN_FORM_PASSWORD_LABEL = "login.passwordLabel";
export const T_LOGIN_FORM_PASSWORD_PLACEHOLDER = "login.passwordPlaceholder";
export const T_PASSWORD_VISIBILITY_ALT_TEXT = "password.visibilityAltText";

export const T_ABOUT_LEGAL_LOGO_ALT = "aboutLegal.logoAlt";
export const T_ABOUT_LEGAL_VERSION = "aboutLegal.versionText";
export const T_ABOUT_LEGAL_COPYRIGHT = "aboutLegal.copyrightText";
export const T_ABOUT_LEGAL_CONTENT_TEXT = "aboutLegal.contentText";
export const T_ABOUT_LEGAL_TERMS_AND_CONDITIONS_LINK_TEXT = "aboutLegal.termsAndConditionsLinkText";
export const T_ABOUT_LEGAL_B2B_PRIVACY_NOTICE_LINK_TEXT = "aboutLegal.b2bPrivacyNoticeLinkText";
export const T_ABOUT_LEGAL_PRIVACY_SUPPLEMENT_LINK_TEXT = "aboutLegal.privacySupplementLinkText";

export const T_BRANDING_LOGO_ALT = "branding.logoAlt";
export const T_USER_ROLE_SYSTEM_ADMIN = "roles.SYSTEM_ADMIN";
export const T_USER_ROLE_ENTERPRISE_OWNER = "roles.ENTERPRISE_OWNER";
export const T_USER_ROLE_ENTERPRISE_ADMIN = "roles.ENTERPRISE_ADMIN";
export const T_USER_ROLE_ENTERPRISE_USER = "roles.ENTERPRISE_USER";
export const T_USER_ROLE_OBSERVER = "roles.OBSERVER";
export const T_USER_ROLE_UNDEFINED = "roles.UNDEFINED";
export const T_ERROR_LOGIN_MESSAGE = "login.errorMessage";
export const T_ERROR_DEVICE_PAGE_LOAD = "errors.devicePageLoadFailed";
export const T_ERROR_DEVICE_PAGE_LOAD_CONTENT = "errors.devicePageLoadFailedMessage";
export const T_ERROR_POLICY_PAGE_LOAD = "errors.policyPageLoadFailed";
export const T_ACCOUNT_FORM_PASSWORD_LABEL = "account.passwordLabel";
export const T_ACCOUNT_FORM_PASSWORD_PLACEHOLDER = "account.passwordPlaceholder";
export const T_ACCOUNT_FORM_NEW_PASSWORD_LABEL = "account.newPasswordLabel";
export const T_ACCOUNT_FORM_NEW_PASSWORD_PLACEHOLDER = "account.newPasswordPlaceholder";
export const T_ACCOUNT_CHANGE_PASSWORD_FORM_TITLE = "account.changePassword";
export const T_ACCOUNT_CHANGE_PASSWORD_BUTTON_LABEL = "account.changePassword";
export const T_ACCOUNT_FORM_EMAIL_LABEL = "account.emailLabel";
export const T_ACCOUNT_FORM_EMAIL_PLACEHOLDER = "account.emailPlaceholder";

export const T_USER_LIST_VIEW_TITLE = "userListView.title";

export const T_USER_LIST_VIEW_ACTION_MENU_EDIT_USER_LABEL = "userListView.actions.editUser";
export const T_USER_LIST_VIEW_ACTION_MENU_RESET_PASSWORD_LABEL = "userListView.actions.resetPassword";
export const T_USER_LIST_VIEW_ACTION_MENU_PROMOTE_USER_LABEL = "userListView.actions.promoteUser";
export const T_USER_LIST_VIEW_ACTION_MENU_DEMOTE_USER_LABEL = "userListView.actions.demoteUser";
export const T_USER_LIST_VIEW_ACTION_MENU_ACTIVATE_USER_LABEL = "userListView.actions.activateUser";
export const T_USER_LIST_VIEW_ACTION_MENU_DEACTIVATE_USER_LABEL = "userListView.actions.deactivateUser";
export const T_USER_LIST_VIEW_ACTION_MENU_DELETE_USER_LABEL = "userListView.actions.deleteUser";
export const T_USER_LIST_VIEW_ACTION_MENU_REMOVE_USER_LABEL = "userListView.actions.removeUser";
export const T_USER_LIST_VIEW_ACTION_MENU_EDIT_ENTERPRISES_LABEL = "userListView.actions.editEnterprises";
export const T_USER_LIST_VIEW_ACTION_MENU_RESET_TFA_LABEL = "userListView.actions.resetTfa";

export const T_USER_LIST_VIEW_RESET_USER_TFA_MODAL_TITLE = "userListView.resetUserTfaModal.title";
export const T_USER_LIST_VIEW_RESET_USER_TFA_MODAL_CONTENT = "userListView.resetUserTfaModal.content";
export const T_USER_LIST_VIEW_RESET_USER_TFA_MODAL_ACCEPT_LABEL = "userListView.resetUserTfaModal.acceptLabel";
export const T_USER_LIST_VIEW_RESET_USER_TFA_MODAL_CANCEL_LABEL = "userListView.resetUserTfaModal.cancelLabel";

export const T_USER_LIST_VIEW_INVITE_USER = "userListView.inviteUsers";
export const T_USER_LIST_VIEW_REFRESH_PAGE = "userListView.refreshLabel";
export const T_USER_LIST_VIEW_SEARCH_LABEL = "userListView.searchLabel";
export const T_USER_LIST_VIEW_SEARCH_PLACEHOLDER = "userListView.searchPlaceholder";
export const T_USER_LIST_VIEW_TOTAL_USERS_LABEL = "userListView.totalUsers";
export const T_USER_LIST_VIEW_LOAD_ERROR_TITLE = "userListView.loadError";
export const T_USER_LIST_VIEW_LOAD_NO_USERS_ERROR_TITLE = "userListView.noUsers";
export const T_USER_LIST_VIEW_LOAD_NO_USERS_ERROR_MESSAGE = "userListView.noUsersErrorMessage";
export const T_USER_LIST_VIEW_LOAD_ERROR_MESSAGE = "userListView.loadErrorMessage";
export const T_USER_LIST_VIEW_IMPORT_USERS = "userListView.importUsers";
export const T_USER_LIST_VIEW_EDIT_USER = "userListView.editUser";

export const T_USER_LIST_VIEW_REMOVE_USER_TITLE = "userListView.removeUser.title";
export const T_USER_LIST_VIEW_REMOVE_USER_CONTENT = "userListView.removeUser.content";
export const T_USER_LIST_VIEW_REMOVE_USER_ACCEPT_LABEL = "userListView.removeUser.accept";
export const T_USER_LIST_VIEW_REMOVE_USER_CANCEL_LABEL = "userListView.removeUser.cancel";

export const T_USER_TABLE_COLUMN_PREFIX = "userTable.column.";
export const T_USER_TABLE_NAME_LABEL = "userTable.column.name";
export const T_USER_TABLE_ROLE_LABEL = "userTable.column.role";
export const T_USER_TABLE_LAST_LOGIN_LABEL = "userTable.column.lastLogin";
export const T_USER_TABLE_STATUS_LABEL = "userTable.column.status";
export const T_USER_TABLE_CREATED_ON_LABEL = "userTable.column.createdOn";
export const T_USER_TABLE_UPDATED_ON_LABEL = "userTable.column.updatedOn";
export const T_USER_TABLE_PASSWORD_UPDATED_ON_LABEL = "userTable.column.passwordUpdatedOn";

export const T_USER_TABLE_COLUMN_TITLE_PREFIX = "userTable.columnTitle.";

export const T_ENTERPRISES_TABLE_COLUMN_PREFIX = "enterprisesTable.column.";
export const T_ENTERPRISES_TABLE_COLUMN_TITLE_PREFIX = "enterprisesTable.column.";
export const T_ENTERPRISES_TABLE_NAME_LABEL = "enterprisesTable.column.name";
export const T_ENTERPRISES_TABLE_ID_LABEL = "enterprisesTable.column.id";
export const T_ENTERPRISES_TABLE_CREATED_ON_LABEL = "enterprisesTable.column.createdOn";
export const T_ENTERPRISES_TABLE_CREATED_BY_LABEL = "enterprisesTable.column.createdBy";

export const T_ENTERPRISE_LIST_VIEW_ACCESS_DENIED_ERROR_MESSAGE = "enterpriseListView.accessDeniedError";
export const T_ENTERPRISE_LIST_VIEW_TITLE = "enterpriseListView.title";
export const T_ENTERPRISE_LIST_VIEW_REFRESH_PAGE = "enterpriseListView.refreshLabel";
export const T_ENTERPRISE_LIST_VIEW_LOAD_ERROR_TITLE = "enterpriseListView.loadError";
export const T_ENTERPRISE_LIST_VIEW_LOAD_ERROR_MESSAGE = "enterpriseListView.loadErrorMessage";
export const T_ENTERPRISE_LIST_VIEW_TOTAL_ENTERPRISES_LABEL = "enterpriseListView.totalEnterprises";
export const T_ENTERPRISE_LIST_VIEW_ADD_ENTERPRISE = "enterpriseListView.addEnterpriseLabel";
export const T_ENTERPRISE_LIST_VIEW_DELETE_ENTERPRISE = "enterpriseListView.deleteEnterpriseLabel";
export const T_ENTERPRISE_LIST_VIEW_DELETE_ENTERPRISE_SUCCESS_MESSAGE = "enterpriseListView.deleteEnterpriseSuccess";
export const T_ENTERPRISE_LIST_VIEW_DELETE_ENTERPRISE_ERROR_MESSAGE = "enterpriseListView.deleteEnterpriseError";
export const T_ENTERPRISE_LIST_VIEW_DELETE_ENTERPRISE_IN_USE_ERROR_MESSAGE = "enterpriseListView.deleteEnterpriseInUseError";
export const T_ENTERPRISE_LIST_VIEW_EDIT_ENTERPRISE_SUCCESS_MESSAGE = "enterpriseListView.editEnterpriseSuccess";
export const T_ENTERPRISE_LIST_VIEW_EDIT_ENTERPRISE_ERROR_MESSAGE = "enterpriseListView.editEnterpriseError";
export const T_ENTERPRISE_LIST_VIEW_EDIT_ENTERPRISE = "enterpriseListView.editEnterprise";
export const T_ENTERPRISE_LIST_VIEW_SEARCH_LABEL = "enterpriseListView.searchLabel";
export const T_ENTERPRISE_LIST_VIEW_SEARCH_PLACEHOLDER = "enterpriseListView.searchPlaceholder";
export const T_ENTERPRISE_LIST_VIEW_EMPTY_TITLE = "enterpriseListView.emptyTitle";
export const T_ENTERPRISE_LIST_VIEW_EMPTY_MESSAGE = "enterpriseListView.emptyMessage";

export const T_ENTERPRISE_AGREEMENT_VIEW_DISCLAIMER = "enterpriseAgreementView.content";
export const T_ENTERPRISE_AGREEMENT_VIEW_OBSERVER_TITLE = "enterpriseAgreementView.titleObserver";
export const T_ENTERPRISE_AGREEMENT_VIEW_OBSERVER_DISCLAIMER = "enterpriseAgreementView.contentObserver";
export const T_ENTERPRISE_AGREEMENT_VIEW_SELECT_ENTERPRISE = "profileMenu.selectEnterprise.Tip";
export const T_ENTERPRISE_AGREEMENT_VIEW_ACCEPT_AGREEMENT_LABEL = "addEnterpriseModal.acceptAgreementLabel2";

export const T_ADD_ENTERPRISE_MODAL_TITLE = "addEnterpriseModal.title";
export const T_ADD_ENTERPRISE_MODAL_CANCEL_LABEL = "addEnterpriseModal.cancelLabel";
export const T_ADD_ENTERPRISE_MODAL_SUB_TITLE = "addEnterpriseModal.subtitle";
export const T_ADD_ENTERPRISE_MODAL_SAVE_LABEL = "addEnterpriseModal.saveLabel";
export const T_ADD_ENTERPRISE_MODAL_SUCCESS_MESSAGE = "addEnterpriseModal.successMessage";
export const T_ADD_ENTERPRISE_MODAL_SUBMIT_ERROR = "addEnterpriseModal.submitError";
export const T_ADD_ENTERPRISE_MODAL_DUPLICATE_ERROR = "addEnterpriseModal.duplicateError";
export const T_ADD_ENTERPRISE_MODAL_ACCEPT_AGREEMENT_LABEL = "addEnterpriseModal.acceptAgreementLabel";
export const T_ADD_ENTERPRISE_MODAL_ACCEPT_AGREEMENT_LINK_TEXT = "addEnterpriseModal.acceptAgreementLinkText";
export const T_ADD_ENTERPRISE_MODAL_PREV_LABEL = "addEnterpriseModal.prevLabel";
export const T_ADD_ENTERPRISE_MODAL_NEXT_LABEL = "addEnterpriseModal.nextLabel";

export const T_ENTERPRISE_MODAL_NAME_LABEL = "enterpriseModal.nameLabel";
export const T_ENTERPRISE_MODAL_NAME_PLACEHOLDER = "enterpriseModal.namePlaceholder";
export const T_ENTERPRISE_MODAL_CONTACT_EMAIL_LABEL = "enterpriseModal.contactEmailLabel";
export const T_ENTERPRISE_MODAL_CONTACT_EMAIL_PLACEHOLDER = "enterpriseModal.contactEmailPlaceholder";
export const T_ENTERPRISE_MODAL_DATA_PROTECTION_TITLE = "enterpriseModal.dataProtectionTitle";
export const T_ENTERPRISE_MODAL_DATA_PROTECTION_SUBTITLE = "enterpriseModal.dataProtectionSubtitle";
export const T_ENTERPRISE_MODAL_DATA_PROTECTION_NAME_LABEL = "enterpriseModal.dataProtectionNameLabel";
export const T_ENTERPRISE_MODAL_DATA_PROTECTION_NAME_PLACEHOLDER = "enterpriseModal.dataProtectionNamePlaceholder";
export const T_ENTERPRISE_MODAL_DATA_PROTECTION_EMAIL_LABEL = "enterpriseModal.dataProtectionEmailLabel";
export const T_ENTERPRISE_MODAL_DATA_PROTECTION_EMAIL_PLACEHOLDER = "enterpriseModal.dataProtectionEmailPlaceholder";
export const T_ENTERPRISE_MODAL_DATA_PROTECTION_PHONE_LABEL = "enterpriseModal.dataProtectionPhoneLabel";
export const T_ENTERPRISE_MODAL_DATA_PROTECTION_PHONE_PLACEHOLDER = "enterpriseModal.dataProtectionPhonePlaceholder";
export const T_ENTERPRISE_MODAL_EU_REPRESENTATIVE_SUBTITLE = "enterpriseModal.euRepresentativeSubtitle";
export const T_ENTERPRISE_MODAL_EU_REPRESENTATIVE_NAME_LABEL = "enterpriseModal.euRepresentativeNameLabel";
export const T_ENTERPRISE_MODAL_EU_REPRESENTATIVE_NAME_PLACEHOLDER = "enterpriseModal.euRepresentativeNamePlaceholder";
export const T_ENTERPRISE_MODAL_EU_REPRESENTATIVE_EMAIL_LABEL = "enterpriseModal.euRepresentativeEmailLabel";
export const T_ENTERPRISE_MODAL_EU_REPRESENTATIVE_EMAIL_PLACEHOLDER = "enterpriseModal.euRepresentativeEmailPlaceholder";
export const T_ENTERPRISE_MODAL_EU_REPRESENTATIVE_PHONE_LABEL = "enterpriseModal.euRepresentativePhoneLabel";
export const T_ENTERPRISE_MODAL_EU_REPRESENTATIVE_PHONE_PLACEHOLDER = "enterpriseModal.euRepresentativePhonePlaceholder";
export const T_ENTERPRISE_MODAL_COLOR_LABEL = "enterpriseModal.colorLabel";
export const T_ENTERPRISE_MODAL_COLOR_PLACEHOLDER = "enterpriseModal.colorPlaceholder";
export const T_ENTERPRISE_MODAL_PAGE1_TITLE = "enterpriseModal.page1.title";
export const T_ENTERPRISE_MODAL_PAGE2_TITLE = "enterpriseModal.page2.title";
export const T_ENTERPRISE_MODAL_PAGE3_TITLE = "enterpriseModal.page3.title";
export const T_ENTERPRISE_MODAL_PAGE4_TITLE = "enterpriseModal.page4.title";
export const T_ENTERPRISE_MODAL_PAGE1_CONTENT = "enterpriseModal.page1.content";
export const T_ENTERPRISE_MODAL_PAGE2_CONTENT = "enterpriseModal.page2.content";
export const T_ENTERPRISE_MODAL_PAGE3_CONTENT = "enterpriseModal.page3.content";
export const T_ENTERPRISE_MODAL_PAGE4_CONTENT = "enterpriseModal.page4.content";
export const T_ENTERPRISE_MODAL_PAGE1_HELP_TEXT = "enterpriseModal.page1.helpText";
export const T_ENTERPRISE_MODAL_PAGE2_HELP_TEXT = "enterpriseModal.page2.helpText";
export const T_ENTERPRISE_MODAL_PAGE3_HELP_TEXT = "enterpriseModal.page2.helpText";
export const T_ENTERPRISE_MODAL_PASSWORD_QUALITY_TITLE = "enterpriseModal.passwordQualityTitle";
export const T_ENTERPRISE_MODAL_PASSWORD_QUALITY_LABEL = "enterpriseModal.passwordQualityLabel";
export const T_ENTERPRISE_MODAL_USERS_LABEL = "enterpriseModal.usersLabel";
export const T_ENTERPRISE_MODAL_CUSTOMER_ENTERPRISE_LABEL = "enterpriseModal.customerEnterpriseLabel";
export const T_ENTERPRISE_MODAL_SELECT_USERS_TITLE = "enterpriseModal.selectUsersTitle";
export const T_ENTERPRISE_MODAL_ADMIN_USER_TITLE = "enterpriseModal.adminUserTitle";
export const T_ENTERPRISE_MODAL_ADMIN_USER_CONTENT = "enterpriseModal.adminUserContent";
export const T_ENTERPRISE_MODAL_ENTERPRISE_DETAILS_TITLE = "enterpriseModal.enterpriseDetailsTitle";
export const T_ENTERPRISE_MODAL_ENTERPRISE_USERS_TITLE = "enterpriseModal.enterpriseUsersTitle";
export const T_ENTERPRISE_MODAL_USERS_WITH_ACCESS_TITLE = "enterpriseModal.usersWithAccessLabel";
export const T_ENTERPRISE_MODAL_DEFAULT_POLICY_TITLE = "enterpriseModal.defaultPolicyLabel";
export const T_ENTERPRISE_MODAL_ADMIN_USER_LABEL = "enterpriseModal.adminUserLabel";
export const T_ENTERPRISE_MODAL_CUSTOMER_ENTERPRISE = "enterpriseModal.customerEnterprise";
export const T_ENTERPRISE_MODAL_ORGANIZATION_ENTERPRISE = "enterpriseModal.organizationEnterprise";
export const T_ENTERPRISE_MODAL_NO_USERS_WITH_ACCESS = "enterpriseModal.noUsersWithAccess";
export const T_ENTERPRISE_MODAL_ENTERPRISE_ACCESS_LABEL = "enterpriseModal.enterpriseAccessLabel";
export const T_ENTERPRISE_MODAL_USERS_PLACEHOLDER = "enterpriseModal.enterpriseAccessPlaceholder";
export const T_ENTERPRISE_MODAL_ENABLE_TFA_LABEL = "enterpriseModal.enableTfaLabel";
export const T_ENTERPRISE_MODAL_TFA_ENABLED = "enterpriseModal.tfaEnabled";
export const T_ENTERPRISE_MODAL_TFA_DISABLED = "enterpriseModal.tfaDisabled";
export const T_ENTERPRISE_MODAL_COLOR_TOOLTIP = "enterpriseModal.colorTooltip";

export const T_EDIT_ENTERPRISE_MODAL_TITLE = "editEnterpriseModal.title";
export const T_EDIT_ENTERPRISE_MODAL_CANCEL_LABEL = "editEnterpriseModal.cancelLabel";
export const T_EDIT_ENTERPRISE_MODAL_SUB_TITLE = "editEnterpriseModal.subtitle";
export const T_EDIT_ENTERPRISE_MODAL_SAVE_LABEL = "editEnterpriseModal.saveLabel";
export const T_EDIT_ENTERPRISE_MODAL_SUCCESS_MESSAGE = "editEnterpriseModal.successMessage";
export const T_EDIT_ENTERPRISE_MODAL_SUBMIT_ERROR = "editEnterpriseModal.submitError";
export const T_EDIT_ENTERPRISE_MODAL_NAME_LABEL = "editEnterpriseModal.nameLabel";
export const T_EDIT_ENTERPRISE_MODAL_NAME_PLACEHOLDER = "editEnterpriseModal.namePlaceholder";

export const T_ENTERPRISE_VIEW_TITLE = "enterpriseView.title";
export const T_ENTERPRISE_VIEW_BACK_BUTTON_LABEL = "enterpriseView.backButtonLabel";
export const T_ENTERPRISE_VIEW_LOADFAIL_TITLE = "enterpriseView.loadFailed.title";
export const T_ENTERPRISE_VIEW_LOADFAIL_MESSAGE = "enterpriseView.loadFailed.message";
export const T_ENTERPRISE_VIEW_ERROR_PREFIX = "enterpriseView.errors";
export const T_ENTERPRISE_VIEW_FETCH_RETRY_LABEL = "enterpriseView.fetchErrorRetryLabel";
export const T_ENTERPRISE_VIEW_CONTACT_DETAILS_TITLE = "enterpriseView.contactDetailsTitle";
export const T_ENTERPRISE_VIEW_CONTACT_DETAILS_CONTENT = "enterpriseView.contactDetailsContent";
export const T_ENTERPRISE_VIEW_ENTERPRISE_ID_LABEL = "enterpriseView.enterpriseIdLabel";
export const T_ENTERPRISE_VIEW_ENABLE_TFA_LABEL = "enterpriseView.enableTfaLabel";
export const T_ENTERPRISE_VIEW_TFA_TITLE = "enterpriseView.tfaTitle";
export const T_ENTERPRISE_VIEW_TFA_CONTENT = "enterpriseView.tfaContent";

export const T_ENTERPRISE_VIEW_TFA_CONFIRM_CONTENT = "enterpriseView.tfaConfirmModal.content";
export const T_ENTERPRISE_VIEW_TFA_CONFIRM_CANCEL_LABEL = "enterpriseView.tfaConfirmModal.cancelLabel";
export const T_ENTERPRISE_VIEW_TFA_CONFIRM_ACCEPT_LABEL = "enterpriseView.tfaConfirmModal.acceptLabel";
export const T_ENTERPRISE_VIEW_TFA_CONFIRM_MODAL_PREFIX = "enterpriseView.tfaConfirmModal";

export const T_DELETE_ENTERPRISE_MODAL_TITLE = "deleteEnterpriseModal.title";
export const T_DELETE_ENTERPRISE_MODAL_CONTENT = "deleteEnterpriseModal.content";
export const T_DELETE_ENTERPRISE_MODAL_CANCEL_LABEL = "deleteEnterpriseModal.cancelLabel";
export const T_DELETE_ENTERPRISE_MODAL_SUCCESS_MESSAGE = "deleteEnterpriseModal.successMessage";
export const T_DELETE_ENTERPRISE_MODAL_SUBMIT_ERROR = "deleteEnterpriseModal.submitError";
export const T_DELETE_ENTERPRISE_MODAL_ACCEPT_LABEL = "deleteEnterpriseModal.acceptLabel";

export const T_FORGOT_PASSWORD_FORM_EMAIL_LABEL = "forgotPassword.emailLabel";
export const T_FORGOT_PASSWORD_FORM_EMAIL_PLACEHOLDER = "forgotPassword.emailPlaceholder";
export const T_FORGOT_PASSWORD_SEND_BUTTON_LABEL = "forgotPassword.send";
export const T_FORGOT_PASSWORD_SEND_FORM_TITLE = "forgotPassword.send";

export const T_NAV_HOME = "nav.home";
export const T_NAV_APPLICATIONS = "nav.applications";
export const T_NAV_ZERO_TOUCH = "nav.zeroTouch";
export const T_NAV_POLICIES = "nav.policies";
export const T_NAV_DEVICES = "nav.devices";
export const T_NAV_DEVICE_GROUPS = "nav.deviceGroups";
export const T_NAV_DEVICE_USERS = "nav.deviceUsers";
export const T_NAV_USERS = "nav.users";
export const T_NAV_ENTERPRISES = "nav.enterprises";
export const T_NAV_ACCOUNT = "nav.account";
export const T_NAV_TITLE_ENTERPRISE = "nav.title.enterprise";
export const T_NAV_TITLE_ADMIN = "nav.title.administration";
export const T_NAV_TITLE_SUPPORT = "nav.title.support";

export const T_MOMENT_DATE_FORMAT = "format.date";
export const T_MOMENT_DATE_TIME_FORMAT = "format.dateTime";
export const T_MOMENT_TIME_FORMAT = "format.time";
export const T_DATE_NEVER = "date.never";

export const T_MAP_MARKER_POPUP_COORDINATE_LABEL = "map.marker.popup.coordinateLabel";
export const T_MAP_MARKER_POPUP_TIME_LABEL = "map.marker.popup.timeLabel";
export const T_MAP_MARKER_POPUP_CREATED_ON_LABEL = "map.marker.popup.createdOnLabel";

export const T_DATE_TIME_PICKER_DATE_FORMAT = "dateTimePicker.date";
export const T_DATE_TIME_PICKER_DATE_TIME_FORMAT = "dateTimePicker.dateTime";
export const T_DATE_TIME_PICKER_TIME_FORMAT = "dateTimePicker.time";

export const T_TABLE_PAGE_OF = "table.pageOf";
export const T_TABLE_PER_PAGE = "table.perPage";

export const T_TABLE_COLUMN_UNDEFINED_VALUE = "table.values.undefined";
export const T_TABLE_COLUMN_TRUE_VALUE = "table.values.true";
export const T_TABLE_COLUMN_FALSE_VALUE = "table.values.false";

export const T_TABLE_COLUMN_TOGGLES_TITLE = "table.columnToggles.title";
export const T_TABLE_COLUMN_TOGGLES_RESET_LABEL = "table.columnToggles.resetLabel";

export const T_POLICY_LIST_VIEW_ADD_POLICY = "policiesView.addPolicy";
export const T_POLICY_LIST_VIEW_REFRESH_POLICY = "policiesView.refreshPolicyList";
export const T_POLICY_LIST_VIEW_TOTAL_POLICIES_LABEL = "policyListView.totalPoliciesLabel";

export const T_POLICY_LIST_VIEW_SEARCH_LABEL = "policyListView.searchLabel";
export const T_POLICY_LIST_VIEW_SEARCH_PLACEHOLDER = "policyListView.searchPlaceholder";

export const T_DEVICE_LIST_VIEW_SEARCH_LABEL = "deviceListView.searchLabel";
export const T_DEVICE_LIST_VIEW_SEARCH_PLACEHOLDER = "deviceListView.searchPlaceholder";

export const T_DEVICE_LIST_VIEW_ADD_DEVICE = "devicesView.addDevice";
export const T_DEVICE_LIST_VIEW_REFRESH_DEVICE = "devicesView.refreshDeviceList";
export const T_DEVICE_LIST_VIEW_TOTAL_DEVICES_LABEL = "deviceView.totalDevicesText";

export const T_ERROR_VIEW_MESSAGE = "error.message";
export const T_ERROR_LOADFAILED_TITLE = "error.loadFailed.title";
export const T_ERROR_LOADFAILED_MESSAGE = "error.loadFailed.message";
export const T_ERROR_VIEW_BUTTON_LABEL = "error.loginButtonLabel";

export const T_DEVICE_TABLE_COLUMN_PREFIX = "deviceTable.column.";
export const T_DEVICE_TABLE_COLUMN_TITLE_PREFIX = "deviceTable.columnTitle.";
export const T_DEVICE_TABLE_COLUMN_DISPLAY_NAME = "deviceTable.column.displayName";
export const T_DEVICE_TABLE_COLUMN_NAME = "deviceTable.column.name";
export const T_DEVICE_TABLE_COLUMN_GROUP_NAME = "deviceTable.column.groupName";
export const T_DEVICE_TABLE_IMEI = "deviceTable.column.imei";
export const T_DEVICE_TABLE_COLUMN_API_LEVEL = "deviceTable.column.apiLevel";
export const T_DEVICE_TABLE_COLUMN_APPLIED_POLICY_NAME = "deviceTable.column.appliedPolicyName";
export const T_DEVICE_TABLE_COLUMN_ENROLLMENT_TOKEN_NAME = "deviceTable.column.enrollmentTokenName";
export const T_DEVICE_TABLE_COLUMN_HARDWARE_INFO_BRAND = "deviceTable.column.hardwareInfo.brand";
export const T_DEVICE_TABLE_COLUMN_HARDWARE_INFO_DEVICE_BASEBAND_VERSION = "deviceTable.column.hardwareInfo.deviceBasebandVersion";
export const T_DEVICE_TABLE_COLUMN_HARDWARE_INFO_HARDWARE = "deviceTable.column.hardwareInfo.hardware";
export const T_DEVICE_TABLE_COLUMN_LAST_STATUS_REPORT_TIME = "deviceTable.column.lastStatusReportTime";
export const T_DEVICE_TABLE_COLUMN_ENROLLMENT_TIME = "deviceTable.column.enrollmentTime";
export const T_DEVICE_TABLE_COLUMN_UPDATED_TIME = "deviceTable.column.updatedOnTime";
export const T_DEVICE_TABLE_COLUMN_CREATED_ON_TIME = "deviceTable.column.createdOn";
export const T_DEVICE_TABLE_COLUMN_HARDWARE_INFO_MANUFACTURER = "deviceTable.column.hardwareInfo.manufacturer";
export const T_DEVICE_TABLE_COLUMN_HARDWARE_INFO_MODEL = "deviceTable.column.hardwareInfo.model";
export const T_DEVICE_TABLE_COLUMN_HARDWARE_INFO_SERIALNUMBER = "deviceTable.column.hardwareInfo.serialNumber";
export const T_DEVICE_TABLE_COLUMN_LAST_POLICY_SYNC_TIME = "deviceTable.column.lastPolicySyncTime";
export const T_DEVICE_TABLE_COLUMN_MANAGEMENT_MODE = "deviceTable.column.managementMode";
export const T_DEVICE_TABLE_COLUMN_MEMORY_INFO_TOTAL_INTERNAL_STORAGE = "deviceTable.column.memoryInfo.totalInternalStorage";
export const T_DEVICE_TABLE_COLUMN_MEMORY_INFO_TOTAL_RAM = "deviceTable.column.memoryInfo.totalRam";
export const T_DEVICE_TABLE_COLUMN_OWNERSHIP = "deviceTable.column.ownership";
export const T_DEVICE_TABLE_COLUMN_SOFTWARE_INFO_ANDROID_BUILD_NUMBER = "deviceTable.column.softwareInfo.androidBuildNumber";
export const T_DEVICE_TABLE_COLUMN_SOFTWARE_INFO_ANDROID_BUILD_TIME = "deviceTable.column.softwareInfo.androidBuildTime";
export const T_DEVICE_TABLE_COLUMN_SOFTWARE_INFO_ANDROID_DEVICE_POLICY_VERSION_CODE = "deviceTable.column.softwareInfo.androidDevicePolicyVersionCode";
export const T_DEVICE_TABLE_COLUMN_SOFTWARE_INFO_ANDROID_DEVICE_POLICY_VERSION_NAME = "deviceTable.column.softwareInfo.androidDevicePolicyVersionName";
export const T_DEVICE_TABLE_COLUMN_SOFTWARE_INFO_ANDROID_VERSION = "deviceTable.column.softwareInfo.androidVersion";
export const T_DEVICE_TABLE_COLUMN_SOFTWARE_INFO_BOOT_LOADER_VERSION = "deviceTable.column.softwareInfo.bootloaderVersion";
export const T_DEVICE_TABLE_COLUMN_SOFTWARE_INFO_DEVICE_BUILD_SIGNATURE = "deviceTable.column.softwareInfo.deviceBuildSignature";
export const T_DEVICE_TABLE_COLUMN_SOFTWARE_INFO_DEVICE_KERNEL_VERSION = "deviceTable.column.softwareInfo.deviceKernelVersion";
export const T_DEVICE_TABLE_COLUMN_SOFTWARE_INFO_PRIMARY_LANGUAGE_CODE = "deviceTable.column.softwareInfo.primaryLanguageCode";
export const T_DEVICE_TABLE_COLUMN_SOFTWARE_INFO_SECURITY_PATCH_LEVEL = "deviceTable.column.softwareInfo.securityPatchLevel";
export const T_DEVICE_TABLE_COLUMN_STATE = "deviceTable.column.state";
export const T_DEVICE_TABLE_COLUMN_USERNAME = "deviceTable.column.userName";
export const T_DEVICE_TABLE_COLUMN_SOFTWARE_INFO_SYSTEM_UPDATE_INFO_UPDATE_STATUS = "deviceTable.column.softwareInfo.systemUpdateInfo.updateStatus";
export const T_DEVICE_TABLE_COLUMN_SOFTWARE_INFO_SYSTEM_UPDATE_INFO_UPDATE_RECEIVED_TIME = "deviceTable.column.softwareInfo.systemUpdateInfo.updateReceivedTime";
export const T_DEVICE_TABLE_COLUMN_PREVIOUS_DEVICE_NAMES = "deviceTable.column.previousDeviceNames";
export const T_DEVICE_TABLE_COLUMN_CUSTOM_ID = "deviceTable.column.customId";
export const T_DEVICE_TABLE_COLUMN_UNENROLLED_ON = "deviceTable.column.unenrolledOn";

export const T_DEVICE_GROUP_DEVICES_TABLE_NO_DEVICES_CONTENT = "deviceGroupDevicesTable.noDevicesFound.content";

export const T_POLICY_TABLE_NAME_LABEL = "policyTable.labels.name";
export const T_POLICY_TABLE_POLICY_TYPE_LABEL = "policyTable.labels.policyType";
export const T_POLICY_TABLE_PASSWORD_MINIMUM_LENGTH_LABEL = "policyTable.labels.passwordRequirements.passwordMinimumLength";
export const T_POLICY_TABLE_VERSION_LABEL = "policyTable.labels.version";

export const T_DEVICE_TABLE_NO_DEVICES_TITLE = "deviceTable.noDevices.title";
export const T_DEVICE_TABLE_NO_DEVICES_CONTENT = "deviceTable.noDevices.content";
export const T_DEVICE_TABLE_NO_DEVICES_FILTER_CONTENT = "deviceTable.noDevicesFilter.content";
export const T_DEVICE_TABLE_NO_FOUND_DEVICES_TITLE = "deviceTable.noDevicesFound.title";
export const T_DEVICE_TABLE_NO_FOUND_DEVICES_CONTENT = "deviceTable.noDevicesFound.content";

export const T_DEVICE_LIST_VIEW_FILTERING_LABEL = "deviceListView.filteringMenu.title";
export const T_DEVICE_LIST_VIEW_CLEAR_FILTERS_LABEL = "deviceListView.filteringMenu.clear";
export const T_DEVICE_LIST_VIEW_FILTERING_FILTER_BY_FIELD_PREFIX = "deviceListView.filteringMenu.filterByField";

export const T_DEVICE_FILTER_OPTIONS_COMPONENT_LABEL_HIDE_FILTERS = "deviceFilterOptionsComponent.label.hideFilters";
export const T_DEVICE_FILTER_OPTIONS_COMPONENT_LABEL_SHOW_ALL_FILTERS = "deviceFilterOptionsComponent.label.showAllFilters";
export const T_DEVICE_FILTER_OPTIONS_DELETED_LABEL = "deviceFilterOptionsComponent.label.deletedLabel";

export const T_DEVICE_LIST_VIEW_BULK_ACTIONS_LABEL = "deviceListView.bulkActionMenu.title";
export const T_DEVICE_LIST_VIEW_ACTION_BULK_MENU_LOCK_LABEL = "deviceListView.bulkActionMenu.lockDevice";
export const T_DEVICE_LIST_VIEW_ACTION_BULK_MENU_REBOOT_LABEL = "deviceListView.bulkActionMenu.rebootDevice";
export const T_DEVICE_LIST_VIEW_ACTION_BULK_MENU_RELINQUISH_OWNERSHIP_LABEL = "deviceListView.bulkActionMenu.relinquishOwnership";
export const T_DEVICE_LIST_VIEW_ACTION_BULK_MENU_RESET_LABEL = "deviceListView.bulkActionMenu.resetDevice";
export const T_DEVICE_LIST_VIEW_ACTION_BULK_MENU_SELECT_AND_DEPLOY_POLICY_LABEL = "deviceListView.bulkActionMenu.selectAndDeployPolicyOnDevice";
export const T_DEVICE_LIST_VIEW_ACTION_BULK_MENU_RESET_PASSWORD_LABEL = "deviceListView.bulkActionMenu.resetPassword";
export const T_DEVICE_LIST_VIEW_ACTION_BULK_MENU_SEND_MESSAGES_LABEL = "deviceListView.bulkActionMenu.sendMessage";

export const T_DEVICE_LIST_VIEW_ACTION_MENU_REMOVE_HIGHLIGHT_LABEL = "deviceTable.actionMenu.unHighlightDevice";
export const T_DEVICE_LIST_VIEW_ACTION_MENU_LOCK_LABEL = "deviceTable.actionMenu.lockDevice";
export const T_DEVICE_LIST_VIEW_ACTION_MENU_REBOOT_LABEL = "deviceTable.actionMenu.rebootDevice";
export const T_DEVICE_LIST_VIEW_ACTION_MENU_RESET_LABEL = "deviceTable.actionMenu.resetDevice";
export const T_DEVICE_LIST_VIEW_ACTION_MENU_DEVICE_ON_MAP_LABEL = "deviceTable.actionMenu.viewDeviceOnMap";
export const T_DEVICE_LIST_VIEW_ACTION_MENU_EDIT_DEVICE_LABEL = "deviceTable.actionMenu.editDevice";
export const T_DEVICE_LIST_VIEW_ACTION_MENU_SELECT_AND_DEPLOY_POLICY_LABEL = "deviceTable.actionMenu.selectAndDeployPolicyOnDevice";
export const T_DEVICE_LIST_VIEW_ACTION_MENU_RESET_PW_LABEL = "deviceTable.actionMenu.resetPassword";
export const T_DEVICE_LIST_VIEW_ACTION_MENU_ADD_DEVICE_GROUP_LABEL = "deviceTable.actionMenu.addGroup";
export const T_DEVICE_LIST_VIEW_ACTION_MENU_REMOVE_DEVICE_GROUP_LABEL = "deviceTable.actionMenu.removeGroup";
export const T_DEVICE_LIST_VIEW_ACTION_MENU_SEND_MESSAGE_LABEL = "deviceTable.actionMenu.sendMessage";

export const T_DEVICE_LIST_VIEW_DOWNLOAD_CSV = "deviceTable.downloadCsv";

export const T_DEVICE_GROUPS_LIST_VIEW_ACCESS_DENIED_ERROR_MESSAGE = "deviceGroupsListView.accessDeniedError";
export const T_DEVICE_GROUPS_LIST_VIEW_TITLE = "deviceGroupsListView.title";
export const T_DEVICE_GROUPS_LIST_VIEW_TOTAL_GROUPS_LABEL = "deviceGroupsListView.totalGroupsLabel";
export const T_DEVICE_GROUPS_LIST_VIEW_ADD_GROUP = "deviceGroupsListView.addGroup";
export const T_DEVICE_GROUPS_LIST_VIEW_REFRESH_PAGE = "deviceGroupsListView.refreshPage";
export const T_DEVICE_GROUPS_LIST_VIEW_DELETE_GROUP = "deviceGroupsListView.deleteGroup";
export const T_DEVICE_GROUPS_LIST_VIEW_EDIT_GROUP = "deviceGroupsListView.editGroup";
export const T_DEVICE_GROUPS_LIST_VIEW_LOAD_ERROR_TITLE = "deviceGroupsListView.loadError.title";
export const T_DEVICE_GROUPS_LIST_VIEW_LOAD_ERROR_MESSAGE = "deviceGroupsListView.loadError.message";
export const T_DEVICE_GROUPS_LIST_VIEW_EMPTY_TITLE = "deviceGroupsListView.emptyTitle";
export const T_DEVICE_GROUPS_LIST_VIEW_EMPTY_MESSAGE = "deviceGroupsListView.emptyMessage";
export const T_DEVICE_GROUPS_LIST_VIEW_DELETE_GROUP_SUCCESS_MESSAGE = "deviceGroupsListView.deleteGroup.successMessage";
export const T_DEVICE_GROUPS_LIST_VIEW_DELETE_GROUP_ERROR_MESSAGE = "deviceGroupsListView.deleteGroup.errorMessage";
export const T_DEVICE_GROUPS_LIST_VIEW_MESSAGE_GROUP = "deviceGroupsListView.messageGroup";

export const T_DEVICE_GROUP_VIEW_BACK_BUTTON_LABEL = "deviceGroupView.backButtonLabel";
export const T_DEVICE_GROUP_VIEW_TITLE = "deviceGroupView.title";

export const T_ADD_DEVICE_GROUP_MODAL_TITLE = "addDeviceGroupModal.title";
export const T_ADD_DEVICE_GROUP_MODAL_SUB_TITLE = "addDeviceGroupModal.subtitle";
export const T_ADD_DEVICE_GROUP_MODAL_NAME_TITLE = "addDeviceGroupModal.name.title";
export const T_ADD_DEVICE_GROUP_MODAL_NAME_PLACEHOLDER = "addDeviceGroupModal.name.placeholder";
export const T_ADD_DEVICE_GROUP_MODAL_CANCEL_LABEL = "addDeviceGroupModal.cancelLabel";
export const T_ADD_DEVICE_GROUP_MODAL_SAVE_LABEL = "addDeviceGroupModal.saveLabel";
export const T_ADD_DEVICE_GROUP_MODAL_SUCCESS_MESSAGE = "addDeviceGroupModal.successMessage";
export const T_ADD_DEVICE_GROUP_MODAL_SUBMIT_ERROR = "addDeviceGroupModal.submitError";
export const T_ADD_DEVICE_GROUP_MODAL_POLICY_TITLE = "addDeviceGroupModal.assignedPolicy.title";
export const T_ADD_DEVICE_GROUP_MODAL_POLICY_PLACEHOLDER = "addDeviceGroupModal.assignedPolicy.placeholder";
export const T_ADD_DEVICE_GROUP_MODAL_DESCRIPTION_TITLE = "addDeviceGroupModal.description.title";
export const T_ADD_DEVICE_GROUP_MODAL_DESCRIPTION_PLACEHOLDER = "addDeviceGroupModal.description.placeholder";

export const T_REMOVE_DEVICE_FROM_GROUP_MODAL_TITLE = "removeDeviceFromGroupModal.title";
export const T_REMOVE_DEVICE_FROM_GROUP_MODAL_CONTENT = "removeDeviceFromGroupModal.content";
export const T_REMOVE_DEVICE_FROM_GROUP_MODAL_NO_IMEI_DEVICE_TEXT = "removeDeviceFromGroupModal.noImeiDeviceText";
export const T_REMOVE_DEVICE_FROM_GROUP_MODAL_CANCEL_LABEL = "removeDeviceFromGroupModal.cancelLabel";
export const T_REMOVE_DEVICE_FROM_GROUP_MODAL_ACCEPT_LABEL = "removeDeviceFromGroupModal.acceptLabel";
export const T_REMOVE_DEVICE_FROM_GROUP_MODAL_ERROR_MESSAGE = "removeDeviceFromGroupModal.errorMessage";

export const T_REMOVE_DEVICE_LIST_FROM_GROUP_MODAL_CONTENT = "removeDeviceListFromGroupModal.content";

export const T_ADD_DEVICE_TO_GROUP_MODAL_TITLE = "addDeviceToGroupModal.title";
export const T_ADD_DEVICE_TO_GROUP_MODAL_DEVICE_GROUP_TITLE = "addDeviceToGroupModal.deviceGroup.title";
export const T_ADD_DEVICE_TO_GROUP_MODAL_DEVICE_GROUP_PLACEHOLDER = "addDeviceToGroupModal.deviceGroup.placeholder";
export const T_ADD_DEVICE_TO_GROUP_MODAL_CANCEL_LABEL = "addDeviceToGroupModal.cancelLabel";
export const T_ADD_DEVICE_TO_GROUP_MODAL_SAVE_LABEL = "addDeviceToGroupModal.saveLabel";
export const T_ADD_DEVICE_TO_GROUP_MODAL_SUCCESS_MESSAGE = "addDeviceToGroupModal.successMessage";
export const T_ADD_DEVICE_TO_GROUP_MODAL_SUBMIT_ERROR = "addDeviceToGroupModal.submitError";
export const T_ADD_DEVICE_TO_GROUP_MODAL_SUBMIT_ERROR_ASSIGNED_USER = "addDeviceToGroupModal.submitError.userWithGroupNotAllowed";

export const T_EDIT_DEVICE_GROUP_MODAL_TITLE = "editDeviceGroupModal.title";
export const T_EDIT_DEVICE_GROUP_MODAL_SUB_TITLE = "editDeviceGroupModal.subtitle";
export const T_EDIT_DEVICE_GROUP_MODAL_NAME_TITLE = "editDeviceGroupModal.name.title";
export const T_EDIT_DEVICE_GROUP_MODAL_NAME_PLACEHOLDER = "editDeviceGroupModal.name.placeholder";
export const T_EDIT_DEVICE_GROUP_MODAL_CANCEL_LABEL = "editDeviceGroupModal.cancelLabel";
export const T_EDIT_DEVICE_GROUP_MODAL_SAVE_LABEL = "editDeviceGroupModal.saveLabel";
export const T_EDIT_DEVICE_GROUP_MODAL_SUCCESS_MESSAGE = "editDeviceGroupModal.successMessage";
export const T_EDIT_DEVICE_GROUP_MODAL_SUBMIT_ERROR = "editDeviceGroupModal.submitError";
export const T_EDIT_DEVICE_GROUP_MODAL_POLICY_TITLE = "editDeviceGroupModal.assignedPolicy.title";
export const T_EDIT_DEVICE_GROUP_MODAL_POLICY_PLACEHOLDER = "editDeviceGroupModal.assignedPolicy.placeholder";
export const T_EDIT_DEVICE_GROUP_MODAL_DESCRIPTION_TITLE = "editDeviceGroupModal.description.title";
export const T_EDIT_DEVICE_GROUP_MODAL_DESCRIPTION_PLACEHOLDER = "editDeviceGroupModal.description.placeholder";
export const T_EDIT_DEVICE_GROUP_MODAL_LARGE_GROUP_WARNING_TEXT = "editDeviceGroupModal.warning";
export const T_EDIT_DEVICE_GROUP_MODAL_LARGE_GROUP_WARNING_TEXT_ESTIMATE = "editDeviceGroupModal.warning.rought.estimate";
export const T_EDIT_DEVICE_GROUP_LARGE_GROUP_CONFIRMATION_MODAL_TITLE = "editDeviceGroupModal.confirmation.title";
export const T_EDIT_DEVICE_GROUP_LARGE_GROUP_CONFIRMATION_MODAL_TEXT_POLICY = "editDeviceGroupModal.confirmation.text.policy";
export const T_EDIT_DEVICE_GROUP_LARGE_GROUP_CONFIRMATION_MODAL_TEXT_DEVICES = "editDeviceGroupModal.confirmation.text.devices";
export const T_EDIT_DEVICE_GROUP_LARGE_GROUP_CONFIRMATION_MODAL_TEXT_TIME = "editDeviceGroupModal.confirmation.text.time";
export const T_EDIT_DEVICE_GROUP_LARGE_GROUP_CONFIRMATION_MODAL_TEXT_TIME2 = "editDeviceGroupModal.confirmation.text.time2";
export const T_EDIT_DEVICE_GROUP_LARGE_GROUP_CONFIRMATION_MODAL_TEXT_ABORT = "editDeviceGroupModal.confirmation.text.abort";
export const T_EDIT_DEVICE_GROUP_LARGE_GROUP_CONFIRMATION_MODAL_SAVE_LABEL = "editDeviceGroupModal.confirmation.update";
export const T_EDIT_DEVICE_GROUP_MODAL_TOTAL_DEVICES = "editDeviceGroupModal.totalDevicesText";
export const T_EDIT_DEVICE_GROUP_MODAL_BACKGROUNDTASK_WARNING_TEXT = "editDeviceGroupModal.warning.backgroudtask.text";

export const T_DEVICE_GROUPS_TABLE_COLUMN_PREFIX = "deviceGroupsTable.column.";
export const T_DEVICE_GROUPS_TABLE_COLUMN_TITLE_PREFIX = "deviceGroupsTable.columnTitle.";
export const T_DEVICE_GROUPS_TABLE_COLUMN_NAME = "deviceGroupsTable.column.name";
export const T_DEVICE_GROUPS_TABLE_COLUMN_CREATED_ON = "deviceGroupsTable.column.createdOn";
export const T_DEVICE_GROUPS_TABLE_COLUMN_UPDATED_ON = "deviceGroupsTable.column.updatedOn";
export const T_DEVICE_GROUPS_TABLE_COLUMN_ASSIGNED_POLICY = "deviceGroupsTable.column.assignedPolicyName";
export const T_DEVICE_GROUPS_TABLE_COLUMN_DEVICE_COUNT = "deviceGroupsTable.column.deviceCount";

export const T_DELETE_DEVICE_GROUP_MODAL_TITLE = "deleteDeviceGroupModal.title";
export const T_DELETE_DEVICE_GROUP_MODAL_CONTENT = "deleteDeviceGroupModal.content";

export const T_DELETE_DEVICE_GROUP_MODAL_CANCEL_LABEL = "deleteDeviceGroupModal.cancelLabel";
export const T_DELETE_DEVICE_GROUP_MODAL_ACCEPT_LABEL = "deleteDeviceGroupModal.acceptLabel";

export const T_POLICY_TABLE_COLUMN_PREFIX = "policyTable.column.";
export const T_POLICY_TABLE_COLUMN_TITLE_PREFIX = "policyTable.columnTitle.";

export const T_POLICY_TABLE_NO_POLICIES_TITLE = "policyTable.noPolicies.title";
export const T_POLICY_TABLE_NO_POLICIES_CONTENT = "policyTable.noPolicies.content";
export const T_POLICY_TABLE_NO_FOUND_POLICIES_TITLE = "policyTable.noPoliciesFound.title";
export const T_POLICY_TABLE_NO_FOUND_POLICIES_CONTENT = "policyTable.noPoliciesFound.content";

export const T_POLICY_TABLE_POLICY_ADDED_CONTENT = "policyTable.policyAddedMessage";
export const T_POLICY_TABLE_POLICY_ADDED_ACTION_LABEL = "policyTable.policyAddedMessageActionLabel";
export const T_POLICY_TABLE_POLICY_ADDED_DISMISS_LABEL = "policyTable.policyAddedMessageDismissLabel";

export const T_SETUP_VIEW_SIGN_OUT_LABEL = "setup.signOutLabel";

export const T_SETUP_VIEW_MAIN_TITLE = "setup.mainTitle";

export const T_SETUP_VIEW_STEP1_TITLE = "setup.step1.title";
export const T_SETUP_VIEW_STEP1_DESCRIPTION = "setup.step1.description";
export const T_SETUP_VIEW_STEP1_CONTINUE_BUTTON = "setup.step1.continueButton";

export const T_SETUP_VIEW_STEP1_COMPLETE_TITLE = "setup.step1.completeTitle";
export const T_SETUP_VIEW_STEP1_COMPLETE_SUB_TITLE = "setup.step1.completeSubTitle";
export const T_SETUP_VIEW_STEP1_COMPLETE_DESCRIPTION = "setup.step1.completeDescription";
export const T_SETUP_VIEW_STEP1_COMPLETE_CONTINUE_BUTTON = "setup.step1.completeContinueButton";

export const T_SETUP_VIEW_STEP2_TITLE = "setup.step2.title";
export const T_SETUP_VIEW_STEP2_DESCRIPTION = "setup.step2.description";
export const T_SETUP_VIEW_STEP2_ENTERPRISE_NAME_LABEL = "setup.step2.enterpriseNameLabel";
export const T_SETUP_VIEW_STEP2_ENTERPRISE_NAME_PLACEHOLDER = "setup.step2.enterpriseNamePlaceholder";
export const T_SETUP_VIEW_STEP2_ENTERPRISE_COLOR_LABEL = "setup.step2.enterpriseColorLabel";
export const T_SETUP_VIEW_STEP2_ENTERPRISE_COLOR_PLACEHOLDER = "setup.step2.enterpriseColorPlaceholder";
export const T_SETUP_VIEW_STEP2_BUTTON_LABEL = "setup.step2.buttonLabel";

export const T_SETUP_VIEW_STEP2_COMPLETE_SUB_TITLE = "setup.step2.completeSubTitle";
export const T_SETUP_VIEW_STEP2_COMPLETE_DESCRIPTION = "setup.step2.completeDescription";
export const T_SETUP_VIEW_STEP2_COMPLETE_BUTTON_LABEL = "setup.step2.completeButtonLabel";

export const T_SETUP_VIEW_STEP3_TITLE = "setup.step3.title";
export const T_SETUP_VIEW_STEP3_DESCRIPTION = "setup.step3.description";

export const T_POLICY_FORM_POLICY_NAME_LABEL = "policyForm.policyName.label";
export const T_POLICY_FORM_POLICY_NAME_PLACEHOLDER = "policyForm.policyName.placeholder";

export const T_POLICY_FORM_PASSWORD_QUALITY_PLACEHOLDER = "policyForm.passwordQualityPlaceholder";

export const T_POLICY_FORM_POLICY_TYPE_LABEL = "policyForm.policyType.label";
export const T_POLICY_FORM_POLICY_TYPE_PLACEHOLDER = "policyForm.policyType.placeholder";

export const T_SETUP_VIEW_POLICY_FORM_POLICY_NAME_PLACEHOLDER = "setup.step3.policyNamePlaceholder";

export const T_POLICY_FORM_PASSWORD_QUALITY_DESCRIPTION = "setup.step3.passwordQualityDescription";
export const T_POLICY_FORM_PASSWORD_QUALITY_LABEL = "setup.step3.passwordQualityLabel";
export const T_SETUP_VIEW_STEP3_POST_NOTE = "setup.step3.postNote";
export const T_SETUP_VIEW_STEP3_BUTTON_LABEL = "setup.step3.buttonLabel";

export const T_SETUP_VIEW_STEP3_COMPLETE_SUB_TITLE = "setup.step3.completeSubTitle";
export const T_SETUP_VIEW_STEP3_COMPLETE_DESCRIPTION = "setup.step3.completeDescription";
export const T_SETUP_VIEW_STEP3_COMPLETE_POST_DESCRIPTION = "setup.step3.completePostDescription";
export const T_SETUP_VIEW_STEP3_COMPLETE_BUTTON_LABEL = "setup.step3.completeButtonLabel";

export const T_POLICY_PASSWORD_QUALITY_ENUM_PREFIX = "policy.passwordQuality.";

export const T_LANG_ENGLISH = "languages.en";
export const T_LANG_FINNISH = "languages.fi";
export const T_LANG_GERMAN = "languages.de";
export const T_LANG_PORTUGUESE = "languages.pt";
export const T_LANG_CHINESE_SIMPLIFIED = "languages.zh";
export const T_LANG_FRENCH = "languages.fr";
export const T_LANG_SPANISH = "languages.es";

export const T_SIGNOUT_MODAL_TITLE = "signOutModal.title";
export const T_SIGNOUT_MODAL_CONTENT = "signOutModal.content";
export const T_SIGNOUT_MODAL_CANCEL_LABEL = "signOutModal.cancelLabel";
export const T_SIGNOUT_MODAL_SIGNOUT_LABEL = "signOutModal.signOutLabel";

export const T_APPROVE_APPS_MODAL_TITLE = "approveAppsModal.title";
export const T_APPROVE_APPS_MODAL_CONTENT = "approveAppsModal.content";
export const T_APPROVE_APPS_MODAL_CANCEL_LABEL = "approveAppsModal.cancelLabel";
export const T_APPROVE_APPS_MODAL_CONTINUE_LABEL = "approveAppsModal.continueLabel";

export const T_NETWORK_CONFIGURATIONS_MODAL_NEW_TITLE = "networkConfigurationsModal.titleNew";
export const T_NETWORK_CONFIGURATIONS_MODAL_EDIT_TITLE = "networkConfigurationsModal.titleEdit";
export const T_NETWORK_CONFIGURATIONS_MODAL_CANCEL_LABEL = "networkConfigurationsModal.cancelLabel";
export const T_NETWORK_CONFIGURATIONS_MODAL_SAVE_LABEL = "networkConfigurationsModal.saveLabel";
export const T_NETWORK_CONFIGURATIONS_MODAL_NETWORK_NAME_LABEL = "networkConfigurationsModal.label.networkName";
export const T_NETWORK_CONFIGURATIONS_MODAL_NETWORK_NAME_PLACEHOLDER = "networkConfigurationsModal.placeholder.networkName";
export const T_NETWORK_CONFIGURATIONS_MODAL_AUTOCONNECT_LABEL = "networkConfigurationsModal.label.autoConnect";
export const T_NETWORK_CONFIGURATIONS_MODAL_ENCRYPTION_LABEL = "networkConfigurationsModal.label.encryption";
export const T_NETWORK_CONFIGURATIONS_MODAL_ENCRYPTION_PLACEHOLDER = "networkConfigurationsModal.placeholder.encryption";
export const T_NETWORK_CONFIGURATIONS_MODAL_PASSPHRASE_LABEL = "networkConfigurationsModal.label.passphrase";
export const T_NETWORK_CONFIGURATIONS_MODAL_PASSPHRASE_PLACEHOLDER = "networkConfigurationsModal.placeholder.passphrase";
export const T_NETWORK_CONFIGURATIONS_MODAL_CERTIFICATES_TITLE = "networkConfigurationsModal.certificatesTitle";
export const T_NETWORK_CONFIGURATIONS_MODAL_CERTIFICATES_INGRESS = "networkConfigurationsModal.certificatesIngress";
export const T_NETWORK_CONFIGURATIONS_MODAL_CERT_TYPE_LABEL = "networkConfigurationsModal.label.certificateType";
export const T_NETWORK_CONFIGURATIONS_MODAL_CERT_CONTENT_LABEL = "networkConfigurationsModal.label.certificateContent";
export const T_NETWORK_CONFIGURATIONS_MODAL_CERT_CONTENT_PLACEHOLDER = "networkConfigurationsModal.placeholder.certificateContent";
export const T_NETWORK_CONFIGURATIONS_MODAL_CERT_ADD_BUTTON_LABEL = "networkConfigurationsModal.label.addCertificateButton";
export const T_NETWORK_CONFIGURATIONS_MODAL_CERT_TABLE_DELETE_LABEL = "networkConfigurationsModal.certificates.deleteLabel";
export const T_NETWORK_CONFIGURATIONS_MODAL_SECURITY_NONE = "networkConfigurationsModal.security.none";
export const T_NETWORK_CONFIGURATIONS_MODAL_SECURITY_WEP_PSK = "networkConfigurationsModal.security.wepPsk";
export const T_NETWORK_CONFIGURATIONS_MODAL_SECURITY_WPA_PSK = "networkConfigurationsModal.security.wpaPsk";
export const T_NETWORK_CONFIGURATIONS_MODAL_SECURITY_WPA_EAP = "networkConfigurationsModal.security.wpaEap";
export const T_NETWORK_CONFIGURATIONS_MODAL_CERTIFICATE_CLIENT = "networkConfigurationsModal.certificates.typeClient";
export const T_NETWORK_CONFIGURATIONS_MODAL_CERTIFICATE_SERVER = "networkConfigurationsModal.certificates.typeServer";

export const T_EDIT_DEVICE_MODAL_TITLE = "editDeviceModal.title";
export const T_EDIT_DEVICE_MODAL_CONTENT = "editDeviceModal.content";
export const T_EDIT_DEVICE_MODAL_SUB_TITLE = "editDeviceModal.subTitle";
export const T_EDIT_DEVICE_MODAL_DISPLAY_NAME_LABEL = "editDeviceModal.displayNameLabel";
export const T_EDIT_DEVICE_MODAL_DESCRIPTION_LABEL = "editDeviceModal.descriptionLabel";
export const T_EDIT_DEVICE_MODAL_CANCEL_LABEL = "editDeviceModal.cancelTitle";
export const T_EDIT_DEVICE_MODAL_SAVE_LABEL = "editDeviceModal.saveLabel";
export const T_EDIT_DEVICE_MODAL_FETCH_ERROR = "editDeviceModal.fetchError";
export const T_EDIT_DEVICE_MODAL_SUBMIT_ERROR_MESSAGE = "editDeviceModal.submitError";
export const T_EDIT_DEVICE_MODAL_FETCH_RETRY_LABEL = "editDeviceModal.fetchRetryLabel";

export const T_EDIT_USER_MODAL_TITLE = "editUserModal.title";
export const T_EDIT_USER_MODAL_CONTENT = "editUserModal.content";
export const T_EDIT_USER_MODAL_SUB_TITLE = "editUserModal.subTitle";
export const T_EDIT_USER_MODAL_FIRST_NAME_LABEL = "editUserModal.firstNameLabel";
export const T_EDIT_USER_MODAL_LAST_NAME_LABEL = "editUserModal.lastNameLabel";
export const T_EDIT_USER_MODAL_DESCRIPTION_LABEL = "editUserModal.descriptionLabel";
export const T_EDIT_USER_MODAL_CANCEL_LABEL = "editUserModal.cancelTitle";
export const T_EDIT_USER_MODAL_SAVE_LABEL = "editUserModal.saveLabel";
export const T_EDIT_USER_MODAL_FETCH_ERROR = "editUserModal.fetchError";
export const T_EDIT_USER_MODAL_SUBMIT_ERROR_MESSAGE = "editUserModal.submitError";
export const T_EDIT_USER_MODAL_FETCH_RETRY_LABEL = "editUserModal.fetchRetryLabel";

export const T_EDIT_USER_ENTERPRISES_MODAL_TITLE = "editUserEnterprisesModal.title";
export const T_EDIT_USER_ENTERPRISES_MODAL_CONTENT = "editUserEnterprisesModal.content";
export const T_EDIT_USER_ENTERPRISES_MODAL_SUB_TITLE = "editUserEnterprisesModal.subTitle";
export const T_EDIT_USER_ENTERPRISES_MODAL_DISPLAY_NAME_LABEL = "editUserEnterprisesModal.displayNameLabel";
export const T_EDIT_USER_ENTERPRISES_MODAL_DESCRIPTION_LABEL = "editUserEnterprisesModal.descriptionLabel";
export const T_EDIT_USER_ENTERPRISES_MODAL_CANCEL_LABEL = "editUserEnterprisesModal.cancelLabel";
export const T_EDIT_USER_ENTERPRISES_MODAL_SAVE_LABEL = "editUserEnterprisesModal.saveLabel";
export const T_EDIT_USER_ENTERPRISES_MODAL_FETCH_ERROR = "editUserEnterprisesModal.fetchError";
export const T_EDIT_USER_ENTERPRISES_MODAL_SUBMIT_ERROR_MESSAGE = "editUserEnterprisesModal.submitError";
export const T_EDIT_USER_ENTERPRISES_MODAL_FETCH_RETRY_LABEL = "editUserEnterprisesModal.fetchRetryLabel";
export const T_EDIT_USER_ENTERPRISES_MODAL_REMOVE_LABEL = "editUserEnterprisesModal.removeLabel";
export const T_EDIT_USER_ENTERPRISES_MODAL_SELECT_ENTERPRISES = "editUserEnterprisesModal.selectEnterprises";

export const T_INVITE_USER_MODAL_TITLE = "inviteUserModal.title";
export const T_INVITE_USER_MODAL_CONTENT = "inviteUserModal.content";
export const T_INVITE_USER_MODAL_SUB_TITLE_PAGE1 = "inviteUserModal.subTitlePage1";
export const T_INVITE_USER_MODAL_SUB_TITLE_PAGE2 = "inviteUserModal.subTitlePage2";

export const T_INVITE_USER_MODAL_LAST_NAME_LABEL = "inviteUserModal.lastNameLabel";
export const T_INVITE_USER_MODAL_LAST_NAME_PLACEHOLDER = "inviteUserModal.lastNamePlaceholder";

export const T_INVITE_USER_MODAL_FIRST_NAME_LABEL = "inviteUserModal.firstNameLabel";
export const T_INVITE_USER_MODAL_FIRST_NAME_PLACEHOLDER = "inviteUserModal.firstNamePlaceholder";

export const T_INVITE_USER_MODAL_EMAIL_LABEL = "inviteUserModal.emailLabel";
export const T_INVITE_USER_MODAL_EMAIL_PLACEHOLDER = "inviteUserModal.emailPlaceholder";

export const T_INVITE_USER_MODAL_ROLE_LABEL = "inviteUserModal.roleLabel";
export const T_INVITE_USER_MODAL_ROLE_DESCRIPTION_PREFIX = "inviteUserModal.roleDescription";
export const T_INVITE_USER_MODAL_ROLE_PLACEHOLDER = "inviteUserModal.rolePlaceholder";

export const T_INVITE_USER_MODAL_CANCEL_LABEL = "inviteUserModal.cancelLabel";
export const T_INVITE_USER_MODAL_SAVE_LABEL = "inviteUserModal.saveLabel";
export const T_INVITE_USER_MODAL_SUBMIT_ERROR = "inviteUserModal.submitError";
export const T_INVITE_USER_MODAL_SUCCESS_MESSAGE = "inviteUserModal.successMessage";

export const T_INVITE_USER_MODAL_PREV_LABEL = "inviteUserModal.prevLabel";
export const T_INVITE_USER_MODAL_NEXT_LABEL = "inviteUserModal.nextLabel";

export const T_USER_ROLE_PREFIX = "userRole";

export const T_NEW_POLICY_MODAL_TITLE = "newPolicyModal.title";
export const T_NEW_POLICY_MODAL_SUB_TITLE = "newPolicyModal.subTitle";

export const T_NEW_POLICY_MODAL_PERMISSION_POLICY_SUB_TITLE = "newPolicyModal.permissionPolicySubTitle";
export const T_NEW_POLICY_MODAL_SET_PERMISSION_POLICY_CONTENT = "newPolicyModal.permissionPolicyContentText";

export const T_NEW_POLICY_MODAL_PASSWORD_REQUIREMENTS_SUB_TITLE = "newPolicyModal.passwordRequirementsSubTitle";
export const T_NEW_POLICY_MODAL_PASSWORD_REQUIREMENTS_CONTENT_TEXT = "newPolicyModal.passwordRequirementsContentText";

export const T_NEW_POLICY_MODAL_DEVICE_SETTINGS_SUB_TITLE = "newPolicyModal.deviceSettingsSubTitle";
export const T_NEW_POLICY_MODAL_DEVICE_SETTINGS_CONTENT_TEXT = "newPolicyModal.deviceSettingsContentText";

export const T_NEW_POLICY_MODAL_LOCATION_SETTINGS_SUB_TITLE = "newPolicyModal.locationSettingsSubTitle";
export const T_NEW_POLICY_MODAL_KIOSK_SETTINGS_SUB_TITLE = "newPolicyModal.kioskSettingsSubTitle";

export const T_NEW_POLICY_MODAL_SYSTEM_UPDATES_SUB_TITLE = "newPolicyModal.systemUpdatesSubTitle";
export const T_NEW_POLICY_MODAL_SYSTEM_UPDATES_CONTENT_TEXT = "newPolicyModal.systemUpdatesContentText";

export const T_NEW_POLICY_MODAL_APPLICATION_UPDATES_SUB_TITLE = "newPolicyModal.applicationAutoUpdatesSubTitle";

export const T_NEW_POLICY_MODAL_APPLICATIONS_SUB_TITLE = "newPolicyModal.applicationsSubTitle";
export const T_NEW_POLICY_MODAL_APPLICATIONS_CONTENT = "newPolicyModal.applicationsContentText";

export const T_NEW_POLICY_MODAL_CONTENT = "newPolicyModal.content";
export const T_NEW_POLICY_MODAL_CANCEL_LABEL = "newPolicyModal.cancelLabel";

export const T_NEW_POLICY_MODAL_NEXT_LABEL = "newPolicyModal.nextLabel";
export const T_NEW_POLICY_MODAL_SAVE_LABEL = "newPolicyModal.saveLabel";
export const T_NEW_POLICY_MODAL_PREV_LABEL = "newPolicyModal.prevLabel";
export const T_APP_SELECT_IFRAME_ERROR_MESSAGE = "appSelectIframe.errorContent";

export const T_NEW_POLICY_MODAL_POLICY_NAME_LABEL = "newPolicyModal.policyNameLabel";
export const T_NEW_POLICY_MODAL_POLICY_NAME_PLACEHOLDER = "newPolicyModal.policyNamePlaceholder";

export const T_NEW_POLICY_MODAL_DEVICE_POLICY_TYPE_LABEL = "newPolicyModal.devicePolicyTypeLabel";
export const T_NEW_POLICY_MODAL_SHOW_ME_ALL_TEXT = "newPolicyModal.showMeAllText";
export const T_NEW_POLICY_MODAL_NEED_HELP_TEXT = "newPolicyModal.needHelpText";

export const T_NEW_POLICY_MODAL_POLICY_NAME_MESSAGE = "newPolicyModal.policyNameMessage";

export const T_NEW_POLICY_MODAL_POLICY_DESCRIPTION_LABEL = "newPolicyModal.policyDescriptionLabel";
export const T_NEW_POLICY_MODAL_POLICY_DESCRIPTION_PLACEHOLDER = "newPolicyModal.policyDescriptionPlaceholder";

export const T_MANAGED_APP_CONFIGURATION_MODAL_TITLE = "managedAppConfiguratonModal.title";
export const T_MANAGED_APP_CONFIGURATION_MODAL_SUMMARY_CONTENT = "managedAppConfiguratonModal.summary";
export const T_MANAGED_APP_CONFIGURATION_MODAL_PACKAGE_NAME_TITLE = "managedAppConfiguratonModal.packageName.title";
export const T_MANAGED_APP_CONFIGURATION_MODAL_PACKAGE_NAME_PLACEHOLDER = "managedAppConfiguratonModal.packageName.placeholder";
export const T_MANAGED_APP_CONFIGURATION_MODAL_KEY_TITLE = "managedAppConfiguratonModal.key.title";
export const T_MANAGED_APP_CONFIGURATION_MODAL_KEY_PLACEHOLDER = "managedAppConfiguratonModal.key.placeholder";
export const T_MANAGED_APP_CONFIGURATION_MODAL_VALUE_TITLE = "managedAppConfiguratonModal.value.title";
export const T_MANAGED_APP_CONFIGURATION_MODAL_VALUE_PLACEHOLDER = "managedAppConfiguratonModal.value.placeholder";
export const T_MANAGED_APP_CONFIGURATION_MODAL_CANCEL_LABEL = "managedAppConfiguratonModal.cancelLabel";
export const T_MANAGED_APP_CONFIGURATION_MODAL_SAVE_LABEL = "managedAppConfiguratonModal.saveLabel";

export const T_PASSWORD_REQUIREMENTS_MODAL_TITLE = "passwordRequirementsModal.title";
export const T_PASSWORD_REQUIREMENTS_MODAL_SUMMARY_CONTENT = "passwordRequirementsModal.summary";
export const T_PASSWORD_REQUIREMENTS_MODAL_CANCEL_LABEL = "passwordRequirementsModal.cancelLabel";
export const T_PASSWORD_REQUIREMENTS_MODAL_SAVE_LABEL = "passwordRequirementsModal.saveLabel";

export const T_APPLICATION_POLICY_MODAL_TITLE = "applicationPolicyModal.title";
export const T_APPLICATION_POLICY_MODAL_SUMMARY_CONTENT = "applicationPolicyModal.summary";

export const T_APPLICATION_POLICY_MODAL_PACKAGE_NAME_LABEL = "applicationPolicyModal.packageName.label";
export const T_APPLICATION_POLICY_MODAL_PACKAGE_NAME_PLACEHOLDER = "applicationPolicyModal.packageName.placeholder";

export const T_APPLICATION_POLICY_MODAL_INSTALL_TYPE_LABEL = "applicationPolicyModal.installType.label";
export const T_APPLICATION_POLICY_MODAL_INSTALL_TOOLTIP_PREFIX = "applicationPolicyModal.installType.tooltips";
export const T_APPLICATION_POLICY_MODAL_INSTALL_TYPE_PLACEHOLDER = "applicationPolicyModal.installType.placeholder";

export const T_APPLICATION_POLICY_MODAL_PERMISSION_POLICY_LABEL = "applicationPolicyModal.permissionPolicy.label";
export const T_APPLICATION_POLICY_MODAL_PERMISSION_POLICY_PLACEHOLDER = "applicationPolicyModal.permissionPolicy.placeholder";

export const T_APPLICATION_POLICY_MODAL_ADD_LABEL_LABEL = "applicationPolicyModal.managedConfigurationTemplate.addLabel";
export const T_APPLICATION_POLICY_MODAL_MODIFY_LABEL_LABEL = "applicationPolicyModal.managedConfigurationTemplate.modifyLabel";

export const T_APPLICATION_POLICY_MODAL_MINIMUM_VERSION_CODE_LABEL = "applicationPolicyModal.minimumVersionCode.label";
export const T_APPLICATION_POLICY_MODAL_MINIMUM_VERSION_CODE_PLACEHOLDER = "applicationPolicyModal.minimumVersionCode.placeholder";
export const T_APPLICATION_POLICY_MODAL_MINIMUM_VERSION_CODE_SUMMARY = "applicationPolicyModal.minimumVersionCode.summary";

export const T_APPLICATION_POLICY_MODAL_ENABLE_APP_LABEL = "applicationPolicyModal.enableApp.label";
export const T_APPLICATION_POLICY_MODAL_ENABLE_APP_SUMMARY = "applicationPolicyModal.enableApp.summary";

export const T_APPLICATION_POLICY_MODAL_CANCEL_LABEL = "applicationPolicyModal.cancelLabel";
export const T_APPLICATION_POLICY_MODAL_SAVE_LABEL = "applicationPolicyModal.saveLabel";

export const T_APPLICATION_POLICY_MODAL_APPTRACK_LABEL = "applicationPolicyModal.appTrackLabel";
export const T_APPLICATION_POLICY_MODAL_APPTRACK_PLACEHOLDER = "applicationPolicyModal.appTrackPlaceholder";

export const T_INSTALL_TYPE_PREFIX = "applicationPolicy.installType.";

export const T_DEVICE_APPLICATIONS_MODAL_TITLE = "deviceApplicationsModal.title";
export const T_DEVICE_APPLICATIONS_MODAL_TOTAL_TEXT = "deviceApplicationsModal.totalText";
export const T_DEVICE_APPLICATIONS_MODAL_LAST_UPDATED = "deviceApplicationsModal.lastUpdated";
export const T_DEVICE_APPLICATIONS_MODAL_LAST_UPDATED_DATE_FORMAT = "deviceApplicationsModal.lastUpdatedDateFormat";
export const T_DEVICE_APPLICATIONS_MODAL_SEARCH_LABEL = "deviceApplicationsModal.searchLabel";
export const T_DEVICE_APPLICATIONS_MODAL_SEARCH_PLACEHOLDER = "deviceApplicationsModal.searchPlaceholder";
export const DEVICE_APPLICATIONS_MODAL_SUMMARY_TEXT = "deviceApplicationsModal.summaryText";
export const T_DEVICE_APPLICATIONS_MODAL_CSV_EXPORT_LABEL = "deviceApplicationsModal.downloadCsvLabel";

export const DEVICE_APPLICATIONS_MODAL_TABLE_HEADER_LABEL_STATE = "deviceApplicationsModal.table.headers.state";
export const DEVICE_APPLICATIONS_MODAL_TABLE_HEADER_LABEL_APPLICATION = "deviceApplicationsModal.table.headers.application";
export const DEVICE_APPLICATIONS_MODAL_TABLE_HEADER_LABEL_PACKAGE_NAME = "deviceApplicationsModal.table.headers.packageName";
export const DEVICE_APPLICATIONS_MODAL_TABLE_HEADER_LABEL_VERSION = "deviceApplicationsModal.table.headers.version";
export const DEVICE_APPLICATIONS_MODAL_TABLE_HEADER_LABEL_SOURCE = "deviceApplicationsModal.table.headers.source";
export const DEVICE_APPLICATIONS_MODAL_TABLE_NO_APP_NAME = "deviceApplicationsModal.table.noAppName";
export const DEVICE_APPLICATIONS_MODAL_TABLE_APPLICATION_SOURCE_PREFIX = "deviceApplicationsModal.table.applicationSource";
export const DEVICE_APPLICATIONS_MODAL_TABLE_STATE_PREFIX = "deviceApplicationsModal.table.state";

export const DEVICE_APPLICATIONS_MODAL_NO_RESULTS_TITLE = "deviceApplicationsModal.table.noResults.title";
export const DEVICE_APPLICATIONS_MODAL_NO_RESULTS_CONTENT = "deviceApplicationsModal.table.noResults.content";

export const T_ZEROTOUCH_VIEW_LOADFAIL_TITLE = "zeroTouchView.loadFailed.title";
export const T_ZEROTOUCH_VIEW_LOADFAIL_MESSAGE = "zeroTouchView.loadFailed.message";

export const T_APPLICATION_VIEW_LOADFAIL_TITLE = "applicationsView.loadFailed.title";
export const T_APPLICATION_VIEW_LOADFAIL_MESSAGE = "applicationsView.loadFailed.message";
export const T_APPLICATIONS_VIEW_APPROVE_APPS = "applicationsView.approveApps";

export const T_ALERT_MESSAGE_DISMISS = "messages.alert.dismiss";
export const T_ALERT_MESSAGE_ACTION_LABEL = "messages.alert.actionLabel";

export const T_SUCCESS_MESSAGE_DISMISS = "messages.success.dismiss";
export const T_SUCCESS_MESSAGE_ACTION_LABEL = "messages.success.actionLabel";

export const T_ERROR_MESSAGE_DISMISS = "messages.error.dismiss";
export const T_ERROR_MESSAGE_ACTION_LABEL = "messages.error.actionLabel";

export const T_LOGIN_SERVICE_LOGIN_ERROR = "errors.loginError";
export const T_LOGIN_SERVICE_CREDENTIALS_ERROR = "login.errorIncorrectCredentials";
export const T_LOGIN_SERVICE_DEACTIVATED_ERROR = "login.errorDeactivatedAccount";

export const T_DEVICE_EDIT_DETAILS_ERROR = "errors.deviceDetailsEditError";
export const T_DEVICE_EDIT_DETAILS_SUCCESS = "errors.deviceDetailsEditSuccess";

export const T_LOGIN_VIEW_PASSWORD_RESET_SUCCESS_MESSAGE_TEXT = "loginView.resetPasswordSuccessMessage";

export const T_LOGIN_VIEW_WELCOME_TITLE = "loginView.welcomeTitle";
export const T_LOGIN_VIEW_DISCLAIMER = "loginView.disclaimer";
export const T_LOGIN_VIEW_TERMS_AND_CONDITIONS_CHECKBOX_TEXT = "loginView.termsAndConditionsCheckBoxText";
export const T_LOGIN_VIEW_TERMS_AND_CONDITIONS_LINK_TEXT = "loginView.termsAndConditionsLinkText";
export const T_LOGIN_VIEW_B2B_PRIVACY_NOTICE_LINK_TEXT = "loginView.b2bPrivacyNoticeLinkText";
export const T_LOGIN_VIEW_PRIVACY_SUPPLEMENT_LINK_TEXT = "loginView.privacySupplementLinkText";
export const T_LOGIN_VIEW_CONTINUE_BUTTON_LABEL = "loginView.continueLabel";

export const T_APPLICATIONS_VIEW_TITLE = "applicationsView.title";
export const T_APPLICATIONS_VIEW_SUB_TITLE = "applicationsView.subTitle";
export const T_APPLICATIONS_VIEW_MANAGE_APPS = "applicationsView.manageApps";

export const T_APPLICATIONS_VIEW_NO_APPS_TITLE = "applicationsView.noApps.title";
export const T_APPLICATIONS_VIEW_NO_APPS_CONTENT = "applicationsView.noApps.content";

export const T_DEVICE_LIST_VIEW_TITLE = "deviceListView.title";
export const T_POLICY_LIST_VIEW_TITLE = "policyListView.title";

export const T_CLONE_POLICY_NEW_NAME_PREFIX = "clonePolicy.newNamePrefix";
export const T_CLONE_POLICY_NEW_NAME_SUFFIX = "clonePolicy.newNameSuffix";
export const T_POLICY_LIST_VIEW_CLONE_POLICY_LABEL = "policyListView.actions.cloneLabel";
export const T_POLICY_LIST_VIEW_DELETE_POLICY_LABEL = "policyListView.actions.deleteLabel";

export const T_DEVICE_VIEW_TITLE = "deviceView.title";
export const T_DEVICE_VIEW_REFRESH_DEVICE_LABEL = "deviceView.refreshLabel";
export const T_DEVICE_VIEW_BASIC_INFO = "deviceView.basicInfo";
export const T_DEVICE_VIEW_POLICY_DETAILS = "deviceView.policyDetails";
export const T_DEVICE_VIEW_FETCH_ERROR = "deviceView.fetchError";
export const T_DEVICE_VIEW_FETCH_RETRY_LABEL = "deviceView.fetchErrorRetryLabel";
export const T_DEVICE_VIEW_BACK_BUTTON_LABEL = "deviceView.backButtonLabel";
export const T_DEVICE_VIEW_ERROR_PREFIX = "deviceView.errors";

export const T_DEVICE_VIEW_SECURITY_DETAILS_TITLE = "deviceView.securityDetails.title";

export const T_DEVICE_VIEW_APPLICATIONS_TITLE = "deviceView.applications.title";
export const T_DEVICE_VIEW_APPLICATIONS_TOOLTIP_TITLE = "deviceView.applications.tooltip.title";
export const T_DEVICE_VIEW_APPLICATIONS_TOOLTIP_CONTENT = "deviceView.applications.tooltip.content";
export const T_DEVICE_VIEW_APPLICATIONS_VIEW_BUTTON_LABEL = "deviceView.applications.viewButtonLabel";
export const T_DEVICE_VIEW_APPLICATIONS_INSTALLED_APPS = "deviceView.applications.installedApps";

export const T_DEVICE_VIEW_APPLICATION_CONFIGURATIONS_TITLE = "deviceView.applicationConfigurations.title";
export const T_DEVICE_VIEW_APPLICATION_CONFIGURATIONS_SUMMARY = "deviceView.applicationConfigurations.summary";
export const T_DEVICE_VIEW_APPLICATION_CONFIGURATIONS_NO_VALUES = "deviceView.applicationConfigurations.noValues";
export const T_DEVICE_VIEW_APPLICATION_CONFIGURATIONS_ADD_BUTTON_LABEL = "deviceView.applicationConfigurations.addButtonLabel";

export const T_DEVICE_VIEW_APPLICATION_CONFIGURATIONS_PACKAGE_NAME_LABEL = "deviceView.applicationConfigurations.packageNameLabel";
export const T_DEVICE_VIEW_APPLICATION_CONFIGURATIONS_CONFIG_KEY_LABEL = "deviceView.applicationConfigurations.configKeyLabel";
export const T_DEVICE_VIEW_APPLICATION_CONFIGURATIONS_CONFIG_VALUE_LABEL = "deviceView.applicationConfigurations.configValueLabel";
export const T_DEVICE_VIEW_APPLICATION_CONFIGURATIONS_USER_CONFIG_TOOLTIP = "deviceView.applicationConfigurations.userConfigTooltip";

export const T_DEVICE_VIEW_DEVICE_DETAILS_SECURITY_POSTURE_SUBTITLE = "deviceView.securityDetails.securityPostureSubTitle";
export const T_DEVICE_VIEW_DEVICE_DETAILS_PASSWORD_AND_ENCRYPTION_SUBTITLE = "deviceView.securityDetails.passwordAndEncryptionSubTitle";

export const T_DEVICE_VIEW_SECURITY_DETAILS_PASSWORD_LABEL = "deviceView.securityDetails.passwordLabel";
export const T_DEVICE_VIEW_SECURITY_DETAILS_ENCRYPTION_STATUS_LABEL = "deviceView.securityDetails.encryptionStatusLabel";
export const T_DEVICE_VIEW_SECURITY_DETAILS_UNKNOWN_SOURCES_LABEL = "deviceView.securityDetails.unknownSourcesLabel";
export const T_DEVICE_VIEW_SECURITY_DETAILS_DEVELOPMENT_SETTINGS_LABEL = "deviceView.securityDetails.developmentSettingsLabel";
export const T_DEVICE_VIEW_SECURITY_DETAILS_ADB_LABEL = "deviceView.securityDetails.adbLabel";
export const T_DEVICE_VIEW_SECURITY_DETAILS_VERIFY_APPS_LABEL = "deviceView.securityDetails.verifyAppsLabel";

export const T_DEVICE_VIEW_SECURITY_DETAILS_ENABLED_TEXT = "deviceView.securityDetails.enabledText";
export const T_DEVICE_VIEW_SECURITY_DETAILS_DISABLED_TEXT = "deviceView.securityDetails.disabledText";
export const T_DEVICE_VIEW_DEVICE_DETAILS_DEVICE_SETTINGS_SUBTITLE = "deviceView.securityDetails.deviceSettings";

export const T_DEVICE_VIEW_DEVICE_DETAILS_POLICY_UPDATE_NOT_ALLOWED_LOCATION = "deviceView.policyDetails.policyUpdateWarningMessage";
export const T_DEVICE_VIEW_DEVICE_DETAILS_POLICY_UPDATE_NOT_ALLOWED_PREFIX = "deviceView.policyDetails.policyUpdateNotAllowedMessage.";

export const T_DEVICE_VIEW_SECURITY_DETAILS_STATUS_LABEL = "deviceView.securityDetails.statusLabel";
export const T_DEVICE_VIEW_SECURITY_DETAILS_SAFETYNET_LABEL = "deviceView.securityDetails.safetyNetStatusLabel";
export const T_DEVICE_VIEW_SECURITY_DETAILS_SAFETYNET_NO_RISK = "deviceView.securityDetails.noSecurityRisk";

export const T_DEVICE_SECURITY_POSTURE_PREFIX = "deviceSecurityPosture.";
export const T_DEVICE_SECURITY_RISK_TITLE_PREFIX = "device.securityRiskTitle";
export const T_DEVICE_SECURITY_RISK_DESCRIPTION_PREFIX = "device.securityRiskDescription";

export const T_DEVICE_ENCRYPTION_STATUS_PREFIX = "device.encryptionStatus";

export const T_DEVICE_VIEW_DEVICE_DETAILS_TITLE = "deviceView.deviceDetails.title";
export const T_DEVICE_VIEW_DEVICE_DETAILS_HARDWARE_SUBTITLE = "deviceView.deviceDetails.hardwareTitle";
export const T_DEVICE_VIEW_DEVICE_DETAILS_MEMORY_SUBTITLE = "deviceView.deviceDetails.memoryTitle";
export const T_DEVICE_VIEW_DEVICE_DETAILS_INTERNAL_STORAGE_LABEL = "deviceView.deviceDetails.internalStorageLabel";
export const T_DEVICE_VIEW_DEVICE_DETAILS_EXTERNAL_STORAGE_LABEL = "deviceView.deviceDetails.externalStorageLabel";
export const T_DEVICE_VIEW_DEVICE_DETAILS_BATTERY_LEVEL_LABEL = "deviceView.deviceDetails.batteryLevelLabel";
export const T_DEVICE_VIEW_DEVICE_DETAILS_RAM_LABEL = "deviceView.deviceDetails.ramLabel";
export const T_DEVICE_VIEW_DEVICE_DETAILS_LAST_UPDATED_LABEL = "deviceView.deviceDetails.lastUpdatedLabel";
export const T_DEVICE_VIEW_DEVICE_DETAILS_OF_USED_TEXT = "deviceView.deviceDetails.ofUsedText";

export const T_DEVICE_VIEW_DEVICE_SECTION_INTERNAL_STORAGE_NA = "deviceView.memory.internalStorage.notAvailable";
export const T_DEVICE_VIEW_DEVICE_SECTION_INTERNAL_STORAGE_USED = "deviceView.memory.internalStorage.ofUsed";

export const T_DEVICE_VIEW_DEVICE_SECTION_EXTERNAL_STORAGE_NA = "deviceView.memory.externalStorage.notAvailable";
export const T_DEVICE_VIEW_DEVICE_SECTION_EXTERNAL_STORAGE_USED = "deviceView.memory.externalStorage.ofUsed";

export const T_DEVICE_VIEW_DEVICE_SECTION_BATTERY_LEVEL_NA = "deviceView.memory.batteryLevel.notAvailable";
export const T_DEVICE_VIEW_DEVICE_SECTION_BATTERY_LEVEL_USED = "deviceView.memory.batteryLevel.ofUsed";

export const T_DEVICE_VIEW_DEVICE_SECTION_RAM_NA = "deviceView.memory.ram.notAvailable";
export const T_DEVICE_VIEW_DEVICE_SECTION_RAM_USED = "deviceView.memory.ram.ofUsed";

export const T_DEVICE_VIEW_DEVICE_USER_TITLE = "deviceView.deviceUser.title";

export const T_DEVICE_VIEW_DEVICE_USER_ASSIGN_USER_LABEL = "deviceView.deviceUser.assignUserLabel";
export const T_DEVICE_VIEW_LABEL_ANDROID_ID = "deviceView.label.androidId";

export const T_GOOGLE_MANAGED_APP_CONFIG_IFRAME_FETCH_ERROR = "managedAppConfigIframe.fetchError";
export const T_GOOGLE_MANAGED_APP_CONFIG_IFRAME_FETCH_RETRY_LABEL = "managedAppConfigIframe.fetchErrorRetryLabel";

export const T_HOME_VIEW_TITLE = "homeView.title";
export const T_HOME_VIEW_LAST_UPDATED = "homeView.lastUpdated";
export const T_HOME_VIEW_LAST_UPDATED_DATE_FORMAT = "homeView.lastUpdated.dateFormat";
export const T_HOME_VIEW_CHART_MONTH_DATE_FORMAT = "homeView.chart.month.dateFormat";
export const T_HOME_VIEW_CHART_OTHER_GROUP = "homeView.chart.otherGroup";
export const T_HOME_VIEW_CHART_ANDROID_VERSIONS_TITLE = "homeView.chart.androidVersions.title";
export const T_HOME_VIEW_CHART_DEVICE_MODELS_TITLE = "homeView.chart.deviceModels.title";
export const T_HOME_VIEW_CHART_DEVICE_MODELS_TOTAL_UNIT = "homeView.chart.deviceModels.totalUnit";
export const T_HOME_VIEW_CHART_DEVICES_BY_STATE_TITLE = "homeView.chart.devicesByState.title";
export const T_HOME_VIEW_CHART_DEVICE_STATE_ACTIVE = "homeView.chart.deviceState.active";
export const T_HOME_VIEW_CHART_DEVICE_STATE_UNENROLLED = "homeView.chart.deviceState.unenrolled";
export const T_HOME_VIEW_CHART_DEVICE_STATE_UNENROLL_PENDING = "homeView.chart.deviceState.unenrollPending";
export const T_HOME_VIEW_CHART_DEVICE_STATE_PROVISIONING = "homeView.chart.deviceState.provisioning";
export const T_HOME_VIEW_CHART_DEVICES_PAST_24_HOURS = "homeView.chart.devices.past24Hours";
export const T_HOME_VIEW_CHART_DEVICES_LAST_SEEN = "homeView.chart.devices.lastSeen";
export const T_HOME_VIEW_CHART_DEVICES_REMOVED = "homeView.chart.devices.removed";
export const T_HOME_VIEW_CHART_DEVICES_UNIT = "homeView.chart.devices.unit";
export const T_HOME_VIEW_CHART_ENROLLMENTS_BY_MONTH_TITLE = "homeView.chart.enrollmentsByMonth.title";
export const T_HOME_VIEW_CHART_TOP_POLICIES = "homeView.chart.topPolicies.title";
export const T_HOME_VIEW_CHART_MONTH_JAN = "homeView.chart.shortMonth.jan";
export const T_HOME_VIEW_CHART_MONTH_FEB = "homeView.chart.shortMonth.feb";
export const T_HOME_VIEW_CHART_MONTH_MAR = "homeView.chart.shortMonth.mar";
export const T_HOME_VIEW_CHART_MONTH_APR = "homeView.chart.shortMonth.apr";
export const T_HOME_VIEW_CHART_MONTH_MAY = "homeView.chart.shortMonth.may";
export const T_HOME_VIEW_CHART_MONTH_JUN = "homeView.chart.shortMonth.jun";
export const T_HOME_VIEW_CHART_MONTH_JUL = "homeView.chart.shortMonth.jul";
export const T_HOME_VIEW_CHART_MONTH_AUG = "homeView.chart.shortMonth.aug";
export const T_HOME_VIEW_CHART_MONTH_SEP = "homeView.chart.shortMonth.sep";
export const T_HOME_VIEW_CHART_MONTH_OCT = "homeView.chart.shortMonth.oct";
export const T_HOME_VIEW_CHART_MONTH_NOV = "homeView.chart.shortMonth.nov";
export const T_HOME_VIEW_CHART_MONTH_DEC = "homeView.chart.shortMonth.dec";
export const T_HOME_VIEW_CHART_APPLICATION_VERSIONS = "homeView.chart.applicationVersions.title";
export const T_HOME_VIEW_CHART_APPLICATION_VERSIONS_TOTAL_UNIT = "homeView.chart.applicationVersions.totalUnit";
export const T_HOME_VIEW_CHART_APPLICATION_VERSIONS_SELECT_PLACEHOLDER = "homeView.chart.applicationVersions.select.placeholder";
export const T_HOME_VIEW_CHART_APPLICATION_VERSIONS_NO_APPS_TITLE = "homeView.chart.applicationVersions.noApps.title";
export const T_HOME_VIEW_CHART_APPLICATION_VERSIONS_NO_APPS_DESC = "homeView.chart.applicationVersions.noApps.description";
export const T_HOME_VIEW_CHART_APPLICATION_VERSIONS_COLLECTING_TITLE = "homeView.chart.applicationVersions.collecting.title";
export const T_HOME_VIEW_CHART_APPLICATION_VERSIONS_COLLECTING_DESC = "homeView.chart.applicationVersions.collecting.desc";

export const T_HOME_VIEW_INFO_BOX_ENROLLED_DEVICES = "homeView.infobox.enrolledDevices.name";
export const T_HOME_VIEW_INFO_BOX_POLICIES = "homeView.infobox.policies.name";
export const T_HOME_VIEW_INFO_BOX_PASSWORD_COMPLIANCE = "homeView.infobox.passwordCompliance.name";
export const T_HOME_VIEW_INFO_BOX_SECURITY_STATUS = "homeView.infobox.securityStatus.name";
export const T_HOME_VIEW_INFO_BOX_SECURITY_STATUS_SECURE = "homeView.infobox.securityStatus.secure";
export const T_HOME_VIEW_INFO_BOX_SECURITY_STATUS_UNIT = "homeView.infobox.securityStatus.unit";

export const T_HOME_VIEW_CONFIG_MODAL_TITLE = "homeView.configModal.title";
export const T_HOME_VIEW_CONFIG_MODAL_SUBTITLE = "homeView.configModal.subTitle";
export const T_HOME_VIEW_CONFIG_MODAL_ADD_APP_LABEL = "homeView.configModal.addAppLabel";
export const T_HOME_VIEW_CONFIG_MODAL_CANCEL_BUTTON = "homeView.configModal.cancelButton";
export const T_HOME_VIEW_CONFIG_MODAL_SAVE_BUTTON = "homeView.configModal.saveButton";

export const T_HOME_VIEW_NO_ENTERPRISE_ACCESS_TITLE = "homeView.noEnterpriseAccess.title";
export const T_HOME_VIEW_NO_ENTERPRISE_ACCESS_CONTENT = "homeView.noEnterpriseAccess.content";

export const T_AUTHORIZED_VIEW_ABOUT_LEGAL = "authorizedView.aboutLegal";
export const T_AUTHORIZED_VIEW_LOGOUT_REQUIRED = "authorizedView.logoutRequired";
export const T_AUTHORIZED_VIEW_SUPPORT_LINK = "authorizedView.supportLink";
export const T_AUTHORIZED_VIEW_SUPPORT_EMAIL_SUBJECT = "authorizedView.supportEmailSubject";

export const T_DEPLOY_POLICY_TO_DEVICE_MODAL_TITLE = "deviceView.deployPolicyToDeviceModal.title";
export const T_DEPLOY_POLICY_TO_DEVICE_MODAL_CONTENT = "deviceView.deployPolicyToDeviceModal.content";
export const T_DEPLOY_POLICY_TO_DEVICE_MODAL_NO_IMEI_DEVICE_TEXT = "deviceView.deployPolicyToDeviceModal.noImeiText";
export const T_DEPLOY_POLICY_TO_DEVICE_MODAL_CANCEL_LABEL = "deviceView.deployPolicyToDeviceModal.cancelLabel";
export const T_DEPLOY_POLICY_TO_DEVICE_MODAL_ACCEPT_LABEL = "deviceView.deployPolicyToDeviceModal.acceptLabel";
export const T_DEPLOY_POLICY_TO_DEVICE_MODAL_ERROR_MESSAGE = "deviceView.deployPolicyToDeviceModal.errorMessage";

export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_MODAL_TITLE = "selectAndDeployPolicyToDeviceModal.title";
export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_MODAL_CONTENT = "selectAndDeployPolicyToDeviceModal.content";
export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_MODAL_NO_IMEI_DEVICE_TEXT = "selectAndDeployPolicyToDeviceModal.noImeiText";
export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_MODAL_CANCEL_LABEL = "selectAndDeployPolicyToDeviceModal.cancelLabel";
export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_MODAL_ACCEPT_LABEL = "selectAndDeployPolicyToDeviceModal.acceptLabel";
export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_MODAL_ERROR_MESSAGE = "selectAndDeployPolicyToDeviceModal.errorMessage";
export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_MODAL_NOT_ENABLED_INFO = "selectAndDeployPolicyToDeviceModal.notEnabled.info";
export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_MODAL_MANAGEMENT_MODE = "selectAndDeployPolicyToDeviceModal.managementMode";
export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_MODAL_MANAGEMENT_MODE_PREFIX = "selectAndDeployPolicyToDeviceModal.managementMode.";
export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_MODAL_MANAGEMENT_MODE_WARNING_PREFIX = "selectAndDeployPolicyToDeviceModal.managementMode.warning.";

export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_LIST_MODAL_TITLE = "selectAndDeployPolicyToDeviceListModal.title";
export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_LIST_MODAL_CONTENT = "selectAndDeployPolicyToDeviceListModal.content";
export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_LIST_MODAL_CANCEL_LABEL = "selectAndDeployPolicyToDeviceListModal.cancelLabel";
export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_LIST_MODAL_ACCEPT_LABEL = "selectAndDeployPolicyToDeviceListModal.acceptLabel";
export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_LIST_MODAL_SUCCESS_MESSAGE = "selectAndDeployPolicyToDeviceListModal.successMessage";
export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_LIST_MODAL_ERROR_MESSAGE = "selectAndDeployPolicyToDeviceListModal.errorMessage";
export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_LIST_MODAL_ERROR_MESSAGE_WITH_HIGHLIGHTS = "selectAndDeployPolicyToDeviceListModal.errorMessageWithHighlights";
export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_LIST_MODAL_CHANGE_POLICY = "selectAndDeployPolicyToDeviceListModal.changePolicy";
export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_LIST_MODAL_WARNING_GROUP_POLICY_1 = "selectAndDeployPolicyToDeviceListModal.warning.1";
export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_LIST_MODAL_WARNING_GROUP_POLICY_2 = "selectAndDeployPolicyToDeviceListModal.warning.2";
export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_LIST_MODAL_WARNING_USER_1 = "selectAndDeployPolicyToDeviceListModal.warning.3";
export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_LIST_MODAL_WARNING_USER_2 = "selectAndDeployPolicyToDeviceListModal.warning.4";
export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_LIST_MODAL_WARNING_CONFIGURATIONS_1 = "selectAndDeployPolicyToDeviceListModal.warning.5";
export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_LIST_MODAL_WARNING_CONFIGURATIONS_2 = "selectAndDeployPolicyToDeviceListModal.warning.6";

export const T_SELECT_AND_DEPLOY_POLICY_TO_DEVICE_LIST_MODAL_NOT_ENABLED_INFO = "selectAndDeployPolicyToDeviceListModal.notEnabled.info";

export const T_SELECT_POLICY_FIELD_POLICY_TYPE_LABEL = "selectPolicyField.policyType.label";

export const T_LOCK_DEVICE_MODAL_ERROR_MESSAGE = "deviceView.lockDeviceModal.errorMessage";
export const T_LOCK_DEVICE_MODAL_TITLE = "deviceView.lockDeviceModal.title";
export const T_LOCK_DEVICE_MODAL_CONTENT = "deviceView.lockDeviceModal.content";
export const T_LOCK_DEVICE_MODAL_TITLE_WORK_PROFILE = "deviceView.lockDeviceModal.title.workProfile";
export const T_LOCK_DEVICE_MODAL_CONTENT_WORK_PROFILE = "deviceView.lockDeviceModal.content.workProfile";
export const T_LOCK_DEVICE_MODAL_NO_IMEI_DEVICE_TEXT = "deviceView.lockDeviceModal.noImeiText";
export const T_LOCK_DEVICE_MODAL_CANCEL_LABEL = "deviceView.lockDeviceModal.cancelLabel";
export const T_LOCK_DEVICE_MODAL_ACCEPT_LABEL = "deviceView.lockDeviceModal.acceptLabel";
export const T_LOCK_DEVICE_MODAL_NOTE_WORK_PROFILE = "deviceView.lockDeviceModal.note.workProfile";

export const T_LOCK_DEVICE_LIST_MODAL_TITLE = "deviceView.lockDeviceListModal.title";
export const T_LOCK_DEVICE_LIST_MODAL_CONTENT = "deviceView.lockDeviceListModal.content";
export const T_LOCK_DEVICE_LIST_MODAL_CANCEL_LABEL = "deviceView.lockDeviceListModal.cancelLabel";
export const T_LOCK_DEVICE_LIST_MODAL_ACCEPT_LABEL = "deviceView.lockDeviceListModal.acceptLabel";
export const T_LOCK_DEVICE_LIST_MODAL_SUCCESS_MESSAGE = "deviceView.lockDeviceListModal.successMessage";
export const T_LOCK_DEVICE_LIST_MODAL_ERROR_MESSAGE = "deviceView.lockDeviceListModal.errorMessage";
export const T_LOCK_DEVICE_LIST_MODAL_ERROR_MESSAGE_WITH_HIGHLIGHTS = "deviceView.lockDeviceListModal.errorMessageWithHighlights";

export const T_RESET_DEVICE_MODAL_TITLE = "deviceView.resetDeviceModal.title";
export const T_RESET_DEVICE_MODAL_CONTENT = "deviceView.resetDeviceModal.content";
export const T_RESET_DEVICE_MODAL_NO_IMEI_DEVICE_TEXT = "deviceView.resetDeviceModal.noImeiText";
export const T_RESET_DEVICE_MODAL_CANCEL_LABEL = "deviceView.resetDeviceModal.cancelLabel";
export const T_RESET_DEVICE_MODAL_ACCEPT_LABEL = "deviceView.resetDeviceModal.acceptLabel";
export const T_RESET_DEVICE_MODAL_ERROR_MESSAGE = "deviceView.resetDeviceModal.errorMessage";
export const T_RESET_DEVICE_MODAL_ADDITIONAL_OPTIONS_TITLE = "deviceView.resetDeviceModal.additionalResetOptions.title";
export const T_RESET_DEVICE_MODAL_ADDITIONAL_OPTIONS_PRESERVE_FACTORY_RESET_DATA = "deviceView.resetDeviceModal.additionalResetOptions.preserveFactoryResetData";
export const T_RESET_DEVICE_MODAL_ADDITIONAL_OPTIONS_WIPE_EXTERNAL_STORAGE = "deviceView.resetDeviceModal.additionalResetOptions.wipeExternalStorage";
export const T_RESET_DEVICE_MODAL_ADDITIONAL_OPTIONS_OPTIONAL_MESSAGE = "deviceView.resetDeviceModal.additionalResetOptions.optionalMessage";
export const T_RESET_DEVICE_MODAL_OPTIONAL_MESSAGE_TITLE = "deviceView.resetDeviceModal.optionalMessageTitle";
export const T_RESET_DEVICE_MODAL_ADDITIONAL_OPTIONS_TOOLTIP_TITLE1 = "deviceView.resetDeviceModal.additionalResetOptions.tooltipTitle1";
export const T_RESET_DEVICE_MODAL_ADDITIONAL_OPTIONS_TOOLTIP_TITLE2 = "deviceView.resetDeviceModal.additionalResetOptions.tooltipTitle2";
export const T_RESET_DEVICE_MODAL_ADDITIONAL_OPTIONS_TOOLTIP_TITLE3 = "deviceView.resetDeviceModal.additionalResetOptions.tooltipTitle3";
export const T_RESET_DEVICE_MODAL_ADDITIONAL_OPTIONS_TOOLTIP_CONTENT1 = "deviceView.resetDeviceModal.additionalResetOptions.tooltipContent1";
export const T_RESET_DEVICE_MODAL_ADDITIONAL_OPTIONS_TOOLTIP_CONTENT2 = "deviceView.resetDeviceModal.additionalResetOptions.tooltipContent2";
export const T_RESET_DEVICE_MODAL_ADDITIONAL_OPTIONS_TOOLTIP_CONTENT3 = "deviceView.resetDeviceModal.additionalResetOptions.tooltipContent3";

export const T_RESET_DEVICE_LIST_MODAL_TITLE = "deviceView.resetDeviceListModal.title";
export const T_RESET_DEVICE_LIST_MODAL_CONTENT = "deviceView.resetDeviceListModal.content";
export const T_RESET_DEVICE_LIST_MODAL_CANCEL_LABEL = "deviceView.resetDeviceListModal.cancelLabel";
export const T_RESET_DEVICE_LIST_MODAL_ACCEPT_LABEL = "deviceView.resetDeviceListModal.acceptLabel";
export const T_RESET_DEVICE_LIST_MODAL_SUCCESS_MESSAGE = "deviceView.resetDeviceListModal.successMessage";
export const T_RESET_DEVICE_LIST_MODAL_ERROR_MESSAGE = "deviceView.resetDeviceListModal.errorMessage";
export const T_RESET_DEVICE_LIST_MODAL_ERROR_MESSAGE_WITH_HIGHLIGHTS = "deviceView.resetDeviceListModal.errorMessageWithHighlights";
export const T_RESET_DEVICE_LIST_MODAL_ADDITIONAL_OPTIONS_TITLE = "deviceView.resetDeviceListModal.additionalResetOptions.title";
export const T_RESET_DEVICE_LIST_MODAL_ADDITIONAL_OPTIONS_PRESERVE_FACTORY_RESET_DATA = "deviceView.resetDeviceListModal.additionalResetOptions.preserveFactoryResetData";
export const T_RESET_DEVICE_LIST_MODAL_ADDITIONAL_OPTIONS_WIPE_EXTERNAL_STORAGE = "deviceView.resetDeviceListModal.additionalResetOptions.wipeExternalStorage";
export const T_RESET_DEVICE_LIST_MODAL_ADDITIONAL_OPTIONS_OPTIONAL_MESSAGE = "deviceView.resetDeviceListModal.additionalResetOptions.optionalMessage";

export const T_REBOOT_DEVICE_MODAL_TITLE = "deviceView.rebootDeviceModal.title";
export const T_REBOOT_DEVICE_MODAL_CONTENT = "deviceView.rebootDeviceModal.content";
export const T_REBOOT_DEVICE_MODAL_ERROR_MESSAGE = "deviceView.rebootDeviceModal.errorMessage";
export const T_REBOOT_DEVICE_MODAL_NO_IMEI_DEVICE_TEXT = "deviceView.rebootDeviceModal.noImeiText";
export const T_REBOOT_DEVICE_MODAL_CANCEL_LABEL = "deviceView.rebootDeviceModal.cancelLabel";
export const T_REBOOT_DEVICE_MODAL_ACCEPT_LABEL = "deviceView.rebootDeviceModal.acceptLabel";
export const T_REBOOT_DEVICE_MODAL_WARNING_1 = "deviceView.rebootDeviceListModal.warning.1";
export const T_REBOOT_DEVICE_MODAL_WARNING_2_ONE = "deviceView.rebootDeviceListModal.warning.2.one";
export const T_REBOOT_DEVICE_MODAL_WARNING_2_LIST = "deviceView.rebootDeviceListModal.warning.2.list";
export const T_REBOOT_DEVICE_MODAL_NOT_ENABLED_INFO = "deviceView.rebootDeviceModal.notEnabled.info";

export const T_REBOOT_DEVICE_LIST_MODAL_TITLE = "deviceView.rebootDeviceListModal.title";
export const T_REBOOT_DEVICE_LIST_MODAL_CONTENT = "deviceView.rebootDeviceListModal.content";
export const T_REBOOT_DEVICE_LIST_MODAL_SUCCESS_MESSAGE = "deviceView.rebootDeviceListModal.successMessage";
export const T_REBOOT_DEVICE_LIST_MODAL_ERROR_MESSAGE = "deviceView.rebootDeviceListModal.errorMessage";
export const T_REBOOT_DEVICE_LIST_MODAL_ERROR_MESSAGE_WITH_HIGHLIGHTS = "deviceView.rebootDeviceListModal.errorMessageWithHighlights";
export const T_REBOOT_DEVICE_LIST_MODAL_CANCEL_LABEL = "deviceView.rebootDeviceListModal.cancelLabel";
export const T_REBOOT_DEVICE_LIST_MODAL_ACCEPT_LABEL = "deviceView.rebootDeviceListModal.acceptLabel";

export const T_RELINQUISH_OWNERSHIP_MODAL_TITLE = "relinquishOwnershipModal.title";
export const T_RELINQUISH_OWNERSHIP_MODAL_CONTENT = "relinquishOwnershipModal.content";
export const T_RELINQUISH_OWNERSHIP_MODAL_CONTENT_ONE = "relinquishOwnershipModal.content.one";
export const T_RELINQUISH_OWNERSHIP_MODAL_CONTENT_LIST = "relinquishOwnershipModal.content.list";
export const T_RELINQUISH_OWNERSHIP_MODAL_WARNING_1 = "relinquishOwnershipModal.warning.1";
export const T_RELINQUISH_OWNERSHIP_MODAL_WARNING_2 = "relinquishOwnershipModal.warning.2";
export const T_RELINQUISH_OWNERSHIP_MODAL_EXPLAIN = "relinquishOwnershipModal.explain";
export const T_RELINQUISH_OWNERSHIP_MODAL_ACCEPT = "relinquishOwnershipModal.accept";
export const T_RELINQUISH_OWNERSHIP_MODAL_NOT_ENABLED_INFO = "relinquishOwnershipModal.notEnabled.info";

export const T_RELINQUISH_OWNERSHIP_SUCCESS_MESSAGE = "relinquishOwnershipModal.successMessage";
export const T_RELINQUISH_OWNERSHIP_ERROR_MESSAGE = "relinquishOwnershipModal.errorMessage";
export const T_RELINQUISH_OWNERSHIP_ERROR_MESSAGE_WITH_HIGHLIGHTS = "relinquishOwnershipModal.errorMessageWithHighlights";

export const T_RESET_PASSWORD_ON_DEVICE_MODAL_TITLE = "deviceView.resetPasswordModal.title";
export const T_RESET_PASSWORD_ON_DEVICE_MODAL_CONTENT = "deviceView.resetPasswordModal.content";
export const T_RESET_PASSWORD_ON_DEVICE_MODAL_ERROR_MESSAGE = "deviceView.resetPasswordModal.errorMessage";
export const T_RESET_PASSWORD_ON_DEVICE_MODAL_SUCCESS_MESSAGE = "deviceView.resetPasswordModal.successMessage";
export const T_RESET_PASSWORD_ON_DEVICE_MODAL_NO_IMEI_DEVICE_TEXT = "deviceView.resetPasswordModal.noImeiText";
export const T_RESET_PASSWORD_ON_DEVICE_MODAL_CANCEL_LABEL = "deviceView.resetPasswordModal.cancelLabel";
export const T_RESET_PASSWORD_ON_DEVICE_MODAL_ACCEPT_LABEL = "deviceView.resetPasswordModal.acceptLabel";
export const T_RESET_PASSWORD_ON_DEVICE_MODAL_ADDITIONAL_OPTIONS = "deviceView.resetPasswordModal.additionalOptions";
export const T_RESET_PASSWORD_ON_DEVICE_MODAL_OPTIONS_LOCK_DEVICE = "deviceView.resetPasswordModal.options.lockDevice";
export const T_RESET_PASSWORD_ON_DEVICE_MODAL_OPTIONS_REQUEST_CREDENTIALS = "deviceView.resetPasswordModal.options.requestCredentials";
export const T_RESET_PASSWORD_ON_DEVICE_MODAL_OPTIONS_FREEZE_PASSWORD = "deviceView.resetPasswordModal.options.freezePassword";
export const T_RESET_PASSWORD_ON_DEVICE_MODAL_NEW_PASSWORD_LABEL = "deviceView.resetPasswordModal.newPasswordLabel";
export const T_RESET_PASSWORD_ON_DEVICE_MODAL_REPEAT_NEW_PASSWORD_LABEL = "deviceView.resetPasswordModal.repeatNewPasswordLabel";
export const T_RESET_PASSWORD_ON_DEVICE_MODAL_NEW_PASSWORD_PLACEHOLDER = "deviceView.resetPasswordModal.newPasswordPlaceholder";
export const T_RESET_PASSWORD_ON_DEVICE_MODAL_PASSWORD_REQUIREMENTS = "deviceView.resetPasswordModal.passwordRequirements";
export const T_RESET_PASSWORD_ON_DEVICE_MODAL_PASSWORD_REQUIREMENTS_HISTORY = "deviceView.resetPasswordModal.passwordRequirements.history";

export const T_RESET_PASSWORD_ON_DEVICE_LIST_MODAL_TITLE = "deviceView.resetPasswordOnDeviceListModal.title";
export const T_RESET_PASSWORD_ON_DEVICE_LIST_MODAL_CONTENT = "deviceView.resetPasswordOnDeviceListModal.content";
export const T_RESET_PASSWORD_ON_DEVICE_LIST_MODAL_ERROR_MESSAGE = "deviceView.resetPasswordOnDeviceListModal.errorMessage";
export const T_RESET_PASSWORD_ON_DEVICE_LIST_MODAL_ERROR_MESSAGE_WITH_HIGHLIGHTS = "deviceView.resetPasswordOnDeviceListModal.errorMessageWithHighlights";
export const T_RESET_PASSWORD_ON_DEVICE_LIST_MODAL_SUCCESS_MESSAGE = "deviceView.resetPasswordOnDeviceListModal.successMessage";
export const T_RESET_PASSWORD_ON_DEVICE_LIST_MODAL_CANCEL_LABEL = "deviceView.resetPasswordOnDeviceListModal.cancelLabel";
export const T_RESET_PASSWORD_ON_DEVICE_LIST_MODAL_ACCEPT_LABEL = "deviceView.resetPasswordOnDeviceListModal.acceptLabel";
export const T_RESET_PASSWORD_ON_DEVICE_LIST_MODAL_ADDITIONAL_OPTIONS = "deviceView.resetPasswordOnDeviceListModal.additionalOptions";
export const T_RESET_PASSWORD_ON_DEVICE_LIST_MODAL_OPTIONS_LOCK_DEVICE = "deviceView.resetPasswordOnDeviceListModal.options.lockDevice";
export const T_RESET_PASSWORD_ON_DEVICE_LIST_MODAL_OPTIONS_REQUEST_CREDENTIALS = "deviceView.resetPasswordOnDeviceListModal.options.requestCredentials";
export const T_RESET_PASSWORD_ON_DEVICE_LIST_MODAL_OPTIONS_FREEZE_PASSWORD = "deviceView.resetPasswordOnDeviceListModal.options.freezePassword";
export const T_RESET_PASSWORD_ON_DEVICE_LIST_MODAL_NEW_PASSWORD_LABEL = "deviceView.resetPasswordOnDeviceListModal.newPasswordLabel";
export const T_RESET_PASSWORD_ON_DEVICE_LIST_MODAL_REPEAT_NEW_PASSWORD_LABEL = "deviceView.resetPasswordOnDeviceListModal.repeatNewPasswordLabel";
export const T_RESET_PASSWORD_ON_DEVICE_LIST_MODAL_NEW_PASSWORD_PLACEHOLDER = "deviceView.resetPasswordOnDeviceListModal.newPasswordPlaceholder";
export const T_RESET_PASSWORD_ON_DEVICE_LIST_MODAL_PASSWORD_REQUIREMENTS = "deviceView.resetPasswordOnDeviceListModal.passwordRequirements";
export const T_RESET_PASSWORD_ON_DEVICE_LIST_MODAL_PASSWORD_REQUIREMENTS_HISTORY = "deviceView.resetPasswordOnDeviceListModal.passwordRequirements.history";
export const T_RESET_PASSWORD_ON_DEVICE_LIST_MODAL_PASSWORD_REQUIREMENTS_WARNING = "deviceView.resetPasswordOnDeviceListModal.passwordRequirements.warning";

export const T_POLICY_VIEW_DELETE_POLICY_MODAL_TITLE = "policyView.deletePolicyModal.title";
export const T_POLICY_VIEW_DELETE_POLICY_MODAL_CONTENT = "policyView.deletePolicyModal.content";
export const T_POLICY_VIEW_DELETE_POLICY_MODAL_CONTENT_DISABLED = "policyView.deletePolicyModal.disabledContent";
export const T_POLICY_VIEW_DELETE_POLICY_MODAL_NO_IMEI_POLICY_TEXT = "policyView.deletePolicyModal.noImeiText";
export const T_POLICY_VIEW_DELETE_POLICY_MODAL_CANCEL_LABEL = "policyView.deletePolicyModal.cancelLabel";
export const T_POLICY_VIEW_DELETE_POLICY_MODAL_ACCEPT_LABEL = "policyView.deletePolicyModal.acceptLabel";

export const T_POLICY_VIEW_LOADFAIL_TITLE = "policyView.loadFailed.title";
export const T_POLICY_VIEW_LOADFAIL_MESSAGE = "policyView.loadFailed.message";

export const T_POLICY_VIEW_SECURITY_TITLE = "policyView.security.title";
export const T_POLICY_VIEW_SECURITY_CONTENT = "policyView.security.content";

export const T_POLICY_VIEW_SECURITY_ENABLE_VPN_CONFIG = "policyView.security.vpnConfigDisabled.label";
export const T_POLICY_VIEW_SECURITY_VPN_PACKAGE_NAME_LABEL = "policyView.security.alwaysOnVpnPackage.packageName.label";
export const T_POLICY_VIEW_SECURITY_VPN_PACKAGE_NAME_PLACEHOLDER = "policyView.security.alwaysOnVpnPackage.packageName.placeholder";
export const T_POLICY_VIEW_SECURITY_VPN_LOCKDOWN = "policyView.security.alwaysOnVpnPackage.lockdownEnabled";

export const T_USER_LIST_VIEW_DELETE_USER_MODAL_TITLE = "userListView.deleteUserModal.title";
export const T_USER_LIST_VIEW_DELETE_USER_MODAL_CONTENT = "userListView.deleteUserModal.content";
export const T_USER_LIST_VIEW_DELETE_USER_MODAL_CANCEL_LABEL = "userListView.deleteUserModal.cancelLabel";
export const T_USER_LIST_VIEW_DELETE_USER_MODAL_ACCEPT_LABEL = "userListView.deleteUserModal.acceptLabel";

export const T_RESET_USER_PASSWORD_MODAL_TITLE = "resetUserPasswordModal.title";
export const T_RESET_USER_PASSWORD_MODAL_CONTENT = "resetUserPasswordModal.content";
export const T_RESET_USER_PASSWORD_MODAL_CANCEL_LABEL = "resetUserPasswordModal.cancelLabel";
export const T_RESET_USER_PASSWORD_MODAL_ACCEPT_LABEL = "resetUserPasswordModal.acceptLabel";

export const T_DEMOTE_USER_MODAL_TITLE = "demoteUserModal.title";
export const T_DEMOTE_USER_MODAL_CONTENT = "demoteUserModal.content";
export const T_DEMOTE_USER_MODAL_CANCEL_LABEL = "demoteUserModal.cancelLabel";
export const T_DEMOTE_USER_MODAL_ACCEPT_LABEL = "demoteUserModal.acceptLabel";
export const T_DEMOTE_USER_MODAL_ALREADY_DEMOTED_CONTENT = "demoteUserModal.alreadyDemotedContent";

export const T_PROMOTE_USER_MODAL_TITLE = "promoteUserModal.title";
export const T_PROMOTE_USER_MODAL_CONTENT = "promoteUserModal.content";
export const T_PROMOTE_USER_MODAL_CANCEL_LABEL = "promoteUserModal.cancelLabel";
export const T_PROMOTE_USER_MODAL_ACCEPT_LABEL = "promoteUserModal.acceptLabel";
export const T_PROMOTE_USER_MODAL_ALREADY_PROMOTED_CONTENT = "promoteUserModal.alreadyPromotedContent";

export const T_ACTIVATE_USER_MODAL_TITLE = "activateUserModal.title";
export const T_ACTIVATE_USER_MODAL_CONTENT = "activateUserModal.content";
export const T_ACTIVATE_USER_MODAL_ALREADY_ACTIVE_CONTENT = "activateUserModal.alreadyActiveContent";
export const T_ACTIVATE_USER_MODAL_ALREADY_PENDING_CONTENT = "activateUserModal.alreadyPendingContent";
export const T_ACTIVATE_USER_MODAL_ERROR_CONTENT = "activateUserModal.errorContent";
export const T_ACTIVATE_USER_MODAL_CANCEL_LABEL = "activateUserModal.cancelLabel";
export const T_ACTIVATE_USER_MODAL_ACCEPT_LABEL = "activateUserModal.acceptLabel";

export const T_DEACTIVATE_USER_MODAL_TITLE = "deactivateUserModal.title";
export const T_DEACTIVATE_USER_MODAL_CONTENT = "deactivateUserModal.content";
export const T_DEACTIVATE_USER_MODAL_ALREADY_DEACTIVE_CONTENT = "deactivateUserModal.alreadyDeactiveContent";
export const T_DEACTIVATE_USER_MODAL_ALREADY_PENDING_CONTENT = "deactivateUserModal.alreadyPendingContent";
export const T_DEACTIVATE_USER_MODAL_ERROR_CONTENT = "deactivateUserModal.errorContent";
export const T_DEACTIVATE_USER_MODAL_CANCEL_LABEL = "deactivateUserModal.cancelLabel";
export const T_DEACTIVATE_USER_MODAL_ACCEPT_LABEL = "deactivateUserModal.acceptLabel";

export const T_USER_LIST_VIEW_RESET_PASSWORD_SUCCESS_MESSAGE = "userListView.resetPasswordSuccess";
export const T_USER_LIST_VIEW_PROMOTE_USER_SUCCESS_MESSAGE = "userListView.promoteUserSuccess";
export const T_USER_LIST_VIEW_DEMOTE_USER_SUCCESS_MESSAGE = "userListView.demoteUserSuccess";
export const T_USER_LIST_VIEW_DELETE_USER_SUCCESS_MESSAGE = "userListView.deleteUserSuccess";
export const T_USER_LIST_VIEW_ACTIVATE_USER_SUCCESS_MESSAGE = "userListView.activateUserSuccess";
export const T_USER_LIST_VIEW_DEACTIVATE_USER_SUCCESS_MESSAGE = "userListView.deactivateUserSuccess";

export const T_USER_LIST_VIEW_ACCESS_DENIED_ERROR_MESSAGE = "userListView.accessDeniedError";

export const T_USER_LIST_VIEW_RESET_PASSWORD_ERROR_MESSAGE = "userListView.resetPasswordError";
export const T_USER_LIST_VIEW_PROMOTE_USER_ERROR_MESSAGE = "userListView.promoteUserError";
export const T_USER_LIST_VIEW_DEMOTE_USER_ERROR_MESSAGE = "userListView.demoteUserError";
export const T_USER_LIST_VIEW_DELETE_USER_ERROR_MESSAGE = "userListView.deleteUserError";
export const T_USER_LIST_VIEW_ACTIVATE_USER_ERROR_MESSAGE = "userListView.activateUserError";
export const T_USER_LIST_VIEW_DEACTIVATE_USER_ERROR_MESSAGE = "userListView.deactivateUserError";

export const T_POLICY_VIEW_DELETE_POLICY_SUCCESS = "policyView.deletePolicyModal.policyDeleteSuccess";
export const T_POLICY_VIEW_DELETE_POLICY_ERROR_DEFAULT_POLICY = "policyView.deletePolicyModal.policyDeleteErrorBecauseDefaultPolicy";
export const T_POLICY_VIEW_DELETE_POLICY_ERROR_LINKED_TO = "policyView.deletePolicyModal.policyDeleteErrorBecauseLinkedTo";
export const T_POLICY_VIEW_DELETE_POLICY_ERROR = "policyView.deletePolicyModal.policyDeleteError";

export const T_PROFILE_SERVICE_CHANGE_PASSWORD_CHANGE_ERROR = "profileService.changePasswordError";
export const T_PROFILE_SERVICE_FORGET_PASSWORD_ERROR = "profileService.forgetPasswordError";

export const T_ACCOUNT_VIEW_TITLE = "accountView.title";
export const T_ACCOUNT_VIEW_PREFERRED_LANGUAGE_TITLE = "accountView.preferredLanguage";
export const T_ACCOUNT_VIEW_PASSWORD_CHANGE_SUCCESS = "accountView.changePasswordSuccess";
export const T_ACCOUNT_VIEW_RESET_TFA_LABEL = "accountView.resetTfa.label";
export const T_ACCOUNT_VIEW_RESET_TFA_TITLE = "accountView.resetTfa.title";

export const T_LOGIN_VIEW_FORGOT_PASSWORD_BUTTON_LABEL = "loginView.forgotPasswordButtonLabel";
export const T_LOGIN_VIEW_CANCEL_FORGOT_PASSWORD_BUTTON_LABEL = "loginView.cancelForgotPasswordButtonLabel";
export const T_LOGIN_VIEW_FORGOT_PASSWORD_SENT_TITLE = "loginView.forgotPasswordSent.title";
export const T_LOGIN_VIEW_FORGOT_PASSWORD_SENT_TEXT = "loginView.forgotPasswordSent.text";

export const T_LOGIN_VIEW_FORGOT_PASSWORD_SUCCESS = "loginView.forgotPasswordSuccess";
export const T_LOGIN_VIEW_FORGOT_PASSWORD_ERROR = "loginView.forgotPasswordError";
export const T_LOGIN_VIEW_RESET_PASSWORD_ERROR = "loginView.resetPasswordError";
export const T_LOGIN_VIEW_RESET_PASSWORD_NO_TOKEN_ERROR = "loginView.resetPasswordNoToken";
export const T_LOGIN_VIEW_EXPIRED_LINK_ERROR = "login.errorExpiredLink";
export const T_LOGIN_VIEW_FORGOT_PASSWORD_HISTORY_ERROR = "loginView.resetPasswordErrorHistory";
export const T_LOGIN_VIEW_FORGOT_PASSWORD_COMPLEXITY_ERROR = "loginView.resetPasswordErrorComplexity";

export const T_LOGIN_VIEW_TERMS_AND_CONDITIONS_ERROR = "loginView.termsAndConditionsError";

export const T_NETWORK_CONNECTION_WARNING_TITLE = "networkConnectionWarning.title";
export const T_NETWORK_CONNECTION_WARNING_SUMMARY = "networkConnectionWarning.summary";

export const T_DEFAULT_VALUE_N_A = "defaults.undefinedValue";
export const T_DEVICE_VIEW_LABEL_NAME = "deviceView.label.name";

export const T_DEVICE_VIEW_LABEL_DISPLAY_NAME = "deviceView.label.displayName";
export const T_DEVICE_VIEW_LABEL_DESCRIPTION = "deviceView.label.description";
export const T_DEVICE_VIEW_LABEL_IMEI = "deviceView.label.imei";
export const T_DEVICE_VIEW_LABEL_SERIAL_NUMBER = "deviceView.label.serialNumber";
export const T_DEVICE_VIEW_LABEL_MANUFACTURER = "deviceView.label.manufacturer";
export const T_DEVICE_VIEW_LABEL_BRAND = "deviceView.label.brand";
export const T_DEVICE_VIEW_LABEL_MODEL = "deviceView.label.model";
export const T_DEVICE_VIEW_LABEL_SOFTWARE = "deviceView.label.software";
export const T_DEVICE_VIEW_LABEL_ANDROID_BUILD_NUMBER = "deviceView.label.androidBuildNumber";
export const T_DEVICE_VIEW_LABEL_SECURITY_PATCH_LEVEL = "deviceView.label.securityPatchLevel";
export const T_DEVICE_VIEW_LABEL_DEVICE_GROUP = "deviceView.label.deviceGroup";
export const T_DEVICE_VIEW_LABEL_ENROLLED_ON_TIME = "deviceView.label.enrolledOnTime";
export const T_DEVICE_VIEW_LABEL_LAST_STATUS_REPORT_TIME = "deviceView.label.lastStatusReportTime";
export const T_DEVICE_VIEW_LABEL_LAST_POLICY_SYNC_TIME = "deviceView.label.lastPolicySyncTime";

export const T_DEVICE_VIEW_LABEL_DEVICE_OWNER = "deviceView.label.deviceOwner";
export const T_DEVICE_VIEW_LABEL_DEVICE_OWNER_VALUE_PREFIX = "deviceView.values.deviceOwner.";

export const T_DEVICE_MANAGEMENT_MODE_PREFIX = "device.values.managementMode.";

export const T_DEVICE_VIEW_LABEL_ASSIGNED_POLICY = "deviceView.label.assignedPolicy";
export const T_DEVICE_VIEW_LABEL_APPLIED_POLICY = "deviceView.label.appliedPolicy";

export const T_DEVICE_VIEW_LOADFAIL_TITLE = "deviceView.loadFailed.title";
export const T_DEVICE_VIEW_LOADFAIL_MESSAGE = "deviceView.loadFailed.message";

export const T_DEVICE_VIEW_UPDATING_TO_POLICY = "deviceView.label.updatingToPolicy";
export const T_DEVICE_VIEW_SELECT_POLICY_PLACEHOLDER = "deviceView.selectPolicyPlaceholder";
export const T_DEVICE_VIEW_SELECT_POLICY_BUTTON_LABEL = "deviceView.selectPolicyButtonLabel";
export const T_DEVICE_VIEW_POLICY_LOADING_FAILED_ERROR = "deviceView.policyLoadFailed.error";

export const T_DEVICE_VIEW_LABEL_COMPLIANCY_STATUS = "deviceView.label.compliancyStatus.label";
export const T_DEVICE_VIEW_LABEL_COMPLIANCY_STATUS_OK = "deviceView.label.compliancyStatus.ok";
export const T_DEVICE_VIEW_LABEL_COMPLIANCY_STATUS_NON_COMPLIANT = "deviceView.label.compliancyStatus.nonCompliant";
export const T_DEVICE_VIEW_LABEL_COMPLIANCY_STATUS_UNKNOWN = "deviceView.label.compliancyStatus.unknown";

export const T_DEVICE_VIEW_LABEL_STATE = "deviceView.label.state";
export const T_DEVICE_VIEW_VALUE_STATE_PREFIX = "deviceView.values.state.";

export const T_DEVICE_VIEW_LABEL_UPDATE_STATUS = "deviceView.label.updateStatus";
export const T_DEVICE_VIEW_VALUE_UPDATE_STATUS_PREFIX = "deviceView.values.updateStatus.";

export const T_DEVICE_VIEW_TOKEN_COPIED_SUCCESS = "deviceView.tokenCopied.success";
export const T_DEVICE_VIEW_TOKEN_COPIED_FAILED = "deviceView.tokenCopied.failed";

export const T_DEVICE_VIEW_ENROLLMENT_TOKEN_LINK_COPIED_SUCCESS = "deviceView.tokenLinkCopied.success";
export const T_DEVICE_VIEW_ENROLLMENT_TOKEN_LINK_COPIED_FAILED = "deviceView.tokenLinkCopied.failed";

export const T_TERABYTES_LABEL = "bytes.terabytes";
export const T_GIGABYTES_LABEL = "bytes.gigabytes";
export const T_MEGABYTES_LABEL = "bytes.megabytes";
export const T_KILOBYTES_LABEL = "bytes.kilobytes";
export const T_BYTES_LABEL = "bytes.bytes";

export const T_POLICY_VIEW_TITLE = "policyView.title";
export const T_POLICY_VIEW_BACK_BUTTON_LABEL = "policyView.backButtonLabel";

export const T_POLICY_VIEW_SAVE_CONTROLS_CONTENT_LABEL = "policyView.saveControls.label";
export const T_POLICY_VIEW_SAVE_CONTROLS_CONTENT_INVALID_LABEL = "policyView.saveControls.invalidLabel";
export const T_POLICY_VIEW_SAVE_CONTROLS_DISMISS_LABEL = "policyView.saveControls.dismissLabel";
export const T_POLICY_VIEW_SAVE_CONTROLS_SAVE_BUTTON_LABEL = "policyView.saveControls.saveButtonLabel";
export const T_POLICY_VIEW_FETCH_ERROR = "policyView.fetchError";
export const T_POLICY_VIEW_FETCH_RETRY_LABEL = "policyView.fetchErrorRetryLabel";

export const T_DEVICE_VIEW_SAVE_CONTROLS_CONTENT_LABEL = "deviceView.saveControls.label";
export const T_DEVICE_VIEW_SAVE_CONTROLS_CONTENT_INVALID_LABEL = "deviceView.saveControls.invalidLabel";
export const T_DEVICE_VIEW_SAVE_CONTROLS_DISMISS_LABEL = "deviceView.saveControls.dismissLabel";
export const T_DEVICE_VIEW_SAVE_CONTROLS_SAVE_BUTTON_LABEL = "deviceView.saveControls.saveButtonLabel";

export const T_POLICY_VIEW_ACTIONS_LABEL = "policyView.actionsLabel";
export const T_POLICY_VIEW_CLONE_POLICY_LABEL = "policyView.actions.cloneLabel";
export const T_POLICY_VIEW_DELETE_POLICY_LABEL = "policyView.actions.deleteLabel";
export const T_POLICY_VIEW_REFRESH_LABEL = "policyView.actions.refreshLabel";

export const T_POLICY_VIEW_EDIT_PASSWORD_POLICY_BUTTON_TEXT = "policyView.editPasswordPolicyButton";

export const T_POLICY_VIEW_TOTAL_DEVICES_TEXT = "policyView.totalDevicesText";
export const T_POLICY_VIEW_TOTAL_DETAILS = "policyView.totalDetailsText";

export const T_POLICY_VIEW_BASIC_INFO_TITLE = "policyView.basicInfoTitle";
export const T_POLICY_VIEW_RESTRICTIONS_TITLE = "policyView.restrictionsTitle";
export const T_POLICY_VIEW_PASSWORD_SETTINGS_TITLE = "policyView.passwordSettingsTitle";
export const T_POLICY_VIEW_PASSWORD_SETTINGS_DESCRIPTION = "policyView.passwordSettingsDescription";
export const T_POLICY_VIEW_APPLICATIONS_PERMISSIONS_TITLE = "policyView.applicationsTitle";
export const T_POLICY_VIEW_NETWORK_CONFIGURATIONS_TITLE = "policyView.networkConfigurations.title";
export const T_POLICY_VIEW_NETWORK_CONFIGURATIONS_INGRESS = "policyView.networkConfigurations.ingress";
export const T_POLICY_VIEW_NETWORK_CONFIGURATIONS_ADD_NETWORK = "policyView.networkConfigurations.addNetwork";
export const T_POLICY_VIEW_NETWORK_CONFIGURATIONS_EDIT = "policyView.networkConfigurations.editNetwork";
export const T_POLICY_VIEW_NETWORK_CONFIGURATIONS_DELETE = "policyView.networkConfigurations.deleteNetwork";
export const T_POLICY_VIEW_NETWORK_CONFIGURATIONS_AC_ON = "policyView.networkConfigurations.autoConnectOn";
export const T_POLICY_VIEW_NETWORK_CONFIGURATIONS_AC_OFF = "policyView.networkConfigurations.autoConnectOff";

export const T_POLICY_VIEW_SYSTEM_UPDATE_TITLE = "policyView.systemUpdate.title";
export const T_POLICY_VIEW_SYSTEM_UPDATE_DESCRIPTION = "policyView.systemUpdate.description";

export const T_POLICY_VIEW_ERROR_PREFIX = "policyView.errors";

export const T_POLICY_VIEW_FIELDS_ADVANCED_SECURITY_OVERRIDES_UNTRUSTED_APPS_POLICY_LABEL = "policyView.fields.deviceSettings.untrustedAppsPolicy.label";
export const T_POLICY_VIEW_FIELDS_ADVANCED_SECURITY_OVERRIDES_UNTRUSTED_APPS_POLICY_PLACEHOLDER = "policyView.fields.deviceSettings.untrustedAppsPolicy.placeholder";
export const T_POLICY_VIEW_FIELDS_ADVANCED_SECURITY_OVERRIDES_UNTRUSTED_APPS_POLICY_VALUES_PREFIX = "policyModel.untrustedAppsPolicy.values";

export const T_POLICY_VIEW_FIELDS_ENABLE_DEBUG_MODE = "policyView.fields.deviceSettings.enableDebugMode";
export const T_POLICY_VIEW_FIELDS_ENABLE_FACTORY_RESET = "policyView.fields.deviceSettings.enableFactoryReset";
export const T_POLICY_VIEW_FIELDS_ENABLE_CAMERA = "policyView.fields.deviceSettings.enableCamera";
export const T_POLICY_VIEW_FIELDS_ENABLE_SCREEN_CAPTURE = "policyView.fields.deviceSettings.enableScreenCapture";
export const T_POLICY_VIEW_FIELDS_ENABLE_MASTER_VOLUME = "policyView.fields.deviceSettings.enableMasterVolume";
export const T_POLICY_VIEW_FIELDS_ENABLE_MICROPHONE = "policyView.fields.deviceSettings.enableMicrophone";
export const T_POLICY_VIEW_FIELDS_ENABLE_SAFE_BOOT = "policyView.fields.deviceSettings.enableSafeBoot";
export const T_POLICY_VIEW_FIELDS_ENABLE_KEYGUARD = "policyView.fields.deviceSettings.enableKeyguard";
export const T_POLICY_VIEW_FIELDS_ENABLE_NETWORK_INFO = "policyView.fields.deviceSettings.enableNetworkInfo";
export const T_POLICY_VIEW_FIELDS_ENABLE_MEMORY_INFO = "policyView.fields.deviceSettings.enableMemoryInfo";
export const T_POLICY_VIEW_FIELDS_ENABLE_HARDWARE_STATUS = "policyView.fields.deviceSettings.enableHardwareStatus";
export const T_POLICY_VIEW_FIELDS_ENABLE_DISPLAY_INFO = "policyView.fields.deviceSettings.enableDisplayInfo";
export const T_POLICY_VIEW_FIELDS_ENABLE_ROAMING = "policyView.fields.deviceSettings.dataRoamingDisabled";
export const T_POLICY_VIEW_FIELDS_ENABLE_BLUETOOTH = "policyView.fields.deviceSettings.bluetoothDisabled";
export const T_POLICY_VIEW_FIELDS_ENABLE_TETHERING_CONFIG = "policyView.fields.deviceSettings.tetheringConfigDisabled";
export const T_POLICY_VIEW_FIELDS_ENABLE_WIFI_CONFIG = "policyView.fields.deviceSettings.wifiConfigDisabled";
export const T_POLICY_VIEW_FIELDS_ENABLE_WIFI_CONFIG_MESSAGE_PREFIX = "policyView.fields.deviceSettings.wifiConfigDisabled.message.";
export const T_POLICY_VIEW_FIELDS_APPLICATION_REPORTS_ENABLED = "policyView.fields.deviceSettings.enableApplicationsReports";
export const T_POLICY_VIEW_FIELDS_LOCATION_SHARING_ENABLED = "policyView.fields.deviceSettings.enableLocationSharing";
export const T_POLICY_VIEW_FIELDS_KIOSK_DEVICE_SETTINGS_LABEL = "policyView.fields.deviceSettings.kioskSettingsAllowed";
export const T_POLICY_VIEW_FIELDS_LOCATION_TRACKING_ENABLED = "policyView.companionApp.locationTracking.label";
export const T_POLICY_VIEW_FIELDS_ENABLE_USB_FILETRANSFER = "policyView.fields.deviceSettings.usbfiletransferEnabled";
export const T_POLICY_VIEW_FIELDS_ENABLE_MOUNTED_MEDIA = "policyView.fields.deviceSettings.mountPhysicalMediaEnabled";

export const T_POLICY_VIEW_FIELDS_DEVICE_SETTINGS_TOOLTIP_LABEL_PREFIX = "policyView.fields.deviceSettings.tooltipLabel";
export const T_POLICY_VIEW_FIELDS_DEVICE_SETTINGS_TOOLTIP_CONTENT_PREFIX = "policyView.fields.deviceSettings.tooltipContent";

export const T_POLICY_VIEW_FIELDS_POLICY_NAME_LABEL = "policyView.fields.policyName.label";
export const T_POLICY_VIEW_FIELDS_POLICY_NAME_PLACEHOLDER = "policyView.fields.policyName.placeholder";

export const T_POLICY_VIEW_FIELDS_ENABLE_KIOSK_CUSTOM_LAUNCHER = "policyView.fields.enableKioskCustomLauncher";

export const T_POLICY_VIEW_FIELDS_POLICY_TYPE_LABEL = "policyView.fields.policyType.label";
export const T_POLICY_VIEW_FIELDS_POLICY_TYPE_PLACEHOLDER = "policyView.fields.policyType.placeholder";

export const T_POLICY_VIEW_FIELDS_POLICY_DESCRIPTION_LABEL = "policyView.fields.description.label";
export const T_POLICY_VIEW_FIELDS_POLICY_DESCRIPTION_PLACEHOLDER = "policyView.fields.description.placeholder";
export const T_POLICY_VIEW_FIELDS_POLICY_DESCRIPTION_DEFAULT = "policyView.fields.description.default";

export const T_POLICY_VIEW_FIELDS_KIOSK_SYSTEM_NAVIGATION_LABEL = "policyView.fields.kiosk.systemNavigation.label";
export const T_POLICY_VIEW_FIELDS_KIOSK_SYSTEM_NAVIGATION_PLACEHOLDER = "policyView.fields.kiosk.systemNavigation.placeholder";

export const T_POLICY_VIEW_FIELDS_KIOSK_STATUS_BAR_LABEL = "policyView.fields.kiosk.statusBar.label";
export const T_POLICY_VIEW_FIELDS_KIOSK_STATUS_BAR_PLACEHOLDER = "policyView.fields.kiosk.statusBar.placeholder";

export const T_POLICY_VIEW_FIELDS_SECTION_TITLE_SECURITY = "policyView.deviceSettings.expandSection.security";
export const T_POLICY_VIEW_FIELDS_SECTION_TITLE_MEDIA = "policyView.deviceSettings.expandSection.media";
export const T_POLICY_VIEW_FIELDS_SECTION_TITLE_NETWORK = "policyView.deviceSettings.expandSection.network";
export const T_POLICY_VIEW_FIELDS_SECTION_TITLE_KIOSK = "policyView.deviceSettings.expandSection.kiosk";
export const T_POLICY_VIEW_FIELDS_SECTION_TITLE_USER_ACCOUNTS = "policyView.deviceSettings.expandSection.userAccounts";
export const T_POLICY_VIEW_FIELDS_SECTION_TITLE_CALLS_AND_MSG = "policyView.deviceSettings.expandSection.callsAndMessaging";

export const T_PASSWORD_REQUIREMENTS_MODAL_REQUIRE_PASSWORD_LABEL = "passwordRequirementsModal.requirePasswordToggle.label";

export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_QUALITY_TITLE = "policyView.fields.passwordQuality.title";
export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_QUALITY_LABEL = "policyView.fields.passwordQuality.label";
export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_QUALITY_PLACEHOLDER = "policyView.fields.passwordQuality.placeholder";

export const T_PASSWORD_REQUIREMENTS_MODAL_PASSWORD_LIFECYCLE_TITLE = "passwordRequirementsModal.passwordLifeCycle.title";

export const T_PASSWORD_REQUIREMENTS_MODAL_PASSWORD_LIFECYCLE_TOOLTIP_LABEL_PREFIX = "passwordRequirementsModal.passwordLifeCycle.tooltipLabel";
export const T_PASSWORD_REQUIREMENTS_MODAL_PASSWORD_LIFECYCLE_TOOLTIP_CONTENT_PREFIX = "passwordRequirementsModal.passwordLifeCycle.tooltipContent";

export const T_PASSWORD_REQUIREMENTS_MODAL_PASSWORD_COMPLIANCE_TOOLTIP_LABEL_PREFIX = "passwordRequirementsModal.passwordComplianceActions.tooltipLabel";
export const T_PASSWORD_REQUIREMENTS_MODAL_PASSWORD_COMPLIANCE_TOOLTIP_CONTENT_PREFIX = "passwordRequirementsModal.passwordComplianceActions.tooltipContent";
export const T_PASSWORD_REQUIREMENTS_MODAL_PASSWORD_COMPLIANCE_ACTIONS_TITLE = "passwordRequirementsModal.passwordComplianceActions.title";

export const T_PASSWORD_REQUIREMENTS_FIELD_ADD_POLICY_LABEL = "passwordRequirementsField.addPolicy";
export const T_PASSWORD_REQUIREMENTS_FIELD_DELETE_POLICY_LABEL = "passwordRequirementsField.deletePolicy";

export const T_POLICY_VIEW_FIELDS_PERMISSION_POLICY_LABEL = "policyView.fields.permissionPolicy.label";
export const T_POLICY_VIEW_FIELDS_PERMISSION_POLICY_PLACEHOLDER = "policyView.fields.permissionPolicy.placeholder";
export const T_POLICY_VIEW_FIELDS_PERMISSION_POLICY_AUTOMATICALLY_GRANT_PERMISSION = "policyView.fields.permissionPolicy.values.AUTOMATICALLY";

export const T_POLICY_VIEW_FIELDS_SYSTEM_UPDATE_TYPE_LABEL = "policyView.fields.systemUpdateType.label";
export const T_POLICY_VIEW_FIELDS_SYSTEM_UPDATE_TYPE_PLACEHOLDER = "policyView.fields.systemUpdateType.placeholder";
export const T_POLICY_VIEW_FIELDS_SYSTEM_UPDATE_TYPE_DESCRIPTIONS_PREFIX = "policyView.fields.systemUpdateType.descriptions";

export const T_POLICY_VIEW_LOCATION_SERVICES_TITLE = "policyView.fields.locationServices.title";
export const T_POLICY_VIEW_LOCATION_SERVICES_DESCRIPTION = "policyView.fields.locationServices.description";
export const T_POLICY_VIEW_LOCATION_SERVICES_DESCRIPTION_WITH_COMPANION_APP = "policyView.fields.locationServices.descriptionWithCompanionApp";
export const T_POLICY_VIEW_FIELDS_LOCATION_SERVICES_MODE_PLACEHOLDER = "policyView.fields.locationServices.placeholder";
export const T_POLICY_VIEW_FIELDS_LOCATION_SERVICES_MODE_LABEL = "policyView.fields.locationServices.modeLabel";
export const T_POLICY_VIEW_LOCATION_SERVICES_TRACK_INTERVAL_LABEL = "policyView.fields.locationServices.trackIntervalTitle";
export const T_POLICY_VIEW_LOCATION_SERVICES_TRACK_INTERVAL_PLACEHOLDER = "policyView.fields.locationServices.trackIntervalPlaceholder";
export const T_POLICY_VIEW_LOCATION_SERVICES_TRACK_INTERVAL_TOOL_TIP = "policyView.fields.locationServices.trackIntervalTooltip";
export const T_POLICY_VIEW_LOCATION_SERVICES_ADD_COMPANION_APP_TEXT = "policyView.fields.locationServices.companionAppTooltip";
export const T_POLICY_VIEW_LOCATION_SERVICES_NOT_ALLOWED = "policyView.fields.locationServices.locationServicesNotAllowedMessage";
export const T_LOCATION_MODE_PREFIX = "policyLocationMode.title";
export const T_LOCATION_MODE_DESCRIPTION_PREFIX = "policyLocationMode.description";
export const T_POLICY_VIEW_COMPANION_APP_TITLE = "policyView.companionApp.title";
export const T_POLICY_VIEW_COMPANION_APP_CONTENT = "policyView.companionApp.content";
export const T_POLICY_VIEW_COMPANION_APP_INSTALL_APP_LABEL = "policyView.companionApp.installApp.label";

export const T_POLICY_VIEW_APP_UPDATE_TITLE = "policyView.fields.appAutoUpdatePolicy.title";
export const T_POLICY_VIEW_APP_UPDATE_DESCRIPTION = "policyView.fields.appAutoUpdatePolicy.description";
export const T_POLICY_VIEW_FIELDS_APP_AUTO_UPDATE_POLICY_LABEL = "policyView.fields.appAutoUpdatePolicy.label";
export const T_POLICY_VIEW_FIELDS_APP_AUTO_UPDATE_POLICY_PLACEHOLDER = "policyView.fields.appAutoUpdatePolicy.placeholder";
export const T_APP_AUTO_UPDATE_POLICY_PREFIX = "appAutoUpdatePolicy";
export const T_POLICY_VIEW_FIELDS_APP_AUTO_UPDATE_TYPE_DESCRIPTIONS_PREFIX = "appAutoUpdatePolicyDescription";

export const T_APPLICATION_POLICY_MODAL_AUTO_UPDATE_MODE_TITLE = "policyView.fields.autoUpdateMode.title";
export const T_APPLICATION_POLICY_MODAL_AUTO_UPDATE_MODE_DESCRIPTION = "policyView.fields.autoUpdateMode.description";
export const T_APPLICATION_POLICY_MODAL_AUTO_UPDATE_MODE_LABEL = "policyView.fields.autoUpdateMode.label";
export const T_APPLICATION_POLICY_MODAL_AUTO_UPDATE_MODE_PLACEHOLDER = "policyView.fields.autoUpdateMode.placeholder";
export const T_AUTO_UPDATE_MODE_PREFIX = "autoUpdateMode";
export const T_APPLICATION_POLICY_MODAL_AUTO_UPDATE_MODE_TOOLTIP_PREFIX = "applicationPolicyModal.autoUpdateMode.tooltips";

export const T_POLICY_VIEW_FIELDS_SYSTEM_UPDATE_TYPE_WINDOWED_ALLOW_UPDATED_BETWEEN = "policyView.fields.systemUpdateType.windowed.allowUpdatedBetween";
export const T_POLICY_VIEW_FIELDS_SYSTEM_UPDATE_TYPE_WINDOWED_ALLOW_UPDATED_BETWEEN_START_TIME_LABEL =
  "policyView.fields.systemUpdateType.windowed.allowUpdatedBetween.startTime.label";
export const T_POLICY_VIEW_FIELDS_SYSTEM_UPDATE_TYPE_WINDOWED_ALLOW_UPDATED_BETWEEN_START_TIME_PLACEHOLDER =
  "policyView.fields.systemUpdateType.windowed.allowUpdatedBetween.startTime.placeholder";
export const T_POLICY_VIEW_FIELDS_SYSTEM_UPDATE_TYPE_WINDOWED_ALLOW_UPDATED_BETWEEN_END_TIME_LABEL =
  "policyView.fields.systemUpdateType.windowed.allowUpdatedBetween.endTime.label";
export const T_POLICY_VIEW_FIELDS_SYSTEM_UPDATE_TYPE_WINDOWED_ALLOW_UPDATED_BETWEEN_END_TIME_PLACEHOLDER =
  "policyView.fields.systemUpdateType.windowed.allowUpdatedBetween.endTime.placeholder";

export const T_POLICY_VIEW_FIELDS_REMOVE_USER_LABEL = "policyView.fields.removeUser.label";
export const T_POLICY_VIEW_FIELDS_ADD_USER_LABEL = "policyView.fields.addUser.label";
export const T_POLICY_VIEW_FIELDS_SET_USER_ICON_LABEL = "policyView.fields.setUserIcon.label";
export const T_POLICY_VIEW_FIELDS_MODIFY_ACCOUNTS_LABEL = "policyView.fields.modifyAccounts.label";
export const T_POLICY_VIEW_FIELDS_MODIFY_ACCOUNTS_TOOLTIP_LABEL_PREFIX = "policyView.fields.modifyAccounts.tooltipLabel";
export const T_POLICY_VIEW_FIELDS_MODIFY_ACCOUNTS_TOOLTIP_CONTENT_PREFIX = "policyView.fields.modifyAccounts.tooltipContent";

export const T_POLICY_VIEW_FIELDS_WALLPAPER_TITLE = "policyView.fields.wallpaper.title";
export const T_POLICY_VIEW_FIELDS_WALLPAPER_LABEL = "policyView.fields.wallpaper.label";
export const T_POLICY_VIEW_FIELDS_WALLPAPER_PLACEHOLDER = "policyView.fields.wallpaper.placeholder";

export const T_POLICY_VIEW_FIELDS_ENABLE_WALLPAPER = "policyView.fields.enableWallpaper";
export const T_POLICY_VIEW_COMPANION_APP_TOOLTIP_LABEL_PREFIX = "policyView.fields.companionapp.tooltipLabel";
export const T_POLICY_VIEW_COMPANION_APP_TOOLTIP_CONTENT_PREFIX = "policyView.fields.companionapp.tooltipContent";

export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_POLICY_PASSWORD_HISTORY_LENGTH_LABEL = "policyView.fields.passwordPolicy.passwordHistoryLength.label";
export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_POLICY_PASSWORD_HISTORY_LENGTH_PLACEHOLDER = "policyView.fields.passwordPolicy.passwordHistoryLength.placeholder";

export const T_PASSWORD_REQUIREMENTS_MODAL_COMPLIANCE_DAYS_TYPE = "passwordRequirementsModal.compliance.daysLabel";

export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_POLICY_COMPLIANCE_ACTION_BLOCK_DEVICE_LABEL = "passwordRequirementsModal.compliance.blockAfterDays.label";
export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_POLICY_COMPLIANCE_ACTION_BLOCK_DEVICE_PLACEHOLDER = "passwordRequirementsModal.compliance.blockAfterDays.placeholder";

export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_POLICY_COMPLIANCE_ACTION_WIPE_DEVICE_LABEL = "passwordRequirementsModal.compliance.wipeAfterDays.label";
export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_POLICY_COMPLIANCE_ACTION_WIPE_DEVICE_PLACEHOLDER = "passwordRequirementsModal.compliance.wipeAfterDays.placeholder";

export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_POLICY_COMPLIANCE_ACTION_PRESERVE_FRP_LABEL = "passwordRequirementsModal.compliance.wipePreserveFactoryResetData.label";

export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_POLICY_PASSWORD_MINIMUM_LENGTH_LABEL = "policyView.fields.passwordPolicy.passwordMinimumLength.label";
export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_POLICY_PASSWORD_MINIMUM_LENGTH_PLACEHOLDER = "policyView.fields.passwordPolicy.passwordMinimumLength.placeholder";

export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_POLICY_PASSWORD_MINIMUM_LETTERS_LABEL = "policyView.fields.passwordPolicy.passwordMinimumLetters.label";
export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_POLICY_PASSWORD_MINIMUM_LETTERS_PLACEHOLDER = "policyView.fields.passwordPolicy.passwordMinimumLetters.placeholder";

export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_POLICY_PASSWORD_MINIMUM_LOWER_CASE_LABEL = "policyView.fields.passwordPolicy.passwordMinimumLowerCase.label";
export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_POLICY_PASSWORD_MINIMUM_LOWER_CASE_PLACEHOLDER = "policyView.fields.passwordPolicy.passwordMinimumLowerCase.placeholder";

export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_POLICY_PASSWORD_MINIMUM_NON_LETTER_LABEL = "policyView.fields.passwordPolicy.passwordMinimumNonLetter.label";
export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_POLICY_PASSWORD_MINIMUM_NON_LETTER_PLACEHOLDER = "policyView.fields.passwordPolicy.passwordMinimumNonLetter.placeholder";

export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_POLICY_PASSWORD_MINIMUM_NUMERIC_LABEL = "policyView.fields.passwordPolicy.passwordMinimumNumeric.label";
export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_POLICY_PASSWORD_MINIMUM_NUMERIC_PLACEHOLDER = "policyView.fields.passwordPolicy.passwordMinimumNumeric.placeholder";

export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_POLICY_PASSWORD_MINIMUM_SYMBOLS_LABEL = "policyView.fields.passwordPolicy.passwordMinimumSymbols.label";
export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_POLICY_PASSWORD_MINIMUM_SYMBOLS_PLACEHOLDER = "policyView.fields.passwordPolicy.passwordMinimumSymbols.placeholder";

export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_POLICY_PASSWORD_MINIMUM_UPPER_CASE_LABEL = "policyView.fields.passwordPolicy.passwordMinimumUpperCase.label";
export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_POLICY_PASSWORD_MINIMUM_UPPER_CASE_PLACEHOLDER = "policyView.fields.passwordPolicy.passwordMinimumUpperCase.placeholder";

export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_POLICY_MAXIMUM_FAILED_PASSWORDS_FOR_WIPE_LABEL = "policyView.fields.passwordPolicy.maximumFailedPasswordsForWipe.label";
export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_POLICY_MAXIMUM_FAILED_PASSWORDS_FOR_WIPE_PLACEHOLDER =
  "policyView.fields.passwordPolicy.maximumFailedPasswordsForWipe.placeholder";

export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_POLICY_PASSWORD_EXPIRATION_TIMEOUT_LABEL = "policyView.fields.passwordPolicy.passwordExpirationTimeout.label";
export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_POLICY_PASSWORD_EXPIRATION_TIMEOUT_PLACEHOLDER =
  "policyView.fields.passwordPolicy.passwordExpirationTimeout.placeholder";

export const T_POLICY_VIEW_FIELDS_PASSWORD_POLICY_PASSWORD_SCOPE_LABEL = "policyView.fields.passwordPolicy.passwordScope.label";
export const T_POLICY_VIEW_FIELDS_PASSWORD_POLICY_PASSWORD_SCOPE_PLACEHOLDER = "policyView.fields.passwordPolicy.passwordScope.placeholder";
export const T_POLICY_VIEW_FIELDS_PASSWORD_POLICY_PASSWORD_SCOPE_PREFIX = "policy.passwordScope";
export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_SCOPE_TOOLTIP_PREFIX = "policy.passwordScopeTooltip";

export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_POLICY_REQUIRE_PASSWORD_UNLOCK_LABEL = "policyView.fields.passwordPolicy.requirePasswordUnlock.label";
export const T_PASSWORD_REQUIREMENTS_MODAL_FIELDS_PASSWORD_POLICY_REQUIRE_PASSWORD_UNLOCK_PLACEHOLDER = "policyView.fields.passwordPolicy.requirePasswordUnlock.placeholder";
export const T_POLICY_VIEW_SUBMIT_SUCCESS_CHANGES_SAVED = "policyView.submitSuccess.changesSaved";

export const T_POLICY_APP_CONFIG_KIOSK_APP_PICKER_LABEL = "policyView.kioskAppPicker.label";
export const T_POLICY_APP_CONFIG_KIOSK_APP_PICKER_PLACEHOLDER = "policyView.kioskAppPicker.placeholder";
export const T_POLICY_APP_CONFIG_KIOSK_APP_ALLOW_APPS_PLACEHOLDER = "policyAppConfig.kioskAppType.allowAppsLabel";
export const T_POLICY_APP_CONFIG_KIOSK_APP_BLOCK_APPS_PLACEHOLDER = "policyAppConfig.kioskAppType.blockAppsLabel";
export const T_POLICY_APP_CONFIG_MANAGED_TYPE_ALLOW_APPS_PLACEHOLDER = "policyAppConfig.managedType.allowAppsLabel";
export const T_POLICY_APP_CONFIG_MANAGED_TYPE_ALLOW_APPS_ITEM_PLACEHOLDER = "policyAppConfig.managedType.allowAppsItemPlaceholder";

export const T_DEVICE_VIEW_EDIT_BUTTON_LABEL = "deviceView.actions.edit";
export const T_DEVICE_VIEW_VIEW_ON_MAP_LABEL = "deviceView.actions.viewOnMap";
export const T_DEVICE_VIEW_LOCK_DEVICE_LABEL = "deviceView.actions.lockDevice";
export const T_DEVICE_VIEW_RESET_DEVICE_LABEL = "deviceView.actions.resetDevice";
export const T_DEVICE_VIEW_REBOOT_DEVICE_LABEL = "deviceView.actions.rebootDevice";
export const T_DEVICE_VIEW_VIEW_ACTIONS_LABEL = "deviceView.actions.label";
export const T_DEVICE_VIEW_RESET_PASSWORD_LABEL = "deviceView.actions.resetPassword";
export const T_DEVICE_VIEW_RESET_RELINQUISH_OWNERSHIP = "deviceView.actions.relinquishOwnership";

export const T_REQUIRE_PASSWORD_UNLOCK_PREFIX = "policy.requirePasswordUnlock";

export const T_POLICY_TYPE_PREFIX = "policy.policyType";

export const T_SYSTEM_NAVIGATION_PREFIX = "policy.systemNavigation";
export const T_STATUS_BAR_PREFIX = "policy.statusBar";

export const T_PERMISSION_POLICY_PREFIX = "policy.permissionPolicy";
export const T_SYSTEM_UPDATE_TYPE_PREFIX = "policy.systemUpdateType";

export const T_PASSWORD_QUALITY_OPTION_PREFIX = "policy.passwordQuality";
export const T_PASSWORD_SCOPE_OPTION_PREFIX = "policy.passwordScope";

export const T_TOGGLE_FIELD_LABEL_ON = "toggleField.onText";
export const T_TOGGLE_FIELD_LABEL_OFF = "toggleField.offText";

export const T_DEVICE_MAP_TITLE = "deviceMap.title";

export const T_DEVICE_MAP_CONTENT = "deviceMap.content";
export const T_DEVICE_MAP_LAST_SEEN = "deviceMap.lastSeen";
export const T_DEVICE_MAP_LAST_SEEN_NOT_AVAILABLE = "deviceMap.lastSeenNotAvailable";
export const T_DEVICE_MAP_REFRESH = "deviceMap.refresh";
export const T_DEVICE_MAP_REFRESH_MESSAGE = "deviceMap.refresh.message";
export const T_DEVICE_MAP_SEARCH_TITLE = "deviceMap.searchHistoryTitle";
export const T_DEVICE_MAP_SEARCH_FROM_DATE = "deviceMap.searchFromDate";
export const T_DEVICE_MAP_SEARCH_TO_DATE = "deviceMap.searchToDate";
export const T_DEVICE_MAP_SEARCH_FROM_DATE_PLACEHOLDER = "deviceMap.searchFromDatePlaceholder";
export const T_DEVICE_MAP_SEARCH_TO_DATE_PLACEHOLDER = "deviceMap.searchToDatePlaceholder";
export const T_DEVICE_MAP_SEARCH_BUTTON_TEST = "deviceMap.searchButtonText";

export const T_DEVICE_MAP_FOOTER_TEXT = "deviceMap.footerText";

export const T_MAP_ERROR_MESSAGE = "map.errorMessage";
export const T_DEVICE_MAP_NO_LOCATIONS_TITLE = "map.noLocations";
export const T_DEVICE_MAP_NO_LOCATIONS_CONTENT = "map.noLocationsContent";

export const T_ENROLLMENT_VIEW_TITLE = "enrollmentView.title";
export const T_ENROLLMENT_VIEW_MESSAGE = "enrollmentView.message";
export const T_ENROLLMENT_VIEW_PRESERVE_SYSTEM_APPS_DESCRIPTION = "enrollmentView.preserveSystemAppsDescription";
export const T_ENROLLMENT_VIEW_ALLOW_PERSONAL_USAGE = "enrollmentView.allowPersonalUsageDescription";
export const T_ENROLLMENT_ALLOW_PERSONAL_USAGE_TOOLTIP_LABEL_PREFIX = "enrollmentView.allowPersonalUsageTooltip.label";
export const T_ENROLLMENT_ALLOW_PERSONAL_USAGE_TOOLTIP_CONTENT_PREFIX = "enrollmentView.allowPersonalUsageTooltip.content";
export const T_ENROLLMENT_VIEW_MOBILE_DATA_DESCRIPTION = "enrollmentView.mobileDataDescription";
export const T_ENROLLMENT_VIEW_TITLE_ZERO_TOUCH = "enrollmentView.title.zeroTouch";
export const T_ENROLLMENT_VIEW_TITLE_WIFI_SETUP = "enrollmentView.title.wifiSetup";
export const T_ENROLLMENT_VIEW_TITLE_GENERAL_SETTINGS = "enrollmentView.title.generalSettingsTitle";
export const T_ENROLLMENT_VIEW_WIFI_TYPE_LABEL = "enrollmentView.wifi.typeLabel";
export const T_ENROLLMENT_VIEW_WIFI_TYPE_PLACEHOLDER = "enrollmentView.wifi.typePlaceholder";
export const T_WIFI_SECURITY_TYPE_PREFIX = "wifiSecurityType";
export const T_ENROLLMENT_VIEW_WIFI_SSID_LABEL = "enrollmentView.wifi.ssidLabel";
export const T_ENROLLMENT_VIEW_WIFI_SSID_PLACEHOLDER = "enrollmentView.wifi.ssidPlaceholder";
export const T_ENROLLMENT_VIEW_WIFI_PASSWORD_LABEL = "enrollmentView.wifi.passwordLabel";
export const T_ENROLLMENT_VIEW_WIFI_PASSWORD_PLACEHOLDER = "enrollmentView.wifi.passwordPlaceholder";
export const T_ENROLLMENT_VIEW_TITLE_TOKEN_EXPIRATION_SETTINGS = "enrollmentView.title.tokenExpirationSettings";
export const T_ENROLLMENT_VIEW_MESSAGE_ZERO_TOUCH = "enrollmentView.message.zeroTouch";
export const T_ENROLLMENT_VIEW_TITLE_QR = "enrollmentView.title.qr";
export const T_ENROLLMENT_VIEW_TITLE_WORK_PROFILE_LINK_TITLE = "enrollmentView.workProfileLink.title";
export const T_ENROLLMENT_VIEW_TITLE_WORK_PROFILE_LINK_TEXT = "enrollmentView.workProfileLink.text";
export const T_ENROLLMENT_VIEW_TITLE_WORK_PROFILE_LINK_COPY_LINK = "enrollmentView.workProfileLink.copyLink";
export const T_ENROLLMENT_VIEW_COPY_TEXT_LABEL = "enrollmentView.copyTextLabel";
export const T_ENROLLMENT_VIEW_MESSAGE_QR = "enrollmentView.message.qr";
export const T_ENROLLMENT_VIEW_SELECT_POLICY_LABEL = "enrollmentView.selectPolicyLabel";
export const T_ENROLLMENT_VIEW_SELECT_POLICY_PLACEHOLDER = "enrollmentView.selectPolicyPlaceholder";
export const T_ENROLLMENT_VIEW_ALLOW_MOBILE_DATA_LABEL = "enrollmentView.allowMobileDataLabel";
export const T_ENROLLMENT_VIEW_LEAVE_ALL_SYSTEM_APPS_ENABLED_LABEL = "enrollmentView.leaveAllSystemAppsEnabledLabel";
export const T_ENROLLMENT_VIEW_ONE_TIME_USE_ONLY_LABEL = "enrollmentView.oneTimeUseOnlyLabel";
export const T_ENROLLMENT_ALLOW_PERSONAL_USAGE_LABEL = "enrollmentView.allowPersonalUsage";
export const T_ENROLLMENT_VIEW_SUBMIT_BUTTON_LABEL = "enrollmentView.submitLabel";
export const T_ENROLLMENT_VIEW_TOKEN_EXPIRES_IN_LABEL = "enrollmentView.tokenExpiresInLabel";
export const T_ENROLLMENT_VIEW_TOKEN_EXPIRE_TIME_TYPE_PLACEHOLDER = "enrollmentView.tokenExpireTimeTypePlaceholder";
export const T_ENROLLMENT_VIEW_TITLE_TOKEN_EXPIRATION_TOOL_TIP = "enrollmentView.tokenExpireTimeToolTip";
export const T_ENROLLMENT_VIEW_TITLE_TOKEN_EXPIRATION_NOTE = "enrollmentView.tokenExpireTimeNote";
export const T_ENROLLMENT_VIEW_TITLE_TOKEN_EXPIRATION_TITLE = "enrollmentView.tokenExpireTitle";
export const T_ENROLLMENT_VIEW_TOKEN_CREATION_FAILED_PREFIX = "enrollmentView.tokenCreationFailed";
export const T_ENROLLMENT_LOCATION_TRACKING_NOT_ALLOWED = "enrollmentView.policyWithLocationTrackingNotAllowedMessage";
export const T_ENROLLMENT_VIEW_SELECT_DEVICE_GROUP_LABEL = "enrollmentView.deviceGroupLabel";
export const T_ENROLLMENT_VIEW_SELECT_DEVICE_GROUP_PLACEHOLDER = "enrollmentView.deviceGroupPlaceholder";
export const T_ENROLLMENT_VIEW_SELECT_DEVICE_USER_LABEL = "enrollmentView.deviceUserLabel";
export const T_ENROLLMENT_VIEW_SELECT_DEVICE_USER_PLACEHOLDER = "enrollmentView.deviceUserPlaceholder";
export const T_ENROLLMENT_VIEW_SELECT_DEVICE_USER_TOOLTIP = "enrollmentView.deviceUserTooltip";
export const T_ENROLLMENT_VIEW_SELECT_DEVICE_GROUP_TOOLTIP = "enrollmentView.deviceGroupTooltip";
export const T_ENROLLMENT_VIEW_TITLE_POLICY_SETTINGS = "enrollmentView.title.policySettings";
export const T_ENROLLMENT_VIEW_POLICY_SETTINGS_DESCRIPTION = "enrollmentView.policySettings.description";
export const T_ENROLLMENT_VIEW_ONE_TIME_USE_ONLY_MESSAGE = "enrollmentView.oneTimeUseOnlyMessage";
export const T_ENROLLMENT_VIEW_CANCEL_LABEL = "enrollmentView.cancelLabel";
export const T_ENROLLMENT_VIEW_PREV_LABEL = "enrollmentView.prevLabel";
export const T_ENROLLMENT_VIEW_NEXT_LABEL = "enrollmentView.nextLabel";
export const T_ENROLLMENT_VIEW_SUMMARY_TITLE = "enrollmentView.summary.title";
export const T_ENROLLMENT_VIEW_SUMMARY_SUBTITLE_POLICY = "enrollmentView.summary.subtitle.policy";
export const T_ENROLLMENT_VIEW_SUMMARY_POLICY_TITLE = "enrollmentView.summary.policy.title";
export const T_ENROLLMENT_VIEW_SUMMARY_USER_TITLE = "enrollmentView.summary.deviceUser.title";
export const T_ENROLLMENT_VIEW_SUMMARY_GROUP_TITLE = "enrollmentView.summary.deviceGroup.title";
export const T_ENROLLMENT_VIEW_SUMMARY_SUBTITLE_GENERAL = "enrollmentView.summary.subtitle.general";
export const T_ENROLLMENT_VIEW_SUMMARY_SUBTITLE_NETWORK = "enrollmentView.summary.subtitle.network";
export const T_ENROLLMENT_VIEW_TITLE_NETWORK = "enrollmentView.title.network";

export const T_DURATION_TYPE_PREFIX = "durationTypePrefix";

export const T_CHANGE_PASSWORD_FORM_BUTTON_LABEL = "changePasswordForm.buttonLabel";
export const T_CHANGE_PASSWORD_FORM_FORM_TITLE = "changePasswordForm.formTitle";
export const T_CHANGE_PASSWORD_FORM_OLD_PASSWORD_LABEL = "changePasswordForm.oldPasswordField.label";
export const T_CHANGE_PASSWORD_FORM_OLD_PASSWORD_PLACEHOLDER = "changePasswordForm.oldPasswordField.placeholder";
export const T_CHANGE_PASSWORD_FORM_PASSWORD1_LABEL = "changePasswordForm.passwordField1.label";
export const T_CHANGE_PASSWORD_FORM_PASSWORD1_PLACEHOLDER = "changePasswordForm.passwordField1.placeholder";
export const T_CHANGE_PASSWORD_FORM_PASSWORD2_LABEL = "changePasswordForm.passwordField2.label";
export const T_CHANGE_PASSWORD_FORM_PASSWORD2_PLACEHOLDER = "changePasswordForm.passwordField2.placeholder";
export const T_CHANGE_PASSWORD_FORM_PASSWORD_TOOL_TIP = "changePasswordForm.passwordToolTip";

export const T_FORGET_PASSWORD_FORM_DESCRIPTION_TEXT = "forgetPasswordForm.description";

export const T_ZERO_TOUCH_IFRAME_TITLE = "zeroTouchIFrame.title";
export const T_ZERO_TOUCH_IFRAME_SUB_TITLE = "zeroTouchIFrame.subTitle";
export const T_ZERO_TOUCH_IFRAME_DESCRIPTION = "zeroTouchIFrame.description";

export const T_APP_SELECT_IFRAME_TITLE = "appSelectIFrame.title";
export const T_APP_SELECT_IFRAME_DESCRIPTION = "appSelectIFrame.description";
export const T_APP_SELECT_IFRAME_PACKAGE_NAME_FORM_FIELD_LABEL = "appSelectIFrame.packageName.fieldLabel";
export const T_APP_SELECT_IFRAME_PACKAGE_NAME_FORM_FIELD_PLACEHOLDER = "appSelectIFrame.packageName.fieldPlaceholder";
export const T_APP_SELECT_IFRAME_PACKAGE_NAME_FORM_BUTTON_LABEL = "appSelectIFrame.packageName.buttonLabel";

export const T_PROFILE_MENU_SIGN_OUT_LABEL = "profileMenu.signOutLabel";
export const T_PROFILE_MENU_ACCOUNT_LABEL = "profileMenu.accountLabel";
export const T_PROFILE_MENU_SELECT_ENTERPRISE = "profileMenu.selectEnterprise";

export const T_NOT_FOUND_VIEW_TITLE = "notFoundView.title";
export const T_NOT_FOUND_VIEW_CONTENT = "notFoundView.content";
export const T_NOT_FOUND_VIEW_AUTHORIZED_BACKLINK = "notFoundView.authorizedBacklink";
export const T_NOT_FOUND_VIEW_UNAUTHORIZED_BACKLINK = "notFoundView.unauthorizedBacklink";

export const T_EMAIL_FIELD_VALIDATION_MESSAGE = "emailField.validationMessage";
export const T_EMAIL_NOT_AVAILABLE_MESSAGE = "emailField.emailNotAvailable";

export const T_CONFIRM_MODAL_DEVICE_COUNT_CONTENT = "confirmModal.deviceCountContent";

export const T_DEVICE_MESSAGE_MODAL_TITLE = "deviceMessageModal.title";
export const T_DEVICE_MESSAGE_MODAL_CONTENT = "deviceMessageModal.content";
export const T_DEVICE_MESSAGE_MODAL_ACCEPT_LABEL = "deviceMessageModal.acceptLabel";
export const T_DEVICE_MESSAGE_MODAL_CANCEL_LABEL = "deviceMessageModal.cancelLabel";
export const T_DEVICE_MESSAGE_MODAL_NO_IMEI_DEVICE_TEXT = "deviceMessageModal.noImeiText";
export const T_DEVICE_MESSAGE_MODAL_ERROR_MESSAGE = "deviceMessageModal.errorMessage";
export const T_DEVICE_MESSAGE_MODAL_TITLE_LABEL = "deviceMessageModal.titleLabel";
export const T_DEVICE_MESSAGE_MODAL_MESSAGE_LABEL = "deviceMessageModal.messageLabel";
export const T_DEVICE_MESSAGE_MODAL_PHONE_LABEL = "deviceMessageModal.phoneLabel";
export const T_DEVICE_MESSAGE_MODAL_EMAIL_LABEL = "deviceMessageModal.emailLabel";
export const T_DEVICE_MESSAGE_MODAL_NOT_ENABLED_INFO = "deviceMessageModal.notEnabled.info";

export const T_DEVICE_LIST_MESSAGE_MODAL_TITLE = "deviceListMessageModal.title";
export const T_DEVICE_LIST_MESSAGE_MODAL_CONTENT = "deviceListMessageModal.content";
export const T_DEVICE_LIST_MESSAGE_MODAL_ACCEPT_LABEL = "deviceListMessageModal.acceptLabel";
export const T_DEVICE_LIST_MESSAGE_MODAL_CANCEL_LABEL = "deviceListMessageModal.cancelLabel";
export const T_DEVICE_LIST_MESSAGE_MODAL_ERROR_MESSAGE = "deviceListMessageModal.errorMessage";
export const T_DEVICE_LIST_MESSAGE_MODAL_SUCCESS_MESSAGE = "deviceListMessageModal.successMessage";
export const T_DEVICE_LIST_MESSAGE_MODAL_TITLE_LABEL = "deviceListMessageModal.titleLabel";
export const T_DEVICE_LIST_MESSAGE_MODAL_MESSAGE_LABEL = "deviceListMessageModal.messageLabel";
export const T_DEVICE_LIST_MESSAGE_MODAL_ERROR_MESSAGE_WITH_HIGHLIGHTS = "deviceListMessageModal.errorMessageWithHighlights";

export const T_DEVICE_GROUP_MESSAGE_MODAL_TITLE = "deviceGroupMessageModal.title";
export const T_DEVICE_GROUP_MESSAGE_MODAL_CONTENT = "deviceGroupMessageModal.content";
export const T_DEVICE_GROUP_MESSAGE_MODAL_ACCEPT_LABEL = "deviceGroupMessageModal.acceptLabel";
export const T_DEVICE_GROUP_MESSAGE_MODAL_CANCEL_LABEL = "deviceGroupMessageModal.cancelLabel";
export const T_DEVICE_GROUP_MESSAGE_MODAL_ERROR_MESSAGE = "deviceGroupMessageModal.errorMessage";
export const T_DEVICE_GROUP_MESSAGE_MODAL_SUCCESS_MESSAGE = "deviceGroupMessageModal.successMessage";
export const T_DEVICE_GROUP_MESSAGE_MODAL_TITLE_LABEL = "deviceGroupMessageModal.titleLabel";
export const T_DEVICE_GROUP_MESSAGE_MODAL_MESSAGE_LABEL = "deviceGroupMessageModal.messageLabel";

export const T_NAV_VIDEOS = "nav.videos";
export const T_OPEN_USERGUIDE = "open.userguide";
export const T_OPEN_FAQ = "open.FAQ";
export const T_OPEN_CONTACT_US = "open.contactUs";
export const T_NAV_HELP = "nav.help";

export const T_VIDEOS_VIEW_BACK_BUTTON_LABEL = "videosView.backButtonLabel";
export const T_VIDEOS_VIEW_LINK_YOUTUBE_TEXT = "videosView.linkToYoutubeText";
export const T_ERROR_YOUTUBE_PLAYLIST_PAGE_LOAD = "errors.youtubeplaylistPageLoadFailed";
export const T_ERROR_YOUTUBE_PLAYLIST_PAGE_LOAD_CONTENT = "errors.youtubeplaylistPageLoadFailedMessage";
export const T_DEVICE_GROUP_MESSAGE_MODAL_PHONE_LABEL = "deviceGroupMessageModal.phoneLabel";
export const T_DEVICE_GROUP_MESSAGE_MODAL_EMAIL_LABEL = "deviceGroupMessageModal.emailLabel";

export const T_USER_LIST_VIEW_NO_FOUND_USERS_TITLE = "userListView.noUsers";
export const T_USER_LIST_VIEW_NO_FOUND_USERS_CONTENT = "userListView.noFoundMessage";
export const T_ENTERPRISE_LIST_VIEW_NO_FOUND_ENTERPRISES = "enterpriseListView.noFoundMessage";
export const T_POLICY_LIST_VIEW_LOADFAIL_TITLE = "policyListView.loadError";
export const T_POLICY_LIST_VIEW_LOADFAIL_MESSAGE = "policyListView.loadErrorMessage";

export const T_IMPORT_USERS_MODAL_TITLE = "importUsersModal.title";
export const T_IMPORT_USERS_MODAL_CONTENT = "importUsersModal.content";
export const T_IMPORT_USERS_MODAL_CANCEL_LABEL = "importUsersModal.cancelLabel";
export const T_IMPORT_USERS_MODAL_ACCEPT_LABEL = "importUsersModal.acceptLabel";
export const T_IMPORT_USERS_MODAL_SELECT_FILE_LABEL = "importUsersModal.selectFileLabel";
export const T_IMPORT_USERS_MODAL_ROLE_LABEL = "importUsersModal.roleLabel";
export const T_IMPORT_USERS_MODAL_ROLE_PLACEHOLDER = "importUsersModal.rolePlaceholder";
export const T_IMPORT_USERS_MODAL_SELECT_ENTERPRISE_LABEL = "importUsersModal.selectEnterpriseLabel";
export const T_IMPORT_USERS_MODAL_SUCCESS_MESSAGE = "importUsersModal.successMessage";
export const T_IMPORT_USERS_MODAL_ERROR_MESSAGE = "importUsersModal.errorMessage";

export const T_TFA_VIEW_TITLE = "tfaView.title";
export const T_TFA_VIEW_SUBTITLE = "tfaView.subtitle";
export const T_TFA_VIEW_VERIFICATION_CODE_LABEL = "tfaView.verificationCode.label";
export const T_TFA_REGISTER_CODE_ERROR = "tfaView.registerCode.error";
export const T_TFA_VERIFY_CODE_ERROR = "tfaView.verifyCode.error";
export const T_TFA_REGISTER_CODE_INGRESS = "tfaView.registerCode.ingress";

export const T_RESET_TFA_MODAL_TITLE = "resetTfaModal.title";
export const T_RESET_TFA_MODAL_CONTENT = "resetTfaModal.content";
export const T_RESET_TFA_MODAL_CANCEL_LABEL = "resetTfaModal.cancelLabel";
export const T_RESET_TFA_MODAL_RESET_LABEL = "resetTfaModal.resetLabel";

export const T_DEVICE_USER_LIST_VIEW_TITLE = "deviceUserListView.title";
export const T_DEVICE_USER_LIST_VIEW_ADD_USER = "deviceUserListView.addUser";

export const T_DEVICE_USER_LIST_VIEW_IMPORT_USERS = "deviceUserListView.importUsers";

export const T_ADD_DEVICE_USER_MODAL_TITLE = "addDeviceUserModal.title";
export const T_ADD_DEVICE_USER_MODAL_SUCCESS_MESSAGE = "addDeviceUserModal.successMessage";
export const T_ADD_DEVICE_USER_MODAL_SUBMIT_ERROR = "addDeviceUserModal.submitError";
export const T_ADD_DEVICE_USER_MODAL_CANCEL_LABEL = "addDeviceUserModal.cancelLabel";
export const T_ADD_DEVICE_USER_MODAL_SAVE_LABEL = "addDeviceUserModal.saveLabel";
export const T_ADD_DEVICE_USER_MODAL_FIRST_NAME_LABEL = "addDeviceUserModal.firstName.label";
export const T_ADD_DEVICE_USER_MODAL_FIRST_NAME_PLACEHOLDER = "addDeviceUserModal.firstName.placeholder";
export const T_ADD_DEVICE_USER_MODAL_LAST_NAME_LABEL = "addDeviceUserModal.lastName.label";
export const T_ADD_DEVICE_USER_MODAL_LAST_NAME_PLACEHOLDER = "addDeviceUserModal.lastName.placeholder";
export const T_ADD_DEVICE_USER_MODAL_EMAIL_LABEL = "addDeviceUserModal.email.label";
export const T_ADD_DEVICE_USER_MODAL_EMAIL_PLACEHOLDER = "addDeviceUserModal.email.placeholder";

export const T_IMPORT_DEVICE_USERS_MODAL_ACCEPT_LABEL = "importDeviceUsersModal.acceptLabel";
export const T_IMPORT_DEVICE_USERS_MODAL_CANCEL_LABEL = "importDeviceUsersModal.cancelLabel";
export const T_IMPORT_DEVICE_USERS_MODAL_CONTENT = "importDeviceUsersModal.content";
export const T_IMPORT_DEVICE_USERS_MODAL_ERROR_MESSAGE = "importDeviceUsersModal.errorMessage";
export const T_IMPORT_DEVICE_USERS_MODAL_SELECT_FILE_LABEL = "importDeviceUsersModal.selectFileLabel";
export const T_IMPORT_DEVICE_USERS_MODAL_SUCCESS_MESSAGE = "importDeviceUsersModal.successMessage";
export const T_IMPORT_DEVICE_USERS_MODAL_TITLE = "importDeviceUsersModal.title";
export const T_IMPORT_DEVICE_USERS_MODAL_SELECT_POLICY_LABEL = "importDeviceUsersModal.selectPolicyLabel";

export const T_APPLICATIONS_VIEW_CONFIGURE_APPS = "applicationsView.configureApps";

export const T_CONFIGURE_APPS_MODAL_TITLE = "configureAppsModal.title";
export const T_CONFIGURE_APPS_MODAL_SUMMARY = "configureAppsModal.summary";
export const T_CONFIGURE_APPS_MODAL_PACKAGE_NAME_LABEL = "configureAppsModal.packageName.label";
export const T_CONFIGURE_APPS_MODAL_CONFIG_KEY_LABEL = "configureAppsModal.configKey.label";
export const T_CONFIGURE_APPS_MODAL_CONFIG_VALUE_LABEL = "configureAppsModal.configValue.label";
export const T_CONFIGURE_APPS_MODAL_NO_VALUES = "configureAppsModal.noValues";
export const T_CONFIGURE_APPS_MODAL_ADD_BUTTON_LABEL = "configureAppsModal.addButton.label";
export const T_CONFIGURE_APPS_MODAL_CANCEL_LABEL = "configureAppsModal.cancelLabel";
export const T_CONFIGURE_APPS_MODAL_ACCEPT_LABEL = "configureAppsModal.acceptLabel";

export const T_ENTERPRISE_APP_CONFIGURATION_MODAL_CANCEL_LABEL = "enterpriseAppConfigurationModal.cancelLabel";
export const T_ENTERPRISE_APP_CONFIGURATION_MODAL_KEY_PLACEHOLDER = "enterpriseAppConfigurationModal.key.placeholder";
export const T_ENTERPRISE_APP_CONFIGURATION_MODAL_KEY_TITLE = "enterpriseAppConfigurationModal.key.title";
export const T_ENTERPRISE_APP_CONFIGURATION_MODAL_PACKAGE_NAME_PLACEHOLDER = "enterpriseAppConfigurationModal.packageName.placeholder";
export const T_ENTERPRISE_APP_CONFIGURATION_MODAL_PACKAGE_NAME_TITLE = "enterpriseAppConfigurationModal.packageName.title";
export const T_ENTERPRISE_APP_CONFIGURATION_MODAL_SAVE_LABEL = "enterpriseAppConfigurationModal.saveLabel";
export const T_ENTERPRISE_APP_CONFIGURATION_MODAL_SUMMARY_CONTENT = "enterpriseAppConfigurationModal.summary.content";
export const T_ENTERPRISE_APP_CONFIGURATION_MODAL_TITLE = "enterpriseAppConfigurationModal.title";
export const T_ENTERPRISE_APP_CONFIGURATION_MODAL_VALUE_PLACEHOLDER = "enterpriseAppConfigurationModal.value.placeholder";
export const T_ENTERPRISE_APP_CONFIGURATION_MODAL_VALUE_TITLE = "enterpriseAppConfigurationModal.value.title";
export const T_POLICY_TABLE_COLUMN_CREATED_ON = "policyTable.column.createdOn";
export const T_POLICY_TABLE_COLUMN_UPDATED_ON = "policyTable.column.updatedOnTime";

export const T_POLICY_VIEW_FIELDS_PERMISSION_POLICY_TITLE = "policyView.fields.permissionPolicy.title";
export const T_POLICY_VIEW_PERMISSION_POLICY_TOOLTIP_LABEL_PREFIX = "policyView.permissionPolicy.tooltipLabel";
export const T_POLICY_VIEW_PERMISSION_POLICY_TOOLTIP_CONTENT_PREFIX = "policyView.permissionPolicy.tooltipContent";
export const T_POLICY_VIEW_FIELDS_APPLICATIONS_TITLE = "policyView.fields.applications.title";
export const T_POLICY_VIEW_APPLICATIONS_TOOLTIP_LABEL_PREFIX = "policyView.applications.tooltipLabel";
export const T_POLICY_VIEW_APPLICATIONS_TOOLTIP_CONTENT_PREFIX = "policyView.applications.tooltipContent";

export const T_POLICY_VIEW_FIELDS_PLAY_STORE_TITLE = "policyView.fields.playStore.title";
export const T_POLICY_VIEW_FIELDS_PLAY_STORE_MODE_LABEL = "policyView.playStoreModeLabel";
export const T_POLICY_VIEW_PLAYSTORE_TOOLTIP_LABEL_PREFIX = "policyView.playStore.tooltipLabel";
export const T_POLICY_VIEW_PLAYSTORE_TOOLTIP_CONTENT_PREFIX = "policyView.playStore.tooltipContent";

export const T_APPLY_PERMISSION_TO_APPS_MODAL_TITLE = "policyView.fields.permissionToAppsModal.title";
export const T_APPLY_PERMISSION_TO_APPS_MODAL_CONTENT = "policyView.fields.permissionToAppsModal.content";
export const T_APPLY_PERMISSION_TO_APPS_MODAL_EXPLAIN = "policyView.fields.permissionToAppsModal.explain";
export const T_APPLY_PERMISSION_TO_APPS_MODAL_ACCEPT_LABEL = "policyView.fields.permissionToAppsModal.accept";
export const T_APPLY_PERMISSION_TO_APPS_MODAL_CANCEL_LABEL = "policyView.fields.permissionToAppsModal.cancel";
export const T_APPLY_PERMISSION_TO_APPS_MODAL_POLICY_TYPE_UPDATED = "policyView.fields.permissionToAppsModal.policyTypeUpdated";

export const T_ADD_DEVICE_USER_MODAL_POLICY_LABEL = "addDeviceUserModal.policyLabel";
export const T_ADD_DEVICE_USER_MODAL_POLICY_PLACEHOLDER = "addDeviceUserModal.policyPlaceholder";

export const T_DEVICE_VIEW_LABEL_DEVICE_USER = "deviceView.label.deviceUser";
export const T_ENTERPRISE_MODAL_COLOR_MESSAGE = "enterpriseModal.colorMessage";
export const T_ENTERPRISE_VIEW_USERS_TITLE = "enterpriseView.users.title";
export const T_ENTERPRISE_VIEW_USERS_CONTENT = "enterpriseView.users.content";
export const T_ENTERPRISE_VIEW_USERS_TABLE_HEADER_NAME = "enterpriseView.users.tableHeader.name";
export const T_ENTERPRISE_VIEW_ADD_USER_BUTTON_LABEL = "enterpriseView.users.addUserButton.label";
export const T_ENTERPRISE_VIEW_INVITE_USER_BUTTON_LABEL = "enterpriseView.users.inviteUserButton.label";
export const T_ENTERPRISE_VIEW_USERS_TABLE_HEADER_LAST_LOGIN = "enterpriseView.users.tableHeader.lastLogin";
export const T_ENTERPRISE_VIEW_REMOVE_USER_TITLE = "enterpriseView.users.removeUser.title";
export const T_ENTERPRISE_VIEW_REMOVE_USER_CONTENT = "enterpriseView.users.removeUser.content";
export const T_ENTERPRISE_VIEW_REMOVE_USER_ACCEPT_LABEL = "enterpriseView.users.removeUser.acceptLabel";
export const T_ENTERPRISE_VIEW_REMOVE_USER_CANCEL_LABEL = "enterpriseView.users.removeUser.cancelLabel";

export const T_ADD_USERS_TO_ENTERPRISE_MODAL_CANCEL_LABEL = "addUsersToEnterpriseModal.cancelLabel";
export const T_ADD_USERS_TO_ENTERPRISE_MODAL_SAVE_LABEL = "addUsersToEnterpriseModal.saveLabel";
export const T_ADD_USERS_TO_ENTERPRISE_MODAL_TITLE = "addUsersToEnterpriseModal.title";
export const T_ADD_USERS_TO_ENTERPRISE_MODAL_USERS_LABEL = "addUsersToEnterpriseModal.usersLabel";
export const T_ADD_USERS_TO_ENTERPRISE_MODAL_USERS_PLACEHOLDER = "addUsersToEnterpriseModal.usersPlaceholder";
export const T_ADD_USERS_TO_ENTERPRISE_MODAL_SUBMIT_ERROR_MESSAGE = "addUsersToEnterpriseModal.submitErrorMessage";
export const T_POLICY_VIEW_FIELDS_SECTION_TITLE_SYSTEM = "policyView.deviceSettings.expandSection.system";

export const T_UNTRUSTED_APPS_POLICY_PREFIX = "untrustedAppsPolicyPrefix";

export const T_POLICY_VIEW_FIELDS_VPN_TITLE = "policyView.fields.vpn.title";
export const T_POLICY_VIEW_FIELDS_UNTRUSTED_APPS_POLICY_TITLE = "policyView.fields.untrustedAppsPolicy.title";
export const T_DEVICE_GROUPS_LIST_VIEW_SEARCH_LABEL = "deviceGroupsListView.searchLabel";
export const T_DEVICE_GROUPS_LIST_VIEW_SEARCH_PLACEHOLDER = "deviceGroupsListView.searchPlaceholder";

export const T_DEVICE_GROUP_VIEW_SEARCH_LABEL = "deviceGroupView.searchLabel";
export const T_DEVICE_GROUP_VIEW_SEARCH_PLACEHOLDER = "deviceGroupView.searchPlaceholder";
export const T_ADD_ENTERPRISE_MODAL_INVITE_USERS_ERROR = "addEnterpriseModal.inviteUsersError";
export const T_ADD_ENTERPRISE_MODAL_INVITE_ADMIN_USER_ERROR = "addEnterpriseModal.inviteAdminUserError";

export const T_DEVICE_GROUP_TABLE_NO_FOUND_DEVICE_GROUPS_CONTENT = "deviceGroupTable.noFoundDeviceGroups.content";

export const T_NEW_POLICY_MODAL_WORK_PROFILE_SUB_TITLE = "newPolicyModal.workProfile.title";
export const T_NEW_POLICY_MODAL_CROSS_PROFILE_SUB_TITLE = "newPolicyModal.workProfile.crossProfile.title";
export const T_NEW_POLICY_MODAL_PERSONAL_USAGE_SUB_TITLE = "newPolicyModal.workProfile.personalUsage.title";
export const T_NEW_POLICY_MODAL_SHOW_WORK_CONTACTS_LABEL = "newPolicyModal.workProfile.showWorkContacts.label";
export const T_NEW_POLICY_MODAL_CROSS_PROFILE_COPY_PASTE_LABEL = "newPolicyModal.workProfile.crossProfileCopyPaste.label";
export const T_NEW_POLICY_MODAL_CROSS_PROFILE_DATA_SHARING_LABEL = "newPolicyModal.workProfile.crossProfileDataSharing.label";
export const T_NEW_POLICY_MODAL_CROSS_PROFILE_DATA_SHARING_PLACEHOLDER = "newPolicyModal.workProfile.crossProfileDataSharing.placeholder";
export const T_CROSS_PROFILE_DATA_SHARING_PREFIX = "crossProfileDataSharingPrefix";
export const T_NEW_POLICY_MODAL_PERSONAL_USAGE_CONTENT = "newPolicyModal.workProfile.personalUsage.content";
export const T_NEW_POLICY_MODAL_PERSONAL_USAGE_ENABLE_CAMERA_LABEL = "newPolicyModal.workProfile.personalUsage.enableCameraLabel";
export const T_NEW_POLICY_MODAL_PERSONAL_USAGE_ENABLE_SCREEN_CAPTURE_LABEL = "newPolicyModal.workProfile.personalUsage.enableScreenCaptureLabel";
export const T_NEW_POLICY_MODAL_PERSONAL_USAGE_MAX_WORK_OFF_LABEL = "newPolicyModal.workProfile.personalUsage.maxWorkOffLabel";
export const T_NEW_POLICY_MODAL_PERSONAL_USAGE_MAX_WORK_OFF_PLACEHOLDER = "newPolicyModal.workProfile.personalUsage.maxWorkOffPlaceholder";
export const T_NEW_POLICY_MODAL_PERSONAL_USAGE_MAX_WORK_OFF_PREFIX = "newPolicyModal.workProfile.personalUsage.maxWorkOffPrefix";
export const T_NEW_POLICY_MODAL_APPLICATIONS_WORK_PROFILE_TITLE = "newPolicyModal.workProfile.applications.workProfileTitle";
export const T_NEW_POLICY_MODAL_APPLICATIONS_PERSONAL_PROFILE_TITLE = "newPolicyModal.workProfile.applications.personalProfileTitle";
export const T_POLICY_VIEW_FIELDS_PERSONAL_PLAY_STORE_MODE_LABEL = "policyView.fields.personalPlayStoreMode.label";
export const T_DEVICE_VIEW_DEVICE_SECTION_TOTAL_INTERNAL_STORAGE_USED = "deviceView.deviceSection.totalInternalStorageUsed";

export const T_DEVICE_TABLE_COLUMN_DEVICE_USER_NAME = "deviceTable.column.deviceUserName";

export const T_DEVICE_USER_VIEW_TITLE = "deviceUserView.title";

export const T_DEVICE_USER_VIEW_SAVE_LABEL = "deviceUserView.saveLabel";

export const T_DEVICE_USER_VIEW_BACK_BUTTON_LABEL = "deviceUserView.backButton.label";
export const T_DEVICE_USER_VIEW_LOADFAIL_TITLE = "deviceUserView.loadFail.title";
export const T_DEVICE_USER_VIEW_LOADFAIL_MESSAGE = "deviceUserView.loadFail.message";
export const T_DEVICE_USER_VIEW_ERROR_PREFIX = "deviceUserView.errors";
export const T_DEVICE_USER_VIEW_FETCH_RETRY_LABEL = "deviceUserView.fetchRetry.label";
export const T_DEVICE_USER_VIEW_ACCESS_DENIED_ERROR_MESSAGE = "deviceUserView.accessDeniedErrorMessage";
export const T_DEVICE_USER_VIEW_SUCCESS_MESSAGE = "deviceUserView.successMessage";
export const T_DEVICE_USER_VIEW_SUBMIT_ERROR = "deviceUserView.submitError";
export const T_DEVICE_USER_VIEW_DETAILS_TITLE = "deviceUserView.details.title";
export const T_DEVICE_USER_VIEW_MANAGED_CONF_TITLE = "deviceUserView.managedConf.title";
export const T_DEVICE_USER_VIEW_REFRESH_DEVICE_LABEL = "deviceUserView.refresh.label";
export const T_DEVICE_USER_VIEW_CONFLICT_ERROR = "deviceUserView.conflictError";

export const T_DEVICE_USER_VIEW_APPLICATION_CONFIGURATIONS_PACKAGE_NAME_LABEL = "deviceUserView.applicationConfigurations.packageNameLabel";
export const T_DEVICE_USER_VIEW_APPLICATION_CONFIGURATIONS_CONFIG_KEY_LABEL = "deviceUserView.applicationConfigurations.configKeyLabel";
export const T_DEVICE_USER_VIEW_APPLICATION_CONFIGURATIONS_CONFIG_VALUE_LABEL = "deviceUserView.applicationConfigurations.configValueLabel";
export const T_DEVICE_USER_VIEW_APPLICATION_CONFIGURATIONS_NO_VALUES = "deviceUserView.applicationConfigurations.noValues";
export const T_DEVICE_USER_VIEW_APPLICATION_CONFIGURATIONS_ADD_BUTTON_LABEL = "deviceUserView.applicationConfigurations.addButtonLabel";

export const T_ADD_DEVICE_USER_MODAL_USERNAME_LABEL = "addDeviceUserModal.usernameLabel";
export const T_ADD_DEVICE_USER_MODAL_USERNAME_PLACEHOLDER = "addDeviceUserModal.usernamePlaceholder";
export const T_ADD_DEVICE_USER_MODAL_DEPARTMENT_LABEL = "addDeviceUserModal.departmentLabel";
export const T_ADD_DEVICE_USER_MODAL_DEPARTMENT_PLACEHOLDER = "addDeviceUserModal.departmentPlaceholder";
export const T_ADD_DEVICE_USER_MODAL_COUNTRY_LABEL = "addDeviceUserModal.countryLabel";
export const T_ADD_DEVICE_USER_MODAL_COUNTRY_PLACEHOLDER = "addDeviceUserModal.countryPlaceholder";

export const T_USER_MANAGED_APP_CONFIGURATION_MODAL_TITLE = "userManagedAppConfigurationModal.title";
export const T_USER_MANAGED_APP_CONFIGURATION_MODAL_SUMMARY_CONTENT = "userManagedAppConfigurationModal.summaryContent";

export const T_DEVICE_USER_DELETE_CONFIG_MODAL_TITLE = "deviceUserDeleteConfigModal.title";
export const T_DEVICE_USER_DELETE_CONFIG_MODAL_CONTENT = "deviceUserDeleteConfigModal.content";
export const T_DEVICE_USER_DELETE_CONFIG_MODAL_ACCEPT_LABEL = "deviceUserDeleteConfigModal.acceptLabel";
export const T_DEVICE_USER_DELETE_CONFIG_MODAL_CANCEL_LABEL = "deviceUserDeleteConfigModal.cancelLabel";
export const T_DEVICE_USER_VIEW_DEVICES_TITLE = "deviceUserView.devices.title";
export const T_DEVICE_USER_VIEW_NO_DEVICES = "deviceUserView.noDevices";
export const T_POLICY_VIEW_WORK_PROFILE_TITLE = "policyView.workProfile.title";
export const T_POLICY_VIEW_WORK_PROFILE_CONTENT = "policyView.workProfile.content";
export const T_POLICY_VIEW_FIELDS_CROSS_PROFILE_TITLE = "policyView.fields.crossProfile.title";
export const T_POLICY_VIEW_FIELDS_PERSONAL_USAGE_TITLE = "policyView.fields.personalUsage.title";
export const T_APPLICATION_POLICY_MODAL_CONNECTED_WORK_PERSONAL_APP_LABEL = "applicationPolicyModal.connectedWorkPersonalApp.label";
export const T_APPLICATION_POLICY_MODAL_CONNECTED_WORK_PERSONAL_APP_SUMMARY = "applicationPolicyModal.connectedWorkPersonalApp.summary";
export const T_NEW_POLICY_MODAL_PERSONAL_APPLICATIONS_CONTENT = "newPolicyModal.workProfile.applications.workProfileContent";
export const T_NEW_POLICY_MODAL_APPLICATIONS_PERSONAL_PROFILE_TOOLTIP_LABEL_PREFIX = "newPolicyModal.personalProfile.tooltip.label";
export const T_NEW_POLICY_MODAL_APPLICATIONS_PERSONAL_PROFILE_TOOLTIP_CONTENT_PREFIX = "newPolicyModal.personalProfile.tooltip.content";
export const T_APPLICATION_POLICY_MODAL_CONNECTED_WORK_PERSONAL_APP_TOOLTIP_LABEL_PREFIX = "applicationPolicyModal.tooltip.label";
export const T_APPLICATION_POLICY_MODAL_CONNECTED_WORK_PERSONAL_APP_TOOLTIP_CONTENT_PREFIX = "applicationPolicyModal.tooltip.content";

export const T_DEVICE_MAP_TIMESTAMPS_TITLE = "deviceMap.timestamps.title";
export const T_DEVICE_MAP_TIMESTAMPS_SHOW_LABEL = "deviceMap.timestamps.showLabel";
export const T_DEVICE_MAP_TIMESTAMPS_HIDE_LABEL = "deviceMap.timestamps.hideLabel";
export const T_DEVICE_GROUP_VIEW_ASYNC_STARTED = "deviceGroupView.async.started";

export const T_DEVICE_USER_TABLE_COLUMN_PREFIX = "deviceUserTable.column.";
export const T_DEVICE_USER_TABLE_NAME_LABEL = "deviceUserTable.column.name";
export const T_DEVICE_USER_TABLE_CREATED_ON_LABEL = "deviceUserTable.column.createdOn";
export const T_DEVICE_USER_TABLE_UPDATED_ON_LABEL = "deviceUserTable.column.updatedOn";
export const T_DEVICE_USER_TABLE_COLUMN_TITLE_PREFIX = "deviceUserTable.columnTitle.";

export const T_DEVICE_USER_LIST_VIEW_NO_FOUND_USERS_TITLE = "deviceUserListView.noUsers";
export const T_DEVICE_USER_LIST_VIEW_NO_FOUND_USERS_CONTENT = "deviceUserListView.noFoundMessage";

export const T_DEVICE_USER_LIST_VIEW_ACTION_MENU_DELETE_USER_LABEL = "deviceUserListView.actions.deleteUser";
export const T_DEVICE_USER_LIST_VIEW_DELETE_USER_SUCCESS_MESSAGE = "deviceUserListView.deleteUserSuccess";
export const T_DEVICE_USER_LIST_VIEW_ACCESS_DENIED_ERROR_MESSAGE = "deviceUserListView.accessDeniedError";

export const T_DEVICE_USER_LIST_VIEW_REFRESH_PAGE = "deviceUserListView.refreshLabel";
export const T_DEVICE_USER_LIST_VIEW_SEARCH_LABEL = "deviceUserListView.searchLabel";
export const T_DEVICE_USER_LIST_VIEW_SEARCH_PLACEHOLDER = "deviceUserListView.searchPlaceholder";
export const T_DEVICE_USER_LIST_VIEW_TOTAL_USERS_LABEL = "deviceUserListView.totalUsers";
export const T_DEVICE_USER_LIST_VIEW_LOAD_ERROR_TITLE = "deviceUserListView.loadError";
export const T_DEVICE_USER_LIST_VIEW_LOAD_NO_USERS_ERROR_TITLE = "deviceUserListView.noUsers";
export const T_DEVICE_USER_LIST_VIEW_LOAD_NO_USERS_ERROR_MESSAGE = "deviceUserListView.noUsersErrorMessage";
export const T_DEVICE_USER_LIST_VIEW_LOAD_ERROR_MESSAGE = "deviceUserListView.loadErrorMessage";
export const T_DEVICE_USER_LIST_VIEW_EDIT_USER = "deviceUserListView.editUser";

export const T_POLICY_VIEW_FIELDS_NETWORK_ESCAPE_HATCH_ENABLED = "policyView.fields.deviceSettings.networkEscapeHatchEnabled";
export const T_POLICY_VIEW_FIELDS_NETWORK_ESCAPE_HATCH_ENABLED_MESSAGE = "policyView.fields.deviceSettings.networkEscapeHatchEnabled.message";
export const T_POLICY_VIEW_FIELDS_BLOCK_SMS_LABEL = "policyView.fields.BlockSms";
export const T_POLICY_VIEW_FIELDS_BLOCK_OUTGOING_CALLS_LABEL = "policyView.fields.BlockOutgoingCalls";

export const T_CSV_EXPORT_MODAL_ALL = "csvExportModal.allButtonLabel";
export const T_CSV_EXPORT_MODAL_FILTERED = "csvExportModal.filteredButtonLabel";
export const T_CSV_EXPORT_MODAL_TITLE = "csvExportModal.title";
export const T_CSV_EXPORT_MODAL_CONTENT = "csvExportModal.contentLabel";

export const T_DEVICE_LIST_VIEW_ACTION_MENU_REMOTE_MGMT_LABEL = "deviceTable.actionMenu.remoteMgmt";
export const T_DEVICE_LIST_VIEW_ACTION_MENU_REMOTE_MGMT_NOT_ENABLED_INFO = "deviceTable.actionMenu.remoteMgmt.notEnabled.info";
export const T_DEVICE_VIEW_REMOTE_MGMT_LABEL = "deviceView.actions.remoteMgmt";
export const T_DEVICE_VIEW_REMOTE_MGMT_NOT_ENABLED_INFO = "deviceView.actions.remoteMgmt.notEnabled.info";

export const T_ADD_GEOFENCE_MODAL_TITLE = "addGeofenceModal.title";
export const T_ADD_GEOFENCE_MODAL_CONTENT = "addGeofenceModal.content";
export const T_POLICY_VIEW_FIELDS_GEOFENCING_TITLE = "policyView.fields.geofencing.title";
export const T_POLICY_VIEW_FIELDS_GEOFENCING_LABEL = "policyView.fields.geofencing.label";
export const T_ADD_GEOFENCE_MODAL_RADIUS_LABEL = "addGeofenceModal.radius.label";
export const T_ADD_GEOFENCE_MODAL_DEVICE_ACTION_TITLE = "addGeofenceModal.deviceAction.title";
export const T_ADD_GEOFENCE_MODAL_DEVICE_ACTION_LABEL = "addGeofenceModal.deviceAction.label";
export const T_DEVICE_GEOFENCE_ACTION_PREFIX = "deviceGeofenceActionPrefix";
export const T_DEVICE_GEOFENCE_TRIGGER_PREFIX = "deviceGeofenceTriggerPrefix";
export const T_ADD_GEOFENCE_MODAL_DEVICE_TRIGGER_LABEL = "addGeofenceModal.deviceTrigger.label";
export const T_ADD_GEOFENCE_MODAL_CANCEL_LABEL = "addGeofenceModal.cancelLabel";
export const T_ADD_GEOFENCE_MODAL_SAVE_LABEL = "addGeofenceModal.saveLabel";
export const T_ADD_GEOFENCE_MODAL_MESSAGE_TITLE_LABEL = "addGeofenceModal.message.title";
export const T_ADD_GEOFENCE_MODAL_MESSAGE_BODY_LABEL = "addGeofenceModal.message.body";
export const T_POLICY_VIEW_GEOFENCE_EDIT_LABEL = "policyView.geofence.editLabel";
export const T_POLICY_VIEW_GEOFENCE_DELETE_LABEL = "policyView.geofence.deleteLabel";
export const T_ADD_GEOFENCE_MODAL_ACTION_TIME_LABEL = "addGeofenceModal.actionTime.label";
export const T_ADD_GEOFENCE_MODAL_ACTION_TIME_PREFIX = "addGeofenceModal.actionTime.prefix";
export const T_ADD_GEOFENCE_LOCATE_LABEL = "addGeofenceModal.locateLabel";
export const T_POLICY_VIEW_FIELDS_GEOFENCING_LABEL_ADD = "policyView.fields.geofencing.label.add";

export const T_EDIT_GEOFENCE_MODAL_TITLE = "editGeofenceModal.title";
export const T_EDIT_GEOFENCE_MODAL_CONTENT = "editGeofenceModal.content";
export const T_EDIT_GEOFENCE_MODAL_RADIUS_LABEL = "editGeofenceModal.radius.label";
export const T_EDIT_GEOFENCE_MODAL_DEVICE_ACTION_TITLE = "editGeofenceModal.deviceAction.title";
export const T_EDIT_GEOFENCE_MODAL_DEVICE_ACTION_LABEL = "editGeofenceModal.deviceAction.label";
export const T_EDIT_GEOFENCE_MODAL_DEVICE_TRIGGER_LABEL = "editGeofenceModal.deviceTrigger.label";
export const T_EDIT_GEOFENCE_MODAL_CANCEL_LABEL = "editGeofenceModal.cancelLabel";
export const T_EDIT_GEOFENCE_MODAL_SAVE_LABEL = "editGeofenceModal.saveLabel";
export const T_EDIT_GEOFENCE_MODAL_MESSAGE_TITLE_LABEL = "editGeofenceModal.message.title";
export const T_EDIT_GEOFENCE_MODAL_MESSAGE_BODY_LABEL = "editGeofenceModal.message.body";
export const T_EDIT_GEOFENCE_MODAL_ACTION_TIME_LABEL = "editGeofenceModal.actionTime.label";
export const T_EDIT_GEOFENCE_MODAL_ACTION_TIME_PREFIX = "editGeofenceModal.actionTime.prefix";

export const T_POLICY_VIEW_GEOFENCE_DESCRIPTION = "policyView.geofence.description";
export const T_POLICY_VIEW_GEOFENCE_DESCRIPTION_ACTION = "policyView.geofence.descriptionAction";
export const T_POLICY_VIEW_GEOFENCE_DESCRIPTION_TRIGGER = "policyView.geofence.descriptionTrigger";

export const T_DEVICE_REMOTE_MANAGEMENT_MODAL_TITLE = "deviceRemoteManagementModal.title";
export const T_DEVICE_REMOTE_MANAGEMENT_MODAL_CONTENT = "deviceRemoteManagementModal.content";
export const T_DEVICE_REMOTE_MANAGEMENT_MODAL_CANCEL_LABEL = "deviceRemoteManagementModal.cancelLabel";
export const T_DEVICE_REMOTE_MANAGEMENT_MODAL_OPEN_CONNECTION_LABEL = "deviceRemoteManagementModal.openConnectionLabel";
export const T_DEVICE_REMOTE_MANAGEMENT_MODAL_CLOSE_CONNECTION_LABEL = "deviceRemoteManagementModal.closeConnectionLabel";
export const T_DEVICE_REMOTE_MANAGEMENT_MODAL_NO_CONNECTION_LABEL = "deviceRemoteManagementModal.noConnectionLabel";
export const T_DEVICE_REMOTE_MANAGEMENT_MODAL_TRY_AGAIN_LABEL = "deviceRemoteManagementModal.tryAgainLabel";
export const T_DEVICE_REMOTE_MANAGEMENT_MODAL_ERROR_LABEL = "deviceRemoteManagementModal.errorLabel";

export const T_POLICY_VIEW_FREEZE_PERIODS_TITLE = "policyView.fields.systemUpdate.freezePeriods.title";
export const T_POLICY_VIEW_FREEZE_PERIODS_DESCRIPTION = "policyView.fields.systemUpdate.freezePeriods.description";
export const T_POLICY_VIEW_FREEZE_PERIODS_ADD_BUTTON_LABEL = "policyView.fields.systemUpdate.freezePeriods.add.label";
export const T_POLICY_VIEW_FREEZE_PERIODS_DATE_FORMAT = "policyView.fields.systemUpdate.freezePeriods.dateFormat";

export const T_POLICY_VIEW_SYSTEM_UPDATE_TOOLTIP_LABEL_PREFIX = "policy.systemUpdate.tooltip.label";
export const T_POLICY_VIEW_SYSTEM_UPDATE_TOOLTIP_CONTENT_PREFIX = "policy.systemUpdate.tooltip.content";

export const T_ADD_FREEZE_PERIOD_MODAL_TITLE = "addFreezePeriodModal.title";
export const T_ADD_FREEZE_PERIOD_MODAL_CONTENT = "addFreezePeriodModal.content.1";
export const T_ADD_FREEZE_PERIOD_MODAL_CONTENT_2 = "addFreezePeriodModal.content.2";
export const T_ADD_FREEZE_PERIOD_MODAL_CONTENT_3 = "addFreezePeriodModal.content.3";
export const T_ADD_FREEZE_PERIOD_MODAL_CONTENT_4 = "addFreezePeriodModal.content.4";
export const T_ADD_FREEZE_PERIOD_MODAL_SUBMIT_LABEL = "addFreezePeriodModal.submit";
export const T_ADD_FREEZE_PERIOD_MODAL_CANCEL_LABEL = "addFreezePeriodModal.cancel";
export const T_ADD_FREEZE_PERIOD_MODAL_START_DATE_LABEL = "addFreezePeriodModal.startDate.label";
export const T_ADD_FREEZE_PERIOD_MODAL_END_DATE_LABEL = "addFreezePeriodModal.endDate.label";
export const T_ADD_FREEZE_PERIOD_MODAL_START_DATE_PLACEHOLDER = "addFreezePeriodModal.startDate.placeholder";
export const T_ADD_FREEZE_PERIOD_MODAL_END_DATE_PLACEHOLDER = "addFreezePeriodModal.endDate.placeholder";
export const T_ADD_FREEZE_PERIOD_MODAL_DATE_FORMAT = "addFreezePeriodModal.dateFormat";

export const T_DEVICE_VIEW_SYSTEM_UPDATE_FREEZE_PERIOD = "deviceView.systemUpdate.freezePeriodActive";

export const T_DEVICE_ON_MAP_MODAL_TIMESTAMPS_CREATED_ON_LABEL = "deviceMap.timestamps.createdOnLabel";
export const T_EDIT_GEOFENCE_MODAL_SAVE_ACTION_LABEL = "editGeofenceModal.saveActionLabel";
export const T_EDIT_GEOFENCE_MODAL_CANCEL_ACTION_LABEL = "editGeofenceModal.cancelActionLabel";
export const T_EDIT_GEOFENCE_MODAL_ADD_ACTION_LABEL = "editGeofenceModal.addActionLabel";
export const T_EDIT_GEOFENCE_MODAL_DEVICE_ACTION_CONTENT = "editGeofenceModal.deviceAction.content";
export const T_ADD_GEOFENCE_MODAL_DEVICE_ACTION_CONTENT = "addGeofenceModal.deviceAction.content";
export const T_ADD_GEOFENCE_MODAL_SAVE_ACTION_LABEL = "addGeofenceModal.saveActionLabel";
export const T_ADD_GEOFENCE_MODAL_CANCEL_ACTION_LABEL = "addGeofenceModal.cancelActionLabel";
export const T_ADD_GEOFENCE_MODAL_ADD_ACTION_LABEL = "addGeofenceModal.addActionLabel";
export const T_POLICY_VIEW_GEOFENCE_DESCRIPTION_MORE = "policyView.geofence.descriptionMore";
export const T_ADD_GEOFENCE_MODAL_ACTION_TIME_MESSAGE = "addGeofenceModal.actionTimeMessage";
export const T_EDIT_GEOFENCE_MODAL_ACTION_TIME_MESSAGE = "editGeofenceModal.actionTimeMessage";

export const T_DEVICE_VIEW_MANAGEMENT_MODE = "deviceView.values.managementMode";
export const T_DEVICE_VIEW_MANAGEMENT_MODE_PREFIX = "deviceView.values.managementMode.";
export const T_DEVICE_VIEW_MANAGEMENT_MODE_DEVICE_OWNER = "deviceView.values.managementMode.DEVICE_OWNER";
export const T_DEVICE_VIEW_MANAGEMENT_MODE_PROFILE_OWNER = "deviceView.values.managementMode.PROFILE_OWNER";
export const T_DEVICE_VIEW_DEVICE_OPERATIONS_LOG_TITLE = "deviceView.operationsLog.title";
export const T_DEVICE_VIEW_DEVICE_OPERATIONS_LOG_CONTENT = "deviceView.operationsLog.content";
export const T_DEVICE_VIEW_DEVICE_OPERATIONS_LOG_ROW = "deviceView.operationsLog.row";
export const T_DEVICE_VIEW_DEVICE_OPERATIONS_LOG_ROW_TYPE = "deviceView.operationsLog.rowType";
export const T_ADD_GEOFENCE_DISPLAY_NAME_LABEL = "addGeofenceModal.displayNameLabel";
export const T_EDIT_GEOFENCE_DISPLAY_NAME_LABEL = "editGeofenceModal.displayNameLabel";
export const T_ADD_GEOFENCE_MODAL_RESET_PASSWORD_LABEL = "addGeofenceModal.resetPasswordLabel";
export const T_EDIT_GEOFENCE_MODAL_RESET_PASSWORD_LABEL = "editGeofenceModal.resetPasswordLabel";

export const T_DEVICE_VIEW_BASIC_INFO_TOOLTIP_TITLE = "deviceView.basicInfo.tooltip.title";
export const T_DEVICE_VIEW_BASIC_INFO_TOOLTIP_CONTENT = "deviceView.basicInfo.tooltip.content";

export const T_LOGIN_VIEW_HELP_LINK = "loginView.helpLink";

export const T_TOAST_SERVICE_SUCCESS_TITLE_PREFIX = "toastService.success.title";
export const T_TOAST_SERVICE_SUCCESS_BODY_PREFIX = "toastService.success.body";
export const T_TOAST_SERVICE_ERROR_TITLE_PREFIX = "toastService.error.title";
export const T_TOAST_SERVICE_ERROR_BODY_PREFIX = "toastService.error.body";
export const T_DEVICE_VIEW_DEVICE_OPERATIONS_LOG_ROW_COMPLETED = "deviceView.operationsLog.rowCompleted";
export const T_DEVICE_VIEW_DEVICE_OPERATIONS_LOG_ROW_PENDING = "deviceView.operationsLog.rowPending";
export const T_DEVICE_VIEW_DEVICE_OPERATIONS_LOG_ROW_ERROR = "deviceView.operationsLog.rowError";
export const T_DEVICE_VIEW_DEVICE_OPERATIONS_LOG_BUTTON_LABEL = "deviceView.operationsLog.buttonLabel";

export const T_DEVICE_OPERATIONS_LOG_MODAL_NO_RESULTS_CONTENT = "deviceOperationsLogModal.noResults.content";
export const T_DEVICE_OPERATIONS_LOG_MODAL_NO_RESULTS_TITLE = "deviceOperationsLogModal.noResults.title";
export const T_DEVICE_OPERATIONS_LOG_MODAL_SUMMARY_TEXT = "deviceOperationsLogModal.summaryText";
export const T_DEVICE_OPERATIONS_LOG_MODAL_TABLE_HEADER_LABEL_OPERATION = "deviceOperationsLogModal.table.headers.operation";
export const T_DEVICE_OPERATIONS_LOG_MODAL_TABLE_HEADER_LABEL_TIME = "deviceOperationsLogModal.table.headers.time";
export const T_DEVICE_OPERATIONS_LOG_MODAL_TABLE_HEADER_LABEL_STATUS = "deviceOperationsLogModal.table.headers.status";
export const T_DEVICE_OPERATIONS_LOG_MODAL_TABLE_HEADER_LABEL_UPDATED_BY = "deviceOperationsLogModal.table.headers.updatedBy";
export const T_DEVICE_OPERATIONS_LOG_MODAL_CSV_EXPORT_LABEL = "deviceOperationsLogModal.csvExportLabel";
export const T_DEVICE_OPERATIONS_LOG_MODAL_LAST_UPDATED = "deviceOperationsLogModal.lastUpdated";
export const T_DEVICE_OPERATIONS_LOG_MODAL_LAST_UPDATED_DATE_FORMAT = "deviceOperationsLogModal.lastUpdatedDateFormat";
export const T_DEVICE_OPERATIONS_LOG_MODAL_SEARCH_LABEL = "deviceOperationsLogModal.searchLabel";
export const T_DEVICE_OPERATIONS_LOG_MODAL_SEARCH_PLACEHOLDER = "deviceOperationsLogModal.searchPlaceholder";
export const T_DEVICE_OPERATIONS_LOG_MODAL_TITLE = "deviceOperationsLogModal.title";
export const T_DEVICE_OPERATIONS_LOG_MODAL_TOTAL_TEXT = "deviceOperationsLogModal.totalText";
export const T_DEVICE_OPERATIONS_LOG_MODAL_GEOFENCE_ACTION = "deviceOperationsLogModal.geofenceAction";
export const T_DEVICE_VIEW_DEVICE_OPERATIONS_LOG_EMPTY = "deviceView.deviceOperationsLog.empty";

export const T_DEVICE_REMOTE_MANAGEMENT_MODAL_FULLSCREEN_LABEL = "deviceRemoteManagementModal.fullscreenLabel";
export const T_DEVICE_REMOTE_MANAGEMENT_MODAL_SEND_MESSAGE_LABEL = "deviceRemoteManagementModal.sendMessageLabel";
export const T_DEVICE_REMOTE_MANAGEMENT_MODAL_LOCK_DEVICE_LABEL = "deviceRemoteManagementModal.lockDeviceLabel";
export const T_ADD_GEOFENCE_MODAL_RADIUS_UNIT = "addGeofenceModal.radiusUnit";
export const T_EDIT_GEOFENCE_MODAL_RADIUS_UNIT = "editGeofenceModal.radiusUnit";
export const T_ENROLLMENT_VIEW_CUSTOM_ID_LABEL = "enrollmentView.customId.label";
export const T_ENROLLMENT_VIEW_CUSTOM_ID_PLACEHOLDER = "enrollmentView.customId.placeholder";
export const T_ENROLLMENT_VIEW_CUSTOM_ID_TOOLTIP_LABEL_PREFIX = "enrollmentView.customId.tooltipLabel";
export const T_ENROLLMENT_VIEW_CUSTOM_ID_TOOLTIP_CONTENT_PREFIX = "enrollmentView.customId.tooltipContent";
export const T_DEVICE_VIEW_LABEL_CUSTOM_ID = "deviceView.label.customId";

export const T_ADD_DEVICE_GROUP_MODAL_POLICY_TOOLTIP_LABEL_PREFIX = "addDeviceGroupModal.assignedPolicy.tooltip.label";
export const T_ADD_DEVICE_GROUP_MODAL_POLICY_TOOLTIP_CONTENT_PREFIX = "addDeviceGroupModal.assignedPolicy.tooltip.content";
export const T_EDIT_DEVICE_GROUP_MODAL_POLICY_TOOLTIP_LABEL_PREFIX = "editDeviceGroupModal.assignedPolicy.tooltip.label";
export const T_EDIT_DEVICE_GROUP_MODAL_POLICY_TOOLTIP_CONTENT_PREFIX = "editDeviceGroupModal.assignedPolicy.tooltip.content";
export const T_POLICY_TABLE_COLUMN_GEOFENCES = "policyTable.column.geofences";
export const T_POLICY_LIST_VIEW_ADD_DEVICE = "policyListView.addDevice";
export const T_POLICY_VIEW_ADD_DEVICE = "policyView.addDevice";

export const T_DEVICE_VIEW_DEVICE_OPERATIONS_LOG_GEOFENCE_ACTION = "deviceView.operationsLog.geofenceAction";

export const T_POLICY_VIEW_LOCK_SCREEN_KEYGUARD_TITLE = "policyView.enableKeyguard.title";
export const T_POLICY_VIEW_LOCK_SCREEN_KEYGUARD_DISABLE_FEATURES = "policyView.enableKeyguard.disableFeatures";
export const T_POLICY_VIEW_LOCK_SCREEN_KEYGUARD_DISABLE_FEATURES_VALUE_START = "policyView.enableKeyguard.value.start";
export const T_POLICY_VIEW_LOCK_SCREEN_KEYGUARD_DISABLE_FEATURES_VALUE_PREFIX = "policyView.enableKeyguard.value.";
export const T_POLICY_VIEW_PASSWORD_LOCK_SCREEN_TITLE = "policyView.passwordLockScreenTitle";
export const T_LOCK_SCREEN_KEYGUARD_CONFIGURATION_MODAL_TITLE = "lockScreenKeyguardConfigurationModal.title";
export const T_LOCK_SCREEN_KEYGUARD_CONFIGURATION_MODAL_CONTENT = "lockScreenKeyguardConfigurationModal.content";
export const T_LOCK_SCREEN_KEYGUARD_CONFIGURATION_MODAL_VALUE_PREFIX = "lockScreenKeyguardConfigurationModal.value.";
export const T_LOCK_SCREEN_KEYGUARD_CONFIGURATION_MODAL_CANCEL_LABEL = "lockScreenKeyguardConfigurationModal.cancel";
export const T_LOCK_SCREEN_KEYGUARD_CONFIGURATION_MODAL_SAVE_LABEL = "lockScreenKeyguardConfigurationModal.save";

export const T_DEVICE_REMOTE_MANAGEMENT_MODAL_UNSUPPORTED_POLICY_LABEL = "deviceRemoteManagementModal.unsupportedPolicy";

export const T_TABLE_CONFIGURATION_ADD_COLUMN_PLACEHOLDER = "table.tableconfiguration.addColumn.placeHolder";
export const T_TABLE_CONFIGURATION_ADD_COLUMN_NOTE_LABEL = "table.tableconfiguration.addColumn.note.label";

export const T_DEVICE_LIST_MESSAGE_MODAL_WARNING_1 = "deviceListMessageModal.warning.1";
export const T_DEVICE_LIST_MESSAGE_MODAL_WARNING_2 = "deviceListMessageModal.warning.2";
export const T_EDIT_DEVICE_MODAL_CUSTOM_ID_LABEL = "editDeviceModal.customIdLabel";

export const T_POLICY_VIEW_CONTACT_LIST_TITLE = "policyView.contactlist.title";
export const T_POLICY_VIEW_CONTACT_LIST_LABEL = "policyView.contactlist.label";
export const T_POLICY_VIEW_CONTACT_LIST_COUNT = "policyView.contactlist.count";

export const T_POLICY_MANAGE_CONTACTS_MODAL_TITLE = "policyManageContactsModal.title";
export const T_POLICY_MANAGE_CONTACTS_MODAL_SUMMARY_TEXT = "policyManageContactsModal.summaryText";
export const T_POLICY_MANAGE_CONTACTS_MODAL_TOTAL_TEXT = "policyManageContactsModal.totalText";
export const T_POLICY_MANAGE_CONTACTS_MODAL_NO_RESULTS_CONTENT = "policyManageContactsModal.noResults.content";
export const T_POLICY_MANAGE_CONTACTS_MODAL_NO_RESULTS_TITLE = "policyManageContactsModal.noResults.title";
export const T_POLICY_MANAGE_CONTACTS_MODAL_TABLE_HEADER_LABEL_NAME = "policyManageContactsModal.tableHeader.label.name";
export const T_POLICY_MANAGE_CONTACTS_MODAL_TABLE_HEADER_LABEL_EMAIL = "policyManageContactsModal.tableHeader.label.email";
export const T_POLICY_MANAGE_CONTACTS_MODAL_TABLE_HEADER_LABEL_PHONE = "policyManageContactsModal.tableHeader.label.phone";
export const T_POLICY_MANAGE_CONTACTS_MODAL_TABLE_HEADER_LABEL_ADDRESS = "policyManageContactsModal.tableHeader.label.address";
export const T_POLICY_MANAGE_CONTACTS_MODAL_TABLE_HEADER_LABEL_COMPANY = "policyManageContactsModal.tableHeader.label.company";
export const T_POLICY_MANAGE_CONTACTS_MODAL_TABLE_HEADER_LABEL_ACTIONS = "policyManageContactsModal.tableHeader.label.actions";
export const T_POLICY_MANAGE_CONTACTS_SAVE_LABEL = "policyManageContactsModal.label.save";
export const T_POLICY_MANAGE_CONTACTS_CANCEL_LABEL = "policyManageContactsModal.label.cancel";
export const T_POLICY_MANAGE_CONTACTS_MODAL_ADD_CONTACT_LABEL = "policyManageContactsModal.label.addContact";
export const T_POLICY_MANAGE_CONTACTS_MODAL_FIRST_NAME_LABEL = "policyManageContactsModal.label.firstName";
export const T_POLICY_MANAGE_CONTACTS_MODAL_LAST_NAME_LABEL = "policyManageContactsModal.label.lastName";
export const T_POLICY_MANAGE_CONTACTS_MODAL_EMAIL_LABEL = "policyManageContactsModal.label.email";
export const T_POLICY_MANAGE_CONTACTS_MODAL_PHONE_NUMBER_LABEL = "policyManageContactsModal.label.phoneNumber";
export const T_POLICY_MANAGE_CONTACTS_MODAL_ADDRESS_LABEL = "policyManageContactsModal.label.address";
export const T_POLICY_MANAGE_CONTACTS_MODAL_COMPANY_NAME_LABEL = "policyManageContactsModal.label.companyName";
export const T_POLICY_MANAGE_CONTACTS_MODAL_IMPORT_CONTACTS_LABEL = "policyManageContactsModal.label.importContacts";
export const T_POLICY_MANAGE_CONTACTS_ADD_CONTACT_CANCEL_LABEL = "policyManageContactsModal.label.addContactCancel";
export const T_POLICY_MANAGE_CONTACTS_ADD_CONTACT_SAVE_LABEL = "policyManageContactsModal.label.addContactSave";
export const T_POLICY_MANAGE_CONTACTS_MODAL_CSV_ERROR = "policyManageContactsModal.csvError";
export const T_POLICY_MANAGE_CONTACTS_MODAL_CSV_TOOLTIP_TITLE = "policyManageContactsModal.csvTooltip.title";
export const T_POLICY_MANAGE_CONTACTS_MODAL_CSV_TOOLTIP_CONTENT = "policyManageContactsModal.csvTooltip.content";
export const T_POLICY_MANAGE_CONTACTS_TOO_MANY_ERROR = "policyManageContactsModal.tooManyError";

export const T_POLICY_VIEW_LOCK_SCREEN_MODAL_TOOLTIP_LABEL_PREFIX = "policyView.fields.lockScreenModal.tooltipLabel";
export const T_POLICY_VIEW_LOCK_SCREEN_MODAL_TOOLTIP_CONTENT_PREFIX = "policyView.fields.lockScreenModal.tooltipContent";

export const T_NEW_POLICY_MODAL_PAGE1_TITLE = "newPolicyModal.page1.title";
export const T_NEW_POLICY_MODAL_PAGE2_TITLE = "newPolicyModal.page2.title";
export const T_NEW_POLICY_MODAL_PAGE3_TITLE = "newPolicyModal.page3.title";
export const T_NEW_POLICY_MODAL_PAGE4_TITLE = "newPolicyModal.page4.title";
export const T_NEW_POLICY_MODAL_PAGE5_TITLE = "newPolicyModal.page5.title";
export const T_NEW_POLICY_MODAL_PAGE6_TITLE = "newPolicyModal.page6.title";
export const T_NEW_POLICY_MODAL_PAGE7_TITLE = "newPolicyModal.page7.title";
export const T_NEW_POLICY_MODAL_PAGE8_TITLE = "newPolicyModal.page8.title";

export const T_POLICY_VIEW_WORK_PROFILE_RESTRICTIONS_TITLE = "policyView.workProfile.restrictionsTitle";

export const T_EDIT_GEOFENCE_CHANGED_MESSAGE = "editGeofenceModal.changedMessage";

export const T_UNSAVED_CHANGES_MODAL_TITLE = "unsavedChangesModal.title";
export const T_UNSAVED_CHANGES_MODAL_CONTENT = "unsavedChangesModal.content";
export const T_UNSAVED_CHANGES_MODAL_ACCEPT_LABEL = "unsavedChangesModal.acceptLabel";
export const T_UNSAVED_CHANGES_MODAL_CANCEL_LABEL = "unsavedChangesModal.cancelLabel";

export const T_POLICY_VIEW_ENABLE_WIFI_CONFIRM_TITLE = "policyView.enableWifiConfirm.title";
export const T_POLICY_VIEW_ENABLE_WIFI_CONFIRM_CONTENT = "policyView.enableWifiConfirm.content";
export const T_POLICY_VIEW_ENABLE_WIFI_CONFIRM_WARNING = "policyView.enableWifiConfirm.warning";
export const T_POLICY_VIEW_ENABLE_WIFI_CONFIRM_ACCEPT_LABEL = "policyView.enableWifiConfirm.acceptLabel";
export const T_POLICY_VIEW_ENABLE_WIFI_CONFIRM_CANCEL_LABEL = "policyView.enableWifiConfirm.cancelLabel";

export const T_NEW_POLICY_MODAL_MANAGED_CONF_MSG = "newPolicyModal.managedConfMsg";
