// Copyright © 2020 HMD Global. All rights reserved.

import SessionService from "./SessionService";
import ProfileService from "./ProfileService";
import WindowService from "./WindowService";
import AppInitializerUtils, { InitializableService } from "./AppInitializerUtils";
import BackendService from "./BackendService";
import NetworkService from "./NetworkService";
import NetworkMessageService from "./NetworkMessageService";
import RemoteLogService from "./RemoteLogService";

const UNAUTHORIZED_SERVICES: Array<InitializableService> = [BackendService, RemoteLogService, WindowService, NetworkService, NetworkMessageService, SessionService, ProfileService];

export class UnauthorizedAppInitializerService {
  static initializeServices() {
    AppInitializerUtils.initializeArray(UNAUTHORIZED_SERVICES);
  }
}

export default UnauthorizedAppInitializerService;
