// Copyright © 2020, 2021 HMD Global. All rights reserved.

export enum AppState {
  ERROR_VIEW,
  LOADING_VIEW,
  LOGIN_VIEW,
  CHANGE_PASSWORD_VIEW,
  TERMS_AND_CONDITIONS_VIEW,
  ENTERPRISE_AGREEMENT_VIEW,
  LOGIN_VIEW_WITH_LOADING,
  SETUP_VIEW,
  TFA_VIEW,
  AUTHORIZED_VIEW,
}

export default AppState;
