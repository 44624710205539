// Copyright © 2021 HMD Global. All rights reserved.

import LogService from "./LogService";
import { get, set } from "../modules/lodash";
import { EnterpriseModel } from "./types/EnterpriseModel";

const LOG = LogService.createLogger("EnterpriseEditor");

export interface ChangeEnterpriseModelCallback {
  (newModel: EnterpriseModel, prevModel: EnterpriseModel): void;
}

export class EnterpriseEditor {
  private readonly _prevModel: EnterpriseModel;
  private readonly _changeCallback: ChangeEnterpriseModelCallback | undefined;
  private _model: EnterpriseModel;

  constructor(model: EnterpriseModel, changeCallback: ChangeEnterpriseModelCallback | undefined = undefined) {
    this._prevModel = model;
    this._model = model;
    this._changeCallback = changeCallback;
  }

  static start(newModel: EnterpriseModel, changeCallback: ChangeEnterpriseModelCallback | undefined = undefined): EnterpriseEditor {
    return new EnterpriseEditor(newModel, changeCallback);
  }

  private _changeModel(newModel: EnterpriseModel): EnterpriseEditor {
    this._model = newModel;
    return this;
  }

  /**
   * If there was changes, will call the callback.
   *
   * @param changeCallback
   */
  public commit(changeCallback: ChangeEnterpriseModelCallback | undefined = undefined): EnterpriseEditor {
    if (this._model !== this._prevModel) {
      if (changeCallback) {
        try {
          if (this._changeCallback) {
            LOG.warn("Warning! The constructor change callback is ignored since commit has one.");
          }

          LOG.debug("Committing changed model: ", this._model);

          changeCallback(this._model, this._prevModel);
        } catch (err) {
          LOG.error("Exception: ", err);
        }
      } else if (this._changeCallback) {
        try {
          LOG.debug("Committing changed model: ", this._model);

          this._changeCallback(this._model, this._prevModel);
        } catch (err) {
          LOG.error("Exception: ", err);
        }
      } else {
        LOG.warn("Warning! No change callback defined and model changed:", this._model);
      }
    } else {
      LOG.debug("Model was not changed: ", this._model);
    }

    return this;
  }

  getModel(): EnterpriseModel {
    return this._model;
  }

  public getModelKey(key: string, defaultValue: any = undefined, sameAsDefaultValue: any = undefined): any {
    const value = get(this._model, key);

    if (value === undefined || value === sameAsDefaultValue) {
      return defaultValue;
    }

    return value;
  }

  public setModelKey(key: string, value: any): EnterpriseEditor {
    const currentModel = this._model;

    const prevValue = get(currentModel, key);

    if (value === prevValue) {
      LOG.warn(`Not changing model for "${key}", values identical: ${prevValue} ==> ${value}`);
      return this;
    }

    LOG.debug(`setModelKey: Changing model for "${key}": ${prevValue} ==> ${value}`);

    const newModel: EnterpriseModel = {
      ...currentModel,
    };

    set(newModel, key, value);

    LOG.debug(`setModelKey: Changing model for "${key}": Setting new model as: `, newModel);

    return this._changeModel(newModel);
  }

  public changeContactInfo(key: string, value: string) {
    const currentModel = this._model;

    const { contactInfo } = currentModel;

    const newContactInfo = {
      ...contactInfo,
      [key]: value,
    };

    const newModel = {
      ...currentModel,
      contactInfo: {
        ...newContactInfo,
      },
    };

    return this._changeModel(newModel);
  }
}

export default EnterpriseEditor;
