// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import "./ChangePasswordForm.scss";
import { CHANGE_PASSWORD_FORM_CLASS_NAME } from "../../../constants/classNames";
import Form, { FormSubmitCallback } from "../../common/form/Form";
import { TextFieldChangeCallback } from "../../common/textField/TextField";
import SubmitButton from "../../common/submitButton/SubmitButton";
import {
  T_CHANGE_PASSWORD_FORM_BUTTON_LABEL,
  T_CHANGE_PASSWORD_FORM_FORM_TITLE,
  T_CHANGE_PASSWORD_FORM_OLD_PASSWORD_LABEL,
  T_CHANGE_PASSWORD_FORM_OLD_PASSWORD_PLACEHOLDER,
  T_CHANGE_PASSWORD_FORM_PASSWORD1_LABEL,
  T_CHANGE_PASSWORD_FORM_PASSWORD1_PLACEHOLDER,
  T_CHANGE_PASSWORD_FORM_PASSWORD2_LABEL,
  T_CHANGE_PASSWORD_FORM_PASSWORD2_PLACEHOLDER,
  T_CHANGE_PASSWORD_FORM_PASSWORD_TOOL_TIP,
  T_LOGIN_VIEW_RESET_PASSWORD_NO_TOKEN_ERROR,
} from "../../../translations/translationTokens";
import PasswordField, { PasswordFieldValidatorCallback } from "../../common/passwordField/PasswordField";
import TranslateCallback from "../../../TranslateCallback";
import LogService from "../../../services/LogService";
import ComponentUtils from "../../ComponentUtils";
import ProfileUtils from "../../../services/ProfileUtils";
import { MINIMUM_PASSWORD_LENGTH } from "../../../constants/backend";
import MessageService from "../../../services/MessageService";
import MessageType from "../../../services/types/MessageType";
import ProfileService from "../../../services/ProfileService";
import AppStateService from "../../../services/AppStateService";

const LOG = LogService.createLogger("ChangePasswordForm");

export interface ChangePasswordFormSubmitCallback {
  (formData: ChangePasswordFormState): void;
}

export interface ChangePasswordFormProps {
  t?: TranslateCallback;
  className?: string | undefined;
  submit: ChangePasswordFormSubmitCallback;
  oldPassword?: string;
  oldPasswordValidationDisabled?: boolean;
  oldPasswordEnabled?: boolean;
  loading?: boolean;
  error?: any;
}

export interface ChangePasswordFormState {
  initial: boolean;
  oldPassword: string;
  password1: string;
  password2: string;
  touched: boolean;
}

export class ChangePasswordForm extends React.Component<ChangePasswordFormProps, ChangePasswordFormState> {
  static defaultProps: Partial<ChangePasswordFormProps> = {
    loading: false,
  };

  private _focusOnOldPassword: boolean;
  private _focusOnPassword1: boolean;
  private _focusOnPassword2: boolean;

  private readonly _handlePassword1ChangeCallback: TextFieldChangeCallback;
  private readonly _handleOldPasswordChangeCallback: TextFieldChangeCallback;
  private readonly _handlePassword2ChangeCallback: TextFieldChangeCallback;
  private readonly _handleSubmitCallback: FormSubmitCallback;
  private readonly _validateOldPasswordCallback: PasswordFieldValidatorCallback;
  private readonly _validatePassword1Callback: PasswordFieldValidatorCallback;
  private readonly _validatePassword2Callback: PasswordFieldValidatorCallback;

  constructor(props: ChangePasswordFormProps) {
    super(props);

    this._focusOnOldPassword = false;
    this._focusOnPassword1 = false;
    this._focusOnPassword2 = false;

    this.state = {
      initial: true,
      oldPassword: "",
      password1: "",
      password2: "",
      touched: false,
    };

    this._handleOldPasswordChangeCallback = this._handleOldPasswordChange.bind(this);
    this._handlePassword1ChangeCallback = this._handlePassword1Change.bind(this);
    this._handlePassword2ChangeCallback = this._handlePassword2Change.bind(this);
    this._handleSubmitCallback = this._handleSubmit.bind(this);
    this._validateOldPasswordCallback = this._validateOldPassword.bind(this);
    this._validatePassword1Callback = this._validatePassword1.bind(this);
    this._validatePassword2Callback = this._validatePassword2.bind(this);
  }

  componentDidMount() {
    const oldPassword = this.props.oldPassword ?? "";

    if (this.state.oldPassword !== oldPassword) {
      this.setState({
        oldPassword: oldPassword,
      });
    }

    const unauthorizedView = AppStateService.isUnauthorizedView();

    if (unauthorizedView) {
      const url = new URL(document.location.href).searchParams;
      const token = url.get("token") ?? "";

      if (!token) {
        MessageService.createMessage({
          type: MessageType.ERROR,
          content: T_LOGIN_VIEW_RESET_PASSWORD_NO_TOKEN_ERROR,
        });
      } else {
        ProfileService.validateToken(token);
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<ChangePasswordFormProps>) {
    if (prevProps.oldPassword !== this.props.oldPassword) {
      const newOldPassword = this.props.oldPassword ?? "";

      if (this.state.oldPassword !== newOldPassword) {
        this.setState({
          oldPassword: newOldPassword,
        });
      }
    }
  }

  componentWillUnmount() {
    this._clearMessages();
  }

  render() {
    const t = this.props.t ?? ((key: string) => key);

    const error = this.props.error ?? "";

    let oldPasswordField: any = "";

    if (this.props?.oldPasswordEnabled) {
      oldPasswordField = (
        <PasswordField
          label={t(T_CHANGE_PASSWORD_FORM_OLD_PASSWORD_LABEL)}
          value={this.state.oldPassword}
          placeholder={t(T_CHANGE_PASSWORD_FORM_OLD_PASSWORD_PLACEHOLDER)}
          change={this._handleOldPasswordChangeCallback}
          isValid={this._validateOldPasswordCallback}
          toggleFocus={(value: boolean) => {
            this._focusOnOldPassword = value;
          }}
        />
      );
    }

    return (
      <Form title={t(T_CHANGE_PASSWORD_FORM_FORM_TITLE)} className={CHANGE_PASSWORD_FORM_CLASS_NAME} loading={this.props.loading} submit={this._handleSubmitCallback}>
        {oldPasswordField}

        <PasswordField
          label={t(T_CHANGE_PASSWORD_FORM_PASSWORD1_LABEL)}
          value={this.state.password1}
          placeholder={t(T_CHANGE_PASSWORD_FORM_PASSWORD1_PLACEHOLDER)}
          isValid={this._validatePassword1Callback}
          change={this._handlePassword1ChangeCallback}
          toggleFocus={(value: boolean) => {
            this._focusOnPassword1 = value;
          }}
        />

        <PasswordField
          label={t(T_CHANGE_PASSWORD_FORM_PASSWORD2_LABEL)}
          value={this.state.password2}
          placeholder={t(T_CHANGE_PASSWORD_FORM_PASSWORD2_PLACEHOLDER)}
          isValid={this._validatePassword2Callback}
          change={this._handlePassword2ChangeCallback}
          toggleFocus={(value: boolean) => {
            this._focusOnPassword2 = value;
          }}
        />

        {ComponentUtils.formatParagraphWithLineBreaks(
          t(T_CHANGE_PASSWORD_FORM_PASSWORD_TOOL_TIP, {
            MINIMUM_PASSWORD_LENGTH: MINIMUM_PASSWORD_LENGTH,
          }),
        )}

        {error}

        <SubmitButton className={CHANGE_PASSWORD_FORM_CLASS_NAME + "-submit-button"} label={t(T_CHANGE_PASSWORD_FORM_BUTTON_LABEL)} enabled={this._isSubmitEnabled()} />
      </Form>
    );
  }

  private _clearMessages() {
    if (MessageService.hasMessages()) {
      const messages = MessageService.getMessages();

      const message = messages.filter((msg) => msg.content.startsWith("login") || msg.content.startsWith("profileService")).map((msg) => msg.messageId);

      message.forEach((id) => id && MessageService.removeMessageById(id));

      LOG.debug("ChangePasswordForm messages cleared.");
    }
  }

  private _handleOldPasswordChange(newValue: string) {
    if (this.state.oldPassword !== newValue) {
      this.setState({
        initial: false,
        oldPassword: newValue,
      });
    }
  }

  private _handlePassword1Change(newValue: string) {
    if (this.state.password1 !== newValue) {
      this.setState({
        initial: false,
        password1: newValue,
      });
    }
  }

  private _handlePassword2Change(newValue: string) {
    if (this.state.password2 !== newValue) {
      this.setState({
        initial: false,
        password2: newValue,
      });
    }
  }

  private _handleSubmit() {
    try {
      this.props.submit(this.state);
    } catch (err) {
      LOG.error("Exception in submit handler: ", err);
    }

    LOG.debug("_handleSubmit: Clearing form state");
    this.setState({
      initial: true,
      oldPassword: "",
      password1: "",
      password2: "",
    });
  }

  private _validateOldPassword(): boolean {
    return true;
  }

  private _validatePassword1(): boolean {
    return this.state.initial || ProfileUtils.validatePassword(this.state.password1);
  }

  private _validatePassword2(): boolean {
    if (this.state.initial) return true;

    if (this._focusOnPassword2) {
      return ProfileUtils.validatePasswordHasEqualStart(this.state.password1, this.state.password2);
    }

    return ProfileUtils.validatePasswordsMatch(this.state.password1, this.state.password2);
  }

  private _isSubmitEnabled(): boolean {
    if (this.props.oldPasswordValidationDisabled) {
      return !this.props.loading && ProfileUtils.validatePassword(this.state.password1) && ProfileUtils.validatePasswordsMatch(this.state.password2, this.state.password1);
    }

    return !this.props.loading && ProfileUtils.validatePasswordChange(this.state.oldPassword, this.state.password1, this.state.password2);
  }
}

export default ChangePasswordForm;
