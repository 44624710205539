// Copyright © 2020 HMD Global. All rights reserved.
//
// NOTE! This is the entry point for unauthorized webpack bundle
//

import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "./i18n";
import UnauthorizedApp from "./apps/unauthorized/UnauthorizedApp";
import * as serviceWorker from "./serviceWorker";
import ModalContainer from "./components/layout/modalContainer/ModalContainer";
import styles from "./styles/variables.scss";

if (styles.themeInUse === "ndac") {
  document.title = "EMM from Nokia";

  const favicon = document.getElementById("hmd-app-favicon");
  favicon?.setAttribute("href", process.env.PUBLIC_URL + "Nokia-Favicon-32x32.png");

  const appleTouchIcon = document.getElementById("hmd-app-appleTouchIcon");
  appleTouchIcon?.setAttribute("href", process.env.PUBLIC_URL + "Nokia-Favicon-192x192.png");

  const manifest = document.getElementById("hmd-app-manifest");
  manifest?.setAttribute("href", process.env.PUBLIC_URL + "manifest-nokia.json");

  const description = document.getElementById("hmd-app-meta-description");
  description?.setAttribute("content", document.title);
}

ReactDOM.render(
  <React.StrictMode>
    <UnauthorizedApp />
    <ModalContainer />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
