// Copyright © 2020 HMD Global. All rights reserved.

import { Language } from "./Language";

export enum TranslationLanguageKey {
  ENGLISH = "en",
  FINNISH = "fi",
  GERMAN = "de",
}

export function parseLanguageToKey(value: Language): TranslationLanguageKey {
  switch (value) {
    case Language.ENGLISH:
      return TranslationLanguageKey.ENGLISH;
    case Language.FINNISH:
      return TranslationLanguageKey.FINNISH;
    case Language.GERMAN:
      return TranslationLanguageKey.GERMAN;
    default:
      throw new TypeError("Unsupported language: " + value);
  }
}

export default TranslationLanguageKey;
