// Copyright © 2020 HMD Global. All rights reserved.

import HttpService, { HttpResponse } from "./HttpService";
import HttpMethod from "./HttpMethod";
import {
  API_CHANGE_PASSWORD_URL,
  API_ENTERPRISE_AGREEMENT_URL,
  API_FORGOT_PASSWORD_URL,
  API_PROFILE_URL,
  API_RESET_PASSWORD_URL,
  API_TERMS_AND_CONDITIONS_URL,
  API_TFA_QR_URL,
  API_TFA_REGISTER_URL,
  API_TFA_RESET_URL,
  API_TFA_VERIFY_RESET_URL,
  API_TFA_VERIFY_URL,
  API_VALIDATE_TOKEN_URL,
} from "../constants/backend";
import EventObserver, { EventObserverCallback, EventObserverDestructorCallback } from "./EventObserver";
import UserRole, { parseUserRole } from "./UserRole";
import AppStateService from "./AppStateService";
import { endsWith, find, has, isEqual } from "../modules/lodash";
import AppState from "./AppState";
import AppStateUtils from "./AppStateUtils";
import SessionService from "./SessionService";
import HttpRequestType from "./HttpRequestType";
import ErrorService, { ErrorCode } from "./ErrorService";
import MessageService from "./MessageService";
import { IconType } from "../components/common/icon/IconType";
import {
  T_PROFILE_SERVICE_CHANGE_PASSWORD_CHANGE_ERROR,
  T_ACCOUNT_VIEW_PASSWORD_CHANGE_SUCCESS,
  T_LOGIN_VIEW_RESET_PASSWORD_ERROR,
  T_LOGIN_VIEW_TERMS_AND_CONDITIONS_ERROR,
  T_LOGIN_VIEW_FORGOT_PASSWORD_HISTORY_ERROR,
  T_LOGIN_VIEW_FORGOT_PASSWORD_COMPLEXITY_ERROR,
  T_LOGIN_VIEW_EXPIRED_LINK_ERROR,
  T_LOGIN_VIEW_FORGOT_PASSWORD_ERROR,
  T_TFA_REGISTER_CODE_ERROR,
  T_TFA_VERIFY_CODE_ERROR,
} from "../translations/translationTokens";
import LogService from "./LogService";
import { IS_DEVELOPMENT_BUILD } from "../constants/environment";
import EnterpriseId from "../types/EnterpriseId";
import EnterpriseUtils from "./EnterpriseUtils";
import PackageName from "../constants/PackageName";
import HttpHeader from "../constants/HttpHeader";
import StringUtils from "./StringUtils";
import { MessageType } from "./types/MessageType";
import BackendService from "./BackendService";
import LocalStorageService from "./LocalStorageService";
import LocalStorageKey from "./LocalStorageKey";

const LOG = LogService.createLogger("ProfileService");

export enum ProfileServiceEvent {
  PROFILE_CHANGED = "ProfileServiceEvent:changed",
  DATA_LOADING_CHANGED = "ProfileServiceEvent:dataLoadingChanged",
  PASSWORD_CHANGED = "ProfileServiceEvent:passwordChanged",
  PASSWORD_CHANGE_FAILED = "ProfileServiceEvent:passwordChangeFail",
  FORGOT_PASSWORD_EMAIL_SENT = "ProfileServiceEvent:forgotPasswordEmailSent",
  RESET_PASSWORD_SUCCESS = "ProfileServiceEvent:resetPasswordSuccess",
  TERMS_AND_CONDITIONS_ACCEPTED = "ProfileServiceEvent:termsAndConditionsAccepted",
  ENTERPRISE_AGREEMENT_ACCEPTED = "ProfileServiceEvent:enterpriseAgreementAccepted",
  TFA_REGISTERED = "ProfileServiceEvent:tfaRegistered",
  TFA_VERIFIED = "ProfileServiceEvent:tfaVerified",
  TFA_RESET = "ProfileServiceEvent:tfaReset",
}

export enum EnterpriseNotificationType {
  ENROLLMENT = "ENROLLMENT",
}

export interface EnterpriseData {
  appAutoApprovalEnabled?: boolean;
  enabledNotificationTypes: Array<EnterpriseNotificationType>;
  enterpriseDisplayName?: string;
  name?: string;
  pubsubTopic: string;
}

export interface ProfileDataResponse {
  username?: string;
  role?: UserRole;
  pwChangeRequired?: boolean;
  termsNeedToBeApproved?: boolean;
  enterprises: EnterpriseData[];
  defaultPolicy: boolean;
  companionApp?: string;
  permittedEnterprises: [];
  googleMapsRegion?: string;
  tfaenabled: boolean;
  tfaregistrationNeeded: boolean;
}

export type ProfileHttpResponse = HttpResponse<ProfileDataResponse>;

export type ProfileServiceDestructor = EventObserverDestructorCallback;

export class ProfileService {
  public static Event = ProfileServiceEvent;

  private static _observer: EventObserver = new EventObserver("ProfileService");
  private static _dataLoading = false;
  private static _initialized = false;
  private static _email = "";
  private static _companionAppPackageName = "";
  private static _defaultPolicy = false;
  private static _role: UserRole = UserRole.UNDEFINED;
  private static _enterpriseList: Array<EnterpriseData> = [];
  private static _selectedEnterpriseData: EnterpriseData | undefined = undefined;
  private static _selectedEnterpriseId: EnterpriseId | undefined = undefined;
  private static _permittedEnterprisesList: any[] = [];
  private static _googleMapsRegion = "";
  private static _tfaEnabled = false;
  private static _tfaRegistrationNeeded = false;
  private static _geofenceEnabled = false;

  public static getName(): string {
    return "ProfileService";
  }

  /**
   * Called when the app starts
   */
  public static initialize() {
    if (IS_DEVELOPMENT_BUILD) {
      // noinspection TypeScriptUnresolvedVariable
      // @ts-ignore
      window.hmdProfileService = this;
    }

    if (AppStateService.isAuthorizedView()) {
      AppStateService.setState(AppState.LOADING_VIEW);
      this.refreshProfileDataFromBackend();
    }

    LOG.debug(`${this.getName()} initialized.`);
  }

  public static clearProfileData() {
    this._setProfileState("", UserRole.UNDEFINED, [], [], false, false, "", "", false, false);
  }

  public static isInitialized(): boolean {
    return this._initialized;
  }

  public static hasProfileData(): boolean {
    return this._initialized && this._role !== UserRole.UNDEFINED;
  }

  public static hasEnterprise(): boolean {
    return this.hasProfileData() && this._selectedEnterpriseData !== undefined;
  }

  public static isEnterpriseInitialized(): boolean {
    return this.hasProfileData() && !!this._enterpriseList && this._enterpriseList.length >= 1 && !!this._enterpriseList[0].enterpriseDisplayName;
  }

  public static isDefaultPolicyInitialized(): boolean {
    return this.hasProfileData() && this._defaultPolicy;
  }

  public static getEnterpriseId(): EnterpriseId | undefined {
    return this?._selectedEnterpriseId ?? undefined;
  }

  public static getUserEmail(): string {
    return this._email;
  }

  public static getEnterprises(): EnterpriseData[] {
    return this._enterpriseList;
  }

  public static getPermittedEnterprises(): any[] {
    return this._permittedEnterprisesList;
  }

  public static getSelectedEnterprise(): EnterpriseData | undefined {
    return this._selectedEnterpriseData;
  }

  public static hasCompanionAppPackageName(): boolean {
    return this._companionAppPackageName !== "";
  }

  public static getCompanionAppPackageName(): PackageName {
    // You can test "no companion app" feature in the frontend by enabling this
    //return '' as PackageName;

    // Dirty workaround to force specific package name for staging test enterprise
    const staging = "LC04eohtxn";

    if (this._selectedEnterpriseId && this._selectedEnterpriseId.getId() === staging) {
      return "com.hmdglobal.app.emmcompanion.emm" as PackageName;
    }

    return this._companionAppPackageName as PackageName;
  }

  public static getEnterpriseDisplayName(): string {
    return this._selectedEnterpriseData?.enterpriseDisplayName ?? "";
  }

  public static getUserRole(): UserRole {
    return this._role;
  }

  public static getGoogleMapsRegion(): string {
    return this._googleMapsRegion;
  }

  public static getTfaRegistrationNeeded(): boolean {
    return this._tfaRegistrationNeeded;
  }

  public static getTfaEnabled(): boolean {
    return this._tfaEnabled;
  }

  public static getGeofenceEnabled(): boolean {
    return this._geofenceEnabled;
  }

  public static setSelectedEnterprise(enterprise: EnterpriseData) {
    const { name, enterpriseDisplayName } = enterprise;

    if (!name) return;

    LOG.debug(`Selecting enterprise: ${enterpriseDisplayName}`);

    LocalStorageService.setProperty(LocalStorageKey.SELECTED_ENTERPRISE, name);
  }

  public static _setProfileState(
    email: string,
    role: UserRole,
    enterprises: Array<EnterpriseData>,
    permittedEnterprises: any[],
    initialized: boolean,
    defaultPolicy: boolean,
    companionAppPackageName: string,
    googleMapsRegion: string,
    tfaEnabled: boolean,
    tfaRegistrationNeeded: boolean,
  ) {
    let changed = false;

    if (email !== this._email) {
      this._email = email;
      LOG.info("Email changed: ", this._email);
      changed = true;
    }

    if (companionAppPackageName !== this._companionAppPackageName) {
      this._companionAppPackageName = companionAppPackageName;
      LOG.info("Companion app packageName changed: ", this._companionAppPackageName);
      changed = true;
    }

    if (role !== this._role) {
      this._role = role;
      LOG.info("Role changed: ", this._role);
      changed = true;
    }

    if (defaultPolicy !== this._defaultPolicy) {
      this._defaultPolicy = defaultPolicy;
      LOG.info("Default policy changed: ", this._defaultPolicy);
      changed = true;
    }

    if (!isEqual(this._enterpriseList, enterprises)) {
      this._enterpriseList = enterprises ? enterprises.slice(0) : [];

      const localSelectedEnterprise = LocalStorageService.getProperty(LocalStorageKey.SELECTED_ENTERPRISE);

      const permittedEnterprises = this._enterpriseList.map(({ name }) => name);

      if (localSelectedEnterprise && !permittedEnterprises.includes(localSelectedEnterprise)) {
        LocalStorageService.removeProperty(LocalStorageKey.SELECTED_ENTERPRISE);
      }

      LOG.info("Enterprise list changed: ", this._enterpriseList);

      changed = true;

      if (!localSelectedEnterprise && this._selectedEnterpriseData === undefined) {
        this._selectedEnterpriseData = this._enterpriseList.length ? this._enterpriseList[0] : undefined;
      } else {
        this._selectedEnterpriseData = find(this._enterpriseList, (item) => {
          return isEqual(item, this._selectedEnterpriseData);
        });

        if (!this._selectedEnterpriseData) {
          if (localSelectedEnterprise) {
            this._selectedEnterpriseData = this._enterpriseList.filter((item) => item.name === localSelectedEnterprise).pop();
          } else {
            this._selectedEnterpriseData = this._enterpriseList.length ? this._enterpriseList[0] : undefined;
          }
        }
      }

      this._selectedEnterpriseId = EnterpriseUtils.parseEnterpriseIdObject(this._selectedEnterpriseData?.name);
    }

    const currentEnterprise = permittedEnterprises.find(({ enterpriseId }) => enterpriseId === this._selectedEnterpriseData?.name) ?? {};
    const geofenceEnabled = currentEnterprise.geofence ?? false;

    if (googleMapsRegion !== this._googleMapsRegion) {
      this._googleMapsRegion = googleMapsRegion;
      LOG.info("Google maps region changed: ", this._googleMapsRegion);
      changed = true;
    }

    if (tfaRegistrationNeeded !== this._tfaRegistrationNeeded) {
      this._tfaRegistrationNeeded = tfaRegistrationNeeded;
      LOG.info("TFA Registration needed changed: ", this._tfaRegistrationNeeded);
      changed = true;
    }

    if (tfaEnabled !== this._tfaEnabled) {
      this._tfaEnabled = tfaEnabled;
      LOG.info("TFA Enabled changed: ", this._tfaEnabled);
      changed = true;
    }

    if (geofenceEnabled !== this._geofenceEnabled) {
      this._geofenceEnabled = geofenceEnabled;
      LOG.info("Geofence Enabled changed: ", this._geofenceEnabled);
      changed = true;
    }

    if (this._initialized !== initialized) {
      LOG.info("Changed initialized state as ", initialized);
      this._initialized = initialized;
      changed = true;
    }

    if (changed) {
      this._observer.triggerEvent(ProfileServiceEvent.PROFILE_CHANGED);
    }
  }

  /**
   * Refresh profile data from the server
   *
   */
  public static refreshProfileDataFromBackend() {
    this._dataLoading = true;
    this._observer.triggerEvent(ProfileServiceEvent.DATA_LOADING_CHANGED);

    const backendId = BackendService.getCurrentBackend();

    HttpService.request(HttpMethod.GET, API_PROFILE_URL(backendId))
      .then(
        (response: ProfileHttpResponse) => {
          LOG.info(`Successfully received profile from backend ${backendId}`);

          LOG.debug("refreshProfileDataFromBackend: Response data: ", response);

          if (HttpService.checkIfResponseUrlHasEndsWithLogin(response)) {
            this._goToLoginView();
            LOG.info("Backend requested login page. Moving to login view.");
            return this._stopDataLoadingAndTriggerEvent();
          }

          if (HttpService.verifyResponseObject(response)) {
            return this._stopDataLoadingAndTriggerEvent();
          }

          this.refreshProfileDataFromPayload(response?.data ?? {});

          this._stopDataLoadingAndTriggerEvent();
        },
        (response) => {
          LOG.debug("refreshProfileDataFromBackend: Error response: ", response);

          if (HttpService.checkIfResponseUrlHasEndsWithLogin(response)) {
            LOG.info("Backend requested login page. Moving to login view.");
            this._goToLoginView();
            return this._stopDataLoadingAndTriggerEvent();
          }

          const statusCode = response?.status ?? 0;

          if (statusCode === 403 || statusCode === 401 || statusCode === 404) {
            LOG.info(`Backend access forbidden, unauthorized or not found (#${statusCode}). Moving to login view.`);
            this._goToLoginView();
            return this._stopDataLoadingAndTriggerEvent();
          }

          LOG.error("PROFILE REFRESH FAIL: ", statusCode, response);

          this._stopDataLoadingAndTriggerEvent();

          this._setProfileState("", UserRole.UNDEFINED, [], [], true, false, "", "", false, false);

          AppStateService.setState(AppState.ERROR_VIEW);
        },
      )
      .catch((error) => {
        this._stopDataLoadingAndTriggerEvent();

        LOG.error("Internal profile error: ", error);

        AppStateService.setState(AppState.ERROR_VIEW);
      });
  }

  private static _goToLoginView() {
    SessionService.clearSession();
    AppStateService.setState(AppState.LOGIN_VIEW);
  }

  private static _stopDataLoadingAndTriggerEvent() {
    this._dataLoading = false;
    this._observer.triggerEvent(ProfileServiceEvent.DATA_LOADING_CHANGED);
  }

  /**
   * Retrurns `true` if we're loading profile data, eg. `refreshProfileDataFromBackend()` is activated.
   */
  public static isDataLoading(): boolean {
    return this._dataLoading;
  }

  public static refreshProfileDataFromPayload(data: ProfileDataResponse) {
    LOG.debug("PROFILE REFRESH SUCCESS: ", data);

    const username: string | undefined = data?.username ?? "";
    const userRole: string | undefined = data?.role;
    const enterprises: EnterpriseData[] = data?.enterprises ?? [];
    const defaultPolicy: boolean = data?.defaultPolicy ?? false;
    const companionApp: string = data?.companionApp ?? "";
    const enterprisesCount: number = data?.enterprises?.length ?? 0;
    const termsNeedToBeApproved: boolean = data?.termsNeedToBeApproved ?? false;
    const pwChangeRequired: boolean = data?.pwChangeRequired ?? false;
    const hasEnterprises: boolean = enterprisesCount !== 0;
    const tfaEnabled: boolean = data.tfaenabled;
    const tfaRegistrationNeeded: boolean = data.tfaregistrationNeeded;
    const { permittedEnterprises } = data;
    const { googleMapsRegion = "" } = data;

    LOG.info(
      `Profile data received: ${username} as ${userRole} with companionApp='${companionApp}', ${enterprisesCount} enterprises (${enterprises
        .map((item) => item?.name ?? "")
        .join(" ")}), ToC=${termsNeedToBeApproved}, PwChangeRequired=${pwChangeRequired}, DefaultPolicy=${defaultPolicy}`,
    );

    this._setProfileState(
      username,
      parseUserRole(userRole),
      enterprises,
      permittedEnterprises,
      true,
      defaultPolicy,
      companionApp,
      googleMapsRegion,
      tfaEnabled,
      tfaRegistrationNeeded,
    );

    const selectedEnterpriseId = this.getEnterpriseId()?.toString();
    const enterpriseAgreementAccepted =
      permittedEnterprises
        .filter(({ enterpriseId }) => enterpriseId === selectedEnterpriseId)
        .map(({ agreementAccepted }) => agreementAccepted)
        .pop() ?? true;

    if (tfaRegistrationNeeded || (tfaEnabled && userRole === UserRole.TFA_REQUIRED)) {
      LOG.info("Moving user to 2FA view.");

      AppStateService.setState(AppState.TFA_VIEW);
    } else if (termsNeedToBeApproved) {
      LOG.info("Moving user to the Terms and conditions view");

      AppStateService.setState(AppState.TERMS_AND_CONDITIONS_VIEW);
    } else if (pwChangeRequired) {
      LOG.info("Moving user to the Change password view");

      AppStateService.setState(AppState.CHANGE_PASSWORD_VIEW);
    } else if (hasEnterprises && !enterpriseAgreementAccepted) {
      LOG.info("Moving user to the Enterprise agreement view");

      AppStateService.setState(AppState.ENTERPRISE_AGREEMENT_VIEW);
    } else {
      AppStateService.setState(AppState.AUTHORIZED_VIEW);
    }
  }

  public static initializeProfile() {
    if (!AppStateUtils.isLoading(AppStateService.getState())) {
      AppStateService.setState(AppState.LOADING_VIEW);
      this.refreshProfileDataFromBackend();
    }
  }

  public static on(e: ProfileServiceEvent, callback: EventObserverCallback): ProfileServiceDestructor {
    return this._observer.listenEvent(e, callback);
  }

  public static changePassword(username: string, password: string, newPassword: string) {
    if (!username || !password || !newPassword) {
      throw new TypeError("Username, password and new password are required.");
    }

    LOG.debug("ProfileService.changePassword: ", username, password, newPassword);

    const payload: { oldPassword: string; newPassword: string } = {
      oldPassword: password,
      newPassword: newPassword,
    };

    const backendId = BackendService.getCurrentBackend();

    HttpService.request(HttpMethod.POST, API_CHANGE_PASSWORD_URL(backendId), payload, HttpRequestType.JSON)
      .then(
        (response: HttpResponse<any>) => {
          LOG.info(`Successfully changed password for user ${username} at backend ${backendId}`);

          LOG.debug("changePassword: Change password  success: ", response);

          MessageService.createMessage({
            type: MessageType.SUCCESS,
            content: T_ACCOUNT_VIEW_PASSWORD_CHANGE_SUCCESS,
            autoDismiss: true,
          });

          this._observer.triggerEvent(ProfileServiceEvent.PASSWORD_CHANGED);
        },
        (response) => {
          LOG.error(`Change password failed for user ${username} at backend ${backendId}`);

          LOG.error("Response data: ", response, response?.data);

          MessageService.createMessage({
            type: MessageType.ERROR,
            icon: IconType.NOTICE,
            content: T_PROFILE_SERVICE_CHANGE_PASSWORD_CHANGE_ERROR,
          });

          this._observer.triggerEvent(ProfileServiceEvent.PASSWORD_CHANGE_FAILED);
        },
      )
      .catch((error) => {
        LOG.error("Error while changing password: ", error);

        MessageService.createMessage({
          type: MessageType.ERROR,
          icon: IconType.NOTICE,
          content: T_PROFILE_SERVICE_CHANGE_PASSWORD_CHANGE_ERROR,
        });

        ErrorService.createError(ErrorCode.CHANGE_PASSWORD_FAILED, error);

        this._observer.triggerEvent(ProfileServiceEvent.PASSWORD_CHANGE_FAILED);
      });
  }

  public static acceptTermsAndConditions() {
    const backendId = BackendService.getCurrentBackend();

    HttpService.request(HttpMethod.POST, API_TERMS_AND_CONDITIONS_URL(backendId), "", HttpRequestType.JSON, {})
      .then(
        (response: HttpResponse<any>) => {
          LOG.info(`Successfully notified backend ${backendId} about accepted ToC`);

          LOG.debug("acceptTermsAndConditions: send success: ", response);

          this._observer.triggerEvent(ProfileServiceEvent.TERMS_AND_CONDITIONS_ACCEPTED);
        },
        (response) => {
          LOG.error("acceptTermsAndConditions: send failed: ", response);

          LOG.error("acceptTermsAndConditions: send failed data: ", response?.data);

          MessageService.createMessage({
            type: MessageType.ERROR,
            icon: IconType.NOTICE,
            content: T_LOGIN_VIEW_TERMS_AND_CONDITIONS_ERROR,
          });
        },
      )
      .catch((error) => {
        LOG.error("acceptTermsAndConditions: send failed: ", error);

        MessageService.createMessage({
          type: MessageType.ERROR,
          icon: IconType.NOTICE,
          content: T_LOGIN_VIEW_TERMS_AND_CONDITIONS_ERROR,
        });
      });
  }

  public static acceptAgreement() {
    const enterpriseId = this._selectedEnterpriseId;

    if (!enterpriseId) throw new TypeError("Enterprise id is required");

    LOG.debug("acceptAgreement: ", enterpriseId);

    const backendId = BackendService.getCurrentBackend();

    const payload = {
      agreementAccepted: true,
    };

    return HttpService.request(HttpMethod.PUT, API_ENTERPRISE_AGREEMENT_URL(enterpriseId.toString(), backendId), payload, HttpRequestType.JSON)
      .then((response) => {
        LOG.debug("acceptAgreement success: ", response);

        this._observer.triggerEvent(ProfileServiceEvent.ENTERPRISE_AGREEMENT_ACCEPTED);
      })
      .catch((error) => {
        LOG.error("acceptAgreement failed: ", error);

        MessageService.createMessage({
          type: MessageType.ERROR,
          icon: IconType.NOTICE,
          content: T_LOGIN_VIEW_TERMS_AND_CONDITIONS_ERROR,
        });

        return Promise.reject(ErrorService.createError(ErrorCode.ENTERPRISE_UPDATE_FAILED, error));
      });
  }

  public static forgotPassword(email: string): Promise<void> {
    if (!email) {
      throw new TypeError("Email is required.");
    }

    LOG.debug("ProfileService.forgotPassword: ", email);

    const payload: { emailAddress: string } = {
      emailAddress: email,
    };

    const backendId = BackendService.getCurrentBackend();

    return HttpService.request(HttpMethod.POST, API_FORGOT_PASSWORD_URL(backendId), payload, HttpRequestType.JSON, {
      [HttpHeader.EMM_USER]: StringUtils.stringToBase64(email),
    })
      .then(
        (response: HttpResponse<any>) => {
          LOG.debug("forgotPassword: send success: ", response);

          this._observer.triggerEvent(ProfileServiceEvent.FORGOT_PASSWORD_EMAIL_SENT);
        },
        (response) => {
          LOG.error("forgotPassword: send failed: ", response, response?.data);

          return Promise.reject(ErrorService.createError(ErrorCode.FORGOT_PASSWORD_FAILED, `HTTP request failed with status #${response?.statusCode}`));
        },
      )
      .catch((error) => {
        LOG.error("forgotPassword: send failed: ", error);

        return Promise.reject(ErrorService.createError(ErrorCode.FORGOT_PASSWORD_FAILED, error));
      });
  }

  public static resetPassword(token: string, newPassword: string) {
    if (!token) {
      throw new TypeError("Token is required.");
    }

    LOG.debug("ProfileService.resetPassword: ", token);

    const payload: { token: string; newPassword: string } = {
      token: token,
      newPassword: newPassword,
    };

    const backendId = BackendService.getCurrentBackend();

    HttpService.request(HttpMethod.POST, API_RESET_PASSWORD_URL(backendId), payload, HttpRequestType.JSON, {
      [HttpHeader.X_TOKEN]: token,
    })
      .then(
        (response: HttpResponse<any>) => {
          LOG.info(`Successfully requested reset password using token '${token}' from backend ${backendId}`);

          LOG.debug("resetPassword: send success: ", response);

          this._observer.triggerEvent(ProfileServiceEvent.RESET_PASSWORD_SUCCESS);
        },
        (response) => {
          const statusCode = response?.status;

          const { errorCode } = response?.data ?? {};

          if (statusCode === 302 && has(response?.headers, HttpHeader.X_LOCATION)) {
            const location = response?.headers[HttpHeader.X_LOCATION];
            if (endsWith(location, "/login")) {
              LOG.info(`HTTP status 302 from backend and backend requested login page.`);
              this._observer.triggerEvent(ProfileServiceEvent.RESET_PASSWORD_SUCCESS);
              return;
            }
          }

          LOG.error("resetPassword: send failed: ", response);

          LOG.error("resetPassword: send failed data: ", response?.data);

          if (errorCode === ErrorCode.API_ERROR_CODE_PASSWORD_MUST_BE_DIFFERENT) {
            MessageService.createMessage({
              type: MessageType.ERROR,
              content: T_LOGIN_VIEW_FORGOT_PASSWORD_HISTORY_ERROR,
            });
          } else if (errorCode && errorCode.substr(0, 4) === ErrorCode[ErrorCode.API_ERROR_CODE_PASSWORD_TOO_SHORT].substr(0, 4)) {
            MessageService.createMessage({
              type: MessageType.ERROR,
              content: T_LOGIN_VIEW_FORGOT_PASSWORD_COMPLEXITY_ERROR,
            });
          } else {
            MessageService.createMessage({
              type: MessageType.ERROR,
              content: T_LOGIN_VIEW_RESET_PASSWORD_ERROR,
            });
          }
        },
      )
      .catch((error) => {
        LOG.error("resetPassword: send failed: ", error);

        MessageService.createMessage({
          type: MessageType.ERROR,
          content: T_LOGIN_VIEW_RESET_PASSWORD_ERROR,
        });
      });
  }

  private static _onValidateToken(token: string): Promise<any> {
    const payload: { token: string; newPassword: string } = {
      token: token,
      newPassword: "",
    };

    const backendId = BackendService.getCurrentBackend();

    return HttpService.request(HttpMethod.POST, API_VALIDATE_TOKEN_URL(backendId), payload, HttpRequestType.JSON, {
      [HttpHeader.X_TOKEN]: token,
    })
      .then(
        (response: HttpResponse<any>) => {
          LOG.info("_onValidateToken:", response);
          const status = response?.status;
          return status;
        },
        (err) => {
          LOG.info("_onValidateToken exception:", err);
          const status = err?.status;
          return status;
        },
      )
      .catch((err) => {
        LOG.error("_onValidateToken error:", err);
        return err;
      });
  }

  public static validateToken(token: string) {
    this._onValidateToken(token).then((status) => {
      if (!status) {
        LOG.error("Token validation unreachable.");
        MessageService.createMessage({
          type: MessageType.ERROR,
          content: T_LOGIN_VIEW_FORGOT_PASSWORD_ERROR,
        });
      } else if (status !== 200) {
        LOG.error("Token validation failed.", status);
        MessageService.createMessage({
          type: MessageType.ERROR,
          content: T_LOGIN_VIEW_EXPIRED_LINK_ERROR,
        });
      }
    });
  }

  private static _isSetupRequired(): boolean {
    if (!ProfileService.hasEnterprise()) {
      return true;
    }

    if (!ProfileService.isEnterpriseInitialized()) {
      return true;
    }

    return !ProfileService.isDefaultPolicyInitialized();
  }

  public static async getTfaQr() {
    const backendId = BackendService.getCurrentBackend();

    return await HttpService.request(HttpMethod.GET, API_TFA_QR_URL(backendId), undefined, HttpRequestType.FILE, {}, "blob")
      .then(({ data }) => URL.createObjectURL(data))
      .catch((error) => {
        LOG.error("getTfaQr failed: ", error);

        MessageService.createMessage({
          type: MessageType.ERROR,
          icon: IconType.NOTICE,
          content: T_LOGIN_VIEW_TERMS_AND_CONDITIONS_ERROR,
        });

        return Promise.reject(ErrorService.createError(ErrorCode.ENTERPRISE_UPDATE_FAILED, error));
      });
  }

  public static registerCode(verificationCode: string) {
    const backendId = BackendService.getCurrentBackend();

    return HttpService.request(HttpMethod.POST, API_TFA_REGISTER_URL(backendId), { verificationCode })
      .then((res) => {
        LOG.debug("registerCode: ", res);

        this._observer.triggerEvent(ProfileServiceEvent.TFA_REGISTERED);
      })
      .catch((error) => {
        LOG.error("registerCode failed: ", error);

        MessageService.createMessage({
          type: MessageType.ERROR,
          icon: IconType.NOTICE,
          content: T_TFA_REGISTER_CODE_ERROR,
        });

        return Promise.reject(ErrorService.createError(ErrorCode.TFA_REGISTER_ERROR, error));
      });
  }

  public static verifyCode(verificationCode: string) {
    const backendId = BackendService.getCurrentBackend();

    return HttpService.request(HttpMethod.POST, API_TFA_VERIFY_URL(backendId, verificationCode))
      .then((res) => {
        LOG.debug("verifyCode: ", res);

        this._observer.triggerEvent(ProfileServiceEvent.TFA_VERIFIED);
      })
      .catch((error) => {
        LOG.error("verifyCode failed: ", error);

        MessageService.createMessage({
          type: MessageType.ERROR,
          icon: IconType.NOTICE,
          content: T_TFA_VERIFY_CODE_ERROR,
        });

        return Promise.reject(ErrorService.createError(ErrorCode.TFA_VERIFY_ERROR, error));
      });
  }

  public static verifyReset(verificationCode: string) {
    const backendId = BackendService.getCurrentBackend();

    return HttpService.request(HttpMethod.POST, API_TFA_VERIFY_RESET_URL(backendId, verificationCode))
      .then((res) => {
        LOG.debug("verifyReset: ", res);

        this._observer.triggerEvent(ProfileServiceEvent.TFA_VERIFIED);
      })
      .catch((error) => {
        LOG.error("verifyReset failed: ", error);

        MessageService.createMessage({
          type: MessageType.ERROR,
          icon: IconType.NOTICE,
          content: T_TFA_VERIFY_CODE_ERROR,
        });

        return Promise.reject(ErrorService.createError(ErrorCode.TFA_VERIFY_ERROR, error));
      });
  }

  public static resetTfa(email: string) {
    const backendId = BackendService.getCurrentBackend();

    return HttpService.request(HttpMethod.DELETE, API_TFA_RESET_URL(backendId, email))
      .then((res) => {
        LOG.debug("resetTfa: ", res);

        this._observer.triggerEvent(ProfileServiceEvent.TFA_RESET);
      })
      .catch((error) => {
        LOG.error("resetTfa failed: ", error);

        MessageService.createMessage({
          type: MessageType.ERROR,
          icon: IconType.NOTICE,
          content: T_TFA_VERIFY_CODE_ERROR,
        });

        return Promise.reject(ErrorService.createError(ErrorCode.TFA_VERIFY_ERROR, error));
      });
  }
}

export default ProfileService;
