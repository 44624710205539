// Copyright © 2020 HMD Global. All rights reserved.

import React from "react";
import "./Loader.scss";
import { LOADER_CLASS_NAME } from "../../../constants/classNames";
import Icon from "../icon/Icon";

/**
 * How long the loader should be hidden before drawn.
 */
const DEFAULT_LOADER_VISIBLE_TIME = 100;

export interface LoaderProps {
  className?: string | undefined;
  icon?: string;
  alt?: string;
  enabled?: boolean;
  visibleTime?: number;
}

export interface LoaderState {
  visible: boolean;
}

export class Loader extends React.Component<LoaderProps, LoaderState> {
  static defaultProps: LoaderProps = {
    className: undefined,
    icon: "LOADING",
    alt: "Loading...",
    enabled: true,
    visibleTime: DEFAULT_LOADER_VISIBLE_TIME,
  };

  private timer: any;

  constructor(props: LoaderProps) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({
        visible: true,
      });
    }, this.props.visibleTime);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    if (!this.state.visible) return "";

    return (
      <div className={LOADER_CLASS_NAME + " " + (this.props.className ?? "")} title={this.props.alt}>
        <Icon type={this.props.icon} label={this.props.alt} />
      </div>
    );
  }
}

export default Loader;
