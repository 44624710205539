// Copyright © 2020 HMD Global. All rights reserved.

import { ReactComponent as HOME } from "../../../assets/icons/svg/ic_home.svg";
import { ReactComponent as APPLICATIONS } from "../../../assets/icons/svg/ic_applications.svg";
import { ReactComponent as POLICIES } from "../../../assets/icons/svg/ic_polices.svg";
import { ReactComponent as DEVICES } from "../../../assets/icons/svg/ic_devices.svg";
import { ReactComponent as USERS } from "../../../assets/icons/svg/ic_users.svg";
import { ReactComponent as LOADING } from "../../../assets/icons/svg/loading.svg";
import { ReactComponent as ADD } from "../../../assets/icons/svg/ic_add_small.svg";
import { ReactComponent as ADD_SIMPLE } from "../../../assets/icons/svg/ic_add_simple.svg";
import { ReactComponent as NOTICE } from "../../../assets/icons/svg/ic_notice.svg";
import { ReactComponent as CLOSE } from "../../../assets/icons/svg/ic_close.svg";
import { ReactComponent as HELP } from "../../../assets/icons/svg/ic_help.svg";
import { ReactComponent as EDIT } from "../../../assets/icons/svg/ic_edit.svg";
import { ReactComponent as REMOVE } from "../../../assets/icons/svg/ic_remove.svg";
import { ReactComponent as NEXT } from "../../../assets/icons/svg/ic_next.svg";
import { ReactComponent as PREV } from "../../../assets/icons/svg/ic_prev.svg";
import { ReactComponent as LOCK } from "../../../assets/icons/svg/ic_lock.svg";
import { ReactComponent as EYE } from "../../../assets/icons/svg/ic_eye.svg";
import { ReactComponent as EYE_CROSSED } from "../../../assets/icons/svg/ic_eye_crossed.svg";
import { ReactComponent as EXT_LINK } from "../../../assets/icons/svg/ic_ext_link.svg";
import { ReactComponent as ARROW_LEFT } from "../../../assets/icons/svg/ic_arrow_left.svg";
import { ReactComponent as ARROW_RIGHT } from "../../../assets/icons/svg/ic_arrow_right.svg";
import { ReactComponent as EMAIL } from "../../../assets/icons/svg/ic_email.svg";
import { ReactComponent as TOGGLE_ON } from "../../../assets/icons/svg/ic_toggle_on.svg";
import { ReactComponent as TOGGLE_OFF } from "../../../assets/icons/svg/ic_toggle_off.svg";
import { ReactComponent as LOCATION } from "../../../assets/icons/svg/ic_location.svg";
import { ReactComponent as DELETE } from "../../../assets/icons/svg/ic_delete.svg";
import { ReactComponent as GEARS } from "../../../assets/icons/svg/ic_gears.svg";
import { ReactComponent as REFRESH } from "../../../assets/icons/svg/ic_refresh.svg";
import { ReactComponent as TARGET } from "../../../assets/icons/svg/ic_target.svg";
import { ReactComponent as SORT } from "../../../assets/icons/svg/ic_sort.svg";
import { ReactComponent as SORT_UP } from "../../../assets/icons/svg/ic_sort_up.svg";
import { ReactComponent as SORT_DOWN } from "../../../assets/icons/svg/ic_sort_down.svg";
import { ReactComponent as DONE } from "../../../assets/icons/svg/ic_done.svg";
import { ReactComponent as RESET } from "../../../assets/icons/svg/ic_reset.svg";
import { ReactComponent as SEARCH } from "../../../assets/icons/svg/ic_search.svg";
import { ReactComponent as DOT } from "../../../assets/icons/svg/ic_dot.svg";
import { ReactComponent as ACTION_DOWN } from "../../../assets/icons/svg/ic_action_down.svg";
import { ReactComponent as MENU } from "../../../assets/icons/svg/ic_menu.svg";
import { ReactComponent as KEY } from "../../../assets/icons/svg/ic_key.svg";
import { ReactComponent as WARNING } from "../../../assets/icons/svg/ic_warning.svg";
import { ReactComponent as STOP } from "../../../assets/icons/svg/ic_stop.svg";
import { ReactComponent as TRIANGLE_NOTICE } from "../../../assets/icons/svg/ic_triangle_notice.svg";
import { ReactComponent as COPY } from "../../../assets/icons/svg/ic_copy.svg";
import { ReactComponent as FILTER } from "../../../assets/icons/svg/ic_filter.svg";
import { ReactComponent as ENTERPRISE } from "../../../assets/icons/svg/ic_enterprise.svg";
import { ReactComponent as ENROLLMENT } from "../../../assets/icons/svg/ic_enrollment.svg";
import { ReactComponent as DOWNLOAD } from "../../../assets/icons/svg/ic_download.svg";
import { ReactComponent as DEVICE_GROUP } from "../../../assets/icons/svg/ic_device_group.svg";
import { ReactComponent as MESSAGE } from "../../../assets/icons/svg/ic_message.svg";
import { ReactComponent as HELP_FAQ } from "../../../assets/icons/svg/ic_help_faq.svg";
import { ReactComponent as VIDEO } from "../../../assets/icons/svg/ic_video.svg";
import { ReactComponent as USER_GUIDE } from "../../../assets/icons/svg/ic_user_guide.svg";
import { ReactComponent as PLAY } from "../../../assets/icons/svg/ic_play.svg";
import { ReactComponent as IMPORT_USERS } from "../../../assets/icons/svg/ic_import_users.svg";
import { ReactComponent as DEVICE_USER } from "../../../assets/icons/svg/ic_device_user.svg";
import { ReactComponent as DRAWER } from "../../../assets/icons/svg/ic_drawer.svg";
import { ReactComponent as MAP } from "../../../assets/icons/svg/ic_map.svg";
import { ReactComponent as GEOFENCE } from "../../../assets/icons/svg/ic_geofence.svg";
import { ReactComponent as FREEZE_PERIOD } from "../../../assets/icons/svg/ic_freeze_period.svg";
import { ReactComponent as CONNECTION } from "../../../assets/icons/svg/ic_connection.svg";
import { ReactComponent as FULLSCREEN } from "../../../assets/icons/svg/ic_fullscreen.svg";
import { ReactComponent as ENROLL_DEVICE } from "../../../assets/icons/svg/ic_enroll_device.svg";
import { ReactComponent as HAMBURGER } from "../../../assets/icons/svg/ic_hamburger.svg";
import { ReactComponent as CONTACT_LIST } from "../../../assets/icons/svg/ic_contact_list.svg";
import { ReactComponent as APP_UPDATES } from "../../../assets/icons/svg/ic_app_updates.svg";
import { ReactComponent as SYSTEM_UPDATES } from "../../../assets/icons/svg/ic_system_updates.svg";
import { ReactComponent as COMPANION_APP } from "../../../assets/icons/svg/ic_companion_app.svg";
import { ReactComponent as SECURITY } from "../../../assets/icons/svg/ic_security.svg";
import { ReactComponent as PROFILE } from "../../../assets/icons/svg/ic_profile.svg";
import { ReactComponent as NETWORK } from "../../../assets/icons/svg/ic_network.svg";
import { ReactComponent as DEVICE } from "../../../assets/icons/svg/ic_device.svg";
import { ReactComponent as BRIEFCASE } from "../../../assets/icons/svg/ic_briefcase.svg";

export const IconImage: { [key: string]: any } = {
  EMAIL,
  LOCK,
  EYE,
  EYE_CROSSED,
  ARROW_LEFT,
  ARROW_RIGHT,
  RESET,
  SORT,
  SORT_UP,
  SORT_DOWN,
  HOME,
  LOCATION,
  APPLICATIONS,
  POLICIES,
  DEVICES,
  USERS,
  LOADING,
  EDIT,
  REMOVE,
  ADD,
  ADD_SIMPLE,
  NOTICE,
  CLOSE,
  HELP,
  NEXT,
  PREV,
  EXT_LINK,
  TOGGLE_ON,
  TOGGLE_OFF,
  DELETE,
  REFRESH,
  TARGET,
  GEARS,
  DOT,
  SEARCH,
  DONE,
  TRIANGLE_NOTICE,
  ACTION_DOWN,
  MENU,
  KEY,
  WARNING,
  COPY,
  STOP,
  FILTER,
  ENTERPRISE,
  ENROLLMENT,
  DOWNLOAD,
  DEVICE_GROUP,
  MESSAGE,
  HELP_FAQ,
  VIDEO,
  USER_GUIDE,
  PLAY,
  IMPORT_USERS,
  DEVICE_USER,
  DRAWER,
  MAP,
  GEOFENCE,
  FREEZE_PERIOD,
  CONNECTION,
  FULLSCREEN,
  ENROLL_DEVICE,
  HAMBURGER,
  CONTACT_LIST,
  APP_UPDATES,
  SYSTEM_UPDATES,
  COMPANION_APP,
  SECURITY,
  PROFILE,
  NETWORK,
  DEVICE,
  BRIEFCASE,
};
