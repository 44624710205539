// Copyright © 2020 HMD Global. All rights reserved.

import AssertUtils from "../services/AssertUtils";
import { IS_DEVELOPMENT_BUILD } from "../constants/environment";
import EnterpriseId from "./EnterpriseId";

/**
 * Frontend's device id data model
 */
export class DeviceId {
  /**
   * The enterprise ID, eg. `"LC04ghmbil"`
   *
   * @private
   */
  private readonly _enterpriseId: string;

  /**
   * The device ID, eg. `"3b92f9437b4f9c9c"`
   *
   * @private
   */
  private readonly _id: string;

  /**
   *
   * @param enterpriseId The enterprise ID, eg. `"LC04ghmbil"`
   * @param deviceId The device ID, eg. `"3b92f9437b4f9c9c"`
   */
  constructor(enterpriseId: string, deviceId: string) {
    if (IS_DEVELOPMENT_BUILD) {
      AssertUtils.isString(enterpriseId);
      AssertUtils.isString(deviceId);

      if (enterpriseId.startsWith("enterprises/")) {
        throw new TypeError('The enterprise ID must not start with "enterprises/"');
      }

      if (deviceId.startsWith("enterprises/")) {
        throw new TypeError('The device ID must not start with "enterprises/". Use DeviceUtils.parseDeviceIdObject().');
      }
    }

    if (!enterpriseId) throw new TypeError("DeviceId: enterpriseId was not defined");
    if (!deviceId) throw new TypeError("DeviceId: deviceId was not defined");

    this._enterpriseId = enterpriseId;
    this._id = deviceId;
  }

  /**
   * Returns the enterprise ID part, eg. `"LC04ghmbil"`
   */
  public getEnterpriseId(): string {
    return this._enterpriseId;
  }

  public getEnterpriseIdObject(): EnterpriseId {
    return EnterpriseId.create(this.getEnterpriseId());
  }

  /**
   * Returns the device ID part, eg. `"3b92f9437b4f9c9c"`
   */
  public getId(): string {
    return this._id;
  }

  /**
   * Returns the full Google ID part, eg. `"enterprises/:enterpriseId/devices/:deviceId"`
   */
  public getGoogleId(): string {
    return `enterprises/${this._enterpriseId}/devices/${this._id}`;
  }

  public valueOf(): string {
    return this.getGoogleId();
  }

  public toString(): string {
    return this.valueOf();
  }

  public toJSON(): string {
    return this.valueOf();
  }

  public isEqual(value: any): value is DeviceId {
    return !!value && value instanceof DeviceId && value.valueOf() === this.valueOf();
  }

  public static create(enterpriseId: string, deviceId: string): DeviceId {
    return new DeviceId(enterpriseId, deviceId);
  }

  public static isEqual(a: DeviceId | undefined, b: DeviceId | undefined) {
    if (a === b) return true;

    if (!a || !(a instanceof DeviceId)) return false;

    return a.isEqual(b);
  }
}

export default DeviceId;
