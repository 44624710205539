// Copyright © 2020 HMD Global. All rights reserved.

import { remove, slice } from "../modules/lodash";
import EventObserver, { EventObserverCallback, EventObserverDestructorCallback } from "./EventObserver";
import LogService from "./LogService";
import PopupService from "./PopupService";

const LOG = LogService.createLogger("ModalService");

export enum ModalServiceEvent {
  MODAL_ADDED = "modalService:modalAdded",
  MODAL_REMOVED = "modalService:modalRemoved",
}

export enum ModalType {
  CENTER = "CENTER",
  SIDE = "SIDE",
  EXCLUSIVE = "EXCLUSIVE",
}

export interface Modal {
  readonly type: ModalType;
  readonly component: any;
  readonly props?: any;
}

export type ModalServiceDestructor = EventObserverDestructorCallback;

export interface ModalDestructor {
  (): void;
}

export class ModalService {
  public static Event = ModalServiceEvent;

  private static _observer: EventObserver = new EventObserver("ModalService");
  private static _modals: Array<Modal> = [];

  public static createModal(modal: Modal): ModalDestructor {
    PopupService.closeAllPopups();

    this._modals.push(modal);

    this._observer.triggerEvent(ModalServiceEvent.MODAL_ADDED);

    LOG.debug("Added modal ", modal);

    return () => this.removeModal(modal);
  }

  public static removeModal(modal: Modal) {
    const removed = remove(this._modals, (item) => item === modal);

    if (removed.length) {
      this._observer.triggerEvent(ModalServiceEvent.MODAL_REMOVED);

      LOG.debug("Removed modal ", modal);
    }
  }

  public static removeFirstModal() {
    this.removeModal(this.getFirstModal());
  }

  public static removeLatestModal() {
    this.removeModal(this.getLatestModal());
  }

  public static hasModals(): boolean {
    return !!this._modals.length;
  }

  public static getAllModals(): Array<Readonly<Modal>> {
    return slice(this._modals);
  }

  public static getFirstModal(): Modal {
    if (!this.hasModals()) {
      throw new TypeError("No modals");
    }

    return this._modals[0];
  }

  public static getLatestModal(): Modal {
    if (!this.hasModals()) {
      throw new TypeError("No modals");
    }

    return this._modals[this._modals.length - 1];
  }

  public static on(e: ModalServiceEvent, callback: EventObserverCallback): ModalServiceDestructor {
    return this._observer.listenEvent(e, callback);
  }
}

export default ModalService;
