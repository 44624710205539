// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import { Switch as ReactSwitch, useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import RouteService, { isRouteLocationObject } from "../../../services/RouteService";
import LogService from "../../../services/LogService";

const LOG = LogService.createLogger("Switch");

export function Switch(props: { children: React.ReactNode }) {
  const location = useLocation();

  useEffect(() => {
    LOG.debug("Setting route location to: ", location);
    if (isRouteLocationObject(location)) {
      RouteService.setRouteLocation(location);
    } else {
      LOG.warn("Location changed to unsupported interface: ", location);
    }
  }, [location]);

  const history = useHistory();

  useEffect(() => {
    return RouteService.on(RouteService.Event.PUSH_HISTORY_REQUEST, (event: any, appTarget: string) => {
      LOG.debug("Changing route to: ", appTarget);

      history.push(appTarget);
    });
  }, [history]);

  return <ReactSwitch>{props.children}</ReactSwitch>;
}

export default Switch;
