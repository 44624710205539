// Copyright © 2020 HMD Global. All rights reserved.

import EventObserver, { EventObserverCallback, EventObserverDestructorCallback } from "./EventObserver";

export enum PopupServiceEvent {
  CLOSE_ALL = "PopupServiceEvent:changed",
}

export type PopupServiceDestructor = EventObserverDestructorCallback;

export class PopupService {
  public static Event = PopupServiceEvent;

  private static _observer: EventObserver = new EventObserver("PopupService");

  public static closeAllPopups() {
    this._observer.triggerEvent(PopupServiceEvent.CLOSE_ALL);
  }

  public static on(e: PopupServiceEvent, callback: EventObserverCallback): PopupServiceDestructor {
    return this._observer.listenEvent(e, callback);
  }
}

export default PopupService;
