// Copyright © 2020 HMD Global. All rights reserved.

import enTranslation from "./en.json";
import fiTranslation from "./fi.json";
import deTranslation from "./de.json";
import ptTranslation from "./pt.json";
import frTranslation from "./fr.json";
import zhRcnTranslation from "./zh-rcn.json";
import esTranslation from "./es.json";
import TranslationService from "../services/TranslationService";
import { IS_DEVELOPMENT_BUILD } from "../constants/environment";
import { DEFAULT_LANGUAGE, ENABLED_LANGUAGES } from "../constants/frontend";
import { reduce } from "../modules/lodash";
import { parseLanguageToKey } from "./TranslationLanguageKey";
import { Language } from "./Language";

export const TRANSLATION_OPTIONS = {
  // we init with resources
  resources: reduce(
    ENABLED_LANGUAGES,
    (resources: Record<string, any>, lang: Language) => {
      switch (lang) {
        case Language.ENGLISH:
          resources.en = { translations: enTranslation };
          break;

        case Language.FINNISH:
          resources.fi = { translations: fiTranslation };
          break;

        case Language.GERMAN:
          resources.de = { translations: deTranslation };
          break;

        case Language.FRENCH:
          resources.fr = { translations: frTranslation };
          break;

        case Language.PORTUGUESE:
          resources.pt = { translations: ptTranslation };
          break;

        case Language.CHINESE:
          resources.zh = { translations: zhRcnTranslation };
          break;

        case Language.SPANISH:
          resources.es = { translations: esTranslation };
          break;

        default:
          throw new TypeError("Unsupported language: " + lang);
      }

      return resources;
    },
    {},
  ),

  fallbackLng: parseLanguageToKey(DEFAULT_LANGUAGE),

  debug: IS_DEVELOPMENT_BUILD,

  saveMissing: true,
  missingKeyHandler: (lng: any, ns: string, key: string) => {
    // LOG.warn(`Translation missing: "${key}" = "${key}`);

    if (IS_DEVELOPMENT_BUILD) {
      TranslationService.addMissingTranslationKey(key);
    }
  },

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  nsSeparator: "|",

  interpolation: {
    escapeValue: false,
  },
};

export default TRANSLATION_OPTIONS;
