// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import "./Field.scss";
import { FIELD_CLASS_NAME } from "../../../constants/classNames";

export interface FieldProps {
  className?: string;
  style?: Record<string, string>;
  borders?: boolean;
  highlight?: boolean;
}

export interface FieldState {}

export class Field extends React.Component<FieldProps, FieldState> {
  static defaultProps: Partial<FieldProps> = {
    borders: false,
    highlight: false,
  };

  //constructor (props : FieldProps) {
  //    super(props);
  //}

  render() {
    const className =
      FIELD_CLASS_NAME +
      " " +
      FIELD_CLASS_NAME +
      (this.props.borders ? "-with-borders" : "-no-borders") +
      " " +
      FIELD_CLASS_NAME +
      (this.props.highlight ? "-with-highlight" : "-no-highlight") +
      " " +
      (this.props.className ?? "");

    const childProps: { style?: Record<string, string>; className?: string } = {
      className: className,
    };

    if (this.props.style) {
      childProps.style = { ...this.props.style };
    }

    return <div {...childProps}>{this.props.children}</div>;
  }
}

export default Field;
