// Copyright © 2020 HMD Global. All rights reserved.

import { has } from "../modules/lodash";

export enum TemporaryViewDataProperty {
  /**
   * From a security point of view, saving passwords in the UI is risky, but this was requested so that
   * the user does not have to input their old password instantly after first login to the change password form.
   *
   * It is stored for 60 seconds by default in the client memory.
   *
   * If a password cannot be found, the user has to input their old password again if it is needed to change the password.
   */
  LOGIN_PASSWORD = "LOGIN_PASSWORD",
}

interface TemporaryViewDataObject {
  readonly timer: any;

  readonly data: any;
}

export class TemporaryViewDataService {
  private static _records: Record<string, TemporaryViewDataObject> = {};

  private static _clearTemporaryViewData(key: TemporaryViewDataProperty) {
    clearTimeout(TemporaryViewDataService._records[key].timer);

    delete TemporaryViewDataService._records[key];
  }

  public static clearTemporaryViewData(key: TemporaryViewDataProperty) {
    if (has(this._records, key)) {
      TemporaryViewDataService._clearTemporaryViewData(key);
    }
  }

  /**
   *
   * @param key The property keyword to save
   * @param data The data to save
   * @param time The time in milliseconds until the data is removed
   */
  public static saveTemporaryViewData(key: TemporaryViewDataProperty, data: any, time: number) {
    if (has(this._records, key)) {
      TemporaryViewDataService._clearTemporaryViewData(key);
    }

    const timer = setTimeout(() => TemporaryViewDataService.clearTemporaryViewData(key), time);

    this._records[key] = {
      timer: timer,

      data: data,
    };
  }

  public static getTemporaryViewData(key: TemporaryViewDataProperty): any | undefined {
    if (has(this._records, key)) {
      return this._records[key].data;
    }

    return undefined;
  }
}
