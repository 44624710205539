// Copyright © 2021 HMD Global. All rights reserved.

import { isString } from "../modules/lodash";

export enum ButtonType {
  DEFAULT = "default",
  PRIMARY = "primary",
  DANGER = "danger",
  ICON = "icon",
}

export function isButtonType(value: any): value is ButtonType {
  if (!isString(value)) return false;

  switch (value) {
    case ButtonType.DEFAULT:
    case ButtonType.PRIMARY:
    case ButtonType.DANGER:
    case ButtonType.ICON:
      return true;
  }

  return false;
}

export default ButtonType;
