// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import "./LoginForm.scss";
import { LOGIN_FORM_CLASS_NAME } from "../../../constants/classNames";
import Form, { FormSubmitCallback } from "../../common/form/Form";
import { TextFieldChangeCallback } from "../../common/textField/TextField";
import SubmitButton from "../../common/submitButton/SubmitButton";
import {
  T_LOGIN_FORM_EMAIL_LABEL,
  T_LOGIN_FORM_EMAIL_PLACEHOLDER,
  T_LOGIN_FORM_PASSWORD_LABEL,
  T_LOGIN_FORM_PASSWORD_PLACEHOLDER,
  T_LOGIN_SIGN_IN_BUTTON_LABEL,
  T_LOGIN_SIGN_IN_FORM_TITLE,
} from "../../../translations/translationTokens";
import PasswordField from "../../common/passwordField/PasswordField";
import EmailField from "../../common/emailField/EmailField";
import TranslateCallback from "../../../TranslateCallback";
import { IconType } from "../../common/icon/IconType";
import MessageService from "../../../services/MessageService";
import LogService from "../../../services/LogService";

const LOG = LogService.createLogger("LoginForm");

export interface LoginFormState {
  email: string;
  password: string;
}

export interface LoginFormSubmitCallback {
  (formData: LoginFormState): void;
}

export interface LoginFormProps {
  t?: TranslateCallback;

  className?: string | undefined;

  submit: LoginFormSubmitCallback;

  loading?: boolean;

  error?: any;
}

export class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
  static defaultProps: LoginFormProps = {
    className: undefined,
    error: undefined,
    submit: () => undefined,
    loading: false,
  };

  public handleEmailChange: TextFieldChangeCallback | undefined;
  public handlePasswordChange: TextFieldChangeCallback | undefined;
  public handleSubmit: FormSubmitCallback | undefined;

  constructor(props: LoginFormProps) {
    super(props);

    this.state = {
      email: "",
      password: "",
    };

    this.handleEmailChange = this._handleEmailChange.bind(this);
    this.handlePasswordChange = this._handlePasswordChange.bind(this);
    this.handleSubmit = this._handleSubmit.bind(this);
  }

  componentWillUnmount() {
    this._clearMessages();
  }

  _clearMessages() {
    if (MessageService.hasMessages()) {
      const messages = MessageService.getMessages();

      const message = messages.filter((msg) => msg.content.startsWith("login") || msg.content.startsWith("profileService")).map((msg) => msg.messageId);

      message.forEach((id) => id && MessageService.removeMessageById(id));

      LOG.debug("LoginForm messages cleared.");
    }
  }

  _handleEmailChange(newValue: string) {
    this.setState({
      email: newValue,
      password: this.state.password,
    });
  }

  _handlePasswordChange(newValue: string) {
    this.setState({
      email: this.state.email,
      password: newValue,
    });
  }

  _handleSubmit() {
    this.props.submit(this.state);

    this._clearMessages();
  }

  protected _isValidState(): boolean {
    return !this.props.loading && !!(this.state.email && this.state.email.indexOf("@") >= 2) && !!this.state.password;
  }

  render() {
    const t = this.props.t ?? ((key: string) => key);

    const error = this.props.error ?? "";

    return (
      <Form title={t(T_LOGIN_SIGN_IN_FORM_TITLE)} className={LOGIN_FORM_CLASS_NAME} loading={this.props.loading} submit={this.handleSubmit}>
        <EmailField iconLabel={t(T_LOGIN_FORM_EMAIL_LABEL)} placeholder={t(T_LOGIN_FORM_EMAIL_PLACEHOLDER)} change={this.handleEmailChange} />

        <PasswordField icon={IconType.PASSWORD} iconLabel={t(T_LOGIN_FORM_PASSWORD_LABEL)} placeholder={t(T_LOGIN_FORM_PASSWORD_PLACEHOLDER)} change={this.handlePasswordChange} />

        <SubmitButton className={LOGIN_FORM_CLASS_NAME + "-submit-button"} label={t(T_LOGIN_SIGN_IN_BUTTON_LABEL)} enabled={this._isValidState()} />

        {error}
      </Form>
    );
  }
}
