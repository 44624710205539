import { isString } from "../modules/lodash";

export enum AutoCompleteType {
  DEFAULT = "DEFAULT",
  OFF = "OFF",
  ON = "ON",
  EMAIL = "EMAIL",
  USERNAME = "USERNAME",
  NEW_PASSWORD = "NEW_PASSWORD",
}

export type AutoCompleteInputType = AutoCompleteType | boolean | undefined;

export class FormUtils {
  static parseAutoCompleteString(value: AutoCompleteType | undefined | boolean): string | undefined {
    if (value === false) {
      return "off";
    }

    if (value === true) {
      return "on";
    }

    if (value === undefined) {
      return undefined;
    }

    if (isString(value)) {
      switch (value) {
        case AutoCompleteType.DEFAULT:
          return undefined;

        case AutoCompleteType.OFF:
          return "off";

        case AutoCompleteType.ON:
          return "on";

        case AutoCompleteType.EMAIL:
          return "email";

        case AutoCompleteType.USERNAME:
          return "username";

        case AutoCompleteType.NEW_PASSWORD:
          return "new-password";
      }
    }

    return undefined;
  }
}

export default FormUtils;
