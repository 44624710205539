// Copyright © 2021 HMD Global. All rights reserved.

import * as React from "react";
import "./EnterpriseAgreementForm.scss";
import { ENTERPRISE_AGREEMENT_FORM_CLASS_NAME } from "../../../constants/classNames";
import Form, { FormSubmitCallback } from "../../common/form/Form";
import SubmitButton from "../../common/submitButton/SubmitButton";
import {
  T_ADD_ENTERPRISE_MODAL_ACCEPT_AGREEMENT_LINK_TEXT,
  T_ENTERPRISE_AGREEMENT_VIEW_ACCEPT_AGREEMENT_LABEL,
  T_ENTERPRISE_AGREEMENT_VIEW_DISCLAIMER,
  T_ENTERPRISE_MODAL_DATA_PROTECTION_EMAIL_LABEL,
  T_ENTERPRISE_MODAL_DATA_PROTECTION_EMAIL_PLACEHOLDER,
  T_ENTERPRISE_MODAL_DATA_PROTECTION_NAME_LABEL,
  T_ENTERPRISE_MODAL_DATA_PROTECTION_NAME_PLACEHOLDER,
  T_ENTERPRISE_MODAL_DATA_PROTECTION_PHONE_LABEL,
  T_ENTERPRISE_MODAL_DATA_PROTECTION_PHONE_PLACEHOLDER,
  T_ENTERPRISE_MODAL_DATA_PROTECTION_SUBTITLE,
  T_ENTERPRISE_MODAL_DATA_PROTECTION_TITLE,
  T_ENTERPRISE_MODAL_EU_REPRESENTATIVE_EMAIL_LABEL,
  T_ENTERPRISE_MODAL_EU_REPRESENTATIVE_EMAIL_PLACEHOLDER,
  T_ENTERPRISE_MODAL_EU_REPRESENTATIVE_NAME_LABEL,
  T_ENTERPRISE_MODAL_EU_REPRESENTATIVE_NAME_PLACEHOLDER,
  T_ENTERPRISE_MODAL_EU_REPRESENTATIVE_PHONE_LABEL,
  T_ENTERPRISE_MODAL_EU_REPRESENTATIVE_PHONE_PLACEHOLDER,
  T_ENTERPRISE_MODAL_EU_REPRESENTATIVE_SUBTITLE,
  T_LOGIN_VIEW_CONTINUE_BUTTON_LABEL,
  T_SETUP_VIEW_SIGN_OUT_LABEL,
} from "../../../translations/translationTokens";
import TranslateCallback from "../../../TranslateCallback";
import CheckboxField, { CheckboxFieldChangeCallback } from "../../common/checkboxField/CheckboxField";
import TranslateUtils from "../../../services/TranslateUtils";
import { MANAGED_GOOGLE_PLAY_AGREEMENT_URL } from "../../../constants/backend";
import Button, { ButtonClickCallback } from "../../common/button/Button";
import SessionService from "../../../services/SessionService";
import ExpandSection from "../../common/expandSection/ExpandSection";
import TextField from "../../common/textField/TextField";
import EmailField from "../../common/emailField/EmailField";
import EnterpriseEditor, { ChangeEnterpriseModelCallback } from "../../../services/EnterpriseEditor";
import { EnterpriseModel } from "../../../services/types/EnterpriseModel";
import LogService from "../../../services/LogService";
import IconType from "../../common/icon/IconType";
import EnterpriseService from "../../../services/EnterpriseService";
import ProfileService from "../../../services/ProfileService";

const LOG = LogService.createLogger("EnterpriseAgreementForm");

export interface EnterpriseAgreementFormState {
  enterpriseAgreementAccepted: boolean;
  model: EnterpriseModel;
  changed: boolean;
}

export interface EnterpriseAgreementFormProps {
  t?: TranslateCallback;
  className?: string | undefined;
  loading?: boolean;
  error?: any;
}

export class EnterpriseAgreementForm extends React.Component<EnterpriseAgreementFormProps, EnterpriseAgreementFormState> {
  private readonly _enterpriseAgreementCheckboxCallback: CheckboxFieldChangeCallback;
  private readonly _modelChangeCallback: ChangeEnterpriseModelCallback;
  private _signOutCallback: ButtonClickCallback;

  static defaultProps: EnterpriseAgreementFormProps = {
    className: undefined,
    error: undefined,
    loading: false,
  };

  public handleSubmit: FormSubmitCallback | undefined;

  constructor(props: EnterpriseAgreementFormProps) {
    super(props);

    this.state = {
      enterpriseAgreementAccepted: false,
      model: {},
      changed: false,
    };

    this.handleSubmit = this._handleSubmit.bind(this);
    this._enterpriseAgreementCheckboxCallback = this._onEnterpriseAgreementCheckboxChanged.bind(this);
    this._signOutCallback = this._onSignOut.bind(this);
    this._modelChangeCallback = this._onEnterpriseModelChange.bind(this);
  }

  componentDidMount() {
    this._fetchData();
  }

  private async _fetchData() {
    const enterpriseId = ProfileService.getEnterpriseId();

    if (!enterpriseId) return LOG.debug("No enterpriseId defined.");

    try {
      const model = await EnterpriseService.getEnterpriseModel(enterpriseId.getGoogleId());
      this.setState({
        model,
      });
    } catch (err) {
      LOG.error("_fetchData failed:", err);
    }
  }

  private async _handleSubmit() {
    const { model, changed } = this.state;

    if (changed) {
      await EnterpriseService.editEnterprise(model);
      await ProfileService.acceptAgreement();
    } else {
      await ProfileService.acceptAgreement();
    }
  }

  private _onEnterpriseAgreementCheckboxChanged() {
    this.setState({
      enterpriseAgreementAccepted: !this.state.enterpriseAgreementAccepted,
    });
  }

  private _onEnterpriseModelChange(newModel: EnterpriseModel) {
    LOG.debug("New model: ", newModel);

    this.setState({
      model: newModel,
      changed: true,
    });
  }

  private _onSignOut() {
    SessionService.logout();
  }

  render() {
    const t = this.props.t ?? ((key: string) => key);

    const error = this.props.error ?? "";

    const { model } = this.state;

    const modelEditor = EnterpriseEditor.start(model, this._modelChangeCallback);

    return (
      <Form title={t(T_ADD_ENTERPRISE_MODAL_ACCEPT_AGREEMENT_LINK_TEXT)} className={ENTERPRISE_AGREEMENT_FORM_CLASS_NAME} loading={this.props.loading} submit={this.handleSubmit}>
        <p className={`${ENTERPRISE_AGREEMENT_FORM_CLASS_NAME}-disclaimer`}>{t(T_ENTERPRISE_AGREEMENT_VIEW_DISCLAIMER)}</p>
        <ExpandSection title={t(T_ENTERPRISE_MODAL_DATA_PROTECTION_TITLE)} openInitially={false}>
          <h3 className={`${ENTERPRISE_AGREEMENT_FORM_CLASS_NAME}-sub-title`}>{t(T_ENTERPRISE_MODAL_DATA_PROTECTION_SUBTITLE)}</h3>
          <TextField
            label={t(T_ENTERPRISE_MODAL_DATA_PROTECTION_NAME_LABEL)}
            placeholder={t(T_ENTERPRISE_MODAL_DATA_PROTECTION_NAME_PLACEHOLDER)}
            value={model?.contactInfo?.dataProtectionOfficerName ?? ""}
            change={(value: string) => modelEditor.changeContactInfo("dataProtectionOfficerName", value).commit()}
          />
          <EmailField
            icon={IconType.NONE}
            label={t(T_ENTERPRISE_MODAL_DATA_PROTECTION_EMAIL_LABEL)}
            placeholder={t(T_ENTERPRISE_MODAL_DATA_PROTECTION_EMAIL_PLACEHOLDER)}
            value={model?.contactInfo?.dataProtectionOfficerEmail ?? ""}
            change={(value: string) => modelEditor.changeContactInfo("dataProtectionOfficerEmail", value).commit()}
          />
          <TextField
            label={t(T_ENTERPRISE_MODAL_DATA_PROTECTION_PHONE_LABEL)}
            placeholder={t(T_ENTERPRISE_MODAL_DATA_PROTECTION_PHONE_PLACEHOLDER)}
            value={model?.contactInfo?.dataProtectionOfficerPhone ?? ""}
            change={(value: string) => modelEditor.changeContactInfo("dataProtectionOfficerPhone", value).commit()}
          />
          <h3 className={`${ENTERPRISE_AGREEMENT_FORM_CLASS_NAME}-sub-title`}>{t(T_ENTERPRISE_MODAL_EU_REPRESENTATIVE_SUBTITLE)}</h3>
          <TextField
            label={t(T_ENTERPRISE_MODAL_EU_REPRESENTATIVE_NAME_LABEL)}
            placeholder={t(T_ENTERPRISE_MODAL_EU_REPRESENTATIVE_NAME_PLACEHOLDER)}
            value={model?.contactInfo?.euRepresentativeName ?? ""}
            change={(value: string) => modelEditor.changeContactInfo("euRepresentativeName", value).commit()}
          />
          <EmailField
            icon={IconType.NONE}
            label={t(T_ENTERPRISE_MODAL_EU_REPRESENTATIVE_EMAIL_LABEL)}
            placeholder={t(T_ENTERPRISE_MODAL_EU_REPRESENTATIVE_EMAIL_PLACEHOLDER)}
            value={model?.contactInfo?.euRepresentativeEmail ?? ""}
            change={(value: string) => modelEditor.changeContactInfo("euRepresentativeEmail", value).commit()}
          />
          <TextField
            label={t(T_ENTERPRISE_MODAL_EU_REPRESENTATIVE_PHONE_LABEL)}
            placeholder={t(T_ENTERPRISE_MODAL_EU_REPRESENTATIVE_PHONE_PLACEHOLDER)}
            value={model?.contactInfo?.euRepresentativePhone ?? ""}
            change={(value: string) => modelEditor.changeContactInfo("euRepresentativePhone", value).commit()}
          />
        </ExpandSection>
        <CheckboxField
          className={`${ENTERPRISE_AGREEMENT_FORM_CLASS_NAME}-agreement-checkbox`}
          label={TranslateUtils.magicTranslateWithComponents(t, T_ENTERPRISE_AGREEMENT_VIEW_ACCEPT_AGREEMENT_LABEL, {
            AGREEMENT_LINK: (
              <a target="_blank" rel="noopener noreferrer" href={MANAGED_GOOGLE_PLAY_AGREEMENT_URL} onClick={() => window.open(MANAGED_GOOGLE_PLAY_AGREEMENT_URL)}>
                {t(T_ADD_ENTERPRISE_MODAL_ACCEPT_AGREEMENT_LINK_TEXT)}
              </a>
            ),
          })}
          checked={this.state.enterpriseAgreementAccepted}
          change={this._enterpriseAgreementCheckboxCallback}
        />
        <div className={`${ENTERPRISE_AGREEMENT_FORM_CLASS_NAME}-actions`}>
          <Button className={`${ENTERPRISE_AGREEMENT_FORM_CLASS_NAME}-sign-out`} click={this._signOutCallback}>
            {t(T_SETUP_VIEW_SIGN_OUT_LABEL)}
          </Button>
          <SubmitButton
            className={`${ENTERPRISE_AGREEMENT_FORM_CLASS_NAME}-submit-button`}
            enabled={this.state.enterpriseAgreementAccepted}
            label={t(T_LOGIN_VIEW_CONTINUE_BUTTON_LABEL)}
          />
        </div>
        {error}
      </Form>
    );
  }
}
