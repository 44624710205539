// Copyright © 2020 HMD Global. All rights reserved.

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translations from "./translations/index";

i18n.use(LanguageDetector).use(initReactI18next).init(translations);

export default i18n;
