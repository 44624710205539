// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import "./ChangePasswordView.scss";
import { SplitView } from "../splitView/SplitView";
import Section from "../../common/section/Section";
import TranslateCallback from "../../../TranslateCallback";
import { ShowcaseView } from "../showcaseView/ShowcaseView";
import Branding from "../../layout/branding/Branding";
import AppStateService from "../../../services/AppStateService";
import AppStateEvent from "../../../services/AppStateEvent";
import AppStateUtils from "../../../services/AppStateUtils";
import { EventObserverDestructorCallback } from "../../../services/EventObserver";
import NotificationContainer from "../../layout/notificationContainer/NotificationContainer";
import MessageService from "../../../services/MessageService";
import { IconType } from "../../common/icon/IconType";
import { T_LOGIN_SERVICE_LOGIN_ERROR } from "../../../translations/translationTokens";
import AdService, { PresentationModel } from "../../../services/AdService";
import LogService from "../../../services/LogService";
import { ChangePasswordForm, ChangePasswordFormState } from "../../forms/changePasswordForm/ChangePasswordForm";
import { CHANGE_PASSWORD_VIEW_CLASS_NAME } from "../../../constants/classNames";
import ProfileService, { ProfileServiceDestructor } from "../../../services/ProfileService";
import { TemporaryViewDataProperty, TemporaryViewDataService } from "../../../services/TemporaryViewDataService";
import SessionService from "../../../services/SessionService";
import { MessageType } from "../../../services/types/MessageType";

const LOG = LogService.createLogger("ChangePasswordView");

export interface ChangePasswordViewState {
  loading: boolean;

  presentations: Readonly<Array<PresentationModel>>;

  oldPassword: string;
}

export interface ChangePasswordViewProps {
  t?: TranslateCallback;

  className?: string | undefined;
}

export class ChangePasswordView extends React.Component<ChangePasswordViewProps, ChangePasswordViewState> {
  static defaultProps: Partial<ChangePasswordViewProps> = {};

  private _appStateListener: EventObserverDestructorCallback | undefined;
  private _changePasswordListener: ProfileServiceDestructor | undefined;

  /**
   * User's new password for re-login later after the password is changed.
   *
   * @private
   */
  private _newPassword: string | undefined;

  constructor(props: ChangePasswordViewProps) {
    super(props);

    this.state = {
      presentations: AdService.getLoginPresentations(),
      loading: AppStateUtils.isLoading(AppStateService.getState()),
      oldPassword: "",
    };

    this._appStateListener = undefined;
  }

  componentDidMount() {
    const oldPassword = TemporaryViewDataService.getTemporaryViewData(TemporaryViewDataProperty.LOGIN_PASSWORD) ?? "";

    this.setState({
      oldPassword: oldPassword,
      presentations: AdService.getLoginPresentations(),
      loading: AppStateUtils.isLoading(AppStateService.getState()),
    });

    this._appStateListener = AppStateService.on(AppStateEvent.CHANGED, () => {
      this.setState({
        ...this.state,
        loading: AppStateUtils.isLoading(AppStateService.getState()),
      });
    });

    this._changePasswordListener = ProfileService.on(ProfileService.Event.PASSWORD_CHANGED, () => {
      if (this._newPassword) {
        SessionService.login(ProfileService.getUserEmail(), this._newPassword);
      } else {
        LOG.warn("We did not have previous password saved. Cannot relogin. This is probably an error!");
      }
    });
  }

  componentWillUnmount() {
    if (this._appStateListener !== undefined) {
      this._appStateListener();
      this._appStateListener = undefined;
    }

    if (this._changePasswordListener !== undefined) {
      this._changePasswordListener();
      this._changePasswordListener = undefined;
    }
  }

  render() {
    const t = this.props.t ?? ((key: string) => key);

    return (
      <SplitView className={CHANGE_PASSWORD_VIEW_CLASS_NAME + " " + (this.props.className ?? "")}>
        <ShowcaseView presentations={this.state.presentations} />

        <Section className={CHANGE_PASSWORD_VIEW_CLASS_NAME + "-section"}>
          <Branding className={CHANGE_PASSWORD_VIEW_CLASS_NAME + "-branding"} t={t} />

          <div className={CHANGE_PASSWORD_VIEW_CLASS_NAME + "-content"}>
            <ChangePasswordForm
              className={CHANGE_PASSWORD_VIEW_CLASS_NAME + "-form"}
              t={t}
              loading={this.state.loading}
              oldPassword={this.state.oldPassword}
              oldPasswordEnabled={this.state.oldPassword === ""}
              submit={(state: ChangePasswordFormState) => this._onSubmit(state)}
            />

            <NotificationContainer className={CHANGE_PASSWORD_VIEW_CLASS_NAME + "-notifications"} t={t} />
          </div>
        </Section>
      </SplitView>
    );
  }

  private _onSubmit(formData: ChangePasswordFormState) {
    try {
      const oldPassword = this.state.oldPassword !== "" ? this.state.oldPassword : formData?.oldPassword;
      const password1 = formData.password1;
      const password2 = formData.password2;

      if (!oldPassword) {
        LOG.warn("No old password provided. This is probably a bug in the code; onSubmit should not be enabled.");
      } else if (password1 === password2) {
        this._newPassword = password1;

        ProfileService.changePassword(ProfileService.getUserEmail(), oldPassword, password1);
      } else {
        LOG.warn("Passwords were not identical. This is probably a bug in the code; onSubmit should not be enabled.");
      }
    } catch (err) {
      LOG.error("Exception: ", err);

      MessageService.createMessage({
        type: MessageType.ERROR,
        icon: IconType.NOTICE,
        content: T_LOGIN_SERVICE_LOGIN_ERROR,
      });

      // this.setState({
      //     loading: false
      // });
    }
  }
}

export default ChangePasswordView;
