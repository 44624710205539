// Copyright © 2021 HMD Global. All rights reserved.

import HttpService, { HttpRequestCanceller, HttpResponse, Pageable } from "./HttpService";
import EventObserver, { EventObserverCallback, EventObserverDestructorCallback } from "./EventObserver";
import LogService from "./LogService";
import { EnterpriseModel } from "./types/EnterpriseModel";
import { UserModel } from "./types/UserModel";
import BackendService from "./BackendService";
import HttpMethod from "./HttpMethod";
import {
  API_ADD_ENTERPRISE_URL,
  API_APPLICATION_CONFIGS,
  API_EDIT_USER_ENTERPRISES,
  API_ENTERPRISE_LIST_URL,
  API_ENTERPRISE_SEARCH_URL,
  API_ENTERPRISE_URL,
  API_USER_INVITE_URL,
} from "../constants/backend";
import HttpRequestType from "./HttpRequestType";
import EnterpriseDTO from "./types/EnterpriseDTO";
import ErrorService, { ErrorCode } from "./ErrorService";
import SortDirection from "../constants/SortDirection";
import { PasswordQuality } from "./types/PasswordQuality";
import UserRole from "./UserRole";
import HttpHeader from "../constants/HttpHeader";
import EnterpriseId from "../types/EnterpriseId";
import { AppConfigurationModel } from "../components/modals/configureAppsModal/ConfigureAppsModal";
import MessageService from "./MessageService";
import MessageType from "./types/MessageType";
import { T_ADD_ENTERPRISE_MODAL_INVITE_ADMIN_USER_ERROR, T_ADD_ENTERPRISE_MODAL_INVITE_USERS_ERROR } from "../translations/translationTokens";

export enum EnterpriseServiceEvent {
  /**
   * The Enterprise model is provided as the second parameter
   */
  ENTERPRISE_ADDED = "EnterpriseServiceEvent:enterpriseAdded",

  /**
   * Triggered if enterprise model data changes
   */
  ENTERPRISE_UPDATED = "EnterpriseServiceEvent:enterpriseUpdated",
}

const LOG = LogService.createLogger("EnterpriseService");

export type EnterpriseModelPage = Pageable<EnterpriseModel>;
export type EnterpriseServiceDestructor = EventObserverDestructorCallback;
export type EnterpriseServiceCanceller = HttpRequestCanceller;
export type EnterpriseListDTO = Pageable<EnterpriseModel>;

export class EnterpriseService {
  public static Event = EnterpriseServiceEvent;
  public static RequestCanceller = HttpRequestCanceller;

  private static _observer: EventObserver = new EventObserver("EnterpriseService");

  public static createCanceller(): EnterpriseServiceCanceller {
    return HttpService.createCanceller();
  }

  public static getEnterpriseList(
    pageSize = 10,
    page = 0,
    sort = "name",
    sortDirection: SortDirection,
    search: string | undefined = undefined,
    canceller: EnterpriseServiceCanceller | undefined = undefined,
  ): Promise<EnterpriseModelPage> {
    if (canceller) {
      LOG.debug("getEnterpriseList: (with canceller): ", pageSize, page);
    } else {
      LOG.debug("getEnterpriseList: (without canceller): ", pageSize, page);
    }

    const backendId = BackendService.getCurrentBackend();

    const url =
      search === undefined || search === ""
        ? API_ENTERPRISE_LIST_URL(backendId, pageSize, page, sort, sortDirection)
        : API_ENTERPRISE_SEARCH_URL(backendId, pageSize, page, sort, sortDirection, search);

    let ret: Promise<HttpResponse<EnterpriseListDTO>> | undefined;

    if (canceller) {
      ret = HttpService.requestWithCanceller(canceller, HttpMethod.GET, url);
    } else {
      ret = HttpService.request(HttpMethod.GET, url);
    }

    return ret.then((response: HttpResponse<EnterpriseListDTO>): EnterpriseModelPage => {
      LOG.debug("getEnterpriseList: response = ", response);

      if (HttpService.verifyResponseObject(response)) {
        return {
          content: [],
          size: pageSize,
          totalElements: 0,
          totalPages: 0,
        };
      }

      if (response.data) {
        return {
          ...response.data,
          content: response.data?.content,
        };
      } else {
        return {
          content: [],
          size: pageSize,
          totalElements: 0,
          totalPages: 0,
        };
      }
    });
  }

  public static addEnterprise(model: EnterpriseModel, agreementAccepted: boolean, passwordQuality: PasswordQuality, selectedUsers: UserModel[], adminUser: UserModel | undefined) {
    const { tfaEnabled } = model;

    delete model.tfaEnabled;

    const payload = {
      enterPrise: {
        ...model,
      },
      agreementAccepted,
      passwordQuality,
      tfaEnabled,
    };

    const backendId = BackendService.getCurrentBackend();

    return HttpService.request(HttpMethod.POST, API_ADD_ENTERPRISE_URL(backendId), payload, HttpRequestType.JSON)
      .then(
        (response: HttpResponse<EnterpriseDTO>) => {
          if (HttpService.verifyResponseObject(response)) return;

          LOG.debug("Add enterprise success: ", response);

          this._observer.triggerEvent(EnterpriseServiceEvent.ENTERPRISE_ADDED, model);

          const { enterPrise } = response.data;

          if (enterPrise.name && selectedUsers.length) {
            const enterpriseUsers = selectedUsers.map((user: UserModel) => ({
              userId: user.id,
              enterprises: user.enterprises?.map((id) => id.getGoogleId()).concat(enterPrise.name ?? ""),
            }));
            HttpService.request(HttpMethod.PUT, API_EDIT_USER_ENTERPRISES(backendId), enterpriseUsers, HttpRequestType.JSON)
              .then((response) => LOG.debug("Add enterprise users success: ", response))
              .catch((error) => {
                LOG.error("Add enterprise users error: ", error);

                MessageService.createMessage({
                  type: MessageType.ERROR,
                  content: T_ADD_ENTERPRISE_MODAL_INVITE_USERS_ERROR,
                });
              });
          }

          if (adminUser) {
            const payload = {
              invites: [
                {
                  ...adminUser,
                  role: UserRole.ENTERPRISE_ADMIN,
                  enterprises: [enterPrise.name],
                },
              ],
            };
            HttpService.request(HttpMethod.POST, API_USER_INVITE_URL(backendId), payload, HttpRequestType.JSON)
              .then((response) => LOG.debug("Enterprise admin user success: ", response))
              .catch((error) => {
                LOG.error("Enterprise admin user error: ", error);

                MessageService.createMessage({
                  type: MessageType.ERROR,
                  content: T_ADD_ENTERPRISE_MODAL_INVITE_ADMIN_USER_ERROR,
                });
              });
          }
        },
        (response) => {
          LOG.error("Add enterprise failed: ", response);

          return Promise.reject(ErrorService.createError(ErrorCode.ENTERPRISE_ADD_FAILED, response));
        },
      )
      .catch((error) => {
        LOG.error("Add enterprise failed: ", error);

        return Promise.reject(ErrorService.createError(ErrorCode.ENTERPRISE_ADD_FAILED, error));
      });
  }

  public static deleteEnterprise(model: EnterpriseModel): Promise<void> {
    const enterpriseId = model?.name;

    if (!enterpriseId) throw new TypeError("Enterprise id is required");

    LOG.debug("deleteEnterprise: ", enterpriseId, model);

    const backendId = BackendService.getCurrentBackend();

    return HttpService.request(HttpMethod.DELETE, API_ENTERPRISE_URL(enterpriseId, backendId))
      .then(
        (response: HttpResponse<EnterpriseDTO>) => {
          const statusCode = response?.status;
          if (statusCode !== 200) throw new TypeError(`Status code was not 200: ${statusCode}`);
          LOG.debug("Enterprise delete success: ", response);
        },
        (response) => {
          LOG.error("Enterprise delete failed: ", response);
          return Promise.reject(ErrorService.createError(ErrorCode.ENTERPRISE_DELETE_FAILED, response));
        },
      )
      .catch((error) => {
        LOG.error("Enterprise delete failed: ", error);
        return Promise.reject(ErrorService.createError(ErrorCode.ENTERPRISE_DELETE_FAILED, error));
      });
  }

  public static getEnterpriseModel(enterpriseId: string): Promise<EnterpriseModel> {
    const backendId = BackendService.getCurrentBackend();

    return HttpService.request(HttpMethod.GET, API_ENTERPRISE_URL(enterpriseId, backendId)).then((response: HttpResponse<EnterpriseDTO>) => {
      const { data } = response;
      const { enterPrise, tfaEnabled, agreementAccepted } = data;

      return {
        ...enterPrise,
        tfaEnabled,
        agreementAccepted,
      };
    });
  }

  public static getEnterpriseModelWithId(enterpriseId: string): Promise<EnterpriseModel> {
    const backendId = BackendService.getCurrentBackend();

    return HttpService.request(HttpMethod.GET, API_ENTERPRISE_URL(enterpriseId, backendId), undefined, HttpRequestType.JSON, { [HttpHeader.EMM_ENTERPRISE]: enterpriseId }).then(
      (response: HttpResponse<EnterpriseDTO>) => {
        return response.data.enterPrise;
      },
    );
  }

  public static editEnterprise(model: EnterpriseModel, agreementAccepted: boolean | undefined = undefined) {
    const enterpriseId = model?.name;

    if (!enterpriseId) throw new TypeError("Enterprise id is required");

    LOG.debug("editEnterprise: ", enterpriseId, model);

    const backendId = BackendService.getCurrentBackend();

    const { tfaEnabled } = model;

    delete model.tfaEnabled;

    const payload = {
      enterPrise: {
        ...model,
      },
      tfaEnabled,
      passwordQuality: null,
      ...(agreementAccepted && { agreementAccepted }),
    };

    return HttpService.request(HttpMethod.PUT, API_ENTERPRISE_URL(enterpriseId, backendId), payload, HttpRequestType.JSON)
      .then(
        (response: HttpResponse<EnterpriseDTO>) => {
          if (HttpService.verifyResponseObject(response)) return;

          LOG.debug("Edit enterprise success: ", response);

          this._observer.triggerEvent(EnterpriseServiceEvent.ENTERPRISE_UPDATED, model);
        },
        (response) => {
          LOG.error("Edit enterprise failed: ", response);

          return Promise.reject(ErrorService.createError(ErrorCode.ENTERPRISE_UPDATE_FAILED, response));
        },
      )
      .catch((error) => {
        LOG.error("Edit enterprise failed: ", error);

        return Promise.reject(ErrorService.createError(ErrorCode.ENTERPRISE_UPDATE_FAILED, error));
      });
  }

  public static getManagedConfs(enterpriseId: EnterpriseId): Promise<any> {
    const backendId = BackendService.getCurrentBackend();

    return HttpService.request(HttpMethod.GET, API_APPLICATION_CONFIGS(enterpriseId, backendId)).then((response: HttpResponse<any>): any => {
      LOG.debug("getManagedConfs: response = ", response);

      const {
        data: { content = [] },
      } = response;

      return content;
    });
  }

  public static saveManagedConfs(enterpriseId: EnterpriseId, managedConfs: Array<AppConfigurationModel>): Promise<any> {
    const backendId = BackendService.getCurrentBackend();

    return HttpService.request(HttpMethod.PUT, API_APPLICATION_CONFIGS(enterpriseId, backendId), managedConfs).then((response: HttpResponse<any>): any => {
      LOG.debug("saveManagedConfs: response = ", response);

      const {
        data: { content = [] },
      } = response;

      return content;
    });
  }

  public static on(e: EnterpriseServiceEvent, callback: EventObserverCallback): EnterpriseServiceDestructor {
    return this._observer.listenEvent(e, callback);
  }
}

export default EnterpriseService;
