// Copyright © 2020 HMD Global. All rights reserved.

import AssertUtils from "../services/AssertUtils";
import { SortDirection } from "./SortDirection";
import { DEFAULT_SORT_DIRECTION } from "./frontend";
import PolicyId from "../types/PolicyId";
import EnterpriseId from "../types/EnterpriseId";
import DeviceId from "../types/DeviceId";
import CompanionAppId from "../types/CompanionAppId";
import { UserIdType } from "../services/types/UserModel";
import { BACKEND_LOGIN_PATH, BACKEND_LOGOUT_PATH, BACKEND_PATH, BACKEND_URL, FRONTEND_LOG_SERVER_URL } from "./environment";

export const NAME_MAX_LENGTH = 127;
export const DESCRIPTION_MAX_LENGTH = 255;
export const MESSAGE_MAX_LENGTH = 500;

export const BACKEND_EMM_PROFILE_PATH = "/v1/emm/profile";

export const ANY_BACKEND_ID = "";

const q = (value: any): string => encodeURIComponent(value);

export const API_URL = (id: string): string => `${BACKEND_URL}${id ? "/" + q(id) : ""}${BACKEND_PATH}`;
export const API_LOGIN_URL = (id: string): string => `${BACKEND_URL}${id ? "/" + q(id) : ""}${BACKEND_LOGIN_PATH}`;
export const API_LOGOUT_URL = (id: string): string => `${BACKEND_URL}${id ? "/" + q(id) : ""}${BACKEND_LOGOUT_PATH}`;
export const API_ENTERPRISE_EDIT_URL = (id: string): string => API_URL(id) + "/v1/emm/enterprise";
export const API_DEFAULT_POLICY_EDIT_URL = (id: string): string => API_URL(id) + "/v1/emm/policy/default";
export const API_POLICY_CREATE_URL = (id: string): string => API_URL(id) + "/v1/emm/policy";
export const API_PROFILE_URL = (id: string): string => API_URL(id) + BACKEND_EMM_PROFILE_PATH;
export const API_SIGN_UP_URL = (id: string): string => API_URL(id) + "/v1/emm/enterprise/signup";
export const API_CHANGE_PASSWORD_URL = (id: string): string => API_URL(id) + "/v1/registration/change_pw";
export const API_FORGOT_PASSWORD_URL = (id: string): string => API_URL(id) + "/v1/forgot_password";
export const API_RESET_PASSWORD_URL = (id: string): string => API_URL(id) + "/v1/maintenance/reset_password";
export const API_VALIDATE_TOKEN_URL = (id: string): string => API_URL(id) + "/v1/maintenance/token_validation";

export const API_TFA_QR_URL = (id: string): string => `${API_URL(id)}/v1/qr`;
export const API_TFA_REGISTER_URL = (id: string): string => `${API_URL(id)}/v1/registration/commit_tfa`;
export const API_TFA_VERIFY_URL = (id: string, code: string): string => `${API_URL(id)}/v1/registration/verify_code?code=${code}`;
export const API_TFA_VERIFY_RESET_URL = (id: string, code: string): string => `${API_URL(id)}/v1/registration/verify_reset?code=${code}`;
export const API_TFA_RESET_URL = (id: string, email: string): string => `${API_URL(id)}/v1/admin/users/${q(email)}/tfa`;

export const API_TERMS_AND_CONDITIONS_URL = (id: string): string => API_URL(id) + "/v1/registration/ack_tac";

export const API_DASHBOARD_URL = (enterprise: EnterpriseId, backendId: string): string => `${API_URL(backendId)}/v1/emm/dashboard?enterpriseId=${q(enterprise.getGoogleId())}`;

export const API_DASHBOARD_CONFIG_URL = (enterprise: EnterpriseId, backendId: string) =>
  `${API_URL(backendId)}/v1/emm/dashboard/applications?enterpriseId=${q(enterprise.getGoogleId())}`;

export const API_GET_PROFILE = (enterpriseName: string, backendId: string) => `${API_URL(backendId)}/v1/emm/profile?enterpriseName=${q(enterpriseName)}`;

export const API_GET_WEB_TOKEN = (backendId: string, enterprise: EnterpriseId, parentFrameUrl: string | undefined = undefined) =>
  parentFrameUrl
    ? `${API_URL(backendId)}/v1/emm/webtoken?enterpriseId=${q(enterprise.getGoogleId())}&parentFrameUrl=${q(parentFrameUrl)}`
    : `${API_URL(backendId)}/v1/emm/webtoken?enterpriseId=${q(enterprise.getGoogleId())}`;

export const API_DEVICE_LIST_URL = (
  backendId: string,
  enterprise: EnterpriseId,
  pageSize: number,
  page: number,
  sort: string,
  direction: SortDirection = DEFAULT_SORT_DIRECTION,
  fullRefresh = false,
) =>
  `${API_URL(backendId)}/v1/emm/devices?enterpriseId=${q(enterprise.getGoogleId())}&size=${q(pageSize)}&page=${q(page)}&sort=${q(sort)}&direction=${q(direction)}${
    fullRefresh ? "&refresh=true" : ""
  }`;

export const API_DEVICE_SEARCH_URL = (
  backendId: string,
  enterprise: EnterpriseId,
  pageSize: number,
  page: number,
  sort: string,
  direction: SortDirection = DEFAULT_SORT_DIRECTION,
  search = "",
) =>
  `${API_URL(backendId)}/v1/emm/devices/search?enterpriseId=${q(enterprise.getGoogleId())}&size=${q(pageSize)}&page=${q(page)}&sort=${q(sort)}&direction=${q(direction)}&search=${q(
    search,
  )}`;

export const API_DEVICE_FILTER_URL = (
  backendId: string,
  enterprise: EnterpriseId,
  pageSize: number,
  page: number,
  sort: string,
  direction: SortDirection = DEFAULT_SORT_DIRECTION,
  search = "",
  filters: string[],
) =>
  `${API_URL(backendId)}/v1/emm/devices/filter?enterpriseId=${q(enterprise.getGoogleId())}&size=${q(pageSize)}&page=${q(page)}&sort=${q(sort)}&direction=${q(direction)}${
    search ? `&search=${q(search)}` : ""
  }` + (filters?.length ? `&` + filters.map((item: string) => `filter=${q(item)}`).join("&") : "");

export const API_DEVICE_EXPORT_URL = (backendId: string, enterprise: EnterpriseId, fileName: string) =>
  `${API_URL(backendId)}/v1/emm/devices-export?enterpriseName=${q(enterprise.getGoogleId())}&fileName=${q(fileName)}&format=CSV`;

export const API_DEVICE_GROUP_LIST_URL = (
  backendId: string,
  enterprise: EnterpriseId,
  pageSize: number,
  page: number,
  sort: string,
  direction: SortDirection = DEFAULT_SORT_DIRECTION,
  fullRefresh = false,
) =>
  `${API_URL(backendId)}/v1/emm/device-groups?enterpriseId=${q(enterprise.getGoogleId())}&size=${q(pageSize)}&page=${q(page)}&sort=${q(sort)}&direction=${q(direction)}${
    fullRefresh ? "&refresh=true" : ""
  }`;

export const API_DEVICE_GROUP_SEARCH_URL = (
  backendId: string,
  enterprise: EnterpriseId,
  pageSize: number,
  page: number,
  sort: string,
  direction: SortDirection = DEFAULT_SORT_DIRECTION,
  search = "",
) =>
  `${API_URL(backendId)}/v1/emm/device-groups/search?enterpriseId=${q(enterprise.getGoogleId())}&size=${q(pageSize)}&page=${q(page)}&sort=${q(sort)}&direction=${q(
    direction,
  )}&search=${q(search)}`;

export const API_DEVICE_GROUP_ADD_URL = (backendId: string): string => `${API_URL(backendId)}/v1/emm/device-groups`;

export const API_DEVICE_GROUP_URL = (deviceGroup: number, backendId: string): string => `${API_URL(backendId)}/v1/emm/device-groups/${deviceGroup}`;

export const API_DEVICE_GROUP_DEVICES_URL = (
  deviceGroup: number,
  backendId: string,
  pageSize: number,
  page: number,
  sort: string,
  direction: SortDirection = DEFAULT_SORT_DIRECTION,
  fullRefresh = false,
): string =>
  `${API_URL(backendId)}/v1/emm/device-groups/${deviceGroup}/devices?size=${q(pageSize)}&page=${q(page)}&sort=${q(sort)}&direction=${q(direction)}${
    fullRefresh ? "&refresh=true" : ""
  }`;

export const API_DEVICE_GROUP_DEVICES_SEARCH_URL = (
  deviceGroup: number,
  backendId: string,
  pageSize: number,
  page: number,
  sort: string,
  direction: SortDirection = DEFAULT_SORT_DIRECTION,
  search = "",
): string =>
  `${API_URL(backendId)}/v1/emm/device-groups/${deviceGroup}/devices/search?size=${q(pageSize)}&page=${q(page)}&sort=${q(sort)}&direction=${q(direction)}&search=${q(search)}`;

export const API_DEVICE_GROUP_ADD_DEVICES_URL = (deviceGroup: number, backendId: string): string => `${API_URL(backendId)}/v1/emm/device-groups/${deviceGroup}/devices`;

export const API_DEVICE_GROUP_REMOVE_DEVICES_URL = (backendId: string): string => `${API_URL(backendId)}/v1/emm/device-groups/devices`;

export const API_DEVICE_GROUP_BACKGROUND_TASK_URL = (deviceGroup: number, backendId: string): string =>
  `${API_URL(backendId)}/v1/emm/device-groups/background-tasks/${deviceGroup}`;

export const API_DEVICE_REQUEST_CONNECTION_URL = (backendId: string): string => `${API_URL(backendId)}/v1/emm/fcm/start-streaming-request`;

export const API_USER_LIST_URL = (backendId: string, pageSize: number, page: number, sort: string, direction: SortDirection = DEFAULT_SORT_DIRECTION) =>
  `${API_URL(backendId)}/v1/admin/users?size=${q(pageSize)}&page=${q(page)}&sort=${q(sort)}&direction=${q(direction)}`;

export const API_ENTERPRISE_USER_LIST_URL = (
  enterpriseId: string,
  backendId: string,
  pageSize: number,
  page: number,
  sort: string,
  direction: SortDirection = DEFAULT_SORT_DIRECTION,
) => `${API_URL(backendId)}/v1/admin/enterprise-users?enterpriseId=${q(enterpriseId)}&size=${q(pageSize)}&page=${q(page)}&sort=${q(sort)}&direction=${q(direction)}`;

export const API_ENTERPRISE_LIST_URL = (backendId: string, pageSize: number, page: number, sort: string, direction: SortDirection = DEFAULT_SORT_DIRECTION) =>
  `${API_URL(backendId)}/v1/emm/enterprises?size=${q(pageSize)}&page=${q(page)}&sort=${q(sort)}&direction=${q(direction)}`;

export const API_ENTERPRISE_SEARCH_URL = (backendId: string, pageSize: number, page: number, sort: string, direction: SortDirection = DEFAULT_SORT_DIRECTION, search = "") =>
  `${API_URL(backendId)}/v1/emm/enterprises/search?size=${q(pageSize)}&page=${q(page)}&sort=${q(sort)}&direction=${q(direction)}&search=${q(search)}`;

export const API_ADD_ENTERPRISE_URL = (backendId: string) => `${API_URL(backendId)}/v1/emm/enterprise`;

export const API_ENTERPRISE_URL = (enterpriseId: string, backendId: string) => `${API_URL(backendId)}/v1/emm/enterprise?enterpriseId=${q(enterpriseId)}`;

export const API_EDIT_USER_ENTERPRISES = (backendId: string) => `${API_URL(backendId)}/v1/admin/users/enterprise/invites`;

export const API_ENTERPRISE_AGREEMENT_URL = (enterpriseId: string, backendId: string) => `${API_URL(backendId)}/v1/emm/enterprise/agreement?enterpriseId=${q(enterpriseId)}`;

export const API_USER_SEARCH_URL = (backendId: string, pageSize: number, page: number, sort: string, direction: SortDirection = DEFAULT_SORT_DIRECTION, search = "") =>
  `${API_URL(backendId)}/v1/admin/users/search?size=${q(pageSize)}&page=${q(page)}&sort=${q(sort)}&direction=${q(direction)}&search=${q(search)}`;

export const API_USER_INVITE_URL = (backendId: string): string => `${API_URL(backendId)}/v1/admin/users/invite`;

export const API_PROMOTE_USER_URL = (backendId: string, userId: UserIdType): string => `${API_URL(backendId)}/v1/admin/user/promote/${q(userId)}`;
export const API_DEMOTE_USER_URL = (backendId: string, userId: UserIdType): string => `${API_URL(backendId)}/v1/admin/user/demote/${q(userId)}`;
export const API_ACTIVATE_USER_URL = (backendId: string, userId: UserIdType): string => `${API_URL(backendId)}/v1/admin/user/activate/${q(userId)}`;
export const API_DEACTIVATE_USER_URL = (backendId: string, userId: UserIdType): string => `${API_URL(backendId)}/v1/admin/user/deactivate/${q(userId)}`;

export const API_USER_URL = (userId: UserIdType, backendId: string): string => `${API_URL(backendId)}/v1/admin/user/${q(userId)}`;
export const API_USER_EDIT_URL = (userId: UserIdType, backendId: string): string => `${API_URL(backendId)}/v1/admin/user/edit/${q(userId)}`;

export const API_DELETE_USER_URL = (userId: UserIdType, backendId: string): string => `${API_URL(backendId)}/v1/admin/user/${q(userId)}`;

export const API_IMPORT_USERS = (enterprise: string, role: string, backendId: string) => `${API_URL(backendId)}/v1/import/users?enterpriseId=${q(enterprise)}&role=${q(role)}`;

export const API_IMPORT_DEVICE_USERS = (backendId: string) => `${API_URL(backendId)}/v1/import/device/users`;

export const API_DEVICE_URL = (device: DeviceId, backendId: string): string =>
  `${API_URL(backendId)}/v1/emm/device?enterpriseId=${q(device.getEnterpriseIdObject().getGoogleId())}&deviceId=${q(device.getGoogleId())}`;

export const API_POLICY_DEVICE_LIST_URL = (appliedPolicyName: PolicyId, backendId: string) =>
  `${API_URL(backendId)}/v1/emm/devices/applied?enterpriseId=${q(appliedPolicyName.getEnterpriseIdObject().getGoogleId())}&appliedPolicyName=${q(appliedPolicyName.getGoogleId())}`;

export const API_WEB_APPS_LIST_URL = (enterprise: EnterpriseId, pageSize: number, pageToken: string, backendId: string) =>
  `${API_URL(backendId)}/v1/emm/webapps?enterpriseId=${q(enterprise.getGoogleId())}&pageSize=${q(pageSize)}&pageToken=${q(pageToken)}`;

export const API_CHECK_EMAIL = (email: string, backendId: string) => `${API_URL(backendId)}/v1/admin/email-address/available?emailAddress=${q(email)}`;

export const API_POLICY_LIST_URL = (
  backendId: string,
  enterprise: EnterpriseId,
  pageSize: number,
  page: number,
  sort: string,
  direction: SortDirection = DEFAULT_SORT_DIRECTION,
  fullRefresh = false,
) =>
  `${API_URL(backendId)}/v1/emm/emmpolicies?enterpriseId=${q(enterprise.getGoogleId())}&size=${q(pageSize)}&page=${q(page)}&sort=${q(sort)},${q(direction)}${
    fullRefresh ? "&refresh=true" : ""
  }`;

export const API_POLICY_SEARCH_URL = (
  backendId: string,
  enterprise: EnterpriseId,
  pageSize: number,
  page: number,
  sort: string,
  direction: SortDirection = DEFAULT_SORT_DIRECTION,
  search = "",
) =>
  `${API_URL(backendId)}/v1/emm/emmpolicies/search?enterpriseId=${q(enterprise.getGoogleId())}&size=${q(pageSize)}&page=${q(page)}&sort=${q(sort)},${q(direction)}&search=${q(
    search,
  )}`;

export const API_POLICY_URL = (policy: PolicyId, backendId: string) => `${API_URL(backendId)}/v1/emm/policy?policyId=${q(policy.getGoogleId())}`;
export const API_POLICY_EDIT_URL = (policy: PolicyId, backendId: string) => `${API_URL(backendId)}/v1/emm/policy/${q(policy.getId())}?policyId=${q(policy.getGoogleId())}`;
export const API_POLICY_DELETE_URL = (policy: PolicyId, backendId: string) => `${API_URL(backendId)}/v1/emm/enterprise/${q(policy.getEnterpriseId())}/policy/${q(policy.getId())}`;

export const API_APP_URL = (enterprise: EnterpriseId, packageName: string, backendId: string) =>
  `${API_URL(backendId)}/v1/emm/application?enterpriseId=${q(enterprise.getGoogleId())}&packageName=${q(packageName)}`;

export const API_DEVICE_NAME_EDIT_URL = (device: DeviceId, backendId: string) => `${API_URL(backendId)}/v1/emm/device/name/${device.getId()}`;

export const API_DEVICE_EDIT_URL = (device: DeviceId, backendId: string) => `${API_URL(backendId)}/v1/emm/device/${device.getId()}?deviceId=${q(device.getGoogleId())}`;
export const API_DEVICE_DELETE_URL = (device: DeviceId, backendId: string) => `${API_URL(backendId)}/v1/emm/device/delete?deviceId=${q(device.getGoogleId())}`;
export const API_DEVICE_COMMAND_URL = (device: DeviceId, backendId: string) => `${API_URL(backendId)}/v1/emm/device/command?deviceId=${q(device.getGoogleId())}`;
export const API_DEVICE_LIST_BULK_OPERATION_URL = (backendId: string) => `${API_URL(backendId)}/v1/emm/devices/bulk/operations`;
export const API_QR_URL = (enterprise: EnterpriseId, backendId: string) => `${API_URL(backendId)}/v1/emm/device/qr?enterpriseId=${q(enterprise.getGoogleId())}`;

export const API_DEVICE_SAVE_MANAGED_CONFIGS_URL = (device: DeviceId, backendId: string) =>
  `${API_URL(backendId)}/v1/emm/device/${device.getId()}/configs?deviceId=${q(device.getGoogleId())}`;

export const API_QRTOKEN_URL = (enterprise: EnterpriseId, backendId: string) => `${API_URL(backendId)}/v1/emm/device/qrtoken?enterpriseId=${q(enterprise.getGoogleId())}`;

export const API_DEVICE_ENROLLMENT_TOKEN_URL = (enterprise: EnterpriseId, backendId: string) =>
  `${API_URL(backendId)}/v1/emm/device/enrollmenttoken?enterpriseId=${q(enterprise.getGoogleId())}`;

export const API_DEVICE_MESSAGES_URL = (enterprise: EnterpriseId, backendId: string) => `${API_URL(backendId)}/v1/emm/fcm/messages?enterpriseId=${q(enterprise.getGoogleId())}`;

export const API_DEVICE_MESSAGES_BULK_URL = (enterprise: EnterpriseId, backendId: string) =>
  `${API_URL(backendId)}/v1/emm/fcm/messages/bulk?enterpriseId=${q(enterprise.getGoogleId())}`;

export const API_DEVICE_USER_LIST_URL = (backendId: string, pageSize: number, page: number, sort: string, direction: SortDirection = DEFAULT_SORT_DIRECTION) =>
  `${API_URL(backendId)}/v1/device/users?size=${q(pageSize)}&page=${q(page)}&sort=${q(sort)}&direction=${q(direction)}`;

export const API_DEVICE_USER_URL = (userId: UserIdType, backendId: string): string => `${API_URL(backendId)}/v1/device/user/${q(userId)}`;

export const API_DEVICE_USER_SEARCH_URL = (backendId: string, pageSize: number, page: number, sort: string, direction: SortDirection = DEFAULT_SORT_DIRECTION, search = "") =>
  `${API_URL(backendId)}/v1/device/users/search?size=${q(pageSize)}&page=${q(page)}&sort=${q(sort)}&direction=${q(direction)}&search=${q(search)}`;

export const API_DEVICE_USER_ADD_URL = (backendId: string): string => `${API_URL(backendId)}/v1/device/user`;

export const API_DEVICE_USER_SAVE_MANAGED_CONFIGS_URL = (deviceUserId: number, backendId: string) => `${API_URL(backendId)}/v1/device/users/${deviceUserId}/configs`;

export const API_DEVICE_USER_DEVICES_URL = (deviceUserId: number, backendId: string) => `${API_URL(backendId)}/v1/device/user/${deviceUserId}/devices`;

/**
 * You can POST [Device]LocationDto to this path to create location
 */
export const API_LOCATION_CREATE_URL = (backendId: string) => `${API_URL(backendId)}/v1/emm/location`;

/**
 * You can GET latest location as [Device]LocationDto from this path
 */
export const API_GET_LATEST_LOCATION_URL = (companionAppId: CompanionAppId, backendId: string) => `${API_URL(backendId)}/v1/emm/location?device=${q(companionAppId.getGUID())}`;

export const API_APPLICATION_CONFIGS = (enterprise: EnterpriseId, backendId: string) =>
  `${API_URL(backendId)}/v1/emm/application/managed-confs?enterpriseName=${q(enterprise.getGoogleId())}`;

/**
 * You can GET latest location as [Device]LocationDto from this path (sorted by createdOn)
 */
export const API_GET_LAST_SEEN_LOCATION_URL = (companionAppId: CompanionAppId, backendId: string) =>
  `${API_URL(backendId)}/v1/emm/location/last-seen?device=${q(companionAppId.getGUID())}`;

/**
 * This is a temporary device name for develoment purposes.
 *
 * Exported as a const so that it's easier to find where is is used and to remove later.
 */
export const TEMPORARY_DEVICE_NAME = "devicex";

export const MINIMUM_PASSWORD_LENGTH = 8;

/**
 * You may GET device locations from this URL
 *
 * @param companionAppId
 * @param start
 * @param end
 * @constructor
 */
export const API_LOCATION_GET_LIST_URL = (companionAppId: CompanionAppId, start: string | undefined, end: string | undefined, backendId: string) => {
  let url = `${API_URL(backendId)}/v1/emm/locations?device=${q(companionAppId.getGUID())}`;

  if (start !== undefined) {
    url += `&start=${q(start)}`;
  }

  if (end !== undefined) {
    url += `&end=${q(end)}`;
  }

  return url;
};

export const GAPI_SELECT_IFRAME_URL = (token: string, mode = "SELECT") => `https://play.google.com/work/embedded/search?token=${q(token)}&mode=${q(mode)}&showsearchbox=FALSE`;

export const GAPI_SEARCH_IFRAME_URL = (token: string, mode = "SELECT", search = "") =>
  `https://play.google.com/work/embedded/search?token=${q(token)}&mode=${q(mode)}&showsearchbox=FALSE&search=${q(search)}`;

export const GAPI_APPROVE_IFRAME_URL = (token: string, mode = "APPROVE") => `https://play.google.com/work/embedded/search?token=${q(token)}&mode=${q(mode)}&showsearchbox=TRUE`;

export const GAPI_ZERO_TOUCH_IFRAME_URL = (token: string, dpcExtras: string) =>
  `https://enterprise.google.com/android/zero-touch/embedded/companyhome?token=${q(token)}&dpcId=com.google.android.apps.work.clouddpc&dpcExtras=${q(dpcExtras)}`;

/**
 *
 * @param token The token returned from Enterprises.createWebToken.
 * @param packageName The product ID of the app. For example, com.google.android.gm.
 * @param mcmId The ID of a managed configurations profile.
 * @param canDelete If TRUE, enables a button in the iframe that allows IT admins to delete the managed configurations profile. If FALSE (default value), the button is disabled.
 * @param locale A well-formed BCP 47 language tag that is used to localize the content in the iframe. If not specified, the default value is en_US.
 * @see https://developers.google.com/android/management/managed-configurations-iframe#url_parameters
 */
export const GAPI_CONFIG_APP_IFRAME_URL = (token: string, packageName: string, mcmId?: string, canDelete?: boolean, locale?: string) => {
  AssertUtils.isString(token);
  AssertUtils.isString(packageName);

  let url = `https://play.google.com/managed/mcm?token=${q(token)}&packageName=${q(packageName)}`;

  if (mcmId !== undefined) {
    AssertUtils.isString(mcmId);
    url += `&mcmId=${q(mcmId)}`;
  }

  if (canDelete !== undefined) {
    AssertUtils.isBoolean(canDelete);
    url += `&canDelete=${canDelete ? "TRUE" : "FALSE"}`;
  }

  if (locale !== undefined) {
    AssertUtils.isString(locale);
    url += `&locale=${q(locale)}`;
  }

  return url;
};

/**
 * If a request to the backend returns non-JSON object response, these phrases will be looked for and if found, will redirect user to
 * the login page.
 * FIXME: Temporary hack. Backend should response with some meaningful JSON content instead of HTML.
 */
export const BACKEND_API_LOGIN_PHRASE = ["Enter your credentials to sign in", "LOGIN REQUIRED"];

/**
 * The backend session cookie name
 */
export const API_COOKIE_NAMES: string[] = ["SESSION", "JSESSIONID"];
export const API_COOKIE_PATH = "/";

export const DEFAULT_POLICY_NAME = "default";

/**
 * This is the path where backend expects the reset password UI to be.
 */
export const RESET_PASSWORD_PATH = "/reset_password";

/**
 * Backend will add this suffix to cloned policies (eg. for wildcard device support)
 */
export const BACKEND_CLONED_POLICY_SUFFIX = "_cl0ned_";

export const WORK_PROFILE_ENROLLMENT_URL = (et: string) => `https://enterprise.google.com/android/enroll?et=${q(et)}`;

export const TERMS_AND_CONDITIONS_URL = "https://www.hmdglobal.com/hmdenablepro/serviceterms";
export const B2B_PRIVACY_NOTICE_URL = "https://www.hmdglobal.com/b2b-privacy-notice";
export const PRIVACY_SUPPLEMENT_URL = "https://www.hmdglobal.com/b2b-privacy-policy#id-tYHVciAz9ir2rHSv1Y1ga";

export const MANAGED_GOOGLE_PLAY_AGREEMENT_URL = "https://www.android.com/enterprise/terms";

export const GET_REMOTE_LOG_TOKEN_URL = `${FRONTEND_LOG_SERVER_URL}/logger/new`;
export const WRITE_REMOTE_LOG_URL = `${FRONTEND_LOG_SERVER_URL}/logger/write`;

export const REMOTE_MANAGEMENT_SOCKET_URL = (backendId: string) => `wss://${location.host + API_URL(backendId)}/socket`;
