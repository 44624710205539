// Copyright © 2020 HMD Global. All rights reserved.

// import EventObserver from "./EventObserver";
import Form from "../components/common/form/Form";
import { has } from "../modules/lodash";
import EventObserver, { EventObserverCallback } from "./EventObserver";
import LogService from "./LogService";

const LOG = LogService.createLogger("FormService");

export enum FormServiceEvent {
  FORM_ADDED = "FormService:formAdded",
  FORM_REMOVED = "FormService:formRemoved",
  FORM_LOADING_UPDATED = "FormService:formLoading",
}

export interface FormServiceDestructor {
  (): void;
}

export type FormName = string;

export class FormService {
  public static Event = FormServiceEvent;

  private static _observer: EventObserver = new EventObserver("FormService");

  private static _forms: Record<string, Form> = {};

  public static hasFormController(name: FormName): boolean {
    return has(this._forms, name);
  }

  public static getFormController(name: FormName): Form {
    if (!has(this._forms, name)) throw new TypeError("FormService.getFormController: Form not found: " + name);

    return this._forms[name];
  }

  public static registerFormController(name: FormName, form: Form): FormServiceDestructor {
    if (has(this._forms, name)) {
      LOG.warn("Warning! Form controller was already registered with same name: " + name);
    }

    this._forms[name] = form;

    this._observer.triggerEvent(FormServiceEvent.FORM_ADDED);

    return () => this.unregisterFormController(name);
  }

  public static unregisterFormController(name: FormName) {
    if (!has(this._forms, name)) {
      LOG.warn("Warning! Could not unregister form controller because it was not registered.");
    } else {
      delete this._forms[name];
      this._observer.triggerEvent(FormServiceEvent.FORM_REMOVED);
    }
  }

  public static submitForm(name: FormName) {
    const controller: Form = this.getFormController(name);

    controller.submitForm();
  }

  public static on(e: FormServiceEvent, callback: EventObserverCallback): FormServiceDestructor {
    return this._observer.listenEvent(e, callback);
  }
}

export default FormService;
