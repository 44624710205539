// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import "./FieldSeparator.scss";
import { FIELD_SEPARATOR_CLASS_NAME } from "../../../constants/classNames";

export interface FieldSeparatorProps {
  className?: string;
}

export interface FieldSeparatorState {}

export class FieldSeparator extends React.Component<FieldSeparatorProps, FieldSeparatorState> {
  static defaultProps: Partial<FieldSeparatorProps> = {};

  constructor(props: FieldSeparatorProps) {
    super(props);

    this.state = {};
  }

  render() {
    return <div className={FIELD_SEPARATOR_CLASS_NAME + " " + (this.props.className ?? "")} />;
  }
}

export default FieldSeparator;
