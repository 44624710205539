// Copyright © 2020 HMD Global. All rights reserved.

import LocalStorageKey from "./LocalStorageKey";

export class LocalStorageService {
  static hasProperty(key: LocalStorageKey): boolean {
    return localStorage.getItem(key) !== null;
  }

  static setProperty(key: LocalStorageKey, value: string) {
    localStorage.setItem(key, value);
  }

  static getProperty(key: LocalStorageKey): string {
    const value = localStorage.getItem(key);

    return value ? value : "";
  }

  static removeProperty(key: LocalStorageKey): void {
    localStorage.removeItem(key);
  }
}

export default LocalStorageService;
