// Copyright © 2021 HMD Global. All rights reserved.

export enum UserStatus {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  DEACTIVE = "DEACTIVE",
}

export function isUserStatus(value: any): value is UserStatus {
  switch (value) {
    case UserStatus.PENDING:
    case UserStatus.ACTIVE:
    case UserStatus.DEACTIVE:
      return true;
  }

  return false;
}

export function parseUserStatus(value: any): UserStatus | undefined {
  if (isUserStatus(value)) return value;

  return undefined;
}

export default UserStatus;
