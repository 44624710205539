// Copyright © 2020, 2021 HMD Global. All rights reserved.

import { Logger } from "./classes/Logger";
import { LogLevel } from "../types/LogLevel";
import { DEFAULT_LOG_LEVEL } from "../constants/environment";

const ORIG_CONSOLE = {
  log: console?.log ? console?.log.bind(console) : () => undefined,
  trace: console?.trace ? console?.trace.bind(console) : () => undefined,
  debug: console?.debug ? console?.debug.bind(console) : () => undefined,
  info: console?.info ? console?.info.bind(console) : () => undefined,
  warn: console?.warn ? console?.warn.bind(console) : () => undefined,
  error: console?.error ? console?.error.bind(console) : () => undefined,
};

export class LogService {
  public static origConsole = ORIG_CONSOLE;

  private static _logLevel: LogLevel = DEFAULT_LOG_LEVEL;

  public static getLogLevel(): LogLevel {
    return this._logLevel;
  }

  public static createLogger(name: string) {
    return new Logger(name, ORIG_CONSOLE, this.getLogLevel());
  }
}

const CONSOLE = new Logger("console", ORIG_CONSOLE, LogService.getLogLevel());

console.log = CONSOLE.debug.bind(CONSOLE);
console.debug = CONSOLE.debug.bind(CONSOLE);
console.trace = CONSOLE.debug.bind(CONSOLE);
console.info = CONSOLE.info.bind(CONSOLE);
console.warn = CONSOLE.warn.bind(CONSOLE);
console.error = CONSOLE.error.bind(CONSOLE);

export default LogService;
