// Copyright © 2020 HMD Global. All rights reserved.

import "./ErrorMessage.scss";
import { ERROR_MESSAGE_CLASS_NAME } from "../../../constants/classNames";
import * as React from "react";
import MessageService, { MessageActionCallback } from "../../../services/MessageService";
import Icon from "../icon/Icon";
import Button, { ButtonClickCallback } from "../button/Button";
import TranslateCallback from "../../../TranslateCallback";
import { T_ERROR_MESSAGE_ACTION_LABEL, T_ERROR_MESSAGE_DISMISS } from "../../../translations/translationTokens";
import { every, isArray, isString, map } from "../../../modules/lodash";
import LogService from "../../../services/LogService";
import { IconType } from "../icon/IconType";
import { MessageModel } from "../../../services/types/MessageModel";
import { MessageType } from "../../../services/types/MessageType";
import { ButtonType } from "../../../types/ButtonType";
import ErrorMessageModel from "../../../services/types/ErrorMessageModel";
import TranslationParamsObject from "../../../types/TranslationParamsObject";

const LOG = LogService.createLogger("ErrorMessage");

export interface MessageDismissCallback {
  (): void;
}

export interface ErrorMessageProps extends ErrorMessageModel {
  className?: string | undefined;
  t?: TranslateCallback;
  message?: MessageModel;
  dismiss?: MessageDismissCallback;
}

export interface ErrorMessageState {}

export class ErrorMessage extends React.Component<ErrorMessageProps, ErrorMessageState> {
  private readonly _dismissCallback: ButtonClickCallback;
  private readonly _actionCallback: ButtonClickCallback;

  static defaultProps: Partial<ErrorMessageProps> = {
    type: MessageType.ERROR,
    enableDismiss: true,
  };

  constructor(props: ErrorMessageProps) {
    super(props);

    this.state = {};

    this._dismissCallback = this.onDismiss.bind(this);
    this._actionCallback = this.onAction.bind(this);
  }

  private _getMessageId(): number | undefined {
    return this.props?.messageId ?? this.props?.message?.messageId ?? undefined;
  }

  private _getActionLabel(): string {
    return this.props?.actionLabel ?? this.props?.message?.actionLabel ?? T_ERROR_MESSAGE_ACTION_LABEL;
  }

  private _getIcon(): IconType | undefined {
    return this.props?.icon ?? this.props?.message?.icon ?? undefined;
  }

  private _getMessageEnableDismiss(): boolean {
    return this.props?.enableDismiss ?? this?.props?.message?.enableDismiss ?? true;
  }

  private _getMessageActionCallback(): MessageActionCallback | undefined {
    return this.props?.action ?? this.props?.message?.action ?? undefined;
  }

  private _getMessageContent(t: TranslateCallback, translationParams: TranslationParamsObject): any {
    let content = this.props?.content ?? this?.props?.message?.content ?? this.props.children;

    if (!React.isValidElement(content)) {
      if (isArray(content) && every(content, isString)) {
        content = map(content, (item: string, index: number) => {
          return (
            <div
              className={ERROR_MESSAGE_CLASS_NAME + "-content-item" + " " + ERROR_MESSAGE_CLASS_NAME + (index === 0 ? "-content-item-first" : "-content-item-rest")}
              key={`message-item-${index}-${item}`}
            >
              {t(item, translationParams)}
            </div>
          );
        });
      } else if (isString(content)) {
        content = t(content, translationParams);
      } else {
        content = "" + content;
      }
    }

    return content;
  }

  render() {
    const t = this.props.t ?? ((key) => key);

    const translationParams: TranslationParamsObject = this.props?.translationParams ?? this.props?.message?.translationParams ?? {};

    const icon = this._getIcon();

    return (
      <div className={ERROR_MESSAGE_CLASS_NAME + " " + (this.props.className ?? "")}>
        {icon ? <Icon className={ERROR_MESSAGE_CLASS_NAME + "-icon"} type={icon} /> : null}

        <div className={ERROR_MESSAGE_CLASS_NAME + "-content"}>{this._getMessageContent(t, translationParams)}</div>

        {this._getMessageEnableDismiss() ? (
          <Button className={ERROR_MESSAGE_CLASS_NAME + "-dismiss"} click={this._dismissCallback} transparent={true} borders={false}>
            {t(T_ERROR_MESSAGE_DISMISS, translationParams)}
          </Button>
        ) : null}

        {this._getMessageActionCallback() ? (
          <Button className={ERROR_MESSAGE_CLASS_NAME + "-action"} click={this._actionCallback} borders={false} type={ButtonType.DEFAULT}>
            {t(this._getActionLabel(), translationParams)}
          </Button>
        ) : null}
      </div>
    );
  }

  private onAction() {
    const actionCallback = this._getMessageActionCallback();

    if (actionCallback) {
      try {
        actionCallback();
      } catch (err) {
        LOG.error("ErrorMessage: Error: ", err);
      }
    } else if (this.props.message) {
      LOG.warn("ErrorMessage: Warning! No action defined.");
    }
  }

  private onDismiss() {
    const dismiss = this.props?.dismiss ?? undefined;
    const messageId = this._getMessageId();

    if (dismiss) {
      try {
        dismiss();
      } catch (err) {
        LOG.error("ErrorMessage: Error: ", err);
      }
    } else if (messageId) {
      MessageService.removeMessageById(messageId);
    } else {
      LOG.debug("ErrorMessage: Warning! No dismiss handler defined for message: ", this.props);
    }
  }
}

export default ErrorMessage;
