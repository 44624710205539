// Copyright © 2020 HMD Global. All rights reserved.

import React from "react";
import { map } from "../../../modules/lodash";
import "./showcase.scss";
import { SHOWCASE_CLASS_NAME } from "../../../constants/classNames";
import Icon from "../icon/Icon";
import Button, { ButtonClickCallback } from "../button/Button";
import PageButtons from "../pageButtons/PageButtons";
import { IconType } from "../icon/IconType";

const SHOWCASE_TIMER_INTERVAL = 5000;

export type ShowcaseLinkCallback = ButtonClickCallback;

export interface ShowcasePageLinkCallback {
  (index: number): void;
}

export interface ShowcaseProps {
  className?: string;
  children?: any;
}

export interface ShowcaseState {
  page: number;
}

export class Showcase extends React.Component<ShowcaseProps, ShowcaseState> {
  static defaultProps: Partial<ShowcaseProps> = {};

  private readonly onPrevLink: ShowcaseLinkCallback;
  private readonly onNextLink: ShowcaseLinkCallback;
  private readonly onPageLink: ShowcasePageLinkCallback;
  private timer: any;

  constructor(props: ShowcaseProps) {
    super(props);

    this.state = { page: 0 };

    this.onPrevLink = this._onPrevLink.bind(this);
    this.onNextLink = this._onNextLink.bind(this);
    this.onPageLink = this._onPageLink.bind(this);
    this.timer = undefined;
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState({ page: this._nextButtonEnabled() ? this.state.page + 1 : 0 });
    }, SHOWCASE_TIMER_INTERVAL);
  }

  componentWillUnmount() {
    if (this.timer !== undefined) {
      clearInterval(this.timer);
      this.timer = undefined;
    }
  }

  render() {
    const children = React.Children.toArray(this.props.children);

    const totalPages = children.length;

    return (
      <div className={SHOWCASE_CLASS_NAME + " " + (this.props.className ?? "")}>
        <div className={SHOWCASE_CLASS_NAME + "-pages"}>
          {map(children, (child, index) => {
            const className = SHOWCASE_CLASS_NAME + "-page" + (this.state.page === index ? " " + SHOWCASE_CLASS_NAME + "-visible-page" : "");

            return (
              <div key={index} className={className}>
                {child}
              </div>
            );
          })}
        </div>

        {totalPages >= 2 ? (
          <div className={SHOWCASE_CLASS_NAME + "-controls"}>
            <PageButtons className={SHOWCASE_CLASS_NAME + "-page-icons"} page={this.state.page} totalPages={totalPages} click={this.onPageLink} />
            <div className={SHOWCASE_CLASS_NAME + "-empty-content"} />
            <div className={SHOWCASE_CLASS_NAME + "-buttons"}>
              <Button
                className={
                  SHOWCASE_CLASS_NAME +
                  "-button " +
                  SHOWCASE_CLASS_NAME +
                  "-prev-button " +
                  SHOWCASE_CLASS_NAME +
                  (this._prevButtonEnabled() ? "-button-enabled" : "-button-disabled")
                }
                click={this.onPrevLink}
              >
                <Icon type={IconType.ARROW_LEFT} label="Previous page" />
              </Button>

              <Button
                className={
                  SHOWCASE_CLASS_NAME +
                  "-button " +
                  SHOWCASE_CLASS_NAME +
                  "-next-button " +
                  SHOWCASE_CLASS_NAME +
                  (this._nextButtonEnabled() ? "-button-enabled" : "-button-disabled")
                }
                click={this.onNextLink}
              >
                <Icon type={IconType.ARROW_RIGHT} label="Next page" />
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  private _nextButtonEnabled() {
    return this.state.page + 1 < React.Children.count(this.props.children);
  }

  private _prevButtonEnabled() {
    return this.state.page - 1 >= 0;
  }

  private _onNextLink() {
    if (!this._nextButtonEnabled()) return;

    this.setState({
      page: this.state.page + 1,
    });
  }

  private _onPrevLink() {
    if (!this._prevButtonEnabled()) return;

    this.setState({
      page: this.state.page - 1,
    });
  }

  private _onPageLink(index: number) {
    this.setState({ page: index });
  }
}
