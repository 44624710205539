// Copyright © 2020, 2021 HMD Global. All rights reserved.

import { isString, trim } from "../modules/lodash";

export enum LogLevel {
  NONE,
  ERROR,
  WARN,
  INFO,
  DEBUG,
  TRACE,
  ALL,
}

export function isLogLevel(value: any): value is LogLevel {
  switch (value) {
    case LogLevel.NONE:
    case LogLevel.ERROR:
    case LogLevel.WARN:
    case LogLevel.INFO:
    case LogLevel.DEBUG:
    case LogLevel.TRACE:
    case LogLevel.ALL:
      return true;

    default:
      return false;
  }
}

export function parseLogLevel(value: any): LogLevel | undefined {
  if (isString(value)) {
    switch (trim(value).toUpperCase()) {
      case "NONE":
        return LogLevel.NONE;

      case "ERR":
      case "ERROR":
        return LogLevel.ERROR;

      case "WARNING":
      case "WARN":
        return LogLevel.WARN;

      case "INFO":
        return LogLevel.INFO;
      case "DEBUG":
        return LogLevel.DEBUG;
      case "TRACE":
        return LogLevel.TRACE;

      case "ALL":
        return LogLevel.ALL;
    }
    return undefined;
  }

  if (isLogLevel(value)) {
    return value;
  }

  return undefined;
}

export function stringifyLogLevel(value: LogLevel): string {
  switch (value) {
    case LogLevel.NONE:
      return `NONE`;
    case LogLevel.ERROR:
      return `ERROR`;
    case LogLevel.WARN:
      return `WARN`;
    case LogLevel.INFO:
      return `INFO`;
    case LogLevel.DEBUG:
      return `DEBUG`;
    case LogLevel.TRACE:
      return `TRACE`;
    case LogLevel.ALL:
      return `ALL`;

    default:
      throw new TypeError(`Not a log level: ${value}`);
  }
}

export default LogLevel;
