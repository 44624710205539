// Copyright © 2020-2021 HMD Global. All rights reserved.

import { FrontendAccessKey } from "../constants/FrontendAccessKey";
import UserRole from "./UserRole";
import PermissionKey from "../constants/PermissionKey";
import { FRONTEND_PERMISSIONS, USER_ROLE_PERMISSIONS } from "../constants/permissions";
import { has, some } from "../modules/lodash";
import ProfileService from "./ProfileService";

export class AuthorizationService {
  public static getPermissionKeysForUserRole(role: UserRole): PermissionKey[] {
    if (has(USER_ROLE_PERMISSIONS, role)) {
      return [...USER_ROLE_PERMISSIONS[role]];
    }
    return [];
  }

  public static getPermissionKeysForFrontendAccessKey(role: FrontendAccessKey): PermissionKey[] {
    if (has(FRONTEND_PERMISSIONS, role)) {
      return [...FRONTEND_PERMISSIONS[role]];
    }
    return [];
  }

  public static getCurrentPermissionKeys() {
    return AuthorizationService.getPermissionKeysForUserRole(ProfileService.getUserRole());
  }

  public static hasAccess(key: FrontendAccessKey): boolean {
    const permissionsToCheck: PermissionKey[] = AuthorizationService.getPermissionKeysForFrontendAccessKey(key);

    const activePermissions: PermissionKey[] = AuthorizationService.getCurrentPermissionKeys();

    return some(permissionsToCheck, (item: PermissionKey): boolean => {
      return some(activePermissions, (item2: PermissionKey): boolean => item === item2);
    });
  }
}

export default AuthorizationService;
