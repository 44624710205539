// Copyright © 2020 HMD Global. All rights reserved.

import { MapCoordinate } from "../components/common/gmap/Gmap";
import SortDirection from "./SortDirection";
import { Language } from "../translations/Language";
import { FRONTEND_CHINESE_LANG_ENABLED } from "./environment";

export const DEFAULT_LANGUAGE: Language = Language.ENGLISH;

// Password length is limited to 4-16 characters in Android device
export const DEVICE_PASSWORD_MIN_LENGTH = 4;
export const DEVICE_PASSWORD_MAX_LENGTH = 16;
export const POLICY_COMPLEX_PASSWORD_REQUIREMENT_CHARACTER_LIMIT = 2;

export const GOOGLE_MAP_API_KEY = "AIzaSyDV7qb4510sDtaW-SaSvFRfjTfdduqQ-uQ";

export const HELP_QUICK_START_GUIDE_PDF_URL =
  "https://hmdneuenablepro.blob.core.windows.net/guide/HMD_Enable_Pro_Quick_Start_Guide.pdf?sv=2020-10-02&st=2022-03-24T14%3A55%3A00Z&se=2050-01-26T14%3A55%3A00Z&sr=b&sp=r&sig=eaUhx1%2FR9r4T%2F%2FTkf%2BQCmSdOS8R4Sa0W2cPoNVKS8%2FA%3D";

export const HELP_KNOWLEDGE_HUB_LINK = "https://eu1.hubs.ly/H02fJwd0";
export const HELP_CONTACT_US_SUPPORT_TICKET = "https://eu1.hubs.ly/H026Sj90";
export const LOGIN_HELP_KNOWLEDGE_HUB_LINK = "https://eu1.hubs.ly/H02djvC0";

export const YOUTUBE_DATA_API_KEY = "AIzaSyBUqliCPLVJ-fRbsUfJL-OvuK5zz4kxDTY";
export const YOUTUBE_PLAYLIST_ID = "PLIUQueLzOmXqJ2Ds2m_qTSBsFhQgmD5rS";
export const YOUTUBE_DATA_API_URL = "https://youtube.googleapis.com/youtube/v3/";
export const YOUTUBE_PLAYLIST_ITEMS_PARAMETERS = "playlistItems?part=snippet&maxResults=25&playlistId=";
export const YOUTUBE_CHANNEL_PLAYLISTS_PARAMETERS = "playlists?part=snippet&maxResults=25&channelId=";

export const DEFAULT_MAP_COORDINATES: MapCoordinate = { lat: 61, lng: 23 };

export const DEFAULT_MAP_ZOOM = 12;

export const DEFAULT_SORT_DIRECTION = SortDirection.ASC;

export const GOOGLE_PLAY_APPROVE_APPS_LINK = "https://play.google.com/work/apps";

export const GOOGLE_PLAY_AUTHENTICATOR_LINK = "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2";

export const SUPPORT_EMAIL = "hmdenableprosupport@hmdglobal.com";

export const DEFAULT_TEXTFIELD_MAXLENGTH = 50;

const STANDARD_LANGUAGES: Language[] = [Language.ENGLISH, Language.FRENCH, Language.PORTUGUESE, Language.SPANISH];

/**
 * You can change this to test other language setups
 *
 * If only one language is in the list, the UI will not display the language change at all.
 */
export const ENABLED_LANGUAGES: Language[] = FRONTEND_CHINESE_LANG_ENABLED ? [...STANDARD_LANGUAGES, Language.CHINESE] : STANDARD_LANGUAGES;

export const FRONTEND_UNAUTHORIZED_BUNDLE_PATH = "/index.html";
export const FRONTEND_AUTHORIZED_BUNDLE_PATH = "/authorized.html";
export const FRONTEND_ADMIN_BUNDLE_PATH = "/admin.html";

export const DEVICE_GROUP_LIMIT_L = 600; //updating devices takes 600s = 10min if 1s/device
export const DEVICE_GROUP_LIMIT_XL = 2700; //updating devices takes 2700s = 45min if 1s/device
export const DEVICE_GROUP_LIMIT_XXL = 6000; //updating devices takes 6000s = 1h 40min if 1s/device

//Android limit for geofences is 100. One action = one geofence, so 5 areas with 10 actions = 50 geofences
export const GEOFENCE_MAX_AREAS = 5;
export const GEOFENCE_MAX_ACTIONS = 10;
