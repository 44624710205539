// Copyright © 2020 HMD Global. All rights reserved.

import * as React from "react";
import "./PageButtons.scss";
import { PAGE_BUTTONS_CLASS_NAME } from "../../../constants/classNames";
import { map, range } from "../../../modules/lodash";
import Button, { ButtonClickCallback } from "../button/Button";
import LogService from "../../../services/LogService";

const LOG = LogService.createLogger("PageButtons");

export interface PageButtonsClickCallback {
  (index: number): void;
}

export interface PageValidCallback {
  (index: number): boolean;
}

export interface PageIconsProps {
  className?: string;
  click: PageButtonsClickCallback;
  page: number;
  totalPages: number;
  isPageValid?: PageValidCallback;
}

export interface PageIconsState {
  pageCallbacks: Array<ButtonClickCallback>;
}

export class PageButtons extends React.Component<PageIconsProps, PageIconsState> {
  private _onPageLinkCallback: PageButtonsClickCallback;
  private _pageCallbacksCallback: Array<ButtonClickCallback>;

  constructor(props: PageIconsProps) {
    super(props);

    this._pageCallbacksCallback = [];

    this.state = {
      pageCallbacks: this._pageCallbacksCallback,
    };

    this._onPageLinkCallback = this._onPageLink.bind(this);
  }

  componentDidMount() {
    this._updatePageCallbacks();
  }

  componentDidUpdate(prevProps: Readonly<PageIconsProps>) {
    if (this.props.totalPages !== prevProps.totalPages) {
      this._updatePageCallbacks();
    }
  }

  render() {
    const pageIcons = map(this._pageCallbacksCallback, (callback: ButtonClickCallback, index: number) => {
      return (
        <Button
          className={PAGE_BUTTONS_CLASS_NAME + "-page " + PAGE_BUTTONS_CLASS_NAME + "-page-" + (this._isPageValid(index) ? "valid" : "not-valid")}
          key={"page:" + index}
          borders={false}
          click={callback}
        >
          <div className={PAGE_BUTTONS_CLASS_NAME + (this.props.page === index ? "-current" : "-other")} />
        </Button>
      );
    });

    LOG.debug("render: pageIcons: ", pageIcons);

    return <div className={PAGE_BUTTONS_CLASS_NAME + " " + (this.props.className ?? "")}>{pageIcons}</div>;
  }

  private _updatePageCallbacks() {
    const totalPages = this.props.totalPages;

    LOG.debug("_updatePageCallbacks: totalPages: ", totalPages);

    if (totalPages >= 1) {
      this._pageCallbacksCallback = map(range(0, totalPages), (index: number) => {
        return this._onPageLinkCallback.bind(undefined, index);
      });

      if (this._pageCallbacksCallback.length <= 0) {
        LOG.warn("No pages to display page buttons.");
      }
    } else {
      this._pageCallbacksCallback = [];

      LOG.debug("No pages to display page buttons.");
    }

    if (this.state.pageCallbacks !== this._pageCallbacksCallback) {
      this.setState({
        ...this.state,
        pageCallbacks: this._pageCallbacksCallback,
      });
    }
  }

  private _isPageValid(page: number) {
    if (this.props.isPageValid) {
      return this.props.isPageValid(page);
    }

    return true;
  }

  private _onPageLink(index: number) {
    try {
      this.props.click(index);
    } catch (err) {
      LOG.error("Exception in click handler: ", err);
    }
  }
}

export default PageButtons;
