// Copyright © 2020, 2021 HMD Global. All rights reserved.

import { IS_DEVELOPMENT_BUILD } from "../../constants/environment";
import LogLevel from "../../types/LogLevel";
import RemoteLogQueue from "../RemoteLogQueue";
import { map } from "../../modules/lodash";
import { LoggableVariable } from "../../types/LogMessage";
import { FRONTEND_ENABLE_DEBUG_LOG_ON_PRODUCTION, FRONTEND_LOG_SERVER_DEBUG_LEVEL_ENABLED, FRONTEND_LOG_SERVER_ENABLED } from "../../constants/environment";

export class Logger {
  private readonly _console: any;
  private readonly _name: string;
  private readonly _logLevel: LogLevel;

  constructor(name: string, console: any, logLevel: LogLevel) {
    this._name = name;
    this._console = console;
    this._logLevel = logLevel;
  }

  public debug(...msg: Array<any>) {
    if (IS_DEVELOPMENT_BUILD || FRONTEND_ENABLE_DEBUG_LOG_ON_PRODUCTION) {
      if (this._logLevel >= LogLevel.DEBUG) {
        this._writeLocalLog(LogLevel.DEBUG, msg);

        if (FRONTEND_LOG_SERVER_DEBUG_LEVEL_ENABLED) {
          this._writeRemoteLog(LogLevel.DEBUG, msg);
        }
      }
    }
  }

  public info(...msg: Array<any>) {
    if (this._logLevel >= LogLevel.INFO) {
      this._writeLocalLog(LogLevel.INFO, msg);
      this._writeRemoteLog(LogLevel.INFO, msg);
    }
  }

  public warn(...msg: Array<any>) {
    if (this._logLevel >= LogLevel.WARN) {
      this._writeLocalLog(LogLevel.WARN, msg);
      this._writeRemoteLog(LogLevel.WARN, msg);
    }
  }

  public error(...msg: Array<any>) {
    if (this._logLevel >= LogLevel.ERROR) {
      this._writeLocalLog(LogLevel.ERROR, msg);
      this._writeRemoteLog(LogLevel.ERROR, msg);
    }
  }

  private _writeRemoteLog(level: LogLevel, msg: Array<any>) {
    if (FRONTEND_LOG_SERVER_ENABLED) {
      try {
        const now = new Date();

        RemoteLogQueue.addMessage({
          time: now.toISOString(),
          level,
          content: [`[${this._name}]`, ...Logger.toLoggableArray(msg)],
        });
      } catch (err) {
        this._console.error("Error while writing remote log message: ", err);
      }
    }
  }

  private _writeLocalLog(level: LogLevel, msg: Array<any>) {
    try {
      if (this._console) {
        switch (level) {
          case LogLevel.DEBUG:
            if (this._console?.debug) {
              this._console.debug(`[${this._name}]`, ...msg);
            }
            return;

          case LogLevel.INFO:
            if (this._console?.info) {
              this._console.info(`[${this._name}]`, ...msg);
            }
            return;

          case LogLevel.WARN:
            if (this._console?.warn) {
              this._console.warn(`[${this._name}]`, ...msg);
            }
            return;

          case LogLevel.ERROR:
            if (this._console?.error) {
              this._console.error(`[${this._name}]`, ...msg);
            }
            return;
        }
      }
    } catch (err) {
      this._console.error("Error while writing local log message: ", err);
    }
  }

  /**
   * This function will change unspecified array into a format which can be sent over to the remote server.
   *
   * Eg. it will remove circular references.
   *
   * @param items
   */
  public static toLoggableArray(items: Array<any>): LoggableVariable[] {
    return map(items, (item: any) => Logger._safeJsonParse(Logger._safeJsonStringify(item)));
  }

  private static _safeJsonParse(input: string): any {
    try {
      return JSON.parse(input);
    } catch (err) {
      console.error("Could not parse value -- not JSON: ", input, err);
    }
  }

  private static _safeJsonStringify(input: any): string {
    try {
      if (input === undefined) {
        return JSON.stringify("undefined");
      }

      const objectCache: any = [];

      return JSON.stringify(input, (key: string, value: any) => {
        if (value === undefined) {
          return "undefined";
        }

        if (typeof value === "object") {
          // Duplicate reference found, discard key
          const cacheIndex = objectCache.indexOf(value);

          if (cacheIndex >= 0) {
            return `CircularRef#${cacheIndex}`;
          }

          // Store value in our collection
          objectCache.push(value);
        }

        return value;
      });
    } catch (err) {
      console.error("Error while preparing log message: ", err);
      return JSON.stringify(`${input}`);
    }
  }
}

export default Logger;
